import { Auth0Client } from "@auth0/auth0-spa-js";

import { userProfiles } from "@/mixins/userProfiles.js";
import { googleRecaptcha } from "@/mixins/googleRecaptcha.js";

import router from "../router/index.js";

export const socialAuth = {
  mixins: [userProfiles, googleRecaptcha],

  data() {
    return {
      socialAuth0Client: new Auth0Client({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        clientId: process.env.VUE_APP_AUTH0_CLIENTID,
        authorizationParams: {
          audience: process.env.VUE_APP_AUDIENCE,
          redirect_uri: `${window.location.origin}/callback`,
        },
        scope: "openid profile email",
        cacheLocation: "localstorage",
      }),
      socialAuthError: false,
      socialServerError: false,
      socialAuthLoading: false,
    };
  },

  computed: {
    socialExpiresAt: {
      get: () => localStorage.getItem("social_expires_at"),
      set: expiresIn => {
        const socialExpiresAt = JSON.stringify(Number(expiresIn) * 1000);
        localStorage.setItem("social_expires_at", socialExpiresAt);
      },
    },
  },

  methods: {
    async socialLogin() {
      this.socialAuthLoading = true;
      const isHuman = await this.verifyRecaptcha("social_login");
      if (isHuman) {
        this.socialAuthLoading = false;
        this.socialAuth0Client.loginWithRedirect({
          authorizationParams: {
            connection: "google-oauth2",
            redirect_uri: `${window.location.origin}/callback`,
          },
        });
      }
      this.socialAuthLoading = false;
    },

    async handleRedirectCallback() {
      await this.socialAuth0Client.handleRedirectCallback();
      const tokenClaims = await this.socialAuth0Client.getIdTokenClaims();
      const { email, __raw: socialAccessToken } = tokenClaims;
      this.socialExpiresAt = tokenClaims.exp;
      localStorage.setItem("social_access_token", socialAccessToken);
      localStorage.setItem("userEmail", email);
      await this.setUserMainProfile({ email });
      router.push({ name: "home" });
    },

    socialAuthenticated() {
      return (localStorage.getItem("social_access_token") && this.socialExpiresAt) &&
        (new Date().getTime() < Number(this.socialExpiresAt));
    },
  },
};
