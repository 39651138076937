export default {
  data() {
    return {
      availableGenders: [
        { sex: "Masculino", value: "M" },
        { sex: "Femenino", value: "F" },
      ],

      availableMaritalStatus: [
        { status: "Soltero", value: "SINGLE" },
        { status: "Casado", value: "MARRIED" },
        { status: "Divorciado", value: "DIVORCED" },
        { status: "Viudo", value: "WIDOWER" },
        { status: "Unión Civil", value: "CIVIL_UNION" },
      ],

      conjugalRegimeOptions: {
        MARRIED: [
          { code: "SEPARATED_PROPERTY", default: true },
          { code: "MARITAL_PROPERTY", default: false },
          { code: "CONJUGAL_SOCIETY", default: false },
        ],

        CIVIL_UNION: [
          { code: "SEPARATED_PROPERTY", default: true },
          { code: "COMMUNITY_PROPERTY", default: false },
        ],
      },

      netWorthOptions: [
        { value: "LT_10" },
        { value: "BT_10_100" },
        { value: "BT_100_300" },
        { value: "GT_300" },
      ],

      salaryRangeOptions: [
        { value: "LT_2" },
        { value: "BT_2_5" },
        { value: "GT_5" },
      ],

      countries: [
        { country: "Andorra", code: "AND" },
        { country: "Emiratos Árabes Unidos", code: "ARE" },
        { country: "Afganistán", code: "AFG" },
        { country: "Antigua y Barbuda", code: "ATG" },
        { country: "Anguila", code: "AIA" },
        { country: "Albania", code: "ALB" },
        { country: "Alemania", code: "DEU" },
        { country: "Andorra", code: "AND" },
        { country: "Angola", code: "AGO" },
        { country: "Anguila", code: "AIA" },
        { country: "Antigua y Barbuda", code: "ATG" },
        { country: "Antártida", code: "ATA" },
        { country: "Arabia Saudita", code: "SAU" },
        { country: "Argelia", code: "DZA" },
        { country: "Argentina", code: "ARG" },
        { country: "Armenia", code: "ARM" },
        { country: "Australia", code: "AUS" },
        { country: "Austria", code: "AUT" },
        { country: "Azerbaiyán", code: "AZE" },
        { country: "Bahamas", code: "BHS" },
        { country: "Bangladés", code: "BGD" },
        { country: "Barbados", code: "BRB" },
        { country: "Baréin", code: "BHR" },
        { country: "Belice", code: "BLZ" },
        { country: "Benín", code: "BEN" },
        { country: "Bermudas", code: "BMU" },
        { country: "Bielorrusia", code: "BLR" },
        { country: "Birmania", code: "MMR" },
        { country: "Bolivia", code: "BOL" },
        { country: "Bosnia-Herzegovina", code: "BIH" },
        { country: "Botsuana", code: "BWA" },
        { country: "Brasil", code: "BRA" },
        { country: "Brunéi", code: "BRN" },
        { country: "Bulgaria", code: "BGR" },
        { country: "Burkina Faso", code: "BFA" },
        { country: "Burundi", code: "BDI" },
        { country: "Bután", code: "BTN" },
        { country: "Bélgica", code: "BEL" },
        { country: "Cabo Verde", code: "CPV" },
        { country: "Camboya", code: "KHM" },
        { country: "Camerún", code: "CMR" },
        { country: "Canadá", code: "CAN" },
        { country: "Caribe Neerlandés", code: "BES" },
        { country: "Catar", code: "QAT" },
        { country: "Chad", code: "TCD" },
        { country: "Chile", code: "CHL" },
        { country: "Chipre", code: "CYP" },
        { country: "Ciudad del Vaticano", code: "VAT" },
        { country: "Colombia", code: "COL" },
        { country: "Comoras", code: "COM" },
        { country: "Corea", code: "KOR" },
        { country: "Costa Rica", code: "CRI" },
        { country: "Costa de Marfil", code: "CIV" },
        { country: "Croacia", code: "HRV" },
        { country: "Cuba", code: "CUB" },
        { country: "Dinamarca", code: "DNK" },
        { country: "Dinastía Joseon", code: "PRK" },
        { country: "Dominica", code: "DMA" },
        { country: "Ecuador", code: "ECU" },
        { country: "Egipto", code: "EGY" },
        { country: "El Salvador", code: "SLV" },
        { country: "Emiratos Árabes Unidos", code: "ARE" },
        { country: "Eritrea", code: "ERI" },
        { country: "Eslovaquia", code: "SVK" },
        { country: "Eslovenia", code: "SVN" },
        { country: "España", code: "ESP" },
        { country: "Estados Federados de Micronesia", code: "FSM" },
        { country: "Estados Unidos", code: "USA" },
        { country: "Estonia", code: "EST" },
        { country: "Etiopía", code: "ETH" },
        { country: "Filipinas", code: "PHL" },
        { country: "Finlandia", code: "FIN" },
        { country: "Fiyi", code: "FJI" },
        { country: "Francia", code: "FRA" },
        { country: "Gabón", code: "GAB" },
        { country: "Gambia", code: "GMB" },
        { country: "Georgia", code: "GEO" },
        { country: "Ghana", code: "GHA" },
        { country: "Gibraltar", code: "GIB" },
        { country: "Granada", code: "GRD" },
        { country: "Grecia", code: "GRC" },
        { country: "Groenlandia", code: "GRL" },
        { country: "Guadalupe", code: "GLP" },
        { country: "Guam", code: "GUM" },
        { country: "Guatemala", code: "GTM" },
        { country: "Guayana Francesa", code: "GUF" },
        { country: "Guernsey", code: "GGY" },
        { country: "Guinea", code: "GIN" },
        { country: "Guinea Ecuatorial", code: "GNQ" },
        { country: "Guinea-Bisáu", code: "GNB" },
        { country: "Guyana", code: "GUY" },
        { country: "Haití", code: "HTI" },
        { country: "Honduras", code: "HND" },
        { country: "Hong Kong", code: "HKG" },
        { country: "Hungría", code: "HUN" },
        { country: "India", code: "IND" },
        { country: "Indias Orientales Neerlandesas", code: "IDN" },
        { country: "Irak", code: "IRQ" },
        { country: "Irlanda", code: "IRL" },
        { country: "Irán", code: "IRN" },
        { country: "Isla de Man", code: "IMN" },
        { country: "Islandia", code: "ISL" },
        { country: "Islas Caimán", code: "CYM" },
        { country: "Islas Cook", code: "COK" },
        { country: "Islas Feroe", code: "FRO" },
        { country: "Islas Georgias del Sur y Sandwich del Sur", code: "SGS" },
        { country: "Islas Malvinas", code: "FLK" },
        { country: "Islas Marianas del Norte", code: "MNP" },
        { country: "Islas Marshall", code: "MHL" },
        { country: "Islas Pitcairn", code: "PCN" },
        { country: "Islas Salomón", code: "SLB" },
        { country: "Islas Turcas y Caicos", code: "TCA" },
        { country: "Islas Ultramarinas Menores de Estados Unidos", code: "UMI" },
        { country: "Islas Vírgenes Británicas", code: "VGB" },
        { country: "Islas Vírgenes de los Estados Unidos", code: "VIR" },
        { country: "Israel", code: "ISR" },
        { country: "Italia", code: "ITA" },
        { country: "Jamaica", code: "JAM" },
        { country: "Japón", code: "JPN" },
        { country: "Jersey", code: "JEY" },
        { country: "Jordania", code: "JOR" },
        { country: "Kazajistán", code: "KAZ" },
        { country: "Kenia", code: "KEN" },
        { country: "Kirguistán", code: "KGZ" },
        { country: "Kiribati", code: "KIR" },
        { country: "Kuwait", code: "KWT" },
        { country: "Laos", code: "LAO" },
        { country: "Lesoto", code: "LSO" },
        { country: "Letonia", code: "LVA" },
        { country: "Liberia", code: "LBR" },
        { country: "Libia", code: "LBY" },
        { country: "Liechtenstein", code: "LIE" },
        { country: "Lituania", code: "LTU" },
        { country: "Luxemburgo", code: "LUX" },
        { country: "Líbano", code: "LBN" },
        { country: "Macao", code: "MAC" },
        { country: "Madagascar", code: "MDG" },
        { country: "Malasia", code: "MYS" },
        { country: "Malaui", code: "MWI" },
        { country: "Maldivas", code: "MDV" },
        { country: "Malta", code: "MLT" },
        { country: "Malí", code: "MLI" },
        { country: "Marruecos", code: "MAR" },
        { country: "Martinica", code: "MTQ" },
        { country: "Mauricio", code: "MUS" },
        { country: "Mauritania", code: "MRT" },
        { country: "Mayotte", code: "MYT" },
        { country: "Moldavia", code: "MDA" },
        { country: "Mongolia", code: "MNG" },
        { country: "Monserrat", code: "MSR" },
        { country: "Montenegro", code: "MNE" },
        { country: "Mozambique", code: "MOZ" },
        { country: "México", code: "MEX" },
        { country: "Mónaco", code: "MCO" },
        { country: "Namibia", code: "NAM" },
        { country: "Nauru", code: "NRU" },
        { country: "Nepal", code: "NPL" },
        { country: "Nicaragua", code: "NIC" },
        { country: "Nigeria", code: "NGA" },
        { country: "Niue", code: "NIU" },
        { country: "Noruega", code: "NOR" },
        { country: "Nueva Zelanda", code: "NZL" },
        { country: "Níger", code: "NER" },
        { country: "Omán", code: "OMN" },
        { country: "Pakistán", code: "PAK" },
        { country: "Palaos", code: "PLW" },
        { country: "Palestina", code: "PSE" },
        { country: "Panamá", code: "PAN" },
        { country: "Papúa Nueva Guinea", code: "PNG" },
        { country: "Paraguay", code: "PRY" },
        { country: "Países Bajos", code: "NLD" },
        { country: "Perú", code: "PER" },
        { country: "Polonia", code: "POL" },
        { country: "Portugal", code: "PRT" },
        { country: "Puerto Rico", code: "PRI" },
        { country: "Reino Unido", code: "GBR" },
        { country: "República Centroafricana", code: "CAF" },
        { country: "República Checa", code: "CZE" },
        { country: "República Democrática del Congo", code: "COD" },
        { country: "República Dominicana", code: "DOM" },
        { country: "República Popular China", code: "CHN" },
        { country: "República de China", code: "TWN" },
        { country: "República de Macedonia", code: "MKD" },
        { country: "República del Congo", code: "COG" },
        { country: "Reunión", code: "REU" },
        { country: "Ruanda", code: "RWA" },
        { country: "Rumania", code: "ROU" },
        { country: "Rusia", code: "RUS" },
        { country: "Samoa", code: "WSM" },
        { country: "Samoa Americana", code: "ASM" },
        { country: "San Cristóbal y Nieves", code: "KNA" },
        { country: "San Marino", code: "SMR" },
        { country: "San Pedro y Miquelón", code: "SPM" },
        { country: "San Vicente y las Granadinas", code: "VCT" },
        { country: "Santa Elena", code: "SHN" },
        { country: "Santa Lucía", code: "LCA" },
        { country: "Santo Tomé y Príncipe", code: "STP" },
        { country: "Senegal", code: "SEN" },
        { country: "Serbia", code: "SRB" },
        { country: "Seychelles", code: "SYC" },
        { country: "Sierra Leona", code: "SLE" },
        { country: "Singapur", code: "SGP" },
        { country: "Siria", code: "SYR" },
        { country: "Somalia", code: "SOM" },
        { country: "Sri Lanka", code: "LKA" },
        { country: "Suazilandia", code: "SWZ" },
        { country: "Sudáfrica", code: "ZAF" },
        { country: "Sudán", code: "SDN" },
        { country: "Sudán del Sur", code: "SSD" },
        { country: "Suecia", code: "SWE" },
        { country: "Suiza", code: "CHE" },
        { country: "Surinam", code: "SUR" },
        { country: "Tailandia", code: "THA" },
        { country: "Tanzania", code: "TZA" },
        { country: "Tayikistán", code: "TJK" },
        { country: "Territorio Británico del Océano Índico", code: "IOT" },
        { country: "Tierras Australes y Antárticas Francesas", code: "ATF" },
        { country: "Timor Oriental", code: "TLS" },
        { country: "Togo", code: "TGO" },
        { country: "Tokelau", code: "TKL" },
        { country: "Tonga", code: "TON" },
        { country: "Trinidad y Tobago", code: "TTO" },
        { country: "Turkmenistán", code: "TKM" },
        { country: "Turquía", code: "TUR" },
        { country: "Tuvalu", code: "TUV" },
        { country: "Túnez", code: "TUN" },
        { country: "Ucrania", code: "UKR" },
        { country: "Uganda", code: "UGA" },
        { country: "Uruguay", code: "URY" },
        { country: "Uzbekistán", code: "UZB" },
        { country: "Vanuatu", code: "VUT" },
        { country: "Venezuela", code: "VEN" },
        { country: "Vietnam", code: "VNM" },
        { country: "Wallis y Futuna", code: "WLF" },
        { country: "Yemen", code: "YEM" },
        { country: "Yibuti", code: "DJI" },
        { country: "Zambia", code: "ZMB" },
        { country: "Zimbabue", code: "ZWE" }],
      bankNames: [
        { name: "Banco de Chile", code: 1 },
        { name: "Banco Internacional", code: 2 },
        { name: "Scotiabank Chile", code: 3 },
        { name: "Banco de Crédito e Inversiones (BCI)", code: 4 },
        { name: "Banco Bice", code: 5 },
        { name: "Banco Santander-Chile", code: 6 },
        { name: "Itaú Corpbanca", code: 7 },
        { name: "Banco Security", code: 8 },
        { name: "Banco Falabella", code: 9 },
        { name: "Banco Ripley", code: 10 },
        { name: "Banco Consorcio", code: 11 },
        { name: "Banco Estado", code: 12 },
        { name: "Banco Coopeuch", code: 13 },
        // { name: "MACH", code: 14 },
        { name: "TENPO", code: 15 },
      ],
      availableBankAccountTypes: [
        { type: "Cuenta Corriente", code: 1 },
        { type: "Cuenta Vista", code: 2 },
        { type: "Chequera Eléctronica", code: 3 },
        { type: "Cuenta de Ahorro", code: 4 },
      ],
      professions: [
        { value: "Abogado", label: "Abogado" },
        { value: "Actor", label: "Actor" },
        { value: "Administrador pub", label: "Administrador pub" },
        { value: "Administrativo", label: "Administrativo" },
        { value: "Agente", label: "Agente" },
        { value: "Agricultor", label: "Agricultor" },
        { value: "Agrónomo", label: "Agrónomo" },
        { value: "Alarife", label: "Alarife" },
        { value: "Albañil", label: "Albañil" },
        { value: "Analista", label: "Analista" },
        { value: "Analista de sistemas", label: "Analista de sistemas" },
        { value: "Antropólogo", label: "Antropólogo" },
        { value: "Apicultor", label: "Apicultor" },
        { value: "Arquitecto", label: "Arquitecto" },
        { value: "Arsenalera", label: "Arsenalera" },
        { value: "Artesano", label: "Artesano" },
        { value: "Asesor", label: "Asesor" },
        { value: "Asesora del hogar", label: "Asesora del hogar" },
        { value: "Asistente", label: "Asistente" },
        { value: "Asistente social", label: "Asistente social" },
        { value: "Auditor", label: "Auditor" },
        { value: "Auxiliar de párvulos", label: "Auxiliar de párvulos" },
        { value: "Auxiliar enfermería", label: "Auxiliar enfermería" },
        { value: "Auxiliar paramédico", label: "Auxiliar paramédico" },
        { value: "Auxiliar", label: "Auxiliar" },
        { value: "Ayudante", label: "Ayudante" },
        { value: "Barman", label: "Barman" },
        { value: "Bibliotecaria", label: "Bibliotecaria" },
        { value: "Bibliotecólogo", label: "Bibliotecólogo" },
        { value: "Biólogo", label: "Biólogo" },
        { value: "Biólogo marino", label: "Biólogo marino" },
        { value: "Biólogo químico", label: "Biólogo químico" },
        { value: "Bioquímico", label: "Bioquímico" },
        { value: "Biotecnólogo médico", label: "Biotecnólogo médico" },
        { value: "Bodeguero", label: "Bodeguero" },
        { value: "Buzo", label: "Buzo" },
        { value: "Cajera", label: "Cajera" },
        { value: "Camarera", label: "Camarera" },
        { value: "Camarógrafo", label: "Camarógrafo" },
        { value: "Capataz", label: "Capataz" },
        { value: "Carnicero", label: "Carnicero" },
        { value: "Carpintero", label: "Carpintero" },
        { value: "Cartero", label: "Cartero" },
        { value: "Chofer", label: "Chofer" },
        { value: "Cocinero", label: "Cocinero" },
        { value: "Comisionista", label: "Comisionista" },
        { value: "Comunicación audiovisual", label: "Comunicación audiovisual" },
        { value: "Comunicador social", label: "Comunicador social" },
        { value: "Concretero", label: "Concretero" },
        { value: "Conserje", label: "Conserje" },
        { value: "Constructor", label: "Constructor" },
        { value: "Constructor civil", label: "Constructor civil" },
        { value: "Contador", label: "Contador" },
        { value: "Contador auditor", label: "Contador auditor" },
        { value: "Contratista", label: "Contratista" },
        { value: "Corredor", label: "Corredor" },
        { value: "Cosmetóloga", label: "Cosmetóloga" },
        { value: "Costurera", label: "Costurera" },
        { value: "Cuidador", label: "Cuidador" },
        { value: "Decoración", label: "Decoración" },
        { value: "Dentista", label: "Dentista" },
        { value: "Desabollador", label: "Desabollador" },
        { value: "Dibujante", label: "Dibujante" },
        { value: "Dibujante técnico", label: "Dibujante técnico" },
        { value: "Digitador", label: "Digitador" },
        { value: "Diseñador", label: "Diseñador" },
        { value: "Diseñador ambiente", label: "Diseñador ambiente" },
        { value: "Diseñador gráfico", label: "Diseñador gráfico" }, // Se repite
        { value: "Diseñador de vestuario", label: "Diseñador de vestuario" },
        { value: "Economista", label: "Economista" },
        { value: "Educador de párvulos", label: "Educador de párvulos" },
        { value: "Egresado", label: "Egresado" },
        { value: "Ejecutivo", label: "Ejecutivo" },
        { value: "Electricista", label: "Electricista" },
        { value: "Electromecánico", label: "Electromecánico" },
        { value: "Electrónico", label: "Electrónico" },
        { value: "Encuadernador", label: "Encuadernador" },
        { value: "Enfermera", label: "Enfermera" },
        { value: "Enfermera obstetra", label: "Enfermera obstetra" },
        { value: "Enfierrador", label: "Enfierrador" },
        { value: "Entrenador", label: "Entrenador" },
        { value: "Estadístico", label: "Estadístico" },
        { value: "Estética", label: "Estética" },
        { value: "Estibador", label: "Estibador" },
        { value: "Estructurero", label: "Estructurero" },
        { value: "Factor de comercio", label: "Factor de comercio" },
        { value: "Farmacéutico", label: "Farmacéutico" },
        { value: "Fuerzas armadas", label: "Fuerzas armadas" }, // Se repite
        { value: "Carabinero", label: "Carabinero" },
        { value: "Fonoaudiólogo", label: "Fonoaudiólogo" },
        { value: "Forestal", label: "Forestal" },
        { value: "Fotógrafo", label: "Fotógrafo" },
        { value: "Futbolista", label: "Futbolista" },
        { value: "Garzón", label: "Garzón" },
        { value: "Gásfiter", label: "Gásfiter" },
        { value: "Gastronomía", label: "Gastronomía" },
        { value: "Gendarme", label: "Gendarme" },
        { value: "Geógrafo", label: "Geógrafo" },
        { value: "Geólogo", label: "Geólogo" },
        { value: "Gráfico", label: "Gráfico" },
        { value: "Guardia", label: "Guardia" },
        { value: "Hotelero", label: "Hotelero" },
        { value: "Industrial", label: "Industrial" },
        { value: "Ingeniero alimentación", label: "Ingeniero alimentación" },
        { value: "Ingeniero biotecnología", label: "Ingeniero biotecnología" },
        { value: "Ingeniero computación", label: "Ingeniero computación" },
        { value: "Ingeniero construcción", label: "Ingeniero construcción" },
        { value: "Ingeniero control gestión", label: "Ingeniero control gestión" },
        { value: "Ingeniero electrónico", label: "Ingeniero electrónico" },
        { value: "Ingeniero información", label: "Ingeniero información" },
        { value: "Ingeniero", label: "Ingeniero" },
        { value: "Ingeniero civil", label: "Ingeniero civil" },
        { value: "Ingeniero civil industrial", label: "Ingeniero civil industrial" },
        { value: "Ingeniero comercial", label: "Ingeniero comercial" },
        { value: "Ingeniero ejecución", label: "Ingeniero ejecución" },
        { value: "Ingeniero eléctrico", label: "Ingeniero eléctrico" },
        { value: "Ingeniero en acuicultura", label: "Ingeniero en acuicultura" },
        { value: "Ingeniero forestal", label: "Ingeniero forestal" },
        { value: "Ingeniero industrial", label: "Ingeniero industrial" },
        { value: "Ingeniero en informática", label: "Ingeniero en informática" },
        { value: "Ingeniero prevención", label: "Ingeniero prevención" },
        { value: "Ingeniero telecomunicaciones", label: "Ingeniero telecomunicaciones" },
        { value: "Inspector", label: "Inspector" },
        { value: "Instalador", label: "Instalador" },
        { value: "Instructor", label: "Instructor" },
        { value: "Jardinero", label: "Jardinero" },
        { value: "Jefe", label: "Jefe" },
        { value: "Jefe de obra", label: "Jefe de obra" },
        { value: "Joyero", label: "Joyero" },
        { value: "Jubilado", label: "Jubilado" },
        { value: "Junior", label: "Junior" },
        { value: "Kinesiólogo", label: "Kinesiólogo" },
        { value: "Laboratorista", label: "Laboratorista" },
        { value: "Laboratorista dental", label: "Laboratorista dental" },
        { value: "Labores agrícolas", label: "Labores agrícolas" },
        { value: "Licenciado en artes", label: "Licenciado en artes" },
        { value: "Licenciado en historia", label: "Licenciado en historia" },
        { value: "Licenciado en letras", label: "Licenciado en letras" },
        { value: "Licenciado", label: "Licenciado" },
        { value: "Liquidador", label: "Liquidador" },
        { value: "Maestro", label: "Maestro" },
        { value: "Maestro de cocina", label: "Maestro de cocina" },
        { value: "Manipulador de alimentos", label: "Manipulador de alimentos" },
        { value: "Maquinista", label: "Maquinista" },
        { value: "Marino", label: "Marino" },
        { value: "Matricero", label: "Matricero" },
        { value: "Matrón(a)", label: "Matrón(a)" },
        { value: "Mayordomo", label: "Mayordomo" },
        { value: "Mecánico", label: "Mecánico" },
        { value: "Mecánico automotriz", label: "Mecánico automotriz" },
        { value: "Mecánico industrial", label: "Mecánico industrial" },
        { value: "Médico", label: "Médico" },
        { value: "Minero", label: "Minero" }, // Se repite
        { value: "Modista", label: "Modista" },
        { value: "Monitor", label: "Monitor" },
        { value: "Motorista", label: "Motorista" },
        { value: "Mueblista", label: "Mueblista" },
        { value: "Músico", label: "Músico" },
        { value: "Ninguna", label: "Ninguna" },
        { value: "No informado", label: "No informado" },
        { value: "Nutricionista", label: "Nutricionista" },
        { value: "Obrero", label: "Obrero" }, // Se repite
        { value: "Obrero agrícola", label: "Obrero agrícola" },
        { value: "Obrero de la construcción", label: "Obrero de la construcción" },
        { value: "Obstetra", label: "Obstetra" },
        { value: "Oficial de Carabineros", label: "Oficial de Carabineros" },
        { value: "Oficial FFAA", label: "Oficial FFAA" },
        { value: "Operador", label: "Operador" },
        { value: "Operario", label: "Operario" },
        { value: "Operario, artesano", label: "Operario, artesano" }, // Se repite
        { value: "Orientador", label: "Orientador" },
        { value: "Overlista", label: "Overlista" },
        { value: "Panificador", label: "Panificador" },
        { value: "Paradocente", label: "Paradocente" },
        { value: "Parvulario", label: "Parvulario" },
        { value: "Pastelero", label: "Pastelero" },
        { value: "Patrón de embarcación", label: "Patrón de embarcación" },
        { value: "Peluquero", label: "Peluquero" },
        { value: "Perforista", label: "Perforista" },
        { value: "Periodista", label: "Periodista" },
        { value: "Pescador", label: "Pescador" },
        { value: "Piloto avión", label: "Piloto avión" },
        { value: "Pintor", label: "Pintor" },
        { value: "Podologista", label: "Podologista" },
        { value: "Policía", label: "Policía" },
        { value: "Portero", label: "Portero" },
        { value: "Portuario", label: "Portuario" },
        { value: "Practicante", label: "Practicante" },
        { value: "Prefecto investigación", label: "Prefecto investigación" },
        { value: "Prensista", label: "Prensista" },
        { value: "Profesional de la salud", label: "Profesional de la salud" },
        { value: "Profesional universitario", label: "Profesional universitario" },
        { value: "Profesor", label: "Profesor" }, // Se repite
        { value: "Programador", label: "Programador" },
        { value: "Promotora", label: "Promotora" },
        { value: "Psicólogo", label: "Psicólogo" },
        { value: "Psicopedagogo", label: "Psicopedagogo" },
        { value: "Publicista", label: "Publicista" },
        { value: "Químico farmacéutico", label: "Químico farmacéutico" },
        { value: "Químico laboratorista", label: "Químico laboratorista" },
        { value: "Químico", label: "Químico" },
        { value: "Recepcionista", label: "Recepcionista" },
        { value: "Relacionador público", label: "Relacionador público" },
        { value: "Religioso", label: "Religioso" },
        { value: "Rentista", label: "Rentista" },
        { value: "Rondín", label: "Rondín" },
        { value: "Secretario", label: "Secretario" },
        { value: "Sereno", label: "Sereno" },
        { value: "Sin profesión", label: "Sin profesión" },
        { value: "Sociólogo", label: "Sociólogo" },
        { value: "Soldador", label: "Soldador" },
        { value: "Carabineros", label: "Carabineros" },
        { value: "Supervisor", label: "Supervisor" },
        { value: "Tapicero", label: "Tapicero" },
        { value: "Taxista", label: "Taxista" },
        { value: "Técnico en administración agropecuario", label: "Técnico en administración agropecuario" },
        { value: "Técnico en administración empresas", label: "Técnico en administración empresas" },
        { value: "Técnico en administración hotelera", label: "Técnico en administración hotelera" },
        { value: "Técnico en administración industrial", label: "Técnico en administración industrial" },
        { value: "Técnico en administración personal", label: "Técnico en administración personal" },
        { value: "Técnico administración", label: "Técnico administración" },
        { value: "Técnico agropecuario", label: "Técnico agropecuario" },
        { value: "Técnico analista sistema", label: "Técnico analista sistema" },
        { value: "Técnico asistente párvulos", label: "Técnico asistente párvulos" },
        { value: "Técnico asistente social", label: "Técnico asistente social" },
        { value: "Técnico obstetricia", label: "Técnico obstetricia" },
        { value: "Técnico comercio exterior", label: "Técnico comercio exterior" },
        { value: "Técnico comunicación", label: "Técnico comunicación" },
        { value: "Técnico construcción", label: "Técnico construcción" },
        { value: "Técnico contabilidad", label: "Técnico contabilidad" },
        { value: "Técnico control calidad", label: "Técnico control calidad" },
        { value: "Técnico control industrial", label: "Técnico control industrial" },
        { value: "Técnico dibujo", label: "Técnico dibujo" },
        { value: "Técnico diseño gráfico", label: "Técnico diseño gráfico" },
        { value: "Técnico electricidad", label: "Técnico electricidad" },
        { value: "Técnico electrónico", label: "Técnico electrónico" },
        { value: "Técnico enfermería", label: "Técnico enfermería" },
        { value: "Técnico financiero", label: "Técnico financiero" },
        { value: "Técnico forestal", label: "Técnico forestal" },
        { value: "Técnico gastronómico", label: "Técnico gastronómico" },
        { value: "Técnico jurídico", label: "Técnico jurídico" },
        { value: "Técnico paramédico", label: "Técnico paramédico" },
        { value: "Técnico prevención de riesgo", label: "Técnico prevención de riesgo" },
        { value: "Técnico programador", label: "Técnico programador" },
        { value: "Técnico publicista", label: "Técnico publicista" },
        { value: "Técnico químico", label: "Técnico químico" },
        { value: "Técnico refrigeración", label: "Técnico refrigeración" },
        { value: "Técnico traducción", label: "Técnico traducción" },
        { value: "Técnico turismo", label: "Técnico turismo" },
        { value: "Técnico", label: "Técnico" },
        { value: "Tecnólogo médico", label: "Tecnólogo médico" },
        { value: "Telefonista", label: "Telefonista" },
        { value: "Terapista ocupacional", label: "Terapista ocupacional" },
        { value: "Tester", label: "Tester" },
        { value: "Textil", label: "Textil" },
        { value: "Topógrafo", label: "Topógrafo" },
        { value: "Tornero", label: "Tornero" },
        { value: "Trabajador social", label: "Trabajador social" },
        { value: "Trabajadores de servicio", label: "Trabajadores de servicio" },
        { value: "Traductor", label: "Traductor" },
        { value: "Transportista", label: "Transportista" },
        { value: "Tripulante barco", label: "Tripulante barco" },
        { value: "Vendedor", label: "Vendedor" },
        { value: "Veterinario", label: "Veterinario" },
        { value: "Vigilante", label: "Vigilante" },
        { value: "Vulcanizador", label: "Vulcanizador" },
        { value: "Zapatero", label: "Zapatero" },
        { value: "Otro", label: "Otro", persistentOption: true },

      ],

      selfEmployedOptions: [
        { value: false, label: "Dependiente" },
        { value: true, label: "Independiente" },
      ],

      capitalOriginOptions: [
        {
          value: "WORK_SALARY", label: "Rentas de mi trabajo", display: true,
        },
        {
          value: "LEGAL_AGREEMENTS", label: "Acuerdos legales", display: false,
        },
        {
          value: "INVESTMENTS", label: "Inversiones", display: false,
        },
        {
          value: "INHERITANCE", label: "Herencia", display: true,
        },
        {
          value: "INSURANCE", label: "Seguros", display: false,
        },
        {
          value: "PENSIONS", label: "Pensiones", display: true,
        },
        {
          value: "FAMILY", label: "Familia", display: false,
        },
        {
          value: "SOLD_ASSETS", label: "Ventas de activos", display: false,
        },
        {
          value: "OTHERS", label: "Otro", display: true,
        },
      ],

      declarations: [
        {
          name: "isFatca",
          formComponent: "PICKER",
          signup: true,
        },
        {
          name: "isCrs",
          formComponent: "PICKER",
          signup: true,
        },
        {
          name: "isPep",
          formComponent: "PICKER",
          signup: true,
        },
        {
          name: "capitalOrigin",
          formComponent: "AUTOCOMPLETE",
          options: "capitalOriginOptions",
          signup: false,
        },
        {
          name: "netWorth",
          formComponent: "AUTOCOMPLETE",
          options: "netWorthOptions",
          translatorPath: "profile.transactionalProfile.questions.netWorth.options",
          signup: false,
        },
        {
          name: "salaryRange",
          formComponent: "AUTOCOMPLETE",
          options: "salaryRangeOptions",
          translatorPath: "profile.transactionalProfile.questions.salaryRange.options",
          signup: false,
        },
        {
          name: "foreignIncome",
          formComponent: "PICKER",
          signup: true,
        },
        // {
        //   name: "reinvestDistributions",
        //   signup: false,
        // },
      ],

      declarationsOptions: [
        { value: true, label: "Si" },
        { value: false, label: "No" },
      ],

      occupations: [
        { value: "Analista", label: "Analista" },
        { value: "Comerciante", label: "Comerciante" },
        { value: "Dueño de Casa", label: "Dueño de casa" },
        { value: "Empleado", label: "Empleado" },
        { value: "Empresario", label: "Empresario" },
        { value: "Estudiante", label: "Estudiante" },
        { value: "Independiente", label: "Independiente" },
        { value: "Jubilado", label: "Jubilado" },
        { value: "Jubilado o pensionado", label: "Jubilado o pensionado" },
        { value: "Retirado FF AA", label: "Retirado FF AA" },
        { value: "Otro", label: "Otro", persistentOption: true },
      ],
    };
  },
};
