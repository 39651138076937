<template>
  <div class="profile-wrapper profile-centered-wrapper">
    <div class="profile-content">
      <div class="profile-step-back">
        <v-icon
          color="#333333"
          size="22"
          @click="$router.push({ name: 'accountRiskProfile' })"
        >
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="profile-title">
        {{ $t("profile.riskProfile.edit") }}
      </h3>

      <RiskProfileCard
        :actionButtonText="$t('base.makeTest')"
        @button-clicked="$router.push({ name: 'accountProfilingStart' })"
      />

      <div class="risk-profile-categories">
        <div
          v-for="(riskProfile, index) in riskProfilesList"
          :key="index"
          class="risk-profile-category risk-profile-item"
          :class="{ highlighted: isHighlighted(riskProfile.type) }"
          :id="`risk-profile-${riskProfile.type}`"
          @click="selectedRiskProfile = riskProfile.type"
        >
          <div class="category-name">
            {{ $t(`profile.riskProfile.options.${riskProfile.type}.name`) }}
          </div>

          <div class="risk-level">
            <span>
              {{ $t("profile.riskProfile.riskLevel") }}
            </span>
            <span>
              {{ index + 1 }}
            </span>
          </div>
        </div>
      </div>

      <div class="save-changes">
        <NewButton
          id="save-changes-button"
          :text="successState ? $t('base.savedChanges') : $t('base.saveChanges')"
          :disabled="disabledSaveChanges"
          :class="{ 'success-state-btn': successState }"
          :loading="performingMutation"
          @on-click="updateCustomerProfiling()"
        />

        <div class="profile-cancel-text" @click="$router.push({ name: 'account' })">
          {{ $t("base.cancel") }}
        </div>
      </div>

      <ReusableDialog
        v-model="showErrorDialog"
        :title="$t('profile.riskProfile.riskProfileResult.errorDialog.title')"
        :text="$t('profile.riskProfile.riskProfileResult.errorDialog.text')"
        @display="showErrorDialog = $event"
      />
    </div>
  </div>
</template>
<script>
import RiskProfileCard from "@/components/profile/RiskProfileCard.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import NewButton from "@/components/shared/NewButton.vue";
import values from "@/helpers/values.js";
import { mapGetters, mapActions } from "vuex";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";

export default {
  name: "EditRiskProfile",

  data() {
    return {
      selectedRiskProfile: null,
      performingMutation: false,
      showErrorDialog: false,
      successState: false,
    };
  },

  components: {
    RiskProfileCard,
    NewButton,
    ReusableDialog,
  },

  mixins: [values, customersApiFunctions],

  computed: {
    ...mapGetters(["customerRiskProfile"]),

    disabledSaveChanges() {
      return (
        (!this.selectedRiskProfile ||
          this.customerRiskProfile === this.selectedRiskProfile) &&
        !this.successState
      );
    },
  },

  methods: {
    ...mapActions(["setAccountData"]),

    isHighlighted(riskProfile) {
      if (!this.selectedRiskProfile) return riskProfile === this.customerRiskProfile;

      return this.selectedRiskProfile === riskProfile;
    },

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    async handleSuccessfulMutation(profiling) {
      // Replacing the customer profiling locally
      this.setAccountData({ profiling });

      await this.showSuccessState();

      // Navigate to the profile main view
      this.$router.push({ name: "account" });
    },

    handleMutationErrors() {
      this.showErrorDialog = true;
      this.performingMutation = false;
    },

    async updateCustomerProfiling() {
      try {
        this.performingMutation = true;

        const profiling = this.riskProfilesList.find(
          riskProfile => riskProfile.type === this.selectedRiskProfile,
        ).averageScore;

        const { errors } = await this.patchCustomerData({ payload: { profiling } });

        this.performingMutation = false;

        if (errors) {
          this.handleMutationErrors();
        } else {
          this.handleSuccessfulMutation(profiling);
        }
      } catch (error) {
        console.error(error);
        this.handleMutationErrors();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.risk-profile-categories {
  margin-top: 40px;
}
.risk-profile-item + .risk-profile-item {
  margin-top: 10px;
}

.save-changes {
  max-width: 600px;
  margin-top: 40px;
}

.profile-content {
  max-width: 600px !important;
}

// risk profile categories

.risk-profile-category {
  border-radius: 10px;
  border: 1px solid #e2e0e0;
  background-color: #fff;
  padding: 20px;
  max-width: 600px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.category-name,
.risk-level {
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  color: #333;
}

.risk-level {
  color: #646363 !important;
}

.highlighted {
  border: 1px solid #333 !important;
}

.success-state-btn {
  background-color: #6d48ff !important;
  border: unset;
}
</style>
