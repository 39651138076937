<template>
  <div class="profile-wrapper">
    <h3 class="profile-title">
      {{ $t("profile.passwordRecoverySuccess.title") }}
    </h3>

    <span class="recovery-text">
      {{ $t("profile.passwordRecoverySuccess.text") }}
    </span>

    <NewButton
      class="return-button"
      :text="$t('base.return')"
      @on-click="$router.push({ name: 'account' })"
    />
  </div>
</template>
<script>
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "PasswordRecoverySuccess",

  components: { NewButton },
};
</script>
<style lang="scss" scoped>
.recovery-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.return-button {
  margin-top: 40px;
}
</style>
