<template>
  <v-dialog v-model="display" max-width="600" content-class="rounded-lg elevation-0">
    <v-card elevation="0" rounded="lg">
      <div class="modal-container">
        <div class="modal-title" v-if="title">
          {{title}}
        </div>
        <div
          class="balance-fund"
          v-for="(fundBalance, index) in balanceData.balanceBySeries"
          :key="index"
          @click="$router.push({ name: 'detailedFund', params: { fundRun: fundBalance.fundRun } }); display = false;"
        >
          <BalanceDetailsFundCard :fundBalance="fundBalance" :balanceType="balanceData.name" class="clickable-item" />
        </div>
        <div class="total-balance-item flex-between">
          <div class="total-balance">
            {{$i18n.t('home.balance.total')}}
          </div>
          <div class="total-amount">
            {{balanceData.details.amount | formatBalanceAmount(balanceData.name)}}
          </div>
        </div>

        <div v-if="explanation" class="explanation-section">
          <div class="explanation-title">
            {{$i18n.t(`home.balance.explanations.${balanceData.name}.title`)}}
          </div>

          <div class="explanation-text">
            {{$i18n.t(`home.balance.explanations.${balanceData.name}.text`)}}
          </div>
        </div>
      </div>

      <div class="button-wrapper">
        <NewButton
          :text="!buttonText ? $i18n.t('base.close') : buttonText"
          :buttonClass="'black'"
          @on-click="display = false"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import NewButton from "../shared/NewButton.vue";
import BalanceDetailsFundCard from "./BalanceDetailsFundCard.vue";

export default {
  components: { NewButton, BalanceDetailsFundCard },
  name: "InvestmentDetailsDialog",

  props: {
    dialog: Boolean,
    title: {
      type: String,
      default: null,
    },
    balanceData: {
      type: Object,
      default: () => {},
    },
    buttonText: String,
    explanation: Boolean,

  },
  computed: {
    display: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("display", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.modal-container{
  height: 100%;
  padding: 30px;
  @media (max-width:600px) {
    padding: 20px;

  }
}

.modal-title{
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #333333;
  margin-bottom: 20px;

}

.button-wrapper{
  border-top: 1px solid #E2E0E0;
  margin-top: 10px;
  padding: 20px 30px;
  width: 100%;

}

.balance-fund + .balance-fund{
  margin-top: 10px;
}

.total-balance-item {
  background: #ffffff;
  border-radius: 10px;
  padding: 0px 30px;
  height: 60px;
  border: 1px solid #E2E0E0;
  margin-top: 40px;
}

.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-balance, .total-amount{
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.explanation-section{
  margin-top: 40px;
  @media (max-width:600px) {
    margin-top: 20px;
  }
}

.explanation-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #333333;
  margin-bottom: 20px;
  @media (max-width:600px) {
    margin-bottom: 10px;
  }
}

.explanation-text{
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  color: #333333;
}

</style>
