<template>
  <v-dialog
    v-model="displayDialog"
    max-width="500"
    class="manual-profiling-dialog"
    content-class="rounded-lg elevation-0"
    :fullscreen="$vuetify.breakpoint.xs"
    :transition="
      $vuetify.breakpoint.xs ? 'scroll-y-reverse-transition' : 'scale-transition'
    "
    persistent
  >
    <v-card elevation="0" rounded="lg">
      <div class="manual-profiling-content">
        <h3 class="signup-header">
          {{ $t("profiling.manual.title") }}
        </h3>
        <p class="manual-profiling-subtitle">
          {{ $t("profiling.manual.subtitle") }}
        </p>
        <div
          v-for="(riskProfile, index) in riskProfilesList"
          :id="`manual-profiling-${riskProfile.type}`"
          class="manual-profiling-option"
          :key="index"
          :class="
            riskProfile.type === highlightedRiskProfile.type
              ? 'highlighted-profiling-option'
              : ''
          "
          @click="selectedRiskProfile = riskProfile"
        >
          <p class="manual-profiling-option-name">
            {{ $t(`profiling.manual.option.${riskProfile.type}.name`) }}
          </p>
          <p class="manual-profiling-option-description">
            {{ $t(`profiling.manual.option.${riskProfile.type}.description`) }}
          </p>
        </div>
        <NewButton
          id="save-manual-profiling"
          class="signup-step-button save-profiling-option"
          :text="$t('profiling.manual.save')"
          @on-click="onSaveManualProfilingClick()"
        />
        <div class="signup-cancel-container" @click="displayDialog = false">
          <span class="signup-cancel">
            {{ $t("profiling.shared.cancel") }}
          </span>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import NewButton from "@/components/shared/NewButton.vue";
import values from "@/helpers/values.js";

export default {
  name: "ManualProfilingDialog",

  mixins: [values],

  components: { NewButton },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    currentRiskProfile: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      selectedRiskProfile: null,
    };
  },

  computed: {
    displayDialog: {
      set(booleanValue) {
        this.$emit("displayManualProfilingDialog", booleanValue);
      },

      get() {
        return this.dialog;
      },
    },

    highlightedRiskProfile() {
      return this.selectedRiskProfile || this.currentRiskProfile;
    },
  },

  methods: {
    ...mapActions(["setManualProfilingCustomerAnswer"]),

    onSaveManualProfilingClick() {
      this.setManualProfilingCustomerAnswer(this.highlightedRiskProfile.averageScore);
      this.displayDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.manual-profiling-dialog {
  box-shadow: none !important;
}

.manual-profiling-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #000000;
  margin: 20px 0px;
}

.manual-profiling-content {
  padding: 40px;
}

.save-profiling-option {
  margin-top: 40px;
}

.manual-profiling-option {
  height: 60px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e2e0e0;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
    background-color: #fafafa;
  }
}
.manual-profiling-option + .manual-profiling-option {
  margin-top: 10px;
}

.manual-profiling-option-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
}

.manual-profiling-option-description {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #646363;
}

.highlighted-profiling-option {
  border: 1px solid #000000 !important;
}
</style>
