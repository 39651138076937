<template>
  <div v-if="userProfiles">
    <TopToolbar
      :shortcuts="
        externalShortcuts.filter(
          (route) =>
            route.name !== 'us' && route.name !== 'funds' && route.name !== 'junior',
        )
      "
      @toogleRightToolbar="displayRightToolbar = !displayRightToolbar"
    />

    <LeftToolbar :shortcuts="internalShortcuts" v-if="displayShortcuts" />

    <RightToolbar
      :internalShortcuts="internalShortcuts"
      :externalShortcuts="externalShortcuts"
      :show="displayRightToolbar"
      :userProfiles="userProfiles"
      @displayRightToolbar="displayRightToolbar = $event"
      @logout="logout"
      v-if="displayShortcuts"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LeftToolbar from "./toolbars/LeftToolbar.vue";
import TopToolbar from "./toolbars/TopToolbar.vue";
import RightToolbar from "./toolbars/RightToolbar.vue";

export default {
  name: "AppToolbars",

  components: {
    LeftToolbar,
    TopToolbar,
    RightToolbar,
  },

  props: {
    userProfiles: Array,
    displayShortcuts: Boolean,
  },

  data: () => ({
    displayRightToolbar: null,
    displayRedemptionsDialog: false,

    newCustomerInternalShortcuts: [
      { icon: "mdi-piggy-bank-outline", route: "home", name: "home" },
      { icon: "mdi-plus-circle-outline", route: "investViewFunds", name: "invest" },
      { icon: "mdi-account-outline", route: "account", name: "account" },
    ],

    externalShortcuts: [
      { route: "blog", name: "blog", href: "https://blog.dvacapital.com/" },
      { route: "us", name: "us", href: "https://www.dvacapital.com/nosotros" },
      { route: "funds", name: "funds", href: "https://www.dvacapital.com/fondos" },
      { route: "junior", name: "junior", href: "https://www.dvajunior.com/" },
      {
        route: "giftcard",
        name: "giftcard",
        href: "https://giftcard.dvacapital.com/",
      },
      {
        route: "fundsComparator",
        name: "fundsComparator",
        href: "https://fondos.dvatools.com/",
      },
    ],
  }),

  computed: {
    ...mapGetters(["investedCustomer", "accountAlertCount"]),

    currentCustomerInternalShortcuts() {
      return [
        { icon: "mdi-piggy-bank-outline", route: "home", name: "myInvestments" },
        { icon: "mdi-compare-horizontal", route: "movements", name: "movements" },
        { icon: "mdi-plus-circle-outline", route: "investViewFunds", name: "invest" },
        {
          icon: "mdi-arrow-top-right-thin-circle-outline",
          route: "chooseRedemptionFlow",
          name: "redemptions",
        },
        {
          icon: "mdi-account-outline",
          route: "account",
          name: "account",
          notificationCount: this.accountAlertCount,
        },
      ];
    },

    internalShortcuts() {
      if (this.investedCustomer) return this.currentCustomerInternalShortcuts;
      return this.newCustomerInternalShortcuts;
    },
  },
  methods: {
    ...mapActions(["cleanStore"]),

    hideIntercom(booleanValue) {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$intercom.update({
          hide_default_launcher: booleanValue,
        });
      }
    },

    logout() {
      this.$intercom.shutdown();
      return this.$auth.logout();
    },
  },
};
</script>
