<template>
  <div class="reusable-amount-input">
    <div class="input-boxes">
      <div v-if="currencyBox" class="currency-box" :class="{ 'disabled-box': disabled }">
        CLP$
      </div>

      <div class="reusable-input-wrapper left-in-page">
        <input
          id="amount-input"
          type="text"
          :value="formattedInputAmount | currencyNoSymbol"
          :class="{ 'disabled-input': disabled }"
          :disabled="disabled"
          @keypress="onlyNumber"
          @keydown="checkSelected"
          v-on:keyup.enter="$emit('on-enter')"
          v-on:input="formatInput"
          @focus="focused = true"
          @blur="focused = false"
        />

        <v-btn
          icon
          @click="clearInput"
          :ripple="false"
          id="clear-btn"
          v-if="inputAmount.length > 0 && !disabled"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>
    </div>

    <small class="error-hint" v-if="invalid && !focused && errorHint !== null">
      {{ errorHint }}</small
    >
  </div>
</template>

<script>
export default {
  name: "CustomAmountInput",

  data: () => ({
    inputAmount: [],
    selectedSubstr: null,
    focused: false,
  }),

  props: {
    errorHint: {
      type: String,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    currencyBox: {
      type: Boolean,
      default: true,
    },
    defaultValue: {
      type: Number,
      default: null,
    },
    initialAmount: {
      type: Number,
      default: null,
    },
  },

  computed: {
    formattedInputAmount() {
      if (this.defaultValue !== null) return this.defaultValue;
      if (this.inputAmount.length > 0) {
        return parseInt(this.inputAmount.toString().split(",").join(""), 10);
      }
      return null;
    },
  },

  methods: {
    formatInput(event) {
      if (
        !["deleteContentBackward", "deleteContentForward", "deleteWordBackward"].includes(
          event.inputType,
        ) &&
        /^-?\d+$/.test(event.data)
      ) {
        if (this.selectedSubstr === this.inputAmount.join("")) {
          this.clearInput();
        }
        // First number ingressed can not be 0.
        if (event.target.selectionStart === 1 && event.data === "0") {
          this.clearInput();
          return;
        }
        this.inputAmount.splice(event.target.selectionStart - 1, 0, event.data);
      } else if (this.inputAmount.length > 0) {
        if (this.selectedSubstr === this.inputAmount.join("")) {
          this.clearInput();
          this.selectedSubstr = null;
        }

        if (event.inputType === "deleteContentBackward") {
          const inFrontDots = this.$options.filters
            .currencyNoSymbol(this.formattedInputAmount)
            .split("")
            .map((e, i) => (e === "." ? i : ""))
            .filter(index => index !== "" && index <= event.target.selectionStart)
            .length;
          this.inputAmount.splice(event.target.selectionStart - inFrontDots, 1);
        } else if (event.inputType === "deleteContentForward") {
          const inFrontDots = this.$options.filters
            .currencyNoSymbol(this.formattedInputAmount)
            .split("")
            .map((e, i) => (e === "." ? i : ""))
            .filter(index => index !== "" && index <= event.target.selectionStart - 1)
            .length;
          this.inputAmount.splice(event.target.selectionStart - inFrontDots, 1);
        } else if (event.inputType === "deleteWordBackward") {
          this.clearInput();
          return;
        }
      }
      this.$emit("amount-ingressed", this.formattedInputAmount);
    },

    checkSelected() {
      const selection = window.getSelection();
      this.selectedSubstr = selection.toString().replaceAll(".", "");
    },

    clearInput() {
      this.inputAmount = [];
      this.$emit("amount-ingressed", null);
    },

    onlyNumber($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57 || keyCode === 46) {
        $event.preventDefault();
      }
    },
  },

  mounted() {
    if (this.initialAmount) {
      this.inputAmount = Array.from(String(this.initialAmount), number => Number(number));
    }
  },
};
</script>

<style lang="scss" scoped>
.reusable-input-wrapper {
  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input {
    border: 1px solid #e2e0e0;
    box-sizing: border-box;
    border-radius: 0px 10px 10px 0px;
    height: 100px;
    width: 299px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 20px;
    font-size: 20px;
    line-height: 23px;
    color: #333333;
    background: #ffffff;
    letter-spacing: normal;
    @media (max-width: 600px) {
      width: 100% !important;
    }
    &:focus {
      outline: none !important;
    }
  }

  @media (max-width: 600px) {
    width: 100% !important;
  }
}

.currency-box {
  border: 1px solid #e2e0e0;
  box-sizing: border-box;
  border-radius: 10px 0px 0px 10px;
  height: 100px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: transparent;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
  background-color: #ffffff;
}

.disabled-box,
input[type="text"]:disabled {
  color: #828282 !important;
  background-color: #ffffff !important;
}

.input-boxes {
  display: flex;
  justify-content: left;
  align-items: center;
}
.reusable-input-wrapper {
  display: inline-block;
  position: relative;

  @media (max-width: 600px) {
    width: 100% !important;
  }
}

#clear-btn::before {
  background-color: transparent !important;
}

#clear-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.error-hint {
  margin-top: 10px;
  font-weight: 500;
  font-size: 11px;
  color: #f20e45;
}
</style>
