<template>
  <div>
    <v-app-bar
      id="signup-toolbar"
      app
      clipped-left
      flat
      class="signup-toolbar"
      color="#F2F2F2"
    >
      <div class="signup-toolbar-logo">
        <v-img
          :max-height="$vuetify.breakpoint.mdAndUp ? 30 : 25"
          :src="require('@/assets/mi-dva-logo.svg')"
          contain
          position="left"
        />
      </div>
      <v-spacer />

      <NewButton
        v-if="$vuetify.breakpoint.lgAndUp && currentUser"
        :text="currentUser | customerName"
        buttonClass="black-outlined"
        @on-click="displayDrawer = !displayDrawer"
        class="signup-current-user-btn"
      />

      <v-app-bar-nav-icon
        @click="displayDrawer = !displayDrawer"
        class="nav-icon"
        color="#000"
        dark
        v-else-if="$vuetify.breakpoint.mdAndDown && currentUser"
      />
    </v-app-bar>

    <v-navigation-drawer
      v-model="displayDrawer"
      app
      right
      temporary
      color="#FFFFFF"
      :width="$vuetify.breakpoint.xs ? '65%' : '300px'"
    >
      <div class="toolbar-items">
        <div class="current-customer name-email-text">
          <div class="initials-circle dark-circle center-in-page">
            <div class="initials-text light">
              {{ currentUser | customerInitials }}
            </div>
          </div>
          <div class="customer-name">{{ currentUser }}</div>
        </div>

        <div class="internal-routes">
          <div v-for="(item, index) in customerBetaOptions" :key="index">
            <RightToolbarItem
              v-if="item.display"
              :icon="item.icon"
              :route="item.route"
              :name="item.name"
            />
          </div>
        </div>

        <div class="user-actions">
          <div v-for="(item, index) in userActions" :key="index">
            <RightToolbarItem :icon="item.icon" :href="item.href" :name="item.name" />
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RightToolbarItem from "@/components/layout/toolbars/RightToolbarItem.vue";
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "SignupToolbar",

  components: {
    RightToolbarItem,
    NewButton,
  },

  data: () => ({
    displayDrawer: null,
  }),

  computed: {
    ...mapGetters(["signupCompleted", "currentUser"]),

    customerBetaOptions() {
      return [
        {
          route: "signupDashboard",
          name: "signupDashboard",
          icon: "mdi-piggy-bank-outline",
          display: true,
        },
        {
          route: "investViewFunds",
          name: "invest",
          icon: "mdi-plus-circle-outline",
          display: this.signupCompleted,
        },
        {
          route: "account",
          name: "account",
          icon: "mdi-account-outline",
          display: this.signupCompleted,
        },
      ];
    },

    userActions() {
      return [
        {
          href: "https://ayuda.dvacapital.com/es/",
          name: "help",
          icon: "mdi-help-circle-outline",
        },
        { name: "logout", icon: "mdi-logout", href: null },
      ];
    },

  },

  methods: {
    logout() {
      this.$auth.logout();
    },
  },
};
</script>

<style scoped lang="scss">
#signup-toolbar {
  margin: 0 40px !important;

  @media (max-width: 960px) {
    margin: 0 20px !important;
  }
}
.signup-toolbar .v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close),
.v-navigation-drawer--temporary:not(.v-navigation-drawer--close) {
  box-shadow: none !important;
}

::v-deep .new-btn {
  max-width: 230px !important;
  min-width: 130px !important;
  width: auto !important;
  height: 50px !important;

  @media (max-width: 600px) {
    height: 40px !important;
  }
}

.customer-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.initials-text {
  font-weight: 700;
  font-size: 11.6px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 600px) {
    font-size: 10px;
  }
}

.initials-circle {
  aspect-ratio: 1;
  height: 30px;
  border-radius: 50%;
  @media (max-width: 600px) {
    height: 25px;
  }
}

.dark-circle {
  background: #000000;
}
.outline-circle {
  background: #ffffff;
  border: 2px solid #333333;
}

.light {
  color: #ffffff;
}
.dark {
  color: #333333;
}

.toolbar-items > .toolbar-item:first-child {
  margin-top: 20px;
  height: 80px;
}

.toolbar-item,
.current-customer {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  height: 60px;
  padding: 0 20px 0 20px;
  margin: 10px 0;
}

.toolbar-item:hover {
  cursor: pointer;
  background: #f2f2f2;
  mix-blend-mode: darken;
}

.toolbar-item-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
}

a {
  text-decoration: none;
}

.name-email-text {
  border-bottom: 1px solid #e2e0e0;
}

.user-actions {
  border-top: 1px solid #e2e0e0;
}
</style>
