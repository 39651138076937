<template>
  <div class="balance-fund-card">
    <FundBadge :fundRun="Number(this.fundBalance.fundRun)" :size="60" />
    <div class="fund-balance-wrapper">
      <div class="fund-name">{{ fundBalance.fundRun | fundShortName }}</div>
      <div class="fund-balance my-1">${{ (currencyAmount || 0) | currencyNoSymbol }}</div>
    </div>
    <div class="relative-wrapper">
      <div class="fund-results">
        {{ results >= 0 ? "+" : "-" }}${{ Math.abs(results) | currencyNoSymbol }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import funds from "../../mixins/funds.js";
import FundBadge from "./FundBadge.vue";

export default {
  name: "FundCard",

  components: { FundBadge },

  mixins: [funds],

  props: {
    fundBalance: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapGetters(["fundsResults"]),

    results() {
      return this.fundBalance?.fundHistoricalResult;
    },

    currencyAmount() {
      return this.fundBalance?.fundCurrent;
    },
  },
};
</script>

<style scoped lang="scss">
.balance-fund-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  height: 80px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}

.fund-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-transform: capitalize;
  color: #333333;
  white-space: nowrap;
}

.fund-balance {
  font-weight: 500;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
}
.relative-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  white-space: nowrap;
}

.fund-balance-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 8px 0 5px 0;
}

.fund-results {
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #646363;
  bottom: 7px;
  right: 10px;
}
</style>
