<template>
  <v-navigation-drawer
    v-model="show"
    app
    clipped
    color="#FFFFFF"
    width="240"
    floating
    class="left-nav-drawer"
    v-if="displayToolbar"
  >
    <div
      v-for="(item, index) in shortcuts"
      :key="index"
      class="left-nav-drawer-shortcuts"
      :id="item.name"
    >
      <LeftTopToolbarItem
        :icon="item.icon"
        :route="item.route"
        :notificationCount="item.notificationCount"
        :name="item.name"
        :href="item.href"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import LeftTopToolbarItem from "./LeftTopToolbarItem.vue";

export default {
  name: "LeftToolbar",

  components: { LeftTopToolbarItem },

  data: () => ({
    show: null,
  }),

  props: {
    shortcuts: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    displayToolbar() {
      return this.$route.meta?.showNavigation;
    },
  },
};
</script>

<style lang="scss" scoped>
.left-nav-drawer-shortcuts:first-child {
  margin-top: 20px;
}

.left-nav-drawer {
  border-radius: 0px 10px 10px 0px !important;
}
</style>
