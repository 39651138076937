<template>
  <div class="redemption-wrapper">
    <div class="redemption-step-back">
      <v-icon color="#333333" size="22" @click="$router.go(-1)"> mdi-arrow-left </v-icon>
    </div>

    <h2 class="redemption-header">
      {{ $i18n.t("redemptions.fromFundRedemption.title") }}
    </h2>

    <div class="customer-funds">
      <div
        v-for="(fundBalance, index) in fundBalances"
        :key="index"
        @click="selectFund(fundBalance.fundRun)"
        class="fund-balance"
      >
        <BalanceDetailsFundCard :fundBalance="fundBalance" class="fund-balance-card" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BalanceDetailsFundCard from "../../components/results/BalanceDetailsFundCard.vue";

export default {
  name: "FromFundRedemption",

  components: { BalanceDetailsFundCard },

  computed: {
    ...mapGetters(["fundsResults", "redemption"]),

    fundBalances() {
      return this.fundsResults
        .map(fund => ({ currencyAmount: fund.fundCurrent, fundRun: fund.fundRun }))
        .filter(balance => balance.currencyAmount > 0)
        .sort((a, b) => b.currencyAmount - a.currencyAmount);
    },
  },

  methods: {
    ...mapActions(["setRedemptionData"]),

    selectFund(fundRun) {
      const seriesName = this.fundsResults
        .find(fund => fund.fundRun === fundRun)
        .fundQuotas.find(series => series.quotasAmount > 0).symbol;
      this.setRedemptionData({
        ...this.redemption,
        redemptionList: [{ fundRun, seriesName }],
      });
      this.$router.push({ name: "chooseRedemptionAmount" });
    },
  },
};
</script>

<style lang="scss" scoped>
.fund-balance + .fund-balance {
  margin-top: 10px;
}

.fund-balance {
  max-width: 420px;
}

.fund-balance-card {
  &:hover {
    cursor: pointer;
    background-color: #fafafa;
  }
}

::v-deep .balance-details-fund-card {
  border: none !important;
}
</style>
