<template>
  <div class="custom-file-input">
    <div class="label" v-if="label">
      {{ label }}
    </div>

    <v-file-input
      :id="id"
      :ref="id"
      :placeholder="placeholder"
      :prepend-icon="null"
      :clearable="false"
      :rules="rules"
      class="input-field file-input"
      v-model="inputValue"
      accept="image/jpeg, image/png, .heic"
      persistent-hint
      persistent-placeholder
      rounded
      outlined
      truncate-length="14"
      hide-details
      :disabled="disabled"
      :class="{ disabled: disabled }"
      @change="$emit('on-change', $event)"
    >
      <template v-slot:prepend-inner v-if="inputValue || disabled">
        <div class="prepend-container">
          <v-icon color="#6D48FF"> mdi-check-circle-outline </v-icon>
          <span>
            {{ placeholder }}
          </span>
        </div>
      </template>
      <template v-slot:append>
        <div class="upload-button-wrapper" v-if="!inputValue">
          <v-btn icon @click="$refs[id].$refs.input.click()">
            <v-icon color="#333333"> mdi-upload-outline </v-icon>
          </v-btn>
        </div>
        <div v-else>
          <v-btn icon @click="$emit('delete')">
            <v-icon color="#333333"> mdi-delete-outline </v-icon>
          </v-btn>
        </div>
      </template>
    </v-file-input>
  </div>
</template>
<script>
export default {
  name: "FileInput",

  props: {
    id: String,
    label: String,
    rules: Array,
    placeholder: String,
    value: File,
    disabled: Boolean,
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("updateValue", value);
      },
    },
  },
};
</script>
<style lang="scss">
.custom-file-input {
  input {
    caret-color: black;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 160% !important;
  }

  .v-text-field--outlined:not(.disabled) > .v-input__control > .v-input__slot {
    background: #ffffff !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #333333;
  }

  .v-text-field--outlined:not(.disabled) > .v-input__control > .v-input__slot {
    background-color: #fff !important;
    padding: 0 10px 0 21px !important;
  }

  .input__prepend-inner {
    margin-top: 20px !important;
  }

  .input-field {
    border-radius: 10px;
  }

  .v-messages__message {
    line-height: 160% !important;
    font-weight: 500 !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: #e2e0e0;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 60px !important;
  }

  :not(.file-input).v-text-field--outlined.v-input--is-focused
    > .v-input__control
    > .v-input__slot
    fieldset {
    border: 1px solid #000 !important;
  }

  .error--text.v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid #f20e45;
  }

  .theme--light.v-text-field--outlined {
    border-radius: 10px;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    color: #333;
    margin-bottom: 10px;
  }

  .v-file-input .v-file-input__text--placeholder {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 27px !important;
    color: #333333 !important;
  }

  .error--text.v-text-field--outlined {
    i {
      color: #f20e45 !important;
    }

    .v-file-input__text--placeholder {
      color: #f20e45 !important;
    }
  }

  .error--text.v-text-field--outlined .upload-button-wrapper {
    i {
      color: #f20e45 !important;
    }

  }

  .v-input--is-label-active .v-file-input__text {
    display: flex;
    justify-content: right;
    white-space: nowrap;
  }

  .file-inputs > .v-text-field--rounded > .v-input__control > .v-input__slot {
    padding: 0 10px 0 21px !important;
  }

  .v-text-field .v-input__append-inner {
    display: flex;
    align-self: center !important;
    margin: 0;
  }

  .file-input.v-text-field--outlined.v-input--is-focused
    > .v-input__control
    > .v-input__slot
    fieldset {
    border: 1px solid #e2e0e0 !important;
  }

  .prepend-container {
    height: 26px !important;
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
  }

  .disabled .prepend-container span {
    display: none;
  }

  .disabled .upload-button-wrapper {
    display: none;
  }

  .upload-button-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
