<template>
  <div class="profile-wrapper profile-centered-wrapper">
    <div class="profile-content">
      <div class="profile-step-back">
        <v-icon color="#333333" size="22" @click="handleStepBack()">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="profile-title">
        {{ $t("profile.contactAddress.title") }}
      </h3>

      <v-form class="profile-form" v-model="formValid">
        <PhoneInput
          :phone="displayablePhone || phone"
          :dropdownOptions="{
            showFlags: true,
            showDialCodeInSelection: true,
            showDialCodeInList: true,
          }"
          :inputOptions="{
            placeholder: $t('signup.identification.contact.fields.phone.placeholder'),
          }"
          :errorMessage="$t('signup.identification.contact.fields.phone.invalidPhone')"
          :label="$t('signup.identification.contact.fields.phone.label')"
          @setPhoneData="setPhoneData"
          :displayNotification="!phone"
        />

        <TextField
          :label="$t('profile.contactAddress.fields.email.label')"
          :value="email"
          disabled
          class="profile-input-field email-field"
        />

        <div class="address-fields">
          <AutoComplete
            id="region"
            :value="region"
            :items="regions"
            item-text="name"
            item-value="name"
            :rules="mustCompleteRule"
            :label="$t('profile.contactAddress.fields.address.label')"
            :placeholder="$t('profile.contactAddress.fields.address.region')"
            @updateValue="handleRegionSelection"
            class="profile-address-field"
            :displayNotification="!region || !locality || !streetName || !houseNumber"
          />

          <AutoComplete
            id="locality"
            :value="locality"
            :items="localitiesList"
            :rules="mustCompleteRule"
            :placeholder="$t('profile.contactAddress.fields.address.locality')"
            @updateValue="handleLocalitySelection"
            class="profile-address-field"
          />

          <TextField
            :value="streetName"
            :placeholder="$t('profile.contactAddress.fields.address.street')"
            :rules="mustCompleteRule"
            @updateValue="streetName = $event"
            refName="street"
            class="profile-address-field"
          />

          <TextField
            :value="houseNumber"
            :placeholder="$t('profile.contactAddress.fields.address.houseNumber')"
            :rules="mustCompleteRule"
            @updateValue="houseNumber = $event"
            refName="houseNumber"
            class="profile-address-field"
          />

          <TextField
            :value="interiorNumber"
            :placeholder="$t('profile.contactAddress.fields.address.interiorNumber')"
            @updateValue="interiorNumber = $event"
            class="profile-address-field"
          />
        </div>

        <NewButton
          class="submit-button"
          @on-click="createCustomerPatch()"
          :disabled="saveButtonDisabled"
          :class="{ 'success-state-btn': successState }"
          :loading="performingMutation"
          :text="successState ? $t('base.savedChanges') : $t('base.saveChanges')"
        />

        <div class="profile-cancel-text" @click="$router.push({ name: 'account' })">
          {{ $t("base.cancel") }}
        </div>

        <ReusableDialog
          :value="errorDialog"
          :title="$t('signup.profiling.occupation.errorDialog.title')"
          :text="$t('signup.profiling.occupation.errorDialog.text')"
          @display="errorDialog = $event"
        />

        <HorizontalButtonDialog
          :value="unsavedChangesDialog"
          :title="$t('profile.shared.unsavedChangesDialog.title')"
          :text="$t('profile.shared.unsavedChangesDialog.text')"
          :primaryButtonText="$t('profile.shared.unsavedChangesDialog.buttons.continue')"
          :secondaryButtonText="$t('profile.shared.unsavedChangesDialog.buttons.back')"
          @secondaryClick="unsavedChangesDialog = false"
          @primaryClick="$router.push({ name: 'account' })"
          @display="unsavedChangesDialog = $event"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import AutoComplete from "@/components/shared/AutoComplete.vue";
import NewButton from "@/components/shared/NewButton.vue";
import TextField from "@/components/shared/TextField.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import { mapGetters, mapActions } from "vuex";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import { addressSelects } from "@/mixins/addressSelects.js";
import HorizontalButtonDialog from "@/components/shared/HorizontalButtonDialog.vue";

export default {
  name: "ContactAddress",

  data() {
    return {
      formValid: false,
      phoneValid: false,

      // Inputs data
      displayablePhone: null,
      phone: null,
      email: null,
      region: null,
      locality: null,
      streetName: null,
      houseNumber: null,
      interiorNumber: null,
      // Form rules
      mustCompleteRule: [v => !!v || this.$t("shared.errors.mustComplete")],
      unsavedChangesDialog: false,
      // Mutation states
      performingMutation: false,
      errorDialog: false,
      successState: false,
    };
  },

  components: {
    TextField,
    NewButton,
    AutoComplete,
    ReusableDialog,
    PhoneInput,
    HorizontalButtonDialog,
  },

  mixins: [addressSelects, customersApiFunctions],

  computed: {
    ...mapGetters(["loading", "customerPhone", "customerAddress"]),

    localitiesList() {
      if (!this.region) {
        return this.regions
          .map(region => region.localities)
          .flat(1)
          .sort();
      }
      return this.regions
        .find(region => region.name === this.region)
        ?.localities.sort();
    },

    customerPatchPayload() {
      const {
        phone, region, locality, streetName, houseNumber, interiorNumber,
      } = this;

      return {
        // Phone
        phone,
        // Address
        region,
        locality,
        streetName,
        houseNumber,
        interiorNumber,
        country: "CHL",
      };
    },

    addressesMatch() {
      return (
        this.region === this.customerAddress?.region &&
        this.locality === this.customerAddress?.locality &&
        this.streetName === this.customerAddress?.streetName &&
        this.houseNumber === this.customerAddress?.houseNumber &&
        this.interiorNumber === this.customerAddress?.interiorNumber
      );
    },

    unchangedForm() {
      return this.phone === this.customerPhone && this.addressesMatch;
    },

    saveButtonDisabled() {
      return (
        (!this.formValid || !this.phoneValid || this.unchangedForm) && !this.successState
      );
    },
  },

  watch: {
    loading() {
      this.loadProfileData();
    },
  },

  methods: {
    ...mapActions(["setContactAddressData"]),

    loadProfileData() {
      const { customerAddress, customerPhone } = this;

      this.phone = customerPhone;
      this.email = localStorage.getItem("userEmail");
      this.region = customerAddress?.region;
      this.locality = customerAddress?.locality;
      this.streetName = customerAddress?.streetName;
      this.houseNumber = customerAddress?.houseNumber;
      this.interiorNumber = customerAddress?.interiorNumber;
    },

    handleRegionSelection(regionName) {
      this.region = regionName;
      this.locality = null;
    },

    handleLocalitySelection(locality) {
      if (!locality) return;
      this.region = this.regions.find(region => region?.localities.includes(locality))?.name;
      this.locality = locality;
    },

    setPhoneData(data) {
      this.phoneValid = data.valid;

      if (data.number) {
        this.phone = data.number;
        this.displayablePhone = data.nationalNumber;
      }
    },

    async handleSuccessfulMutation(payload) {
      this.saveContactAddressLocally(payload);
      await this.showSuccessState();
      this.$router.go(-1);
    },

    handleMutationErrors() {
      this.errorDialog = true;
      this.performingMutation = false;
    },

    async createCustomerPatch() {
      const data = {
        payload: this.customerPatchPayload,
      };

      try {
        this.performingMutation = true;
        const { errors } = await this.patchCustomerData(data);
        this.performingMutation = false;

        if (!errors) {
          await this.handleSuccessfulMutation(data.payload);
        } else {
          this.handleMutationErrors();
        }
      } catch (error) {
        console.error(error);
        this.handleMutationErrors();
      }
    },

    saveContactAddressLocally(payload) {
      const { phone, ...address } = payload;

      this.setContactAddressData({
        addressData: address,
        phoneData: { phone },
      });
    },

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    handleStepBack() {
      // If there is no changes, go back directly
      if (this.unchangedForm) {
        this.$router.push({ name: "account" });
      } else {
        // Otherwise. display the unsavedChangesDialog
        this.unsavedChangesDialog = true;
      }
    },
  },

  created() {
    this.loadProfileData();
  },
};
</script>

<style lang="scss" scoped>
.success-state-btn {
  background-color: #6d48ff !important;
  border: unset;
}

.profile-address-field:first-child {
  margin-top: 40px;
}
.email-field {
  margin-top: 20px;
}

.profile-address-field + .profile-address-field {
  margin-top: 10px;
}
</style>
