<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="signup-step-back">
        <v-icon id="signup-step-back" color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="profiling-title">
        {{
          currentQuestion.isBonus
            ? $t("profiling.questions.title.bonus")
            : $t("profiling.questions.title.profiling", {
              currentQuestion: currentQuestionNumber,
              totalQuestions: profilingQuestions.filter(
                (profilingQuestion) => !profilingQuestion.isBonus,
              ).length,
            })
        }}
      </h3>
      <div class="profiling-question">
        {{ currentQuestion.question }}
      </div>
      <div
        v-for="(option, index) in currentQuestion.answers"
        :id="`profiling-answer-${index}`"
        :key="index"
        class="profiling-option-container"
        @click="saveAnswer({ answer: option, answerIndex: index })"
      >
        <p class="profiling-option-text">
          {{ option }}
        </p>
      </div>
      <div class="signup-cancel-container" @click="onCancel()">
        <p class="signup-cancel">
          {{ $t("profiling.shared.cancel") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import profilingQuestions from "@/mixins/profilingQuestions.js";

export default {
  name: "ProfilingQuestion",

  props: ["currentQuestionNumber"],

  mixins: [profilingQuestions],

  computed: {
    currentQuestion() {
      return this.profilingQuestions.find(
        profilingQuestion => profilingQuestion.order === Number(this.currentQuestionNumber),
      );
    },
  },

  methods: {
    ...mapActions([
      "setManualProfilingCustomerAnswer",
      "setProfilingCustomerAnswer",
      "setProfilingCustomerReferralAnswer",
    ]),

    saveAnswer({ answer, answerIndex }) {
      if (this.currentQuestion.isBonus) {
        this.setProfilingCustomerReferralAnswer(answer);
      } else {
        this.setProfilingCustomerAnswer({
          questionNumber: this.currentQuestionNumber,
          questionAnswer: answerIndex + 1,
        });
      }

      const { signup } = this.$route.meta;

      const nextQuestionNumber = Number(this.currentQuestionNumber) + 1;

      // In account no bonus questions are displayed
      const displayableQuestions = signup ?
        this.profilingQuestions :
        this.profilingQuestions.filter(question => !question.isBonus);

      const isLastQuestion = displayableQuestions.length < nextQuestionNumber;

      const baseRouteName = signup ? "signupProfiling" : "accountProfiling";

      const targetRoute = isLastQuestion ?
        `${baseRouteName}Result` :
        `${baseRouteName}Question`;

      this.$router.push({
        name: targetRoute,
        params: { currentQuestionNumber: nextQuestionNumber },
      });

      if (targetRoute === `${baseRouteName}Question`) {
        this.setManualProfilingCustomerAnswer(null);
      }
    },

    onCancel() {
      if (this.$route.meta.signup) this.$router.push({ name: "signupDashboard" });
      else {
        this.$router.push({ name: "accountEditRiskProfile" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profiling-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
}

.profiling-question {
  font-weight: 500;
  font-size: 18px;
  line-height: 28.8px;
  color: #333333;
  margin: 20px 0 40px 0px !important;
  @media (max-width: 600px) {
    margin: 10px 0 40px 0px !important;
  }
}

.profiling-option-container {
  background-color: #ffffff;
  border-radius: 10px;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 20px;
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}

.profiling-option-container + .profiling-option-container {
  margin-top: 10px;
}

.profiling-option-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin: 0px;
}
</style>
