<template>
  <div
    class="compass-pending-account-data"
    @click="$router.push({ name: 'accountCompassCompleteData' })"
    v-if="isMissingSignupData || isMissingTransactionalProfileData"
  >
    <div class="compass-pending-account-data-text">
      {{ $t('home.announces.pendingAccountData.text') }}
    </div>
    <v-icon color="#fff"> mdi-chevron-right </v-icon>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CompassPendingAccountData",

  computed: {
    ...mapGetters(["isMissingSignupData", "isMissingTransactionalProfileData"]),
  },

};
</script>

<style lang="scss" scoped>
.compass-pending-account-data {
  background-color: #6d48ff;
  height: 40px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

 @media (max-width: 1264px) {
    margin: 0 20px !important;
  }
}

.compass-pending-account-data-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  color: #fff;
}
</style>
