<template>
  <v-dialog v-model="display" max-width="420" content-class="rounded-lg elevation-0">
    <v-card elevation="0" rounded="lg">
      <div class="modal-container">
        <div class="message-title" v-if="title">
          {{title}}
        </div>

        <div class="message-text">
          {{message}}
        </div>
      </div>

      <div class="button-wrapper">
        <NewButton
          :text="!buttonText ? $i18n.t('base.close') : buttonText"
          :buttonClass="'black'"
          @on-click="display = false"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import NewButton from "./NewButton.vue";

export default {
  name: "MessageDialog",
  props: {
    dialog: Boolean,
    title: String,
    message: String,
    buttonText: String,
  },
  components: {
    NewButton,
  },
  computed: {
    display: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("display", value);
      },
    },
  },

};
</script>

<style lang="scss" scoped>

.modal-container{
  height: 100%;
  padding: 20px;
}

.message-title{
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
    color: #333333;
    margin-bottom: 10px;
}

.message-text{
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    color: #333333;
}

.button-wrapper{
  border-top: 1px solid #E2E0E0;
  margin-top: 10px;
  padding: 20px 30px;
  width: 100%;

}

</style>
