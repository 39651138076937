<template>
  <div class="customer-bank-account-wrapper">
    <CustomerBankCard @toggleEditBankDialog="toggleEditBankDialog" />

    <NewButton
      :text="$t('base.confirm')"
      @on-click="$emit('onConfirm')"
      :disabled="!clpBankAccount"
    />

    <div class="cancel-text" @click="$emit('onCancel')">
      {{ $t("base.cancel") }}
    </div>

    <EditBankAccountDialog
      :dialog="showEditBankDialog"
      @display="showEditBankDialog = $event"
      @onPatchSuccess="showSuccessToast()"
      v-if="showEditBankDialog"
    />

    <div class="success-toast" v-if="successToast">
      <v-scale-transition>
        <ToastNotification toastType="success" :toastText="$t('base.savedChanges')" />
      </v-scale-transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditBankAccountDialog from "./EditBankAccountDialog.vue";
import ToastNotification from "./ToastNotification.vue";
import NewButton from "./NewButton.vue";
import CustomerBankCard from "./CustomerBankCard.vue";

export default {
  name: "ManageCustomerBankAccount",

  data: () => ({
    showEditBankDialog: false,
    successToast: false,
  }),

  components: {
    EditBankAccountDialog,
    ToastNotification,
    NewButton,
    CustomerBankCard,
  },

  computed: {
    ...mapGetters(["clpBankAccount"]),
  },

  methods: {
    ...mapActions(["setLoadingProfile"]),

    toggleEditBankDialog() {
      this.showEditBankDialog = !this.showEditBankDialog;
    },

    async showSuccessToast() {
      this.successToast = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successToast = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.success-toast {
  margin: 40px 0;
}
</style>
