<template>
  <div :class="[optionalClass]" @click="$emit('optionClicked')">
    <div class="account-option-container">
      <v-icon>
        {{ leftIcon }}
      </v-icon>
      <span class="account-option-text" v-html="text" />
    </div>

    <v-icon v-if="rightIcon && !notifications">
      {{ rightIcon }}
    </v-icon>

    <div class="notification-container" v-if="notifications > 0">
      <div class="notification-number">
        {{ notifications }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AccountOption",

  props: {
    leftIcon: String,
    rightIcon: String,
    text: String,
    notifications: Number,
    optionalClass: String,
  },
};
</script>

<style lang="scss" scoped>
.account-option {
  display: flex;
  height: 60px;
  padding: 20px;
  align-items: center;
  gap: 20px;
  justify-content: space-between;

  background-color: #fff;
  border-radius: 10px;
  max-width: 420px;

  &:hover {
    cursor: pointer;
    background-color: #fafafa;
  }

  i {
    color: #333;
  }
}

.alert,
.logout {
  i,
  span {
    color: #f20e45 !important;
  }
}

.account-option-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  color: #333;
}

.account-option-container {
  gap: 0 10px;
  display: flex;
  align-items: center;
}

.notification-container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6d48ff;
  border-radius: 50%;
}

.notification-number {
  color: #fff;
  font-size: 11px;
}
</style>
