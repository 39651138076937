<template>
  <div class="px-0 pt-0">
    <AnimationWrapper
      :images="successImages"
      :lazyImage="lazyImage"
      class="animated-images"
    />
    <div class="success-items">
      <div class="success-texts">
        <div class="mx-6 title-row">
          <span class="success-title">
            {{
              $i18n.t(`${isRedemption ? "redemptions" : "reinvestment"}.success.title`)
            }}
          </span>
        </div>
        <div class="success-subtitle mx-6">
          {{
            $i18n.t(`${isRedemption ? "redemptions" : "reinvestment"}.success.message`)
          }}
        </div>
      </div>
      <div class="button-row center-in-page flex-column">
        <div class="success-btn-wrapper">
          <NewButton
            :text="$i18n.t('base.finish')"
            @on-click="endProcess()"
            id="finish-redemption"
          />
        </div>
        <div
          class="support-text mt-5"
          @click="
            $intercom.showNewMessage(
              `Hola! necesito ayuda con un ${isRedemption ? 'retiro' : 'traspaso'}`,
            )
          "
        >
          {{ $i18n.t("base.help") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import lazyLoadingImage from "@/assets/animations/success/success-lazy-img.png";
import success1 from "@/assets/animations/success/success1.svg";
import success2 from "@/assets/animations/success/success2.svg";
import success3 from "@/assets/animations/success/success3.svg";
import NewButton from "@/components/shared/NewButton.vue";
import AnimationWrapper from "@/components/shared/AnimationWrapper.vue";

export default {
  name: "RedemptionSuccess",

  components: { NewButton, AnimationWrapper },

  data: () => ({
    successImages: [success1, success2, success3],
    lazyImage: lazyLoadingImage,
  }),

  computed: {
    ...mapGetters(["redemption"]),

    isRedemption() {
      return Object.keys(this.redemption).length > 0;
    },
  },

  methods: {
    ...mapActions(["setRedemptionData", "setReinvestmentData"]),

    endProcess() {
      this.setRedemptionData({});
      this.setReinvestmentData({});
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="scss" scoped>
.success-title {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 140%;
  color: #000000;
}

.success-btn-wrapper {
  width: 100%;
  max-width: 420px;
  @media (max-width: 960px) {
    max-width: 100%;
    padding: 0 20px;
  }
}

::v-deep .black-btn {
  span {
    font-size: 14px !important;
    line-height: 16px !important;
  }
}
.loading-images {
  margin-top: 60px;
  @media (max-width: 600px) {
    margin-top: 50px;
  }
}

.animated-images {
  margin-top: 60px;
  @media (max-width: 600px) {
    margin-top: 50px;
  }
}

::v-deep .animation-wrapper {
  height: 340px;
}

.success-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  max-width: 420px;
}

.support-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #646363;

  &:hover {
    cursor: pointer;
  }
}

.success-items {
  @media (min-width: 960px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.title-row {
  margin-top: 20px !important;
}

.success-texts {
  @media (max-width: 960px) and (min-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.button-row {
  width: 100%;
  margin-top: 40px;
}
</style>
