<template>
  <table class="full-width-table">
    <tbody>
      <tr v-for="(row, index) in translatedTableData" v-bind:key="index">
        <td class="attribute-cell">
          <div class="attribute">
            <span>
              {{ row.key }}
            </span>
          </div>
        </td>
        <td class="value-cell">
          <span class="value">
            {{ row.value }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TwoColumnsTable",

  props: {
    tableData: Array,
    translatorPath: String,
  },

  computed: {
    translatedTableData() {
      if (!this.translatorPath) return this.tableData;
      return this.tableData.map(row => ({
        key: this.$t(`${this.translatorPath}.${row.key}`),
        value: row?.useTranslation ? this.$t(`${this.translatorPath}.${row.value}`) : row.value,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width-table {
  border-collapse: collapse;
  width: 100%;
}

tbody tr {
  height: 40px;
  border-top: 1px solid #e2e0e0;
}
tbody tr:last-child {
  border-bottom: 1px solid #e2e0e0;
}

tr td:first-child {
  width: 50%;
  padding-left: 30px;
  @media (min-width: 1264px) {
    width: 60%;
  }
  @media (max-width:600px) {
    padding-left: 20px;
  }
}

.attribute,
.value {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.value {
  font-weight: 600 !important;
}
</style>
