<template>
  <div class="balance-item">
    <div class="no-wrap-container">
      <span class="cash-amount"> {{ cashAmount >= 0 ? '+' : "-"}}${{ Math.abs(cashAmount || 0) | currencyNoSymbol}} </span>
    </div>
    <div class="label center-in-page">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BalanceItem",

  props: {
    cashAmount: {
      type: Number,
    },
    label: {
      type: String,
    },
  },

};
</script>

<style lang="scss" scoped>

.label {
  font-size: 14px;
  line-height: 16px;
  color: #646363;
  font-weight: 500;
  @media (max-width:600px) {
    font-size: 10px;
    line-height: 140%;
  }
}

.balance-item {
  border: 1px solid #e2e0e0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  aspect-ratio: 2/1;
}

.no-wrap-container{
  display: flex;
  white-space: nowrap;
}

.cash-amount {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #333333;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 140%;
  }
}

</style>
