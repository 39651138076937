<template>
  <div class="invest-wrapper">
    <div class="invest-content">
      <div class="invest-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <div class="invest-amount-title-container">
        <span class="invest-amount-title">
          {{ $t("investment.chooseInvestmentAmount.label") }}
          {{ fundRun | fundShortName }}
        </span>
        <v-icon
          dark
          size="10"
          class="mx-2 my-auto fund-icon"
          :style="`background-color: ${fundColor}`"
        >
          {{ `mdi-${fundData.icon}` }}</v-icon
        >
      </div>

      <div class="invest-amount-subtitle">
        {{ $t("investment.chooseInvestmentAmount.title") }}
      </div>

      <p class="invest-amount-warning">
        {{ $t("investment.chooseInvestmentAmount.warning") }}
      </p>

      <CustomAmountInput
        :redemptions="false"
        bigger
        :disabled="false"
        :initial-amount="amountToInvest"
        @amount-ingressed="amountToInvest = $event"
        @on-enter="amountToInvest >= 1000 ? confirmInvestmentAmount() : {}"
        :width="$vuetify.breakpoint.xs ? '100%' : '420px'"
        class="custom-input"
      />

      <div class="invest-in-usd-label" @click="showUsdInvestmentDialog = true">
        {{ $t("investment.chooseInvestmentAmount.usdInvestment") }}
      </div>

      <ReusableCheckbox
        v-if="showHighestProfilingWarning"
        class="profiling-warning-checkbox"
        @setChecked="acceptedProfilingWarning = !acceptedProfilingWarning"
        :checked="acceptedProfilingWarning"
        :checkboxLabel="
          !profile?.profiling
            ? $t('investment.chooseInvestmentAmount.noProfilingWarning')
            : $t('investment.chooseInvestmentAmount.profilingWarning')
        "
      />

      <div class="next-button-container">
        <NewButton
          id="next-step"
          :disabled="
            loading
              || !amountToInvest
              || amountToInvest < 1000
              || !acceptedProfilingWarning
          "
          :text="$t('investment.chooseInvestmentAmount.buttonText')"
          :loading="performingMutation"
          @on-click="confirmInvestmentAmount()"
        />
      </div>

      <div
        class="invest-cancel-label"
        @click="$router.push({ name: investedCustomer ? 'home' : 'signupDashboard' })"
      >
        {{ $t("base.cancel") }}
      </div>

      <ReusableDialog
        id="failed-patch-dialog"
        :value="errorDialog"
        :title="$t('investment.chooseInvestmentAmount.errorDialog.title')"
        :text="$t('investment.chooseInvestmentAmount.errorDialog.text')"
        @display="errorDialog = $event"
      />

      <ReusableDialog
        v-model="showUsdInvestmentDialog"
        :title="$t('investment.chooseInvestmentAmount.usdInvestmentDialog.title')"
        :text="$t('investment.chooseInvestmentAmount.usdInvestmentDialog.text')"
      >
        <template v-slot:buttonRow>
          <div class="usd-investment-button-row">
            <div class="close-btn">
              <NewButton
                :text="$t('base.close')"
                @on-click="showUsdInvestmentDialog = false"
              />
            </div>

            <div class="chat-btn">
              <NewButton :text="$t('base.goToChat')" @on-click="openChat()" />
            </div>
          </div>
        </template>
      </ReusableDialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import funds from "@/mixins/funds.js";
import CustomAmountInput from "@/components/shared/CustomAmountInput.vue";
import NewButton from "@/components/shared/NewButton.vue";
import ReusableCheckbox from "@/components/shared/ReusableCheckbox.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";

export default {
  name: "ChooseInvestmentAmount",

  data: () => ({
    amountToInvest: null,
    showUsdInvestmentDialog: false,
    acceptedProfilingWarning: true,
    performingMutation: false,
    errorDialog: false,
  }),

  mixins: [funds, customersApiFunctions],

  components: {
    CustomAmountInput,
    NewButton,
    ReusableDialog,
    ReusableCheckbox,
  },

  props: ["fundRun"],

  computed: {
    ...mapGetters([
      "profile",
      "invest",
      "investedCustomer",
      "signupStatus",
      "canSignCompassContract",
      "isMissingSignupData",
      "loading",
      "isMissingTransactionalProfileData",
    ]),

    showHighestProfilingWarning() {
      return (
        this.fundData.highestRecommendedProfilingScore &&
        (!this.profile?.profiling ||
          this.fundData.highestRecommendedProfilingScore > this.profile.profiling)
      );
    },

    fundColor() {
      return this.$options.filters.addFundColor(this.fundRun);
    },

    fundData() {
      return this.getFundStaticData(this.fundRun);
    },
  },

  methods: {
    ...mapActions(["setInvestData", "addToRouteStack"]),

    confirmInvestmentAmount() {
      const investData = {
        ...this.invest,
        fundRun: this.fundRun,
        amount: this.amountToInvest,
      };

      this.setInvestData(investData);

      if (this.showHighestProfilingWarning) {
        this.saveCustomerWarningAnswer();
      } else {
        this.continueToNextStep();
      }
    },

    async continueToNextStep() {
      let targetRoute;

      if (this.isMissingTransactionalProfileData && !this.isMissingSignupData) {
        targetRoute = "investTransactionalProfileIntro";
      } else if (
        this.signupStatus.completedContractCompass &&
        this.isMissingSignupData &&
        this.investedCustomer
      ) {
        targetRoute = "investCompassCompleteData";
      } else if (this.canSignCompassContract) {
        this.addToRouteStack({ name: "investBankSelect" });
        targetRoute = "accountCompassSignatureNotice";
      } else {
        targetRoute = "investBankSelect";
      }

      this.$router.push({ name: targetRoute });
    },

    handleMutationError(error) {
      this.errorDialog = true;
      console.error(error);
    },

    async saveCustomerWarningAnswer() {
      const payload = {
        response: "YES",
        declaration: "ACCEPT_HIGHER_RISK_FUND",
      };

      try {
        this.performingMutation = true;
        const customerDeclarationResponse = await this.createCustomerDeclaration(payload);

        if (customerDeclarationResponse.error) {
          this.handleMutationError(customerDeclarationResponse.error);
        } else {
          this.continueToNextStep();
        }
      } catch (error) {
        this.handleMutationError(error);
      } finally {
        this.performingMutation = false;
      }
    },

    openChat() {
      this.$intercom.showNewMessage(
        this.$t("investment.chooseInvestmentAmount.usdInvestmentDialog.intercom"),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.invest-content {
  margin: 0 20px !important;
}

.invest-amount-warning {
  max-width: 420px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 40px;
}

.next-button-container {
  max-width: 420px;
}

.fund-icon {
  border-radius: 50%;
  height: 18px;
  width: 18px;
}

.invest-in-usd-label,
.invest-cancel-label {
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  text-align: right;
  color: #646363;

  &:hover {
    cursor: pointer;
  }
}

.invest-in-usd-label {
  margin-bottom: 40px;
}

.invest-cancel-label {
  margin-top: 40px;
  font-size: 14px;
}

.custom-input {
  margin-bottom: 20px;
}

.close-btn::v-deep .new-btn {
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  span {
    color: #000000 !important;
  }
}

.usd-investment-button-row {
  display: flex;
  gap: 0 20px;
  margin-top: 40px;
  > * {
    max-width: 200px;
    width: 100%;
  }
}

.profiling-warning-checkbox {
  padding-left: 0px;
  margin: 40px 0px;
  background-color: transparent !important;
}

.profiling-warning-checkbox::v-deep .checkbox-text {
  font-size: 11px !important;
}
</style>
