var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-5"},[(_vm.loading)?_c('div',{staticClass:"skeleton-container"},[_c('v-skeleton-loader',{attrs:{"type":"article","width":"100%"}})],1):(_vm.emptyBalances)?_c('div',{staticClass:"balance"},[_vm._v("$"+_vm._s(_vm._f("currencyNoSymbol")(0)))]):_c('div',{staticClass:"balance",on:{"click":function($event){return _vm.$emit('getDetails')}}},[_vm._v(" $"+_vm._s(_vm._f("currencyNoSymbol")(_vm.currentBalance))+" ")]),(!_vm.loading && !_vm.withdrawals)?_c('div',{staticClass:"results-amount mt-3 mb-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.historicalResults >= 0 ? "+" : "-")+"$"+_vm._s(_vm._f("currencyNoSymbol")(Math.abs(_vm.historicalResults || 0)))+" ")])]):(!_vm.loading && _vm.withdrawals)?_c('div',{staticClass:"results-boxes mt-3 mb-4",on:{"click":function($event){return _vm.$emit('getDetails')}}},[_c('BalanceItem',{attrs:{"id":"current-results","cashAmount":_vm.currentResults,"label":_vm.$t(
          `home.welcome.balanceCard.current${
            _vm.currentResults > 0 ? 'Profits' : 'Results'
          }`,
        )}}),_c('BalanceItem',{attrs:{"id":"historic-results","cashAmount":_vm.historicalResults,"label":_vm.$t(
          `home.welcome.balanceCard.historic${
            _vm.currentResults > 0 ? 'Profits' : 'Results'
          }`,
        )}})],1):_vm._e(),_c('NewButton',{attrs:{"id":"invest-button","text":_vm.$t('base.invest'),"icon":"mdi-plus-circle-outline"},on:{"on-click":function($event){return _vm.$emit('invest')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }