<template>
  <div class="profile-wrapper">
    <div class="profile-step-back">
      <v-icon color="#333333" size="22" @click="$router.go(-1)"> mdi-arrow-left </v-icon>
    </div>

    <h3 class="profile-title">
      {{ $t("profile.investmentCertificates.title") }}
    </h3>

    <v-form class="profile-form">
      <div class="form-label">
        {{ $t("profile.investmentCertificates.label") }}
      </div>
      <div class="investment-certificates-fields">
        <AutoComplete
          id="year"
          :value="year"
          :items="yearList"
          @updateValue="year = $event"
          :placeholder="$t('profile.investmentCertificates.fields.year')"
          class="year-autocomplete"
        />
        <AutoComplete
          id="month"
          item-text="name"
          item-value="value"
          :value="month"
          :items="availableMonths"
          :placeholder="$t('profile.investmentCertificates.fields.month')"
          :disabled="!year"
          @updateValue="month = $event"
          class="month-autocomplete"
        />
      </div>
      <div class="save-changes">
        <NewButton
          id="generate-investement-certificate-button"
          :text="$t('profile.investmentCertificates.button.generate')"
          :disabled="!(year && month)"
          :loading="loading"
          @on-click="requestInvestmentCertificate()"
        />
      </div>
    </v-form>

    <HorizontalButtonDialog
      :value="errorDialog"
      :title="$t('profile.investmentCertificates.errorDialog.title')"
      :text="$t('profile.investmentCertificates.errorDialog.text')"
      :primaryButtonText="$t('profile.investmentCertificates.errorDialog.buttons.close')"
      :secondaryButtonText="$t('profile.investmentCertificates.errorDialog.buttons.chat')"
      @secondaryClick="openChat()"
      @primaryClick="errorDialog = false"
      @display="errorDialog = $event"
    />
  </div>
</template>
<script>
import AutoComplete from "@/components/shared/AutoComplete.vue";
import NewButton from "@/components/shared/NewButton.vue";
import { certificatesFunctions } from "@/mixins/investmentCertificates.js";
import HorizontalButtonDialog from "@/components/shared/HorizontalButtonDialog.vue";

export default {
  name: "InvestmentCertificates",

  components: { NewButton, AutoComplete, HorizontalButtonDialog },

  mixins: [certificatesFunctions],

  data() {
    return {
      errorDialog: false,
      loading: false,
      month: null,
      year: null,
    };
  },

  computed: {
    availableMonths() {
      if (!this.year) return [];
      const currentYear = this.$dayjs().year();
      const currentMonth = this.$dayjs().format("MM");

      if (this.year < currentYear) return this.monthList;

      return this.monthList.filter(month => month.value < currentMonth);
    },
  },

  methods: {
    getReportDate() {
      // Get the last day of the selected month
      const monthLastDay = this.$dayjs(`${this.year}-${this.month}-01`)
        .endOf("month")
        .date();
      return `${this.year}-${this.month}-${monthLastDay}`;
    },

    async requestInvestmentCertificate() {
      this.loading = true;

      const reportDate = this.getReportDate();

      const getCertificateResponse = await this.generateInvestmentCertificate(reportDate);

      this.loading = false;

      if (getCertificateResponse.error) {
        this.errorDialog = true;
      }
    },

    openChat() {
      this.$intercom.showNewMessage(
        this.$t("profile.investmentCertificates.errorDialog.intercom"),
      );
    },

    requestByChat() {
      this.$intercom.showNewMessage(
        this.$t("profile.investmentCertificates.underMaintenance.intercom"),
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.save-changes {
  margin-top: 40px;
}

.investment-certificates-fields {
  display: flex;
  gap: 0 10px;
}

.year-autocomplete {
  width: 140px !important;
}

.month-autocomplete {
  // substract the year autocomplete width and the gap
  flex: 0 0 calc(100% - (140px + 10px));
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  color: #333;
  margin-bottom: 20px;
}

.under-maintenance-content{
  max-width: 420px;
}

.under-maintenance-text{
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: left;
  margin-bottom: 40px;

}
</style>
