<template>
  <v-btn
    class="new-btn"
    :loading="loading"
    :disabled="disabled"
    :type="type"
    :class="`${buttonClass}-btn`"
    :style="`background-color: ${backgroundColor};`"
    :href="link"
    :target="link ? '_blank' : '_self'"
    @click="$emit('on-click')"
  >
    <v-icon dark size="18" color="#ffffff" v-if="icon"> {{ icon }} </v-icon>

    <span class="new-btn-text" :class="{ 'ml-2': icon }">
      {{ text }}
    </span>

    <template v-slot:loader>
      <div class="center-in-page loader">
        <div class="circle" v-for="n in 3" :key="n" />
      </div>
    </template>
  </v-btn>
</template>

<script>
export default {
  name: "NewButton",

  props: {
    text: String,

    buttonClass: {
      type: String,
      default: "black",
      validator(value) {
        return ["black", "black-small", "black-outlined"].includes(value);
      },
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "button",
    },

    link: String,

    icon: String,
  },

  computed: {
    backgroundColor() {
      let bgColor = "";
      if (this.disabled) bgColor = "#BDBDBD !important";
      return bgColor;
    },
  },
};
</script>

<style lang="scss" scoped>
.black-btn,
.black-outlined-btn {
  width: 100% !important;
  height: 60px !important;
  border-radius: 10px;

  span {
    font-size: 14px;
    line-height: 16px;
  }
}

.black-btn {
  background-color: #000000 !important;
  span {
    color: #ffffff !important;
  }
}

.black-outlined-btn {
  background-color: transparent !important;
  border: 1px solid #000000;
  span {
    color: #000000 !important;
  }
}
.black-small-btn {
  width: 100% !important;
  height: 40px !important;
  border-radius: 10px;
  background-color: #000000 !important;
  span {
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }
}

.new-btn {
  box-shadow: none;
  letter-spacing: normal;
  text-transform: none;
}
.loader {
  .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation: grow 1s linear infinite;
    margin: 7px;
    animation-delay: 0.33s;
  }
  .circle:nth-child(2) {
    animation: grow 1s linear infinite;
    animation-delay: 0.66s;
  }
  .circle:nth-child(3) {
    animation: grow 1s linear infinite;
  }
  @keyframes grow {
    0% {
      transform: scale(1.5);
    }
    25% {
      transform: scale(0.75);
    }
    75% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(1.5);
    }
  }
}
</style>
