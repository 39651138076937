<template>
  <div class="profile-wrapper profile-centered-wrapper">
    <div class="profile-content">
      <div class="profile-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <div id="salary-range" class="transactional-profile-attribute">
        <h3 id="salary-range-title" class="transactional-profile-title">
          {{ $t("profile.transactionalProfile.questions.salaryRange.title") }}
        </h3>

        <div
          v-for="(salaryOption, index) in salaryRangeOptions"
          :id="`salary-${salaryOption.value}`"
          :key="index"
          class="profile-option-container"
          :class="{ 'selected-option': salaryRange === salaryOption.value }"
          @click="salaryRange = salaryOption.value"
        >
          <p class="profile-option-text">
            {{
              $t(
                `profile.transactionalProfile.questions.salaryRange.options.${salaryOption.value}`,
              )
            }}
          </p>
        </div>

      </div>

      <div id="networth" class="transactional-profile-attribute">
        <h3 id="networth-title" class="transactional-profile-title">
          {{ $t("profile.transactionalProfile.questions.netWorth.title") }}
        </h3>

        <div
          v-for="(netWorthOption, index) in netWorthOptions"
          :id="`networth-${netWorthOption.value}`"
          :key="index"
          class="profile-option-container"
          :class="{ 'selected-option': netWorth === netWorthOption.value }"
          @click="netWorth = netWorthOption.value"
        >
          <p class="profile-option-text">
            {{
              $t(
                `profile.transactionalProfile.questions.netWorth.options.${netWorthOption.value}`,
              )
            }}
          </p>
        </div>
      </div>

      <div class="answers-hints">
        {{$t('profile.transactionalProfile.questions.answersHint')}}
      </div>

      <NewButton
        :text="$t('base.nextStep')"
        :disabled="!salaryRange || !netWorth"
        :loading="performingMutation"
        @on-click="createCustomerPatch()"
      />

      <div
        class="profile-cancel-container"
        @click="$router.push({ name: 'home' })"
      >
        <p class="profile-cancel-text">
          {{ $t("base.cancel") }}
        </p>
      </div>

      <ReusableDialog
        id="failed-patch-dialog"
        :value="errorDialog"
        :title="$t('profile.transactionalProfile.questions.errorDialog.title')"
        :text="$t('profile.transactionalProfile.questions.errorDialog.text')"
        @display="errorDialog = $event"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import userSelects from "@/mixins/userSelects.js";

import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "TransactionalProfileQuestions",

  data: () => ({
    salaryRange: null,
    netWorth: null,
    errorDialog: false,
    performingMutation: false,
  }),

  mixins: [userSelects, customersApiFunctions],

  components: { NewButton, ReusableDialog },

  methods: {
    ...mapActions(["setAccountData"]),

    handleMutationErrors() {
      this.errorDialog = true;
      this.performingMutation = false;
    },

    async handleSuccessfulMutation() {
      // Save the selected salary range and net worth to the store
      this.setAccountData({ salaryRange: this.salaryRange, netWorth: this.netWorth });

      // Redirects to the investment flow
      this.$router.push({ name: "investBankSelect" });
    },

    async createCustomerPatch() {
      const payload =
        {
          salaryRange: this.salaryRange,
          netWorth: this.netWorth,
        };

      try {
        this.performingMutation = true;
        const { errors } = await this.patchCustomerData({ payload });

        this.performingMutation = false;

        if (!errors) {
          this.handleSuccessfulMutation();
        } else {
          this.handleMutationErrors();
        }
      } catch (error) {
        console.error(error);
        this.handleMutationErrors();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.selected-option{
  border: 1px solid #333333;
}

.transactional-profile-attribute + .transactional-profile-attribute{
  margin-top: 40px;
}

.answers-hints{
  font-family: Work Sans;
  font-size: 11px;
  font-weight: 500;
  line-height: 17.6px;
  text-align: left;
  color: #646363;
  margin: 40px 0;

}

.profile-wrapper{
  margin: 40px 20px;
  @media (max-width: 600px) {
    margin: 30px 20px;
  }
}

</style>
