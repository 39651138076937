<template>
  <div class="redemption-wrapper">
    <h2 class="redemption-header">
      {{ $t("redemptions.chooseRedemptionFlow.title") }}
    </h2>

    <div class="options">
      <ClickableOptionCard
        id="redemption-card"
        leftIcon="mdi-arrow-top-right-thin-circle-outline"
        :title="$t('redemptions.chooseRedemptionFlow.options.redemption')"
        :disabled="loadingProfile || isUnderage || isJuridicPerson"
        @optionClicked="handleOption('redemption')"
      />

      <ClickableOptionCard
        id="reinvestment-card"
        leftIcon="mdi-swap-horizontal-circle-outline"
        :title="$t('redemptions.chooseRedemptionFlow.options.reinvestment')"
        :disabled="loadingProfile || isUnderage || isJuridicPerson"
        @optionClicked="handleOption('reinvestment')"
      />

      <UnauthorizedRedemption
        :unauthorizedAccountType="unauthorizedAccountType"
        :dialog="showUnauthorizedDialog"
        @closeDialog="showUnauthorizedDialog = false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClickableOptionCard from "@/components/shared/ClickableOptionCard.vue";
import UnauthorizedRedemption from "@/components/redemptions/UnauthorizedRedemption.vue";

export default {
  name: "ChooseRedemptionFlow",

  data: () => ({
    showUnauthorizedDialog: false,
    unauthorizedAccountType: null,
  }),

  components: {
    ClickableOptionCard,
    UnauthorizedRedemption,
  },

  watch: {
    loadingProfile() {
      this.checkPermissions();
    },
  },

  computed: {
    ...mapGetters(["loadingProfile", "isUnderage", "isJuridicPerson"]),

  },

  methods: {
    ...mapActions(["setRedemptionData", "setReinvestmentData"]),

    handleOption(option) {
      if (this.showUnauthorizedDialog) return;

      this.setRedemptionData({});
      this.setReinvestmentData({});

      if (option === "redemption") {
        this.$router.push({ name: "redemptionReasons" });
      } else {
        this.$router.push({ name: "selectOriginFund" });
      }
    },

    checkPermissions() {
      if (this.isJuridicPerson || this.isUnderage) {
        this.showUnauthorizedDialog = true;
        this.unauthorizedAccountType = this.isJuridicPerson ? "legalEntity" : "children";
      }
    },
  },

  mounted() {
    this.checkPermissions();
  },
};
</script>

<style lang="scss">
.redemption-wrapper,
.reinvestment-wrapper {
  margin: 90px 120px;
  @media (max-width: 960px) {
    margin: 30px 20px;
    padding-bottom: 20px;
    min-height: 600px !important;
  }

  @media (max-width: 380px) {
    padding: 0;
    min-height: 500px !important;
  }
}

.redemption-content,
.reinvestment-content {
  max-width: 420px;
  width: 100%;
}

.redemption-centered-wrapper,
.reinvestment-centered-wrapper {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.redemption-header,
.reinvestment-header {
  font-weight: 500;
  font-size: 22px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 40px;
}

.redemption-step-back,
.reinvestment-step-back {
  margin-bottom: 20px;
}

.options {
  max-width: 420px;
  > * {
    margin-bottom: 10px;
  }
}

.redemption-flex-columns,
.reinvestment-flex-columns {
  display: flex;
  gap: 30px;
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 60px;
  }
}

.redemption-column,
.reinvestment-column {
  flex: 1;
}

.redemption-column,
.reinvestment-column > * {
  max-width: 420px;
}

.cancel-text {
  font-weight: 500;
  text-align: right !important;
  color: #646363 !important;
  margin-top: 40px;
  &:hover {
    cursor: pointer;
  }
}

.redemption-loading-container,
.reinvestment-loading-container {
  position: relative;
  min-height: 600px;
}

#reinvestment-card .option-subtitle {
  line-height: 130% !important;
}
</style>
