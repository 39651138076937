/* eslint-disable no-param-reassign */
export const signupFunctions = {
  methods: {
    signupHeaders(additionalHeaders = {}) {
      return {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...additionalHeaders,
      };
    },

    async sendValidationData() {
      try {
        const formData = new FormData();
        formData.append("email", this.$store.state.signup.email || localStorage.getItem("userEmail"));
        formData.append("rut", this.$store.state.signup.rut);
        formData.append("serial", this.$store.state.signup.documentNumber.replace(/[.\s]/g, ""));
        formData.append("front", this.$store.state.signup.frontImage);
        formData.append("back", this.$store.state.signup.backImage);
        const response = await this.axios.post(`${process.env.VUE_APP_SIGNUP_BACKEND}/steps/validation`, formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.status !== 200) {
          return {
            data: response.data,
            error: true,
            errorType: "serverError",
          };
        }
        this.$store.commit("setSignupStatus", { completedValidation: true });
        return {
          data: response.data,
          error: false,
        };
      } catch (e) {
        return {
          error: true,
          errorType: "internalError",
        };
      }
    },

    async sendIdentificationData() {
      try {
        const response = await this.axios.post(`${process.env.VUE_APP_SIGNUP_BACKEND}/steps/identification`, {
          email: this.$store.state.signup.email || localStorage.getItem("userEmail"),
          name: this.$store.state.signup.name,
          family_name: this.$store.state.signup.familyName,
          gender: this.$store.state.signup.gender,
          birthdate: this.$store.state.signup.birthdate,
          birth_country: this.$store.state.signup.birthCountry,
          nationality: this.$store.state.signup.nationality,
          phone: this.$store.state.signup.phone,
          country: this.$store.state.signup.country,
          region: this.$store.state.signup.region,
          locality: this.$store.state.signup.locality,
          street: this.$store.state.signup.street,
          street_number: this.$store.state.signup.houseNumber,
          interior_number: this.$store.state.signup.interiorNumber,
          marital_status: this.$store.state.signup.maritalStatus,
          spouse_name: this.$store.state.signup.spouseName,
          spouse_family_name: this.$store.state.signup.spouseFamilyName,
          spouse_rut: this.$store.state.signup.spouseIdentifier,
          conjugal_regime: this.$store.state.signup.conjugalRegime,
        }, {
          headers: this.signupHeaders(),
        });
        if (response.data.status !== 200) {
          return {
            data: response.data,
            error: true,
            errorType: "serverError",
          };
        }
        this.$store.commit("setSignupStatus", { completedIdentification: true });
        return {
          data: response.data,
          error: false,
        };
      } catch (e) {
        return {
          error: true,
          errorType: "internalError",
        };
      }
    },

    async sendProfilingData() {
      try {
        const signupState = this.$store.state.signup;
        const profileState = this.$store.getters.profile;
        const response = await this.axios.post(`${process.env.VUE_APP_SIGNUP_BACKEND}/steps/profiling`, {
          email: signupState.email || localStorage.getItem("userEmail"),
          profiling: this.$store.getters.profilingScore,
          referral: signupState.referral,
          capital_origin: profileState.capitalOrigin || signupState.capitalOrigin,
          net_worth: profileState.netWorth || signupState.netWorth,
          occupation: profileState.occupation || signupState.occupation,
          profession: profileState.profession || signupState.profession,
          self_employed: profileState.selfEmployed || signupState.selfEmployed,
          is_pep: profileState.isPep || signupState.declarations.isPep,
          is_fatca: profileState.isFatca || signupState.declarations.isFatca,
          is_crs: profileState.isCrs || signupState.declarations.isCrs,
          crs_values: signupState.declarations.tinValues,
        }, {
          headers: this.signupHeaders(),
        });
        if (response.data.status !== 200) {
          return {
            data: response.data,
            error: true,
            errorType: "serverError",
          };
        }
        this.$store.commit("setSignupStatus", { completedProfiling: true });
        return {
          data: response.data,
          error: false,
        };
      } catch (e) {
        return {
          error: true,
          errorType: "internalError",
        };
      }
    },

    async sendAmerisContractData() {
      try {
        const response = await this.axios.post(`${process.env.VUE_APP_SIGNUP_BACKEND}/steps/contract`, {
          email: this.$store.state.signup.email || localStorage.getItem("userEmail"),
          reinvest_dividends: this.$store.state.signup.reinvestDividends,
          origin: "https://mi.dvacapital.com",
        }, {
          headers: this.signupHeaders(),
        });

        if (response.data.status !== 200) {
          return {
            data: response.data,
            error: true,
            errorType: "serverError",
          };
        }
        this.$store.commit("setSignupStatus", { completedContract: true });
        return {
          data: response.data,
          error: false,
        };
      } catch (e) {
        return {
          error: true,
          errorType: "internalError",
        };
      }
    },

    async saveUserEmail(email) {
      if (!email) {
        return {
          responseData: {},
          error: true,
          loading: false,
          errorType: "payloadError",
          errorMessage: "signup.errors.default",
        };
      }

      this.$store.commit("setEmail", email);
      this.$cookies.set("emailToRegister", email, "1h");

      try {
        const response = await this.axios.post(
          `${process.env.VUE_APP_SIGNUP_BACKEND}/email`,
          {
            email,
            ...(this.$store.state.signup.referralSource ?
              { referralSource: this.$store.state.signup.referralSource } : {}),
          },
          {
            headers: this.signupHeaders(),
          },
        );

        if (response.data.code !== 200) {
          return {
            responseData: {},
            error: true,
            loading: false,
            errorType: "serverError",
            errorMessage: `signup.errors.${response.data.code}`,
          };
        }
        return {
          responseData: response.data,
          error: false,
          loading: false,
          errorType: "",
          errorMessage: "",
        };
      } catch (error) {
        return {
          responseData: {},
          error: true,
          loading: false,
          errorType: "serverError",
          errorMessage: error,
        };
      }
    },

    async getContractPreview(agfName) {
      try {
        let response;
        if (agfName === "COMPASS") {
          // Fetch the compacss contract in PDF from S3 bucket
          const s3Response = await fetch("https://dvacapital-files.s3.amazonaws.com/contrato-compass.pdf");

          if (!s3Response.ok) {
            return {
              error: true,
            };
          }
          response = await s3Response.blob();
        } else {
          // Fetch the Ameris contract in PDF from the signup API
          response = await this.axios.get(
        `${process.env.VUE_APP_SIGNUP_BACKEND}/newcontract/preview`,
        {
          headers: this.signupHeaders(),
          responseType: "arraybuffer",
        },
          );
          if (response.data.code && response.data.code !== 200) {
            return {
              error: true,
            };
          }
          response = new Blob([response.data], { type: "application/pdf" });
        }

        // Create a Blob and download the PDF
        const blob = new Blob([response], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "contrato.pdf";
        link.click();

        return {
          error: false,
        };
      } catch {
        return {
          error: true,
        };
      }
    },

  },
};
