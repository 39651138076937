<template>
  <div class="reset-wrapper">
    <div class="reset-content">
      <div class="back-arrow">
        <v-btn icon large @click="$router.push({ name: 'login' })">
          <v-icon color="#000"> mdi-arrow-left</v-icon>
        </v-btn>
      </div>

      <div class="center-in-page">
        <v-img class="mi-dva-logo" :src="require('@/assets/mi-dva-logo.svg')" />
      </div>
      <v-form v-model="valid" class="reset-form">
        <p class="reset-text mb-10">{{ $t("reset.title") }}</p>
        <v-text-field
          class="reset-field"
          @keyup.enter="resetSend"
          v-model="credentials.email"
          :rules="emailRules"
          :placeholder="$i18n.t('reset.emailLabel')"
          required
          outlined
          rounded
          hide-details
        />

        <NewButton
          id="reset-btn"
          :loading="loading"
          :text="$i18n.t('reset.resetButton')"
          :disabled="!valid || loading"
          @on-click="resetSend"
        />

        <p v-if="recoverError" class="error-credentials">
          {{ $t("reset.error") }}
        </p>
      </v-form>

    </div>
    <v-dialog v-model="successDialog" max-width="290">
      <v-card>
        <v-card-text class="pt-10">
          {{ $t("reset.popup.text") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="deep-purple darken-1" text @click="successDialog = false">
            {{ $t("reset.popup.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import NewButton from "@/components/shared/NewButton.vue";

export default {
  components: { NewButton },
  data() {
    return {
      valid: true,
      recoverError: false,
      loading: false,
      credentials: {
        email: null,
      },
      emailRules: [
        v => !!v || this.$t("register.registerForm.error.emailRequired"),
        v => /.+@.+\..+/.test(v) || this.$t("register.registerForm.error.emailRequired"),
      ],
      successDialog: false,
    };
  },
  methods: {
    async resetSend() {
      this.loading = true;
      await this.$auth.recoverPassword(this.credentials.email.trim(), this.whenErrorCallback);
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.loading = false;
    },
    whenErrorCallback(err) {
      if (err) {
        this.recoverError = true;
      } else {
        this.successDialog = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.popup-close-text {
  color: #4e5ff1;
}

.reset-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 960px) {
    align-items: inherit;
  }
}

.back-arrow {
  display: flex;
  justify-content: left;

  @media (max-width: 960px) {
    margin: 0 0 60px 0
  }
}

.reset-container {
  /* Grises/Blanco */

  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 580px;

  @media (max-width: 960px) {
    box-shadow: none;
    max-width: 330px;
  }
}

.reset-button {
  margin-bottom: 84px;
}

#reset-btn{
  margin-top: 40px;
}

.reset-form {
  text-align: center;
  padding-top: 20% !important;

  @media (max-width: 960px) {
    padding-top: 30% !important;
    width: 100%;
  }
}

.error-credentials {
  font-size: 14px;
  color: red;
}

.reset-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #333333;
}

.mi-dva-logo {
  max-width: 120px;
}
</style>
