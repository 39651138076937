<template>
  <div class="profile-wrapper">
    <div class="profile-step-back">
      <v-icon color="#333333" size="22" @click="$router.go(-1)"> mdi-arrow-left </v-icon>
    </div>

    <h3 class="profile-title">
      {{ $t("profile.documentsView.title") }}
    </h3>

    <div
      class="pending-contract-container"
      v-if="pendingContracts > 0"
      @click="handleContractRedirection()"
    >
      <div class="pending-contract">
        <div class="pending-contract-left-column">
          <v-icon color="#fff" size="18"> mdi-clipboard-clock-outline </v-icon>

          <div class="pending-contract-text">
            {{
              $t(
                `profile.documentsView.pendingContracts.${
                  pendingContracts === 1 ? "single" : "multiple"
                }`,
                { pendingContracts },
              )
            }}
          </div>
        </div>

        <v-icon color="#fff"> mdi-chevron-right </v-icon>
      </div>
    </div>

    <div class="account-options">
      <div
        v-for="(option, index) in accountOptions"
        :key="index"
        class="account-option-item"
      >
        <AccountOption
          class="account-option"
          :id="option.id"
          :leftIcon="option.leftIcon"
          :rightIcon="option.rightIcon"
          :text="$t(`profile.documentsView.options.${option.name}`)"
          @optionClicked="handleAccountOptionClick(option)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AccountOption from "@/components/profile/AccountOption.vue";

export default {
  name: "DocumentsView",

  components: { AccountOption },

  computed: {
    ...mapGetters(["canSignCompassContract"]),

    pendingContracts() {
      // Fill with the contract list
      const contractsList = [
        {
          pending: this.canSignCompassContract,
        },
      ];
      return contractsList.filter(contract => contract.pending).length;
    },

    accountOptions() {
      const options = [
        // {
        //   name: "accountContractsAgreements",
        //   leftIcon: "mdi-clipboard-edit-outline",
        // },
        {
          name: "investmentCertificates",
          leftIcon: "mdi-chart-box-outline",
        },
      ];

      // Set default values for rightIcon and hasAlertState if not provided
      // We also set the routes for each option. The only one without route is the logout option
      return options.map(option => ({
        ...option,
        id: this.getAccountOptionId(option.name),
        route: this.getAccountOptionRoute(option.name),
        rightIcon: "mdi-chevron-right",
      }));
    },
  },

  methods: {
    getAccountOptionId(optionName) {
      // camelCase to kebab-case
      return optionName.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
    },

    getAccountOptionRoute(optionName) {
      if (optionName === "logout" || !optionName) return null;

      // Capitalize the first letter of the account option
      const capitalizedName = optionName.charAt(0).toUpperCase() + optionName.slice(1);

      // For example, accountInvestmentCertificates and accountRiskProfile
      return `account${capitalizedName}`;
    },

    handleAccountOptionClick(option) {
      this.$router.push({ name: option.route });
    },

    handleContractRedirection() {
      this.$router.push({ name: "accountPendingContracts" });
    },
  },
};
</script>

<style lang="scss" scoped>
.pending-contract-container {
  border-bottom: 1px solid #e2e0e0;
  padding-bottom: 40px;
  margin-bottom: 40px;
  max-width: 420px;
}
.pending-contract {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  background-color: #6d48ff;
  padding: 20px;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
}

.pending-contract-left-column {
  display: flex;
  justify-content: left;
  gap: 0 10px;
}

.pending-contract-text {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
</style>
