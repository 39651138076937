<template>
  <div class="id-document-wrapper">
    <div class="id-document-content">
      <div class="signup-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h2 class="id-document-title id-document-title">
        {{ $t("profile.updateIdDocument.title") }}
      </h2>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="saveCustomerDocuments()"
        class="id-document-form file-inputs"
      >

        <TextField
          id="name-input"
          :label="$t('profile.updateIdDocument.identifier.label')"
          :value="profile.identifier"
          disabled
          class="id-document-field"
        />

        <p class="id-document-field-hint">
          {{ $t("profile.updateIdDocument.identifier.hint") }}
        </p>

        <div class="label-row">
          <div class="form-label">
            {{ $t("profile.updateIdDocument.documentNumber.label") }}
          </div>
          <div
            class="form-label where-is-label"
            @click="displayHintDialog = true"
          >
            {{ $t("profile.updateIdDocument.documentNumber.whereIsIt") }}
          </div>
        </div>

        <DocumentNumberTextField
          id="document-number"
          :value="documentNumber"
          :placeholder="$t('profile.updateIdDocument.documentNumber.placeholder')"
          @updateValue="documentNumber = $event"
          class="id-document-field serial-number-field"
        />

        <FileInput
          id="front-identification-card"
          v-model="frontUpload"
          :rules="mustCompleteRule"
          :label="$t('profile.updateIdDocument.images.label')"
          :placeholder="$t('profile.updateIdDocument.images.front.placeholder')"
          ref="filef"
          @updateValue="frontUpload = $event"
          @delete="frontUpload = null"
          @on-change="handleImageSize(frontUpload, 'frontUpload')"
          class="id-document-field"
        />

        <FileInput
          id="back-identification-card"
          v-model="backUpload"
          :rules="mustCompleteRule"
          :placeholder="$t('profile.updateIdDocument.images.back.placeholder')"
          ref="fileb"
          @updateValue="backUpload = $event"
          @delete="backUpload = null"
          @on-change="handleImageSize(backUpload, 'backUpload')"
          class="id-document-field"
        />

        <p class="id-document-hint">
          {{ $t("profile.updateIdDocument.cmfExplanation") }}
        </p>

        <NewButton
          id="save-changes"
          class="save-changes-button"
          :class="{ 'success-state-btn': successState }"
          :text="successState ? $t('base.savedChanges') : $t('base.save')"
          :type="'submit'"
          :loading="loading"
          :disabled="!valid || loading"
        />

        <div class="id-document-cancel-container" @click="onCancel()">
          <p class="id-document-cancel">
            {{ $t("base.cancel") }}
          </p>
        </div>
      </v-form>
    </div>

    <ReusableDialog
      v-model="errorDialog"
      :title="$t('profile.updateIdDocument.errorDialog.title')"
      :text="$t('profile.updateIdDocument.errorDialog.text')"
      @display="errorDialog = $event"
      v-if="errorDialog"
      id="error-dialog"
    >
      <template v-slot:buttonRow>
        <div class="signup-button-row">
          <div class="id-document-chat-button">
            <NewButton :text="$t('base.goToChat')" @on-click="openChat()" />
          </div>

          <div class="keep-trying-btn">
            <NewButton :text="$t('base.close')" @on-click="errorDialog = false" />
          </div>
        </div>
      </template>
    </ReusableDialog>

    <DocumentNumberHintDialog
      :dialog="displayHintDialog"
      @displayDialog="displayHintDialog = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { imagesFunctions } from "@/mixins/images.js";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import NewButton from "@/components/shared/NewButton.vue";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import FileInput from "@/components/shared/FileInput.vue";
import DocumentNumberTextField from "@/components/shared/DocumentNumberTextField.vue";
import TextField from "@/components/shared/TextField.vue";
import DocumentNumberHintDialog from "@/views/signup/identification/DocumentNumberHintDialog.vue";

export default {
  name: "UpdateIdDocument",

  components: {
    NewButton,
    ReusableDialog,
    FileInput,
    DocumentNumberTextField,
    TextField,
    DocumentNumberHintDialog,
  },

  mixins: [imagesFunctions, customersApiFunctions],

  data() {
    return {
      loading: false,
      errorDialog: false,
      displayHintDialog: false,
      valid: false,
      documentNumber: null,
      frontUpload: null,
      backUpload: null,
      mustCompleteRule: [v => !!v || this.$t("shared.errors.mustComplete")],
      successState: false,
    };
  },

  computed: {
    ...mapGetters(["profile", "nextRouteFromStack"]),
  },

  methods: {
    ...mapActions(["popFromRouteStack", "setAccountData"]),

    handleErrorResponse(errors) {
      this.errorDialog = true;
      this.loading = false;
      console.error(errors);
    },

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    async handleSuccessfulMutation(payload) {
      // Sets the document number and the default document expiration date locally
      this.setAccountData({ ...payload, documentExpirationDate: null });
      await this.showSuccessState();
      const nextRoute = this.nextRouteFromStack.name;
      this.popFromRouteStack();

      this.$router.push({ name: nextRoute });
    },

    async saveCustomerDocuments() {
      try {
        this.loading = true;

        const { removeDots } = this.$options.filters;

        const data = {
          payload: { documentNumber: removeDots(this.documentNumber) },
        };

        const customerPatchResponse = await this.patchCustomerData(data);

        if (customerPatchResponse.errors) {
          this.handleErrorResponse(customerPatchResponse.errors);
          return;
        }

        const idCardUploadResponse = await this.updateIdDocument({
          frontIdentificationDocument: this.frontUpload,
          backIdentificationDocument: this.backUpload,
        });

        if (idCardUploadResponse.errors?.error) {
          this.handleErrorResponse(idCardUploadResponse.errors?.error);
          return;
        }

        this.handleSuccessfulMutation(data.payload);
      } catch (error) {
        this.handleErrorResponse(error);
      }
    },

    onCancel() {
      const cancelRoute = this.nextRouteFromStack?.cancelRoute;

      this.popFromRouteStack();
      this.$router.push({ name: cancelRoute || "home" });
    },

    openChat() {
      this.$intercom.showNewMessage(
        this.$t("profile.updateIdDocument.errorDialog.intercom"),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.id-document-wrapper {
  display: flex;
  justify-content: center;
  margin: 40px 20px;

  @media (max-width: 600px) {
    margin: 30px 20px;
  }
}

.id-document-content {
  max-width: 420px;
  width: 100%;
}

p.id-document-hint {
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  color: #646363;
  margin: 40px 0 0 0 !important;
}

.id-document-cancel-container {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 40px;
  &:hover {
    cursor: pointer;
  }
}

.id-document-cancel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #646363;
}

.id-document-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  color: #333;
}

.save-changes-button {
  margin-top: 40px;
}

.id-document-chat-button::v-deep .new-btn {
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  span {
    color: #000000 !important;
  }
}

.id-document-title {
  font-size: 18px !important;
}

.id-document-field + .id-document-field {
  margin-top: 10px;
}

.serial-number-field {
  margin-bottom: 20px;
}

.id-document-form {
  margin: 40px 0;
}

.success-state-btn {
  background-color: #6d48ff !important;
  border: unset;
}

.id-document-field-hint{
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #646363;
  padding: 0;
  margin: 10px 0 20px 0;
}

.label-row {
  display: flex;
  justify-content: space-between;
}

.form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #333333;
  margin-bottom: 10px;
}

.where-is-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #646363;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

</style>
