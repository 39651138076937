import { getFintoc } from "@fintoc/fintoc-js";

// You can read more about the parameters below
const options = {
  holderType: process.env.VUE_APP_FINTOC_HOLDER_TYPE,
  publicKey: process.env.VUE_APP_FINTOC_PUBLIC_KEY,
  product: process.env.VUE_APP_FINTOC_PRODUCT,
};

export const fintocMixin = {
  data() {
    return {
      widget: null,
    };
  },

  methods: {
    async openFintoc({
      customerIdentifier, institutionId, widgetToken, onSuccess, onExit, onEvent,
    }) {
      const Fintoc = await getFintoc();
      this.widget = Fintoc.create({
        username: { value: customerIdentifier, editable: false },
        widgetToken,
        institutionId,
        onSuccess,
        onExit,
        onEvent,
        ...options,
      });
      this.widget.open();
    },

    async closeFintoc() {
      this.widget.destroy();
    },
  },
};
