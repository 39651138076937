<template>
  <div class="profile-wrapper profile-centered-wrapper">
    <div class="profile-content">
      <div class="profile-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="profile-subtitle">
        {{ $t("profiling.intro.title") }}
      </h3>
      <v-img
        class="profiling-intro-image"
        :src="require('@/assets/profiling-intro.svg')"
        :lazy-src="require('@/assets/profiling-intro-lazy.png')"
      />
      <NewButton
        id="next-step"
        class="next-step-button"
        :text="$t('profiling.intro.button')"
        @on-click="handleRoute()"
      />
    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "ProfilingIntro",

  components: { NewButton },

  methods: {
    handleRoute() {
      this.$router.push({
        name: "accountProfilingQuestion",
        params: { currentQuestionNumber: 1 },
      });
    },
  },
};
</script>
<style lang="scss" scoped>

.profile-wrapper{
  margin: 40px 20px;
  @media (max-width: 600px) {
    margin: 30px 20px;
  }
}
.profile-step-back {
  padding-bottom: 25px !important;
}

.profiling-intro-image {
  margin: 50px 0px;
}

.next-step-button{
  margin-top: 40px;
  @media (max-width: 600px) {
    margin: 0;
  }
}
</style>
