<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="signup-step-back">
        <v-icon id="signup-step-back" color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="signup-subtitle">
        {{ $t("profiling.result.header") }}
      </h3>
      <h2 class="signup-title">
        {{ $t(`profiling.profiles.options.${customerRiskProfile.type}.name`) }}
      </h2>
      <p class="profiling-result-description">
        {{ $t(`profiling.profiles.options.${customerRiskProfile.type}.description`) }}
      </p>
      <NewButton
        id="signup-modify-profiling"
        class="signup-modify-profiling-button"
        button-class="black-outlined"
        :text="$t('profiling.result.modifyButton')"
        @on-click="showManualProfilingDialog = true"
      />
      <NewButton
        id="next-step"
        class="signup-step-button"
        :class="{ 'success-state-btn': successState }"
        :text="buttonText"
        :loading="performingMutation"
        @on-click="onAcceptProfilingClick()"
      />

      <div class="signup-cancel-container" @click="onCancel()">
        <p class="signup-cancel">
          {{ $t("profiling.shared.cancel") }}
        </p>
      </div>
    </div>
    <ManualProfilingDialog
      :dialog="showManualProfilingDialog"
      :currentRiskProfile="customerRiskProfile"
      @displayManualProfilingDialog="showManualProfilingDialog = $event"
    />

    <ReusableDialog
      v-model="showErrorDialog"
      :title="$t('profiling.result.errorDialog.title')"
      :text="$t('profiling.result.errorDialog.text')"
      @display="showErrorDialog = $event"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import values from "@/helpers/values.js";
import NewButton from "@/components/shared/NewButton.vue";
import ManualProfilingDialog from "@/components/signup/profiling/ManualProfilingDialog.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";

export default {
  name: "ProfilingResult",

  mixins: [values, customersApiFunctions],

  components: { NewButton, ManualProfilingDialog, ReusableDialog },

  data() {
    return {
      showManualProfilingDialog: false,
      performingMutation: false,
      showErrorDialog: false,
      successState: false,
    };
  },

  computed: {
    ...mapGetters(["profilingScore"]),

    isSignup() {
      return this.$route.meta?.signup;
    },

    buttonText() {
      if (this.isSignup) {
        return this.$t("base.nextStep");
      }
      if (this.successState) return this.$t("base.savedChanges");

      return this.$t("base.saveChanges");
    },

    customerRiskProfile() {
      if (!this.profilingScore) return { type: "", averageScore: 0 };
      return this.riskProfilesList.find(
        riskProfile => this.profilingScore >= riskProfile.scoreRange[0] &&
          this.profilingScore <= riskProfile.scoreRange[1],
      );
    },
  },

  methods: {
    ...mapActions(["setAccountData"]),

    onAcceptProfilingClick() {
      if (this.isSignup) {
        this.$router.push({ name: "signupCapitalOrigin" });
      } else {
        this.updateCustomerProfiling();
      }
    },

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    async handleSuccessfulMutation() {
      // Replacing the customer profiling locally
      this.setAccountData({ profiling: this.profilingScore });
      await this.showSuccessState();

      // Navigate to the profile main view
      this.$router.push({ name: "account" });
    },

    handleMutationErrors() {
      this.showErrorDialog = true;
      this.performingMutation = false;
    },

    async updateCustomerProfiling() {
      try {
        this.performingMutation = true;
        const payload = { profiling: this.profilingScore };
        const { errors } = await this.patchCustomerData({ payload });
        this.performingMutation = false;

        if (errors) {
          this.handleMutationErrors();
        } else {
          await this.handleSuccessfulMutation();
        }
      } catch (error) {
        console.error(error);
        this.handleMutationErrors();
      }
    },

    onCancel() {
      const cancelRoute = this.isSignup ? "signupDashboard" : "accountEditRiskProfile";
      this.$router.push({ name: cancelRoute });
    },

  },
};
</script>
<style lang="scss" scoped>
.signup-title {
  margin: 40px 0px !important;
}

.profiling-result-description {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #000000;
  margin: 0px;
}

.signup-step-button {
  margin-top: 40px;
}

.signup-modify-profiling-button {
  width: 105px !important;
  height: 40px !important;
  margin-top: 40px;
}

.success-state-btn {
  background-color: #6d48ff !important;
  border: unset;
}
</style>
