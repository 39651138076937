<template>

  <v-dialog
    id="document-number-hint-dialog"
    v-model="displayDialog"
    :max-width="$vuetify.breakpoint.xs ? '100%' : 500"
    content-class="rounded-lg elevation-0 absolute-dialog-position"
  >
    <v-card elevation="0" rounded="lg">
      <div class="id-document-dialog-content">

        <v-img
          class="id-document-image"
          :src="require('@/assets/signup/new-id-card.png')"
        />

        <NewButton
          class="close-button"
          @on-click="displayDialog = false"
          :text="$t('base.close')"
        />

      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "DocumentNumberHintDialog",

  components: {
    NewButton,
  },

  props: {
    dialog: Boolean,
  },

  computed: {
    displayDialog: {
      get() {
        return this.dialog;
      },

      set(booleanValue) {
        this.$emit("displayDialog", booleanValue);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
 ::v-deep .absolute-dialog-position{
  @media (max-width: 600px) {
    position: absolute !important;
    bottom: 0 !important;
    margin: 0 !important;
  }
}

.id-document-dialog-content {
  height: 100%;
  padding: 40px;
}

.hint-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 10px;
}

.close-button {
  margin-top: 40px;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

</style>
