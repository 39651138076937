<template>
  <div class="custom-text-field">
    <div class="label-row" v-if="label">
      <div class="missing-value-notification" v-if="displayNotification">
        <v-icon color="#6D48FF" size="16">
          mdi-alert-circle
        </v-icon>
      </div>
      <div class="label-text">
        {{ label }}
      </div>
    </div>

    <v-text-field
      :id="id"
      v-model="inputValue"
      outlined
      rounded
      :placeholder="placeholder"
      :type="type"
      required
      autocomplete="off"
      :rules="rules"
      @keydown="$emit('keydown', $event)"
      @beforeinput="$emit('beforeinput', $event)"
      :hide-details="
        value !== null && $refs[id] && !$refs[id].isFocused ? $refs[id].validate() : ''
      "
      :disabled="disabled"
      :ref="id"
      :class="{ disabled: disabled }"
    />
  </div>
</template>
<script>
export default {
  name: "TextField",

  props: {
    id: String,
    label: String,
    rules: Array,
    placeholder: String,
    value: [String, Number],
    disabled: Boolean,
    type: {
      type: String,
      default: "text",
    },
    displayNotification: Boolean,
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("updateValue", value);
      },
    },
  },
};
</script>
<style lang="scss">
.custom-text-field {
  input {
    caret-color: black;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 160% !important;
  }

  .v-text-field--outlined:not(.disabled) > .v-input__control > .v-input__slot {
    background-color: #fff !important;
  }

  .disabled {
    background-color: #e2e0e0 !important ;
  }

  .v-messages__message {
    line-height: 160% !important;
    font-weight: 500 !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: #e2e0e0;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 60px !important;
  }

  :not(.file-input).v-text-field--outlined.v-input--is-focused
    > .v-input__control
    > .v-input__slot
    fieldset {
    border: 1px solid #000 !important;
  }

  .error--text.v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid #f20e45;
  }

  .theme--light.v-text-field--outlined {
    border-radius: 10px;
  }
  .label-row{
    display: flex;
    gap: 0 5px;
    margin-bottom: 10px;
  }

  .label-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    color: #333;
  }
}
</style>
