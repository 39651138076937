<template>
  <div class="custom-autocomplete">
    <div class="label-row" v-if="label">
      <div class="missing-value-notification" v-if="displayNotification">
        <v-icon color="#6D48FF" size="16">
          mdi-alert-circle
        </v-icon>
      </div>
      <div class="label-text">
        {{ label }}
      </div>
    </div>

    <v-autocomplete
      :id="id"
      :items="items"
      :rules="rules"
      :menu-props="{
        rounded: 'lg',
        contentClass: 'custom-menu elevation-0',
        offsetY: true,
      }"
      :no-data-text="$t('base.noResults')"
      :item-text="item => getItemText(item)"
      :item-value="itemValue"
      :placeholder="placeholder"
      :filter="customFilter"
      :disabled="disabled"
      :return-object="returnObject"
      v-model="selectedItem"
      append-icon="mdi-chevron-down"
      :auto-select-first="!selectedItem"
      outlined
      rounded
      required
      autocomplete="off"
      hide-details
      @change="$emit('on-change', $event)"
      class="v-autocomplete"
    >

      <template v-slot:item="{ item }">
        <v-list-item-content
          :id="`${id}-${itemValue ? item[itemValue] : item}`"
          class="dropdown-row"
        >
          <v-list-item-title
            class="dropdown-item-text"
          >
            {{ getItemText(item)}}
          </v-list-item-title>

        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
export default {
  name: "AutoComplete",

  props: {
    id: String,
    label: String,
    rules: Array,
    items: Array,
    itemText: String,
    itemValue: String,
    placeholder: String,
    disabled: Boolean,
    returnObject: Boolean,
    translatorPath: String,
    value: [String, Number, Object],
    displayNotification: Boolean,
  },

  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("updateValue", value);
      },
    },
  },

  methods: {
    normalizeString(text) {
      return text
        .toLowerCase() // Parse strings to lower case
        .trim() // Remove the last spaces
        .normalize("NFD") // Decompose characters with diacritics
        .replace(/[\u0300-\u036f]/g, ""); // Remove diacritics
    },

    levenshteinDistance(a, b) {
      /** The Levenshtein distance measures how much two text strings differ
       * by counting the minimum number of operations required to convert
       * one string into another. These operations are the insertion of a character,
       * the deletion of a character or the substitution of one character for another.
       * */

      // Initialize the matrix with dimensions (b.length + 1) x (a.length + 1)
      const matrix = Array.from({ length: b.length + 1 }, (_, i) => [i]);

      // Initialize the first row of the matrix
      matrix[0] = Array.from({ length: a.length + 1 }, (_, j) => j);

      for (let i = 1; i <= b.length; i += 1) {
        for (let j = 1; j <= a.length; j += 1) {
          // Calculate the cost of the operation (insertion, deletion, or substitution)
          const cost = a[j - 1] === b[i - 1] ? 0 : 1;

          matrix[i][j] = Math.min(
            matrix[i - 1][j] + 1, // Insertion
            matrix[i][j - 1] + 1, // Deletion
            matrix[i - 1][j - 1] + cost, // Substitution
          );
        }
      }
      // The final result is in the bottom-right corner of the matrix
      return matrix[b.length][a.length];
    },

    getItemText(item) {
      // If there's a translator path, we'll use it to translate the text
      if (this.translatorPath) {
        return this.$t(`${this.translatorPath}.${this.itemValue ? item[this.itemValue] : item}`);
      }
      // Otherwise, we'll return the item text
      return this.itemText ? item[this.itemText] : item;
    },

    customFilter(item, queryText, itemText) {
      const isPersistentItem = item?.persistentOption;

      const includesQuery = itemText.toLowerCase().includes(queryText.toLowerCase());

      const distance = this.levenshteinDistance(
        this.normalizeString(queryText),
        this.normalizeString(itemText),
      );

      // Maximum distance between the input search and the options
      const similarityThreshold = 3;

      return isPersistentItem || includesQuery || distance < similarityThreshold;
    },
  },
};
</script>
<style lang="scss">
.custom-autocomplete {
  .v-autocomplete__content.v-menu__content,
  .v-autocomplete__content.v-menu__content .v-card {
    border-radius: 10px !important;
  }

  .v-text-field--rounded {
    border-radius: 10px !important;
  }

  .custom-menu {
    border: 1px solid #e2e0e0 !important;

    .v-list-item--active {
      background-color: #fafafa !important;
    }
  }

  .dropdown-item-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #333333 !important;
  }

  .error--text.v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid #f20e45;
  }

  .label-row{
    display: flex;
    gap: 0 5px;
    margin-bottom: 10px;
  }

  .label-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    color: #333;
  }

  .v-autocomplete i {
    color: #000 !important;
    &:hover {
      cursor: pointer !important;
    }
  }

  input {
    caret-color: black;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 160% !important;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    background-color: #fff !important;
  }

  .v-messages__message {
    line-height: 160% !important;
    font-weight: 500 !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: #e2e0e0;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 60px !important;
  }

  :not(.file-input).v-text-field--outlined.v-input--is-focused
    > .v-input__control
    > .v-input__slot
    fieldset {
    border: 1px solid #000 !important;
  }
}
</style>
