<template>
  <v-btn
    id="invest"
    depressed
    @click="$emit('on-click')"
    :color="color"
    :style="btnStyle"
    :disabled="disabled"
    :outlined="outlined"
    :href="link"
    :target="link ? '_blank' : '_self'"
    :dark="dark"
    elevation="0"
    :loading="loading"
    :fixed="fixed"
    :bottom="fixed"
    :ripple="ripple"
  >
    <v-icon v-if="icon && !iconRight" left :size="iconSize">
      {{ icon }}
    </v-icon>

    {{ text }}

    <v-icon v-if="icon && iconRight" right :size="iconSize">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "CustomButton",

  props: {
    text: {
      type: String,
      default: "Invertir",
    },
    height: {
      type: String,
      default: "38px",
    },
    width: {
      type: String,
      default: "165px",
    },
    fontSize: {
      type: String,
      default: "12px",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
    },
    color: {
      type: String,
      default: "#00aa9d",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    upperCase: {
      type: Boolean,
      default: true,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    normalSpacing: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: "25px",
    },
    icon: {
      type: String,
      default: undefined,
    },
    border: {
      type: Boolean,
      default: true,
    },
    capitalize: {
      type: Boolean,
      default: true,
    },
    hoverColor: {
      type: String,
      default: null,
    },
    disabledColor: {
      type: String,
      default: null,
    },
    iconSize: {
      type: Number,
      default: 12,
    },
    leftAlign: {
      type: Boolean,
      default: false,
    },
    horizontalPadding: {
      type: Number,
      default: 40,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    btnStyle() {
      let textTransform = "uppercase";
      let fontWeight = "500";
      if (!this.upperCase) textTransform = "capitalize";
      if (!this.upperCase && !this.capitalize) textTransform = "inherit";
      if (this.bold) fontWeight = "700";
      let props = `height: ${this.height} !important;
        font-size: ${this.fontSize} !important;
        width: ${this.width} !important;
        text-transform: ${textTransform} !important;
        font-weight: ${fontWeight} !important;
        border-radius: ${this.borderRadius} !important;
        backgroundColor:${this.disabled && this.disabledColor ? this.disabledColor : this.backgroundColor} !important;
      `;
      if (!this.disabled && this.border) props = "".concat(props, "border: 1px solid;");
      if (this.normalSpacing) props = "".concat(props, " ", "letter-spacing: normal;");

      if (this.leftAlign && !this.iconRight) {
        props = "".concat(props, `display : flex; justify-content: left; padding-left: ${this.horizontalPadding}px;`);
      }
      if (this.iconRight) {
        props = "".concat(props, `display : flex; justify-content: space-between; padding: 0 ${this.horizontalPadding}px;`);
      }

      return props;
    },
  },

  methods: {
    setCustomHoverColor() {
      if (this.hoverColor !== null) {
        const buttonElement = document.getElementById("investment-container") || document.getElementById("invest");
        // when the user put the mouse on, we display the hover color
        buttonElement.addEventListener("mouseenter", () => {
          buttonElement.style.backgroundColor = this.hoverColor;
        });
        // when the user removes the mouse, the original colour is displayed.
        buttonElement.addEventListener("mouseleave", () => {
          buttonElement.style.backgroundColor = this.color;
        });
      }
    },
  },

  mounted() {
    this.setCustomHoverColor();
  },
};
</script>

<style>
#invest {
  font-size: 16px;
  box-sizing: border-box;
}

#invest::before {
  background-color: transparent !important;
}
.theme--light.v-btn {
  color: rgba(0, 0, 0, 0.5);
}
</style>
