import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/lib/util/colors.js";
import es from "vuetify/es5/locale/es.js";
import en from "vuetify/es5/locale/en.js";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#000",
        secondary: colors.grey,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: "#F2F2F2", // Not automatically applied,
        signup: "#723FEF",
        black: "#000000",
        stepper: "#00AA9D",
        contract: "#F6F6F6",
        back: "#EBF0FF",
        grey: {
          "01": "#333333",
          "02": "#646363",
          "03": "#828282",
          "04": "#bdbdbd",
          "05": "#e2e0e0",
          funds: "#f2f2f2",
          fundsContrast: "#e9e9e9",
          hover: "#fafafa",
        },
      },
      dark: {
        primary: colors.blue.lighten3,
        background: "#F2F2F2", // If not using lighten/darken, use base to return hex
      },
    },
  },
  lang: {
    locales: { en, es },
    current: "es",
  },
};

export default new Vuetify(opts);
