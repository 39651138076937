<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="signup-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <h2 class="signup-title">
        {{ $t(`signup.contract.brief.COMPASS.title`) }}
      </h2>
      <div class="contract-brief-container">
        <ul class="signup-bullet-list">
          <li v-for="(briefKey, index) in contractBriefKeys" :key="index">
            {{ $t(`signup.contract.brief.COMPASS.${briefKey}`) }}
          </li>
        </ul>

        <div class="view-full-contract">
          <span
            id="display-contract-dialog"
            @click="displayFullContractDialog = !displayFullContractDialog"
          >
            {{ $t(`signup.contract.brief.COMPASS.viewFullContract`) }}
          </span>
        </div>
      </div>

      <ReusableCheckbox
        class="dividend-reinvestment-checkbox"
        @setChecked="() => onAuthorizeDividendReinvestmentClick()"
        :checked="reinvestDividends"
      >
        <template v-slot:textSlot>
          <div class="checkbox-text">
            {{ $t("signup.contract.checkbox") }}
            <span class="info-icon" @click="dividendInfoDialog = !dividendInfoDialog">
              ⓘ
            </span>
          </div>
        </template>
      </ReusableCheckbox>

      <FullContractDialog
        :dialog="displayFullContractDialog"
        :authoriseDividendReinvestment="reinvestDividends"
        :signingContract="loading"
        :contractSignSuccess="completedContract"
        @displayFullContractDialog="displayFullContractDialog = $event"
        @openDividendInfoDialog="dividendInfoDialog = !dividendInfoDialog"
        @authoriseDividendReinvestment="onAuthorizeDividendReinvestmentClick"
        @signContract="signContract()"
        @error="
          errorDialog = true;
          errorType = 'generating';
        "
      />

      <NewButton
        id="next-step"
        :class="{ 'signup-success-btn': completedContract || successState }"
        :text="
          completedContract || successState
            ? $t('signup.contract.success')
            : $t('signup.contract.sign')
        "
        :disabled="loading"
        :loading="loading"
        @on-click="signContract()"
      />

      <p class="signup-contract-sign-disclaimer">
        {{ $t("signup.contract.signDisclaimer") }}
      </p>

      <ReusableDialog
        v-model="dividendInfoDialog"
        :title="$t('signup.contract.dividendInfoDialog.title')"
        :text="$t('signup.contract.dividendInfoDialog.body')"
        @display="dividendInfoDialog = $event"
        :fullscreen="$vuetify.breakpoint.xs"
      >
        <template v-slot:buttonRow>
          <NewButton
            :text="$t('signup.contract.dividendInfoDialog.button')"
            class="dividend-btn"
            @on-click="dividendInfoDialog = !dividendInfoDialog"
          />
        </template>
      </ReusableDialog>

      <HorizontalButtonDialog
        :value="errorDialog"
        :title="$t('signup.contract.errorDialog.title')"
        :text="$t(`signup.contract.responseErrors.${errorCode}`)"
        :primaryButtonText="$t('signup.contract.errorDialog.primaryButton')"
        :secondaryButtonText="$t('signup.contract.errorDialog.secondaryButton')"
        @primaryClick="errorDialog = false"
        @secondaryClick="openChat()"
        @display="errorDialog = $event"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReusableCheckbox from "@/components/shared/ReusableCheckbox.vue";
import NewButton from "@/components/shared/NewButton.vue";
import FullContractDialog from "@/components/signup/contract/FullContractDialog.vue";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import HorizontalButtonDialog from "@/components/shared/HorizontalButtonDialog.vue";
import { signupFunctions } from "@/mixins/signup.js";
import contracts from "@/mixins/contracts.js";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";

export default {
  name: "ContractView",

  components: {
    NewButton,
    ReusableCheckbox,
    FullContractDialog,
    ReusableDialog,
    HorizontalButtonDialog,
  },

  mixins: [signupFunctions, contracts, customersApiFunctions],

  data: () => ({
    displayFullContractDialog: false,
    errorDialog: false,
    errorCode: "UNKNOWN_ERROR",
    dividendInfoDialog: false,
    successState: false,
    loading: false,
  }),

  computed: {
    ...mapGetters(["reinvestDividends", "signupStatus", "signupEmail", "isSignedUp"]),

    completedContract() {
      return this.signupStatus.completedContractCompass;
    },
  },

  methods: {
    ...mapActions(["setContractReinvestDividends"]),

    handleErrorResponse(response) {
      this.errorCode = response?.errorCode || "UNKNOWN_ERROR";
      this.errorDialog = true;
      this.loading = false;
    },

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    async signContract() {
      if (this.completedContract || this.loading) return;

      let contractResponse;
      let destinationRoute;

      this.loading = true;

      // If the user is not signed, sign the Compass contract
      if (this.isSignedUp) {
        contractResponse = await this.signCompassContract();
        destinationRoute = "accountContractSignatureSuccess";
      } else {
        // else if the user is signed, perform the 'existing-create' request to sign
        const payload = {
          email: this.signupEmail,
          reinvestDividends: this.reinvestDividends,
          origin: "https://mi.dvacapital.com",
        };
        contractResponse = await this.signUpContract(payload);
        destinationRoute = "signupDashboard";
      }

      this.loading = false;

      if (contractResponse.error) {
        this.handleErrorResponse(contractResponse);
      } else {
        this.showSuccessState();
        this.$router.push({ name: destinationRoute });
      }
    },

    onAuthorizeDividendReinvestmentClick() {
      this.setContractReinvestDividends(!this.reinvestDividends);
    },

    openChat() {
      this.$intercom.showNewMessage(
        this.$t("signup.contract.errorDialog.intercom.error"),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-bullet-list {
  margin-top: 40px;
  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #333333;
  }

  li + li {
    margin-top: 20px;
  }
}

.view-full-contract {
  margin-top: 40px;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #646363;
    &:hover {
      cursor: pointer;
    }
  }
}

.contract-brief-container {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e0e0;
}

.dividend-reinvestment-checkbox {
  background-color: #f2f2f2 !important;
  margin-bottom: 40px;
  padding: 0 !important;
  align-items: flex-start !important;
}

.dividend-reinvestment-checkbox ::v-deep .label-row {
  a,
  span,
  .checkbox-text {
    font-weight: 500;
    font-size: 11px !important;
    line-height: 160%;
  }
}

p.signup-contract-sign-disclaimer {
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  margin-top: 20px;
  color: #646363;
}

.info-icon {
  color: #333333;
  &:hover {
    cursor: pointer;
  }
}

.dividend-btn {
  margin-top: 40px;
}

.signup-success-btn {
  background-color: #6d48ff !important;
  border: unset;
}
</style>
