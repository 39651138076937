import { Analytics } from "@segment/analytics-node";
import { v4 } from "uuid";

if (!localStorage.getItem("segmentAnonymousId")) {
  localStorage.setItem("segmentAnonymousId", v4());
}

const analytics = new Analytics({ writeKey: process.env.VUE_APP_SEGMENT_KEY });
analytics.on("error", err => {
  console.log(`Segment error: ${err}`);
});

export default {
  install: Vue => {
    /* eslint no-param-reassign: "error" */
    Vue.prototype.$analytics = analytics;
  },
};
