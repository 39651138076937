<template>
  <div class="dividend-wrapper">
    <div class="dividend-content">
      <h3 class="dividend-subtitle">
        {{
          $t("dividendDeclaration.chooseDividendOption.title", {
            fundName: customerPendingDividends.fundDividendNames,
          })
        }}
      </h3>

      <p class="dividend-text">
        <strong>
          {{ $t("dividendDeclaration.chooseDividendOption.important") }}
        </strong>
        {{ $t("dividendDeclaration.chooseDividendOption.text") }}
      </p>

      <div class="button-rows">
        <NewButton
          :text="$t('dividendDeclaration.chooseDividendOption.reinvest')"
          @on-click="setDividendOption('reinvest')"
        />

        <div class="deposit-text-button">
          <span @click="setDividendOption('deposit-clp')">
            {{ $t("dividendDeclaration.chooseDividendOption.deposit") }}
          </span>
        </div>
      </div>

      <div class="dividend-cancel-container">
        <div class="dividend-cancel" @click="$router.push({ name: 'home' })">
          {{ $t("base.cancel") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "ChooseDividendOption",

  components: {
    NewButton,
  },

  computed: {
    ...mapGetters(["customerPendingDividends", "expiredOrMissingIdDocument"]),
  },

  methods: {
    ...mapActions(["setDividendDeclarationAnswer", "addToRouteStack"]),

    determineNextRoute(optionChoosen) {
      let nextRoute =
        optionChoosen === "reinvest" ? "confirmDividendDeclaration" : "depositDividend";

      if (this.expiredOrMissingIdDocument) {
        this.addToRouteStack({ name: nextRoute });
        nextRoute = "expiredIdDocumentNotice";
      }

      return nextRoute;
    },

    setDividendOption(optionChoosen) {
      this.setDividendDeclarationAnswer(optionChoosen);

      const nextRoute = this.determineNextRoute(optionChoosen);

      this.$router.push({ name: nextRoute });
    },
  },
};
</script>

<style lang="scss">
.dividend-wrapper {
  display: flex;
  justify-content: center;
  margin: 90px 20px;

  @media (max-width: 600px) {
    margin: 30px 20px;
  }
}

.dividend-content {
  max-width: 420px;
  width: 100%;
}

.dividend-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  color: #333333;
}

.dividend-subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 20px;
}

.dividend-cancel-container,
.dividend-support-container {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
  }
}

.dividend-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 40px !important;
}

.dividend-cancel,
.dividend-support {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #646363;
}

.deposit-text-button {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  span {
    font-size: 14px;
    line-height: 100%;
    font-weight: 500;
    color: #333333;
    &:hover {
      cursor: pointer;
    }
  }
}

.dividend-step-back {
  margin-bottom: 30px;
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
}
</style>
