<template>
  <div class="dividend-wrapper">
    <div class="dividend-content">
      <div class="dividend-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="dividend-subtitle">
        {{
          $t(
            `dividendDeclaration.depositDividend.${
              modifiedBankAccount ? "newBankAccount" : "confirmBankAccount"
            }`,
          )
        }}
      </h3>

      <ManageCustomerBankAccount
        @onPatchSuccess="modifiedBankAccount = true"
        @onConfirm="$router.push({ name: 'confirmDividendDeclaration' })"
        @onCancel="$router.push({ name: 'home' })"
      />
    </div>
  </div>
</template>

<script>
import ManageCustomerBankAccount from "@/components/shared/ManageCustomerBankAccount.vue";

export default {
  name: "DepositDividend",

  data: () => ({
    modifiedBankAccount: false,
  }),

  components: {
    ManageCustomerBankAccount,
  },
};
</script>

<style lang="scss" scoped>
.dividend-wrapper {
  margin: 40px 20px;

  @media (max-width: 600px) {
    margin: 30px 20px;
  }
}
</style>
