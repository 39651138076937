<template>
  <div
    class="route-container"
    @click="toRoute()"
    v-if="name"
    :class="{ logout: isLogout }"
  >
    <div
      class="icon-container"
      :class="['icon-container', { active: isActiveRoute }]"
      v-if="icon"
    >
      <v-icon size="18" :color="iconColor"> {{ icon }}</v-icon>
    </div>
    <div class="route-name-flex-row">
      <div class="route-name">
        {{ $t(`appbar.menu.${name}`) }}
      </div>

      <div class="notification-container" v-if="notificationCount > 0">
        <div class="notification-number">
          {{ notificationCount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightToolbarItem",
  props: {
    name: String,
    icon: String,
    route: String,
    href: String,
    notificationCount: Number,
  },

  methods: {
    toRoute() {
      if (this.isLogout) {
        this.$auth.logout();
      } else if (this.href) {
        window.open(this.href, "_blank");
      } else if (this.$route.name !== this.route) {
        this.$router.push({ name: this.route });
      }
    },
  },

  computed: {
    isLogout() {
      return this.name === "logout";
    },

    isActiveRoute() {
      return this.$route.name === this.route || this.$route.name?.includes(this.route);
    },

    iconColor() {
      return this.isActiveRoute ? "#FFFFFF" : "#000000";
    },
  },
};
</script>

<style scoped lang="scss">
.route-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
  color: #333333;
}

.route-container {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  height: 60px;

  padding: 0 20px;
  @media (max-width: 600px) {
    height: 65px;
  }
  @media (min-width: 450px) and (max-height: 830px) {
    height: 50px;
  }

  &:hover {
    cursor: pointer;
    background: #f2f2f2;
    mix-blend-mode: darken;
  }
}

.icon-container {
  height: 30px;
  width: 30px !important;
  min-width: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  aspect-ratio: 1 !important;

  @media (max-width: 600px) {
    height: 25px !important;
    width: 25px !important;
    min-width: 25px !important;
  }
}

.logout .route-name,
.logout .icon-container i {
  color: #f20e45 !important;
}

.notification-container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6d48ff;
  border-radius: 50%;
}

.notification-number {
  color: #fff;
  font-size: 11px;
}

.route-name-flex-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-container.active {
  background-color: #000000;
}
</style>
