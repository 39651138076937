<template>
  <ReusableDialog
    :value="display"
    :title="title"
    :text="text"
    @display="display = $event"
  >
    <template v-slot:buttonRow>
      <div class="button-row">
        <div class="secondary-button">
          <NewButton
            :text="secondaryButtonText"
            @on-click="$emit('secondaryClick')"
            buttonClass="black-outlined"
          />
        </div>

        <div class="primary-button">
          <NewButton :text="primaryButtonText" @on-click="$emit('primaryClick')" />
        </div>
      </div>
    </template>
  </ReusableDialog>
</template>
<script>
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "HorizontalButtonDialog",

  components: { NewButton, ReusableDialog },

  props: {
    value: Boolean,
    title: String,
    text: String,
    primaryButtonText: String,
    secondaryButtonText: String,
  },

  computed: {
    display: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("display", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.button-row {
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  gap: 20px 0;
}
</style>
