<template>
  <div>
    <div v-for="(row, index) in fundBankingData" :key="index" class="bank-row px-5">
      <div class="bank-data">
        <div v-if="row.key">
          {{ $i18n.t(`reusableTable.${row.key}`) }}: {{ row.value }}
        </div>
        <div v-else>
          {{ row.value }}
        </div>
      </div>

      <div class="copy-text">
        <div class="copied" v-if="copiedData.index === index && copiedData.status">
          {{ $i18n.t("investment.bankTransfer.copied") }}
        </div>
        <div class="copy" @click="toClipboard(row.value, index)" v-else>
          {{ $i18n.t("investment.bankTransfer.copy") }}
        </div>
      </div>
    </div>

    <NewButton
      class="copy-all-button"
      :class="{ 'copied-all-button': copiedAll }"
      buttonClass="black-outlined"
      :text="$t(`investment.bankTransfer.${copiedAll ? 'copiedAll' : 'copyAll'}`)"
      @on-click="copyAll"
    />
  </div>
</template>

<script>
import funds from "@/mixins/funds.js";
import clipboard from "@/mixins/clipboard.js";
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "FundBankingData",

  mixins: [funds, clipboard],

  components: { NewButton },

  data: () => ({
    timeout: 2000,
    copiedData: { status: false, index: "" },
    copiedAll: false,
  }),

  props: {
    fund: {
      type: Number,
      default: null,
    },
  },

  computed: {
    fundBankingData() {
      if (this.fund) {
        const staticData = this.getFundStaticData(this.fund);
        const attributes = [
          { key: "name", value: staticData.name },
          { key: "RUT", value: this.$options.filters.formatRut(staticData.rut) },
          { key: null, value: staticData.bank },
          {
            key: null,
            value: this.$i18n.t("investment.bankTransfer.defaultAttributes.accountType"),
          },
          { key: "accountNumber", value: staticData.accountNumber },
          { key: null, value: staticData.email },
        ];
        return attributes;
      }
      return [{ key: "", value: "" }];
    },
  },

  methods: {
    noDotsRut(investData) {
      const data = [];
      investData.forEach(val => data.push({ ...val }));
      const arrayIndex = data
        .map(item => item.key && item.key.includes("RUT"))
        .indexOf(true);
      const accountRUT = this.$options.filters.supressDots(
        data.map(attribute => attribute.value)[arrayIndex],
      );
      data[arrayIndex].value = accountRUT;

      return data;
    },

    async toClipboard(text, index) {
      this.copiedData = { status: true, index };
      const textToCopy = this.$options.filters.supressDots(text);
      this.copyToClipboard(textToCopy);
      await new Promise(resolve => setTimeout(resolve, 500));
      this.copiedData = { status: false, index };
    },

    async copyAll() {
      this.copiedAll = true;
      const data = this.noDotsRut(this.fundBankingData).map(
        attribute => attribute.value,
      );
      const textToCopy = data.toString().split(",").join("\r\n");
      this.copyToClipboard(textToCopy);
      await new Promise(resolve => setTimeout(resolve, 1500));
      this.copiedAll = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bank-row {
  border-radius: 10px;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4.5rem;
  white-space: nowrap;
  @media (max-width: 600px) {
    gap: 0.75rem;
  }
}

.bank-row + .bank-row {
  margin-top: 10px;
}

.bank-data {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;

  @media (max-width: 375px) {
    font-size: 12px;
  }
}

.copy {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #646363;

  &:hover {
    cursor: pointer;
  }
}

.copied {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #6d48ff;
}

.copy-text {
  width: 55px;
  text-align: right;
}

.copy-all-button {
  margin-top: 20px;
  background-color: #fff !important;
}

.copied-all-button {
  background-color: #6d48ff !important;
  border: unset !important;
}

.copied-all-button::v-deep span {
  color: #fff !important;
}
</style>
