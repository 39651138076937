<template>
  <div class="fund-selection-container">
    <div class="invest-step-back mx-lg-auto invest-header-component">
      <v-icon color="#333333" size="22" @click="$router.go(-1)"> mdi-arrow-left </v-icon>
    </div>
    <filter-funds
      class="mx-lg-auto invest-header-component"
      @setFilteredFunds="setFilteredFunds"
    />
    <div class="invest-fund-list" v-for="(fund, index) in fundsToShow" :key="index">
      <InvestmentFundCard class="mx-auto" :fundData="fund" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InvestmentFundCard from "@/components/investment/cards/InvestmentFundCard.vue";
import FilterFunds from "@/components/investment/FilterFunds.vue";
import funds from "@/mixins/funds.js";

export default {
  name: "ViewFunds",

  components: {
    InvestmentFundCard,
    FilterFunds,
  },

  mixins: [funds],

  data: () => ({
    filteredFunds: null,
  }),

  computed: {
    ...mapGetters(["signupStatus", "canSignCompassContract"]),

    fundsToShow() {
      return this.filteredFunds || this.getFundValues();
    },
  },

  methods: {
    setFilteredFunds(fundRuns) {
      if (!fundRuns) {
        this.filteredFunds = null;
        return;
      }

      const parsedFundRuns = fundRuns.map(fundRun => Number(fundRun));

      this.filteredFunds = this.getFundValues().filter(fund => parsedFundRuns.includes(fund.fundRun));
    },
  },
};
</script>

<style lang="scss" scoped>
.invest-header-component {
  margin: 0 24px;
  @media (min-width: 1264px) {
    max-width: 70%;
  }
  @media (max-width: 960px) {
    margin: 0 0 0 20px;
  }
}

.fund-selection-container {
  padding-top: 60px;
  background-color: #f2f2f2;
  height: "100%";
  @media (max-width: 600px) {
    padding-top: 40px;
  }
}

div .invest-fund-list {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1264px) {
    margin: 0 20px;
  }
}

div .invest-fund-list + .invest-fund-list {
  margin-top: 10px;
  @media (min-width: 960px) and (max-height: 940px) {
    margin-top: 10px;
  }
}

.invest-fund-list:last-child {
  padding-bottom: 60px;
  @media (max-width: 600px) {
    padding-bottom: 40px;
  }
}

.skeleton-card {
  border-radius: 20px;
}

.skeleton-list + .skeleton-list {
  margin-top: 20px;
}
selectedFunds .desktop-filter {
  display: flex;
  justify-content: center;
}

.fixed-button {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
</style>
