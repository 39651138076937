<template>
  <v-container class="movements" fluid>
    <div
      class="max-width-wrapper"
      :class="{
        'mt-3 px-16': $vuetify.breakpoint.mdAndUp,
        'px-3': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-row>
        <v-col cols="12">
          <div class="movements-title ma-5">
            {{ this.$i18n.t("movements.title") }}
          </div>
        </v-col>
      </v-row>
      <v-col
        :class="{
          'mx-auto': $vuetify.breakpoint.mdAndUp,
          'mx-auto px-0': $vuetify.breakpoint.smAndDown,
        }"
        cols="12"
      >
        <v-card id="movements-card">
          <v-card-title>
            <v-col>
              <FundsFilter
                v-if="!loadingMovements"
                :handleChangeFilterArray="updateFilterOptionsArray"
                :includedFunds="includedFunds"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :class="{ 'px-12': $vuetify.breakpoint.lgAndUp }"
            :items="movements"
            :headers="tableHeaders"
            :loading="loadingMovements"
            disable-sort
            mobile-breakpoint="0"
            hide-default-header
            :search="filterOptionsString"
            :custom-filter="filterMovements"
            :footer-props="footerProps"
          >
            <template v-slot:[`item.fundSeries.fund.shortName`]="{ item }">
              <div class="table-date">{{ item.settlementDate | formatDate }}</div>
              <br />
              <v-avatar
                class="table-avatar-offset"
                :color="item.fundSeries.fund.run | addFundColor"
                size="48"
              >
                <span class="white--text">{{ item.fundSeries.fund.acronym }}</span>
              </v-avatar>
            </template>

            <template v-slot:[`item.movementType`]="{ item }">
              <div class="movement-title-table">
                <div
                  v-if="
                    item.movementType === 'DISTRIBUTION'
                      && item.settlementDate >= todayDate
                  "
                >
                  {{
                    $i18n.t("movements.status.fulfilled.PENDING_DISTRIBUTION", {
                      date: todayDate,
                    })
                  }}
                  <v-tooltip
                    v-if="
                      item.movementType === 'DISTRIBUTION'
                        && item.settlementDate >= todayDate
                    "
                    max-width="350"
                    class="my-0"
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="grey"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >mdi-information</v-icon
                      >
                    </template>
                    <span>
                      {{
                        $i18n.t("movements.status.tooltip.pendingDistribution", {
                          date: $options.filters.formatDate(item.settlementDate),
                        })
                      }}
                    </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  {{ $i18n.t("movements.status.fulfilled." + item.movementType) }}
                  <v-tooltip
                    max-width="350"
                    class="my-0"
                    v-if="item.movementType === 'PENDING_REDEMPTION'"
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="grey"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >mdi-information</v-icon
                      >
                    </template>
                    <span>{{
                      $i18n.t("movements.status.tooltip.pendingRedemption")
                    }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div class="bluer">{{ item.fundSeries.fund.shortName }}</div>
            </template>

            <template v-slot:[`item.amount`]="{ item }">
              <div class="table-number">
                {{ getMovementDirection(item.movementType) === "in" ? "+" : "-" }}${{
                  item.shareState.CLP | currencyNoSymbol
                }}
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </div>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters, mapState } from "vuex";
import FundsFilter from "@/components/movements/FundsFilter.vue";
import movements from "@/mixins/movements.js";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";

export default {
  name: "MovementsView",

  components: {
    FundsFilter,
  },

  mixins: [movements, customersApiFunctions],

  data() {
    return {
      todayDate: dayjs().format("YYYY-MM-DD"),
      footerProps: { "items-per-page-options": [5, 10, 20, 100] },
      title: this.$i18n.t("movements.title"),
      subtitle: this.$i18n.t("movements.subtitle"),
      tableHeaders: [
        {
          text: this.$i18n.t("movements.title"),
          value: "fundSeries.fund.shortName",
          align: "left",
          width: "140px",
        },
        {
          text: "",
          value: "movementType",
          align: "left",
        },
        {
          text: "",
          value: "amount",
          align: "right",
        },
      ],
      filterOptionsArray: [],
      page: 1,
      itemsPerPage: 10,
      fab: false,
    };
  },

  computed: {
    ...mapState(["fundsMovementsData"]),

    ...mapGetters(["loadingMovements", "movements", "reinvestments"]),
    // Workaround to use the filter as the search key in v-data-table. It can only be as string
    // so we construct the array of selected funds as a string and then deconstruct it.

    filterOptionsString() {
      return this.filterOptionsArray.join("-");
    },

    totalMovements() {
      return this.movements.length;
    },

    includedFunds() {
      return this.movements
        .map(movement => movement.fundSeries.fund.run)
        .filter((value, index, array) => array.indexOf(value) === index);
    },
  },

  methods: {
    ...mapActions(["setFundsMovementsData", "setLoadingFundsMovements"]),

    updateFilterOptionsArray(newFilterOptionsArray) {
      this.filterOptionsArray = [...newFilterOptionsArray];
    },

    filterMovements(value, search, item) {
      // Workaround to use the filter as the search key in v-data-table. It can only be as string
      // so we construct the array of selected funds as a string and then deconstruct it.
      const filterAsArray = search.split("-");
      if (!search) return item;
      return filterAsArray.includes(item.fundSeries.fund.run);
    },

    async loadMovementsData() {
      if (!this.fundsMovementsData) {
        this.setLoadingFundsMovements(true);

        const { data, errors } = await this.getCustomerMovementsData();

        if (!errors) {
          const fundsMovements = data?.funds;
          this.setFundsMovementsData(fundsMovements);
          this.setLoadingFundsMovements(false);
        }
      }
    },
  },

  watch: {
    // Whenever selected option changes, this function will run
    filterOptionsArray() {
      this.page = 1;
    },
  },

  async mounted() {
    this.loadMovementsData();
  },
};
</script>

<style scoped lang="scss">
#movements-card {
  border-radius: 25px;
  box-shadow: 2px 2px 18px 2px rgba(0, 0, 0, 0.1);
}

.v-data-table__expanded__content {
  box-shadow: none !important;
}

.pre-title {
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 16px;
  }
  strong {
    margin-right: 5px;
  }
}

.movements-title {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  display: flex;
  align-items: center;
  color: #000;
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 36px;
    /* or 0% */
  }
}

.lateral-padding {
  padding-left: 28px;
  padding-right: 28px;
  @media (min-width: 1024px) {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.ok-chip {
  background-color: #49dd9f !important;
  color: white !important;
}

.table-date {
  padding-top: 5px;
  text-transform: uppercase;
  font-size: 12px;
}

.table-number {
  font-weight: 700;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.movement-title-table {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}

.table-avatar-offset {
  margin-top: -13px;
  margin-bottom: 23px;
}

.table-title {
  font-family: "Work Sans";
  font-style: normal;
  color: #757575 !important;
}

.movements-filter-small {
  width: 100%;
}
</style>
