<template>
  <div class="signup-wrapper">
    <div class="signup-under-maintenance-view">
      <div class="under-maintenance-box">
        <p class="under-maintenance-text">
          {{ $t("signup.underMaintenance.text") }}
        </p>
      </div>

      <div class="under-maintenance-content">
        <div class="under-maintenance-image">
          <v-img class="mi-dva-logo" :src="require('@/assets/mi-dva-logo.svg')" contain />
        </div>

        <div class="under-maintenance-buttons">
          <div class="signup-button create-account-whatsapp">
            <NewButton :text="$t('signup.underMaintenance.buttons.whatsApp')" icon="mdi-whatsapp" link="https://wa.me/+56982128541" />
          </div>

          <div class="signup-button create-account-typeform">
            <NewButton :text="$t('signup.underMaintenance.buttons.typeForm')" link="https://dvacapital.typeform.com/enrolamiento" />
          </div>

          <router-link to="/login" class="signup-has-account">
            {{ $t("signup.auth.hasAccount") }}
          </router-link>

        </div>

        <div class="under-maintenance-disclaimer signup-terms-conditions">
          {{ $t("signup.auth.terms.toContinue") }}
          <a
            :href="$t('signup.auth.terms.terms.url')"
            target="_blank"
            rel="noopener noreferrer"
            class="signup-terms-url"
            @click.stop
          >
            {{ $t("signup.auth.terms.terms.text") }}
          </a>
          <a
            :href="$t('signup.auth.terms.privacy.url')"
            target="_blank"
            rel="noopener noreferrer"
            class="signup-terms-url"
            @click.stop
          >
            {{ $t("signup.auth.terms.privacy.text") }}
          </a>
          <a
            :href="$t('signup.auth.terms.protection.url')"
            target="_blank"
            rel="noopener noreferrer"
            class="signup-terms-url"
            @click.stop
          >
            {{ $t("signup.auth.terms.protection.text") }}
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "SignupUnderMaintenance",

  components: {
    NewButton,
  },

};
</script>

<style lang="scss" scoped>

.under-maintenance-box{
  border: 1px solid #9378FF;
  padding: 20px 30px;
  background-color: #EDE8FF;
  border-radius: 10px;
  margin-bottom: 20px;
}

.under-maintenance-text{
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #6D48FF;
  margin: 0px !important;
}

.signup-button + .signup-button{
  margin-top: 20px;
  @media (max-width: 600px) {
      margin-top: 10px;
  }
}

.signup-under-maintenance-view{
  max-width: 580px !important;
  width: 100% !important;
  margin-top: 60px;

  @media (max-width: 600px) {
    margin-top: 10px;
  }

}

.under-maintenance-content {
  padding: 80px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #E2E0E0;

  @media (max-width: 600px) {
    padding: 0;
    margin: 40px 0;
    background-color: inherit;
    border: none;
  }

}

.under-maintenance-buttons{
  margin-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e0e0;
}

.signup-has-account {
  margin-top: 40px;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #646363 !important;
  &:hover{
    cursor: pointer;
  }

}

.under-maintenance-disclaimer, a{
  color: #828282 !important;
  font-size: 11px;
  font-weight: 500;
  line-height: 17.6px;
  text-align: left;

  @media (max-width: 600px) {
    text-align: center;
    font-size: 9px;
  }
}

.create-account-whatsapp::v-deep .new-btn{
  background-color: #29BA5F !important;

}

.mi-dva-logo{
  max-height: 45px;
  @media (max-width: 600px) {
    max-height: 30px;
  }
}

</style>
