export default {
  methods: {
    getRecommendedFunds(profilingScore, investmentHorizon) {
      const investorProfile = [
        {
          scoreRange: [6, 8],
          recommendedFunds: {
            LOW: [
              {
                run: 9994,
                proportion: 100,
              },
            ],
            MID: [
              {
                run: 10275,
                proportion: 100,
              },
            ],
            MIDLONG: [
              {
                run: 10275,
                proportion: 90,
              },
              {
                run: 9118,
                proportion: 10,
              },
            ],
            LONG: [
              {
                run: 10275,
                proportion: 25,
              },
              {
                run: 9118,
                proportion: 56.3,
              },
              {
                run: 9937,
                proportion: 7.5,
              },
              {
                run: 9725,
                proportion: 11.3,
              },
            ],
          },
        },
        {
          scoreRange: [9, 11],
          recommendedFunds: {
            LOW: [
              {
                run: 9994,
                proportion: 97.5,
              },
              {
                run: 10275,
                proportion: 2.5,
              },
            ],
            MID: [
              {
                run: 10275,
                proportion: 97.5,
              },
              {
                run: 9118,
                proportion: 2.5,
              },
            ],
            MIDLONG: [
              {
                run: 10275,
                proportion: 80,
              },
              {
                run: 9118,
                proportion: 20,
              },
            ],
            LONG: [
              {
                run: 10275,
                proportion: 15,
              },
              {
                run: 9118,
                proportion: 63.8,
              },
              {
                run: 9937,
                proportion: 8.5,
              },
              {
                run: 9725,
                proportion: 12.8,
              },
            ],
          },
        },
        {
          scoreRange: [12, 14],
          recommendedFunds: {
            LOW: [
              {
                run: 9994,
                proportion: 95,
              },
              {
                run: 10275,
                proportion: 5,
              },
            ],
            MID: [
              {
                run: 10275,
                proportion: 95,
              },
              {
                run: 9118,
                proportion: 5,
              },
            ],
            MIDLONG: [
              {
                run: 10275,
                proportion: 70,
              },
              {
                run: 9118,
                proportion: 20,
              },
              {
                run: 9937,
                proportion: 5,
              },
              {
                run: 9725,
                proportion: 5,
              },
            ],
            LONG: [
              {
                run: 10275,
                proportion: 5,
              },
              {
                run: 9958,
                proportion: 3,
              },
              {
                run: 9118,
                proportion: 67,
              },
              {
                run: 9937,
                proportion: 10,
              },
              {
                run: 9725,
                proportion: 15,
              },
            ],
          },
        },
        {
          scoreRange: [15, 17],
          recommendedFunds: {
            LOW: [
              {
                run: 9994,
                proportion: 92.5,
              },
              {
                run: 10275,
                proportion: 7.5,
              },
            ],
            MID: [
              {
                run: 10275,
                proportion: 92.5,
              },
              {
                run: 9118,
                proportion: 7.5,
              },
            ],
            MIDLONG: [
              {
                run: 10275,
                proportion: 60,
              },
              {
                run: 9118,
                proportion: 25,
              },
              {
                run: 9937,
                proportion: 7.5,
              },
              {
                run: 9725,
                proportion: 7.5,
              },
            ],
            LONG: [
              {
                run: 9958,
                proportion: 5,
              },
              {
                run: 9118,
                proportion: 67,
              },
              {
                run: 9395,
                proportion: 3,
              },
              {
                run: 9937,
                proportion: 10,
              },
              {
                run: 9725,
                proportion: 15,
              },
            ],
          },
        },
        {
          scoreRange: [18, 24],
          recommendedFunds: {
            LOW: [
              {
                run: 9994,
                proportion: 90,
              },
              {
                run: 10275,
                proportion: 10,
              },
            ],
            MID: [
              {
                run: 10275,
                proportion: 90,
              },
              {
                run: 9118,
                proportion: 10,
              },
            ],
            MIDLONG: [
              {
                run: 10275,
                proportion: 50,
              },
              {
                run: 9118,
                proportion: 30,
              },
              {
                run: 9937,
                proportion: 10,
              },
              {
                run: 9725,
                proportion: 10,
              },
            ],
            LONG: [
              {
                run: 9958,
                proportion: 5,
              },
              {
                run: 9118,
                proportion: 65,
              },
              {
                run: 9395,
                proportion: 5,
              },
              {
                run: 9937,
                proportion: 10,
              },
              {
                run: 9725,
                proportion: 15,
              },
            ],
          },
        },
      ];

      const riskProfile = investorProfile.find(
        profile => profilingScore >= profile.scoreRange[0] &&
          profilingScore <= profile.scoreRange[[profile.scoreRange.length - 1]],
      );

      return riskProfile.recommendedFunds[investmentHorizon];
    },
  },
};
