<template>
  <div>
    <div class="hint">
      <v-icon :size="$vuetify.breakpoint.smAndUp ? 26 : 22" color="#333333">
        mdi-alert-circle-outline
      </v-icon>
      <div>
        <span> {{ $i18n.t("reinvestment.hints.variableAmount") }} </span>
        <span @click="displayHintDialog = true" class="info-icon"> ⓘ </span>
      </div>
    </div>

    <ReusableDialog
      v-model="displayHintDialog"
      :text="$i18n.t('reinvestment.dialogHints.variableAmount')"
      @display="displayHintDialog = $event"
    />
  </div>
</template>

<script>
import ReusableDialog from "../shared/ReusableDialog.vue";

export default {
  name: "RedemptionHints",

  data: () => ({
    displayHintDialog: false,
  }),

  components: { ReusableDialog },
};
</script>

<style lang="scss" scoped>
.hint {
  display: flex;
  align-items: center;
  gap: 0 20px;
  @media (max-width: 600px) {
    gap: 0 10px;
  }
}
.hint + .hint {
  margin-top: 20px;
}

span {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.info-icon {
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
}
</style>
