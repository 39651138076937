<template>
  <div>
    <Login v-if="'login' == $route.name" />
    <ResetPassword v-if="'reset' == $route.name" />
  </div>
</template>

<script>
import Login from "../components/authorization/Login.vue";
import ResetPassword from "../components/authorization/ResetPassword.vue";

export default {
  name: "LoginView",

  components: {
    Login,
    ResetPassword,
  },
};
</script>

<style lang="scss">
/**Begin: Login reusable styles */

.login-wrapper,
.reset-wrapper {
  display: flex;
  justify-content: center;
  margin: 120px 20px;
  /** Tablets */
  @media (max-width: 1400px) {
    margin: 60px 22px;
  }
  /** Large mobile devices */
  @media (max-width: 500px) {
    margin: 60px 20px 20px 20px;
  }
  /** Small mobile devices */
  @media (max-width: 380px) {
    margin: 40px 20px 20px 20px;
  }
}

.login-content,
.reset-content {
  max-width: 540px;
  width: 100%;
  border: 1px solid #e2e0e0;
  border-radius: 10px;
  padding: 60px;

  @media (max-width: 960px) {
    border: 0;
    padding: 0;
  }
}

.login-form {
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e0e0;
}

.login-form,
.reset-form {
  margin: 40px 0;

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    /**Fill color for every input slot in signup pages */
    background: #ffffff !important;
    /** Overwriting vuetify input text properties */
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 27px !important;
    color: #333333;

    input {
      caret-color: black;
    }
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: #e2e0e0;
  }

  .v-text-field--outlined.v-input--is-focused
    > .v-input__control
    > .v-input__slot
    fieldset {
    border: 1px solid #000 !important;
  }

  .v-autocomplete__content.v-menu__content,
  .v-autocomplete__content.v-menu__content .v-card {
    border-radius: 10px !important;
  }

  .error--text.v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid #e2e0e0;
  }

  .theme--light.v-text-field--outlined {
    border-radius: 10px;
  }

  .v-input--radio-group.v-input--radio-group--row .v-radio .v-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
  }

  .error--text.v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid #e2e0e0 !important;
  }

  .v-text-field .v-input__append-inner {
    display: flex;
    align-self: center !important;
    margin: 0;
  }
  .v-input--is-label-active .v-file-input__text {
    display: flex;
    justify-content: right;
    white-space: nowrap;
  }
}
</style>
