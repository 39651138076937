<template>
  <div class="fund-pills">
    <div v-for="(characteristic, index) in fundTags" :key="index" class="fund-pill">
      <span class="fund-pill-text"> {{ characteristic }} </span>
    </div>
  </div>
</template>

<script>
import values from "../../helpers/values.js";
import funds from "../../mixins/funds.js";

export default {
  name: "FundCategoryPills",

  props: {
    fundRun: Number,
  },

  mixins: [values, funds],

  computed: {
    fundTags() {
      const staticData = this.getFundStaticData(this.fundRun);
      return [
        staticData.currency,
        this.$i18n.t(`fund.fundType.${this.fundRun}`),
        this.$i18n.t(`fund.shortHorizon.${this.fundRun}`),
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.fund-pills {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0.625rem;
  justify-content: left;

  @media (max-width: 1366px) {
    justify-content: left;
    gap: 1rem 0.35rem;
  }

  @media (max-width: 600px) {
    flex-wrap: nowrap;
    gap: 0.75rem 0.5rem;
  }

  @media (max-width: 405px) {
    gap: 0.75rem 0.4rem;
  }
}

.fund-pill {
  margin: 0px;
  height: 30px;
  border-radius: 90px;
  background-color: #f2f2f2;
}

.fund-pill-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #646363;
  padding: 0 13px 0 13px;
  white-space: nowrap;
  @media (max-width: 600px) {
    font-size: 10px;
    padding: 0 10px 0 10px;
  }
  @media (max-width: 380px) {
    font-size: 9px;
    padding: 0 8px 0 8px;
  }
}
</style>
