<template>
  <div class="invest-wrapper">
    <div class="invest-content">
      <div class="invest-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <DetailedFund
        :fundData="fundData"
        :tableData="tableData"
        :tableTitle="$t('fundDetails.details')"
        :canInvest="canInvest"
        @goBackStep="$router.go(-1)"
      />

      <div class="mobile-invest-button-container" v-if="mobileInvestmentAllowed">
        <NewButton
          id="invest-fund-btn"
          :text="$i18n.t('base.invest')"
          @on-click="handleInvestRedirection()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import funds from "@/mixins/funds.js";
import DetailedFund from "@/components/investment/DetailedFund.vue";
import NewButton from "@/components/shared/NewButton.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FundDetails",

  mixins: [funds],

  components: {
    DetailedFund,
    NewButton,
  },

  props: ["fundRun"],

  computed: {
    ...mapGetters(["signupStatus", "authenticatedStatus"]),

    fundData() {
      if (!this.fundRun) return {};
      return this.getFundStaticData(this.fundRun);
    },

    canInvest() {
      const { authenticatedStatus, signupStatus } = this;
      const { completedContractCompass, completedContract } = signupStatus;

      if (
        authenticatedStatus === "authenticated" ||
        completedContract ||
        completedContractCompass
      ) {
        return true;
      }

      return false;
    },

    mobileInvestmentAllowed() {
      return this.canInvest && this.$vuetify.breakpoint.smAndDown;
    },

    tableData() {
      if (Object.keys(this.fundData).length > 0) {
        return [
          { key: this.$i18n.t("reusableTable.name"), value: this.fundData?.shortName },
          {
            key: this.$i18n.t("reusableTable.type"),
            value: this.$i18n.t(`funds.fundTypes.${this.fundData.fundType}`),
          },
          {
            key: this.$i18n.t("reusableTable.currency"),
            value: this.$i18n.t(`currencies.${this.fundData.currency}`),
          },
          {
            key: this.$i18n.t("reusableTable.idealTerm"),
            value: this.$i18n.t(`funds.timeHorizon.${this.fundData.timeHorizon}.years`),
          },
          { key: this.$i18n.t("reusableTable.fixedFee"), value: this.fundData.fixedFee },
          {
            key: this.$i18n.t("reusableTable.redemptionPeriod"),
            value: this.$i18n.t(
              `funds.redemptionPeriods.${this.fundData.redemptionPeriod}`,
            ),
          },
          {
            key: this.$i18n.t("reusableTable.location"),
            value: this.fundData.geoDistribution,
          },
        ];
      }
      return [{ key: "", value: "" }];
    },
  },

  methods: {
    ...mapActions(["addToRouteStack"]),

    handleInvestRedirection() {
      const hasCompassSignature = this.signupStatus.completedContractCompass;

      // If the fund is managed by compass and the customer does not have the compass signature, navigate to the compass notice page
      if (!hasCompassSignature) {
        // Add the next route to the route stack before navigating to the compass notice page
        this.addToRouteStack({
          name: "investChooseInvestmentAmount",
          params: { fundRun: this.fundRun },
        });

        this.$router.push({ name: "accountCompassSignatureNotice" });
      } else {
        // If the fund is managed by compass and the customer has the compass signature,
        // or if the fund is not managed by compass, navigate to the investment amount page

        this.$router.push({
          name: "investChooseInvestmentAmount",
          params: { fundRun: this.fundRun },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-invest-button-container {
  padding: 0 20px 20px 20px;
  margin-top: -60px;
  background-color: #fff;
}

.invest-step-back {
  @media (max-width: 600px) {
    margin-left: 20px;
  }
}
</style>
