<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="signup-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h2 class="signup-title">
        {{ $t("signup.profiling.occupation.title") }}
      </h2>

      <h3 class="signup-subtitle occupation-subtitle" v-if="$route.meta.showSubheader">
        {{ $t("signup.profiling.occupation.subheader") }}
      </h3>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="saveUserLaborData"
        class="signup-form profile-form"
      >
        <MultiButtonPicker
          id="selfEmployed"
          :items="selfEmployedOptions"
          :value="selfEmployed"
          item-text="label"
          item-value="value"
          :label="$t('signup.profiling.occupation.fields.selfEmployed.label')"
          @selectItem="selfEmployed = $event"
          class="occupation-field"
        />

        <AutoComplete
          id="profession"
          :value="profession"
          :items="professions"
          item-text="label"
          item-value="value"
          :rules="selectRules"
          :label="$t('signup.profiling.occupation.fields.profession.label')"
          :placeholder="$t('signup.profiling.occupation.fields.profession.placeholder')"
          @updateValue="profession = $event"
          class="occupation-field"
        />

        <AutoComplete
          id="occupation"
          :value="occupation"
          :items="occupations"
          item-text="label"
          item-value="value"
          :rules="selectRules"
          :label="$t('signup.profiling.occupation.fields.occupation.label')"
          :placeholder="$t('signup.profiling.occupation.fields.occupation.placeholder')"
          @updateValue="occupation = $event"
          class="occupation-field"
        />

        <NewButton
          id="next-step"
          class="signup-step-button"
          :text="$t('signup.shared.buttons.next')"
          :disabled="!valid || selfEmployed === null"
          :type="'submit'"
          :loading="loading"
        />
      </v-form>
    </div>

    <ReusableDialog
      :value="errorDialog"
      :title="$t('signup.profiling.occupation.errorDialog.title')"
      :text="$t('signup.profiling.occupation.errorDialog.text')"
      @display="errorDialog = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import userSelects from "@/mixins/userSelects.js";
import NewButton from "@/components/shared/NewButton.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import AutoComplete from "@/components/shared/AutoComplete.vue";
import MultiButtonPicker from "@/components/shared/MultiButtonPicker.vue";

export default {
  name: "OccupationForm",

  components: {
    NewButton,
    ReusableDialog,
    AutoComplete,
    MultiButtonPicker,
  },

  mixins: [userSelects, customersApiFunctions],

  data() {
    return {
      valid: false,
      selfEmployed: null,
      occupation: null,
      profession: null,
      selectRules: [v => !!v || this.$t("signup.shared.errors.mustComplete")],
      loading: false,
      errorDialog: false,
    };
  },

  computed: {
    ...mapGetters(["expiredOrMissingIdDocument"]),
  },

  methods: {
    ...mapActions(["setProfilingCustomerLaborData"]),

    async saveUserLaborData() {
      const payload = {
        selfEmployed: this.selfEmployed,
        occupation: this.occupation,
        profession: this.profession,
      };

      this.setProfilingCustomerLaborData({ ...payload });
      this.$router.push({ name: "signupOtherDeclarations" });
    },

    getTargetRoute() {
      return this.expiredOrMissingIdDocument ?
        "accountUpdateIdDocument" :
        "accountCompassContract";
    },
  },
};
</script>

<style lang="scss" scoped>
.v-autocomplete__content.v-menu__content {
  box-shadow: none !important;
}

.occupation-subtitle {
  margin: 20px 0;
}

.occupation-field + .occupation-field {
  margin-top: 20px;
}
</style>
