<template>
  <div class="bank-card clickable-item px-2 py-3">
    <div class="mx-2">
      <div class="bank my-auto">
        <div v-if="bankId !== null">
          <v-img :src="require(`@/assets/banks/${bankData.logo}.png`)" width="40px" height="40px" contain />
        </div>
        <div class="bank-name">{{ bankData.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { bankSelects } from "../../../mixins/bankSelects.js";

export default {
  name: "BankCard",

  mixins: [bankSelects],

  props: {
    bankId: {
      type: String,
      required: true,
    },
  },

  computed: {
    bankData() {
      return this.bankNames.find(bank => bank.id === this.bankId);
    },
  },
};
</script>

<style lang="scss" scoped>
.bank-card {
  border-radius: 10px;
  width: 100%;
  max-width: 790px;
  max-height: 100px;
  background-color: #ffffff;
}

.bank {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.625rem;
}

.bank-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
}
</style>
