export default {
  data() {
    return {
      isMobileContainer: false,
    };
  },

  methods: {
    checkIsMobileContainer() {
      const containerElement = document.getElementById(this.containerId);

      if (containerElement) {
        this.isMobileContainer = containerElement.offsetWidth < 960;
      }
    },
  },

  mounted() {
    // Call the function to set the initial width
    this.checkIsMobileContainer();

    // Add a listener for the 'resize' event
    window.addEventListener("resize", this.checkIsMobileContainer);
  },

  beforeDestroy() {
    // Remove the 'resize' event listener when the component is being destroyed
    window.removeEventListener("resize", this.checkIsMobileContainer);
  },

};
