<template>
  <div id="filters-container" class="my-8 filter-funds">
    <FilterFundsPill
      v-for="(categoryOption, index) in availableCategories"
      :key="`category-${index}`"
      class="fund-pill"
      :optionIcon="categoryOption.categoryName === 'CLEAR_FILTERS' ? 'close' : ''"
      :optionName="
        categoryOption.categoryName === 'CLEAR_FILTERS'
          ? ''
          : $i18n.t(
            `home.invest.filterFunds.${categoryOption.categoryName}.${categoryOption.categoryOptionName}`,
          )
      "
      :isSelected="
        getCategoryMatchIndex({
          categoryName: categoryOption.categoryName,
          categoryOptionName: categoryOption.categoryOptionName,
        }) >= 0
      "
      @click.native="
        onCategoryClick({
          categoryName: categoryOption.categoryName,
          categoryOptionName: categoryOption.categoryOptionName,
        })
      "
    />
  </div>
</template>

<script>
import intersection from "lodash.intersection";
import helpers from "../../helpers/values.js";
import FilterFundsPill from "./FilterFundsPill.vue";

export default {
  name: "FilterFunds",

  components: {
    FilterFundsPill,
  },

  mixins: [helpers],

  data() {
    return {
      selectedCategories: [],
    };
  },

  computed: {
    availableCategories() {
      const availableCategories = [];
      // If any of the posible options for a category is selected,
      // we only show that option for the category and the rest
      // is hidden until the user unselects that category
      this.categoriesData.forEach(categoryData => {
        // First find if any option from the category is selected
        const optionSelectedFromCategory = this.selectedCategories.find(
          selectedCategory => selectedCategory.categoryName === categoryData.categoryName,
        );
        // If there is already an option selected from this category, we only show that option
        if (optionSelectedFromCategory) {
          // We use unshift beacause we want the selected elements to appear at start of array
          availableCategories.unshift({
            categoryName: optionSelectedFromCategory.categoryName,
            categoryOptionName: optionSelectedFromCategory.categoryOptionName,
          });
          // If no option from this category has been selected.
          // We show all options for this particular category
        } else {
          const categoryOptions = categoryData.categoryOptions.map(categoryOption => ({
            categoryName: categoryData.categoryName,
            categoryOptionName: categoryOption.optionName,
          }));
          // If the user has already selected other options,
          // we need to filter the ones that are incompatible. If the user
          // has not selected any category, we just show every available option
          if (this.selectedCategories.length) {
            // We check which options from the category
            // are compatible with the already selected options. In incompatible
            // option is one in which the intersection betweeen funds associated
            // to the option and the funds associated
            // to the already selected categories is empty
            const compatibleCategoryOptions = categoryOptions.filter(categoryOption => {
              const fundsFromCategoryOption = this.getCategoryOptionFunds({
                categoryName: categoryData.categoryName,
                categoryOptionName: categoryOption.categoryOptionName,
              });
              const intersectionWithSelectedCategories = intersection(
                fundsFromCategoryOption,
                this.getFundsFromSelectedCategories(),
              );
              // Filter returning only the funds where the intersection is not empty
              return intersectionWithSelectedCategories.length > 0;
            });
            availableCategories.push(...compatibleCategoryOptions);
            // In this case (selectedCategories is empty) just show every available option
          } else {
            availableCategories.push(...categoryOptions);
          }
        }
      });
      if (this.selectedCategories.length) {
        availableCategories.unshift({
          categoryName: "CLEAR_FILTERS",
          categoryOptionName: null,
        });
      }
      return availableCategories;
    },
  },

  methods: {
    // Returns the runs for the funds associated to the category options that the user has selected
    getFundsFromSelectedCategories() {
      const selectedCategoriesFunds = this.selectedCategories
        .map(selectedCategory => this.getCategoryOptionFunds({
          categoryName: selectedCategory.categoryName,
          categoryOptionName: selectedCategory.categoryOptionName,
        }));
      const selectedRuns = intersection(...selectedCategoriesFunds);
      return selectedRuns;
    },

    // Get the funds associated to one specific category option.
    // For example, if the categoryName is currency and categoryOption is CLP
    // This returns the runs of all the funds in CLP
    getCategoryOptionFunds({ categoryName, categoryOptionName }) {
      const categoryData = this.categoriesData.find(
        categoryData => categoryData.categoryName === categoryName,
      );
      const categoryOption = categoryData.categoryOptions.find(
        categoryOption => categoryOption.optionName === categoryOptionName,
      );
      return categoryOption.fundRuns;
    },

    // Category group is something such as currency and categoryName is USD or CLP
    // Check if the pair categoryGroup categoryName was already selected returning its position in
    // the selectedCategories array. If is not selected it returns -1
    getCategoryMatchIndex({ categoryName, categoryOptionName }) {
      return this.selectedCategories.findIndex(
        selectedCategory => selectedCategory.categoryName === categoryName &&
          selectedCategory.categoryOptionName === categoryOptionName,
      );
    },

    // Category group is something such as currency and categoryName is USD or CLP
    onCategoryClick({ categoryName, categoryOptionName }) {
      if (categoryName === "CLEAR_FILTERS") {
        this.selectedCategories = [];
      } else {
        // First check if the element is already selected. We do this finding the index of a match.
        const categoryMatchIndex = this.getCategoryMatchIndex({
          categoryName,
          categoryOptionName,
        });
        // If the category is already selected and in the array,
        // the categoryMatchIndex is a positive integer that indicates
        // the position of the elment inside the array
        if (categoryMatchIndex >= 0) {
          this.selectedCategories.splice(categoryMatchIndex, 1);
          // If the category is not selected the index is -1
          // and we add it to the selectedCategories array
        } else {
          this.selectedCategories.push({ categoryName, categoryOptionName });
        }
      }
      // Resets to filter to initial position after clicking in a new element
      const filtersContainer = document.getElementById("filters-container");
      filtersContainer.scrollLeft = 0;
      // Update the funds shown
      this.updateFilteredFunds();
    },

    updateFilteredFunds() {
      // If there are categories selected, we filter the funds and show only
      // the ones that match with the selected categories
      if (this.selectedCategories.length > 0) {
        const selectedFundsRun = this.getFundsFromSelectedCategories();
        this.$emit("setFilteredFunds", selectedFundsRun);
      } else {
        this.$emit("setFilteredFunds", null);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-funds {
  display: flex;
  flex-direction: horizontal;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  @media (min-width: 960px) and (max-width: 1400px) {
    flex-wrap: wrap;
    gap: 10px 0;
  }
}
.filter-funds::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.fund-pill {
  margin: 0 4px;
  @media (min-width: 960px) and (max-width: 1400px) {
    margin: 0 2px;
  }
}
</style>
