<template>
  <v-dialog
    id="contract-dialog"
    v-model="displayDialog"
    max-width="620"
    class="full-contract-dialog"
    content-class="rounded-lg elevation-0"
    :fullscreen="$vuetify.breakpoint.xs"
    :transition="
      $vuetify.breakpoint.xs ? 'scroll-y-reverse-transition' : 'scale-transition'
    "
  >
    <v-card elevation="0" rounded="lg" class="full-contract-content">
      <div class="scroll contract-container">
        <div class="contract-text">
          <div class="signup-step-back">
            <v-icon
              color="#333333"
              size="22"
              @click="$emit('displayFullContractDialog', false)"
            >
              mdi-arrow-left
            </v-icon>
          </div>

          <div
            v-for="(contract, contractIndex) in displayingContract"
            :key="contractIndex"
          >
            <div class="contract-dialog-header-row">
              <h3 class="contract-title" :id="`${contract.agf}-contract`">
                {{ $t("signup.contract.fullContractTitle") }}
              </h3>
              <v-btn
                text
                class="download-contract"
                @click="getPreview(contract.agf)"
                height="22"
                :loading="loadingDownload"
              >
                {{ $t("signup.contract.download") }}
              </v-btn>
            </div>

            <div
              v-for="(declaration, declarationIndex) in contract.declarations"
              :key="declarationIndex"
            >
              <p v-for="line in declaration.values" :key="line">
                {{
                  $t(
                    `signup.documents.contracts.${contract.agf}.${declaration.name}.${line}`,
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card-bottom-container">
        <div
          class="dividend-checkbox-container"
        >
          <ReusableCheckbox
            class="dividend-reinvestment-checkbox"
            @setChecked="authoriseReinvestment = !authoriseReinvestment"
            :checked="authoriseReinvestment"
            :checkboxLabel="$t('signup.contract.checkbox')"
          >
            <template v-slot:textSlot>
              <div class="checkbox-text">
                {{ $t("signup.contract.checkbox") }}
                <span class="info-icon" @click="$emit('openDividendInfoDialog')">
                  ⓘ
                </span>
              </div>
            </template>
          </ReusableCheckbox>
        </div>
        <div class="sign-contract-btn">
          <NewButton
            id="modal-next-step"
            @on-click="$emit('signContract')"
            :class="{ 'signup-success-btn': contractSignSuccess }"
            :text="
              contractSignSuccess
                ? $t('signup.contract.success')
                : $t('signup.contract.sign')
            "
            :disabled="signingContract"
            :loading="signingContract"
          />
        </div>

        <p class="signup-contract-sign-disclaimer">
          {{ $t("signup.contract.signDisclaimer") }}
        </p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";
import ReusableCheckbox from "@/components/shared/ReusableCheckbox.vue";
import { signupFunctions } from "@/mixins/signup.js";
import contracts from "@/mixins/contracts.js";

export default {
  name: "FullContractDialog",

  components: {
    NewButton,
    ReusableCheckbox,
  },

  mixins: [signupFunctions, contracts],

  data: () => ({
    loadingDownload: false,
    agfName: "COMPASS",
  }),

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    signingContract: {
      type: Boolean,
      default: false,
    },
    authoriseDividendReinvestment: {
      type: Boolean,
      default: true,
    },
    contractSignSuccess: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    displayDialog: {
      set(booleanValue) {
        this.$emit("displayFullContractDialog", booleanValue);
      },
      get() {
        return this.dialog;
      },
    },

    authoriseReinvestment: {
      set(booleanValue) {
        this.$emit("authoriseDividendReinvestment", booleanValue);
      },
      get() {
        return this.authoriseDividendReinvestment;
      },
    },

    displayingContract() {
      // Temporal: in the next release it will be possible to show more than one contract
      return [this.getAgfValues(this.agfName)];
    },
  },

  methods: {
    async getPreview(agfName) {
      this.loadingDownload = true;

      const contractPreviewResponse = await this.getContractPreview(
        agfName.toUpperCase(),
      );

      this.loadingDownload = false;

      if (contractPreviewResponse.error) {
        this.$emit("error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-contract-btn {
  margin-top: 40px;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

.dividend-reinvestment-checkbox {
  max-width: 100% !important;
  padding: 0 !important;
}

.dividend-reinvestment-checkbox ::v-deep .label-row {
  a,
  span,
  .checkbox-text {
    font-weight: 500;
    font-size: 11px !important;
    line-height: 160%;
  }
}

.info-icon:hover {
  cursor: pointer;
}

p.signup-contract-sign-disclaimer {
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  margin-top: 20px;
  color: #646363;
  margin-bottom: 0;
}

.card-bottom-container {
  border-top: 1px solid #e2e0e0;
  padding: 20px 40px;

  @media (max-width: 600px) {
    padding: 20px;
  }
}

.scroll {
  overflow-y: scroll;
}

.contract-container {
  border-radius: 10px;
  max-height: 580px;
}

.contract-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  color: #333333;
  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.contract-container {
  padding: 40px;
  @media (max-width: 600px) {
    padding: 20px;
  }
}

.contract-dialog-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

h3.contract-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  color: #333333;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 160%;
  }
}

.download-contract {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #646363;
  text-transform: capitalize;
  letter-spacing: normal;
}

.signup-success-btn {
  background-color: #6d48ff !important;
  border: unset;
}
</style>
