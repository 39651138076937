<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <v-img
        class="profiling-intro-image"
        :src="require('@/assets/profiling-intro.svg')"
        :lazy-src="require('@/assets/profiling-intro-lazy.png')"
      />
      <h3 class="signup-subtitle">
        {{ $t("signup.profiling.intro.title") }}
      </h3>

      <NewButton
        id="next-step"
        class="signup-step-button"
        :text="$t('signup.profiling.intro.button')"
        @on-click="handleRoute()"
      />

      <div
        class="signup-cancel-container"
        @click="$router.push({ name: 'signupDashboard' })"
      >
        <p class="signup-cancel">
          {{ $t("base.cancel") }}
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "ProfilingIntro",

  components: { NewButton },

  methods: {
    handleRoute() {
      this.$router.push({
        name: "signupProfilingQuestion",
        params: { currentQuestionNumber: 1 },
      });
    },
  },
};
</script>
<style lang="scss" scoped>

.signup-subtitle{
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: left;
  margin: 40px 0;
}
</style>
