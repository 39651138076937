<!-- eslint-disable no-tabs -->
<template>
  <div class="checkbox-wrapper">
    <div class="checkbox-item">
      <label class="checkbox-label">
        <input type="checkbox" :checked="checked" @change="$emit('setChecked')" />
        <span class="input-circle" />
        <div class="check-icon">
          <v-icon v-show="checked" size="12" color="#000"> mdi-check</v-icon>
        </div>
      </label>
    </div>
    <div class="label-row">
      <slot name="textSlot" />
      <div v-if="!$slots.textSlot" class="checkbox-text">{{ checkboxLabel }}</div>
      <a
        v-if="labelLink && linkText"
        :href="labelLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ linkText }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReusableCheckbox",

  props: {
    checkboxLabel: String,
    labelLink: String,
    linkText: String,
    checked: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.checkbox-wrapper {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-width: 420px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  padding: 0 20px;
}

.checkbox-text,
span,
a {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333 !important;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.checkbox-item {
  display: flex;
  align-items: center;
  height: 30px;
  width: 20px;
}

.label-row {
  display: flex;
  gap: 0 4px;
}

.checkbox-label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #0a0;
}

/* Hide the browser's default checkbox */
.checkbox-label input {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.input-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  border-radius: 50%;
  color: #000;
}
.check-icon {
  width: 15px;
  display: flex;
  justify-content: center;
}
</style>
