<!-- eslint-disable max-len -->
<template>
  <div>
    <div class="redemption-wrapper redemption-centered-wrapper" v-if="!loading">
      <div class="redemption-content">
        <div class="redemption-step-back">
          <v-icon color="#333333" size="22" @click="$router.go(-1)">
            mdi-arrow-left
          </v-icon>
        </div>

        <h2 class="redemption-header">
          {{ $t("redemptions.redemptionSummary.title") }}
        </h2>

        <div class="redemption-flex-columns">
          <div class="redemption-summary-column">
            <div class="summary-table-container">
              <div class="summary-table-title">
                {{ $t("redemptions.redemptionSummary.table.redemptionDetails.details") }}
              </div>

              <TwoColumnsTable :tableData="detailsTableData" translatorPath="redemptions.redemptionSummary.table.redemptionDetails" />

              <div class="summary-table-title">
                {{ $t("redemptions.redemptionSummary.table.bankAccount.paymentAccount") }}
              </div>

              <TwoColumnsTable :tableData="bankAccountTableData" translatorPath="redemptions.redemptionSummary.table.bankAccount" />
            </div>

            <div class="accept-terms-container">
              <ReusableCheckbox
                @setChecked="acceptedTerms = !acceptedTerms"
                :checked="acceptedTerms"
                :checkboxLabel="$t('redemptions.redemptionSummary.accept')"
                :linkText="$t('redemptions.redemptionSummary.termsAndConditions')"
                :labelLink="'https://ayuda.dvacapital.com/es/articles/5164287-terminos-y-condiciones?_gl=1*e9mvcj*_ga*MjA1NTYxOTE4Ny4xNjY4NjM4Njkz*_ga_XPHVZ3MFD2*MTY3NjMyNDYyMC40Ny4xLjE2NzYzMjQ3NTkuMC4wLjA'"
              />
            </div>
            <NewButton
              @on-click="performRedemptionRequests()"
              :text="$t('redemptions.redemptionSummary.confirmRedemption')"
              :disabled="!acceptedTerms"
            />
            <div class="cancel-text" @click="$router.push({ name: 'home' })">
              {{ $t("base.cancel") }}
            </div>
          </div>

          <div class="redemption-column">
            <RedemptionHints :showPayment="false" />
          </div>
        </div>

        <RedemptionsErrorDialog v-model="error" :redemptionResults="redemptionResults" />
      </div>
    </div>

    <div class="redemption-loading-container" v-else>
      <LoadingCard
        :loadingTitle="$t('redemptions.loading.redemptionMessage')"
        :loadingText="$t('redemptions.loading.loadingTime')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import RedemptionHints from "@/components/redemptions/RedemptionHints.vue";
import TwoColumnsTable from "@/components/shared/TwoColumnsTable.vue";
import NewButton from "@/components/shared/NewButton.vue";
import ReusableCheckbox from "@/components/shared/ReusableCheckbox.vue";
import LoadingCard from "@/components/shared/LoadingCard.vue";
import RedemptionsErrorDialog from "@/components/redemptions/RedemptionsErrorDialog.vue";
import funds from "@/mixins/funds.js";

export default {
  name: "RedemptionSummary",

  components: {
    TwoColumnsTable,
    RedemptionHints,
    NewButton,
    ReusableCheckbox,
    LoadingCard,
    RedemptionsErrorDialog,
  },

  data: () => ({
    acceptedTerms: true,
    loading: false,
    error: false,
    redemptionResults: [],
  }),

  mixins: [customersApiFunctions, funds],

  computed: {
    ...mapGetters(["redemption", "clpBankAccount", "profile"]),

    detailsTableData() {
      const { redemptionAmount } = this.redemption;

      const fundRun = this.redemption.redemptionOptions.fromTotal ? null : this.redemption.redemptionList.find(fund => fund.fundRun).fundRun;
      const fundData = fundRun ? this.getFundStaticData(fundRun) : null;

      // If there is no fundRun, the redemption period is the default of 5 days
      const redemptionPeriod = fundData ? fundData.redemptionPeriod : "fromFiveDays";

      return [
        {
          key: "type",
          useTranslation: true,
          value: fundRun || "FROM_TOTAL",
        },
        {
          key: "amount",
          value: `CLP $${this.$options.filters.currencyNoSymbol(redemptionAmount)}`,
        },
        {
          key: "paymentDate",
          useTranslation: true,
          value: redemptionPeriod,
        },
      ];
    },

    bankAccountTableData() {
      return [
        {
          key: "bank",
          value: this.clpBankAccount.bankName,
        },
        {
          key: "accountNumber",
          value: this.clpBankAccount.accountNumber,
        },
        {
          key: "accountType",
          value: this.clpBankAccount.accountType,
        },
      ];
    },
  },

  methods: {
    buildRedemptionPayload(redemption) {
      return {
        requester: this.profile.emails.find(email => email.principal).email,
        requestSource: "Mi DVA",
        requestedAmount: redemption.requestedAmount,
        requestedAmountCurrency: redemption.requestedAmountCurrency,
        fundSeriesSymbol: redemption.seriesName,
      };
    },

    handleErrorResponse(error) {
      this.error = true;
      console.error(error);
      this.loading = false;
    },

    async createSingleRedemption(redemption) {
      // Construct the payload for the redemption request
      const redemptionPayload = this.buildRedemptionPayload(redemption);

      try {
        // Perform the redemption request and handle the response
        const response = await this.createRedemptionRequest(redemptionPayload);

        if (response.error) {
          this.handleErrorResponse(response.errors);
        }

        // Return the redemption request with the response data
        return { ...redemption, data: response };
      } catch (error) {
        // Handle the error if the redemption request fails
        return this.handleErrorResponse(error);
      }
    },

    checkConfirmedStatus(redemption, responses) {
      const redemptionResponse = responses.find(response => response?.fundRun === redemption.fundRun);
      return redemptionResponse && !redemptionResponse?.data?.error;
    },

    async performRedemptionRequests() {
      this.loading = true;
      // Creates an array of promises for each redemption request
      const redemptionPromises = this.redemption.redemptionList.map(redemption => this.createSingleRedemption(redemption));

      try {
        const responses = await Promise.all(redemptionPromises);
        // Checks the status of each redemption request and updates the redemptionResults array
        this.redemptionResults = this.redemption.redemptionList.map(redemption => ({
          ...redemption,
          confirmed: this.checkConfirmedStatus(redemption, responses),
        }));
        this.loading = false;
      } catch (error) {
        this.handleErrorResponse(error);
      }
      // If there are any redemptions that have not been confirmed, launch the error dialog
      if (this.redemptionResults.some(redemption => !redemption.confirmed)) {
        this.error = true;
      } else {
        this.$router.push({ name: "redemptionReinvestmentSuccess" });
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.summary-table-container {
  background-color: #ffffff;
  width: 100%;
  border-radius: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    padding-bottom: 0;
  }
}

.summary-table-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  padding: 20px 30px;
  @media (max-width: 600px) {
    padding: 40px 20px 20px 20px;
  }
}

.redemption-flex-columns {
  gap: 0 80px;
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 60px;
  }
}

.redemption-summary-column {
  flex: 2;
  max-width: 600px;
}

::v-deep .full-width-table {
  tr td {
    height: 60px;
  }

  @media (min-width: 1264px) {
    tr td:first-child {
      width: 50% !important;
    }
  }

  @media (max-width: 600px) {
    tr {
      display: block;
      height: 80px;
    }
    td {
      height: 40px !important;
    }
    td.value-cell {
      float: left;
      line-height: 22px;
      padding-left: 20px;
    }
    tr td:first-child {
      width: inherit !important;
    }
  }

  .value,
  .attribute {
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 160%;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
  .attribute {
    color: #646363;
  }
}

.accept-terms-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 40px;
}

.redemption-content {
  max-width: 1200px;
}
</style>
