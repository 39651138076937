<template>
  <div
    class="fund-diversification-fund"
    :class="{ 'px-2': $vuetify.breakpoint.smAndDown }"
  >
    <div class="left-in-page">
      <div
        class="diversification-line"
        :style="`background-color: ${fundColor}; width: ${bardWidth}`"
      />
      <div class="fund-distribution ml-4">
        <span class="fund-percentage">
          {{ formattedPercentage }}
        </span>
        <span class="fund-name">
          {{ fundName }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FundsDiversificationFund",

  props: {
    fundProportion: {
      type: Object,
    },
    biggestProportion: {
      type: Number,
      default: null,
    },
  },

  computed: {
    bardWidth() {
      if (this.biggestProportion !== null) {
        return this.$options.filters.toDecimalPercentage(
          this.fundProportion.proportion * (100 / this.biggestProportion),
          1,
        );
      }
      return 0;
    },

    formattedPercentage() {
      if (this.fundProportion.proportion) {
        return this.$options.filters.toDecimalPercentage(
          this.fundProportion.proportion,
          1,
        );
      }
      return "";
    },

    fundColor() {
      return this.$options.filters.addFundColor(this.fundProportion.run);
    },

    fundName() {
      return this.$options.filters.fundShortName(this.fundProportion.run);
    },
  },
};
</script>

<style scoped lang="scss">
.fund-diversification-fund {
  width: 100%;
  margin: 15px 0px;

  .diversification-line {
    max-width: 90%;
    height: 60px;
    border-radius: 10px;
    @media (max-width: 600px) {
      max-width: 75%;
    }
  }
  .fund-distribution {
    display: flex;
    flex-direction: column;
    line-height: 140%;
    color: #333333;
    width: 150px;

    @media (max-width: 600px) {
      width: 115px;
    }

    .fund-name {
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }
    .fund-percentage {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
</style>
