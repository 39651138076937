<template>
  <v-row v-bind:class="{ 'selected-filter': isSelected, 'icon-fund-pill': optionIcon && !optionName }" class="my-auto filter-fund-pill">
    <v-icon v-if="optionIcon" :size="16" :color="$vuetify.theme.themes.light.grey['01']">
      {{ `mdi-${optionIcon}` }}
    </v-icon>
    <span v-if="optionName">
      {{ optionName }}
    </span>
  </v-row>
</template>

<script>
export default {
  name: "FilterFundsPill",

  props: {
    optionName: {
      type: String,
      required: true,
    },

    optionIcon: {
      type: String,
    },

    isSelected: {
      type: Boolean,
      required: true,
    },
  },

};
</script>

<style scoped lang="scss">
.filter-fund-pill {
  height: 32px;
  max-width: max-content;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #000000;
  padding: 8px 12px;
  border-radius: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  text-align: center;
  transition: background-color 0.2s ease-out;
}

.icon-fund-pill {
  height: 32px;
  min-width: 32px !important;
  padding: 0px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes show {
  100% {
    opacity: 1;
    transform: none;
  }
}

.selected-filter {
  background-color: #000000;
  color: #ffffff;
  transition: background-color 0.4s;
}
</style>
