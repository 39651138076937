<template>
  <div class="fund-cards" v-if="!loading && myFunds.length > 0">
    <SectionTitle :text="$t('home.myFunds.title')" />
    <div class="fund-cards-grid">
      <div
        v-for="fund in myFunds"
        :key="fund.id"
        @click="$router.push({ name: 'detailedFund', params: { fundRun: fund.fundRun } })"
      >
        <FundCard :fundBalance="filterBalances(fund.fundRun)" class="clickable-item" />
      </div>
    </div>
  </div>
  <v-row v-else-if="loading" class="mt-10">
    <v-col cols="12" sm="6" md="4" lg="3" v-for="n in 8" :key="n.index">
      <v-skeleton-loader type="list-item-two-line" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import FundCard from "@/components/shared/FundCard.vue";
import SectionTitle from "@/components/home/SectionTitle.vue";

export default {
  name: "FundCards",

  components: {
    FundCard,
    SectionTitle,
  },

  computed: {
    ...mapGetters(["loading", "fundsResults"]),

    myFunds() {
      return this.fundsResults
        .filter(
          (v, i, a) => a.findIndex(t => t?.fundRun === v?.fundRun && t?.fundCurrent > 0) === i,
        )
        .sort((fundA, fundB) => (fundB?.fundCurrent || 0) - (fundA?.fundCurrent || 0));
    },
  },

  methods: {
    filterBalances(fundRun) {
      return this.fundsResults.find(fund => fund?.fundRun === fundRun);
    },
  },
};
</script>

<style lang="scss" scoped>
.fund-cards-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 20px;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
