<template>
  <div class="customer-bank-account-wrapper">
    <div class="customer-bank-account">
      <div class="bank-data">
        <div class="bank-row" v-if="loadingProfile || !clpBankAccount">
          {{
            loadingProfile ? $t("base.loading") : $t("customerBankAccount.noBankAccount")
          }}
        </div>

        <div class="bank-info" v-else>
          <div class="bank-row">
            {{
              $t("customerBankAccount.RUT", {
                rut: $options.filters.formatRut(profile.identifier),
              })
            }}
          </div>
          <div class="bank-row">
            {{ clpBankAccount.accountType }} {{ clpBankAccount.accountNumber }}
          </div>
          <div class="bank-row">
            {{ clpBankAccount.bankName }}
          </div>
        </div>
      </div>

      <div class="edit-account" v-if="!loadingProfile" @click="toggleEditBankDialog()">
        {{ !clpBankAccount ? $t("base.add") : $t("base.edit") }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "CustomerBankCard",

  computed: {
    ...mapGetters(["clpBankAccount", "profile", "loadingProfile"]),
  },

  methods: {
    toggleEditBankDialog() {
      this.$emit("toggleEditBankDialog");
    },
  },
};
</script>
<style lang="scss" scoped>
.customer-bank-account-wrapper {
  max-width: 420px;
}

.customer-bank-account {
  height: 100px;
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 30px 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-account {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #646363;

  &:hover {
    cursor: pointer;
  }
}

.bank-row {
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
}
</style>
