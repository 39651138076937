<template>
  <div class="footer-container">
    <v-footer :color="$vuetify.theme.themes[theme].background">
      <div class="dividing-line" />
      <div class="disclaimer my-9">
        {{ this.$i18n.t("footer.disclaimer") }}
      </div>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "MainFooter",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style scoped lang="scss">
.footer-container {
  margin-top: 140px;
}
.disclaimer {
  font-weight: 500;
  font-size: 8.5px;
  line-height: 160%;
  color: #646363;
  @media (min-width: 1280px) {
    margin-left: 250px;
    margin-right: 40px;
  }
}

.dividing-line {
  width: 100%;
  border-bottom: 2px solid #e2e0e0;
}
</style>
