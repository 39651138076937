<template>
  <div>
    <div v-if="$vuetify.breakpoint.smAndDown && investedCustomer">
      <SectionTitle :text="$i18n.t('home.welcome.totalBalance')" />
    </div>
    <div class="balance-card" v-if="investedCustomer">
      <MobileBalanceCard
        v-if="$vuetify.breakpoint.smAndDown"
        :currentBalance="currentBalance"
        :currentResults="currentResults"
        :emptyBalances="emptyBalances"
        :historicalResults="historicalResults"
        :withdrawals="withdrawals"
        :loading="loading"
        @invest="$router.push({ name: 'investViewFunds' })"
        @getDetails="$router.push({ name: 'details' })"
      />

      <DesktopBalanceCard
        v-else
        :currentBalance="currentBalance"
        :currentResults="currentResults"
        :emptyBalances="emptyBalances"
        :historicalResults="historicalResults"
        :withdrawals="withdrawals"
        :loading="loading"
        @invest="$router.push({ name: 'investViewFunds' })"
        @getDetails="$router.push({ name: 'details' })"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SectionTitle from "./SectionTitle.vue";
import DesktopBalanceCard from "./BalanceCard/DesktopBalanceCard.vue";
import MobileBalanceCard from "./BalanceCard/MobileBalanceCard.vue";

export default {
  name: "BalanceCard",

  components: {
    SectionTitle,
    DesktopBalanceCard,
    MobileBalanceCard,
  },

  computed: {
    ...mapGetters([
      "loading",
      "currentResults",
      "historicalResults",
      "withdrawals",
      "customerCurrent",
      "investedCustomer",
    ]),

    emptyBalances() {
      return this.customerCurrent === null || this.customerCurrent.length === 0;
    },

    currentBalance() {
      if (!this.emptyBalances) {
        return this.customerCurrent
          ?.map(fund => fund?.currencyAmount)
          .reduce((total, amount) => total + amount);
      }
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
.balance-card {
  background: #ffffff;
  border-radius: 10px;
  @media (max-width: 960px) {
    margin: 0 20px;
    &:hover {
      background-color: #fafafa;
    }
  }

  @media (max-width: 960px) {
    margin: 0 20px;
  }
}
</style>
