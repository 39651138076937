<template>
  <div id="loading-card">
    <div class="card-container center-in-page flex-column" :class="[fullScreen ? 'full-screen' : 'pa-16']">
      <div class="center-in-page">
        <div class="circle" v-for="n in 3" :key="n" />
      </div>
      <h3 class="loading-title pt-8">
        {{ loadingTitle }}
      </h3>

      <p class="loading-text" v-if="loadingText">
        {{ loadingText }}
      </p>

    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingCard",

  props: {
    loadingTitle: String,
    loadingText: String,
    fullScreen: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  height: 100%;
}
#loading-card {
  .circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #000000;
    animation: grow 1s linear infinite;
    margin: 7px;
    animation-delay: 0.33s;
  }
  .circle:nth-child(2) {
    animation: grow 1s linear infinite;
    animation-delay: 0.66s;
  }
  .circle:nth-child(3) {
    animation: grow 1s linear infinite;
  }
  @keyframes grow {
    0% {
      transform: scale(1.5);
    }
    25% {
      transform: scale(0.75);
    }
    75% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(1.5);
    }
  }

  .loading-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    text-align: center;
    color: #000000;

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 160%;
    }
  }

  .loading-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    margin-top: 10px;
  }

  .full-screen {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
</style>
