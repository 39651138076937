export default {
  methods: {
    copyToClipboard(textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .catch(error => {
          console.log(error);
        });
    },
  },
};
