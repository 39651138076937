<template>
  <div class="error-wrapper">
    <div class="error-content">
      <v-img
        :src="require('@/assets/error.svg')"
        :lazy-src="require('@/assets/error-lazy.png')"
      />

      <h2 class="error-title">
        {{ errorText.title}}
      </h2>

      <p class="error-text">
        {{ errorText.text}}
      </p>

      <div class="button-rows">
        <NewButton
          :text="$t(`errorView.${isGenericError ? 'contactSupport' : 'backToCompleteData'}`)"
          @on-click="isGenericError ? openChat() : handleBackOption()"
        />

        <NewButton
          :text="$t('base.exit')"
          buttonClass="black-outlined"
          @on-click="$router.push({ name: 'home' })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ErrorView",

  components: {
    NewButton,
  },

  computed: {
    ...mapGetters(["nextRouteFromStack", "latestMutationError"]),

    isGenericError() {
      return this.$route.meta?.genericError;
    },

    errorText() {
      return {
        title: this.$t(`errorView.titles.${this.latestMutationError?.code || "DEFAULT"}`),
        text: this.$t(`errorView.texts.${this.latestMutationError?.code || "DEFAULT"}`),
      };
    },
  },

  methods: {
    ...mapActions(["popFromRouteStack"]),

    // Method used for generic errors. Its open the intercom chat
    openChat() {
      this.$intercom.showNewMessage(
        this.$t("errorView.intercom"),
      );
    },

    // Methods used for account or signup errors
    handleBackOption() {
      const isSignup = this.$route.meta.signup;
      const startView = isSignup ? "signupDashboard" : "home";

      const nextRoute = this.nextRouteFromStack?.name || startView;
      this.popFromRouteStack();

      this.$router.push({ name: nextRoute });
    },
  },

};
</script>

<style lang="scss" scoped>

.error-wrapper{
  display: flex;
  justify-content: center;
  margin: 40px 20px;

  @media (max-width: 600px) {
    margin: 30px 20px;
    height: calc(100% - 30px);
  }
}

.error-content {
  max-width: 420px;
  width: 100%;
}

.error-title{
  font-family: Work Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  margin-top: 40px;
  color: #333 !important;

  @media (max-width: 600px) {
    text-align: center;
  }
}

.error-text{
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  color: #333;
  margin: 20px 0 0 0;
  color: #333 !important;

  @media (max-width: 600px) {
    text-align: center;
  }
}

.button-rows{
    margin-top: 40px;
}

.button-rows > * {
  margin-top: 20px;
}

</style>
