<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="signup-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <h2 class="signup-title">
        {{ $t("signup.identification.title") }}
      </h2>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="saveUserInfo()"
        class="signup-form"
      >

        <div class="spouse-fields">
          <AutoComplete
            id="conjugal-regime"
            :value="conjugalRegime"
            :label="$t('signup.identification.spouse.conjugalRegime.label')"
            :placeholder="$t('signup.identification.spouse.conjugalRegime.placeholder')"
            :rules="mustCompleteRule"
            :items="displayableConjugalRegimeOptions"
            item-value="code"
            translator-path="signup.identification.spouse.conjugalRegime.options"
            @updateValue="conjugalRegime = $event"
            class="profile-input-field"
          />

          <div class="signup-form-label">
            {{ $t("signup.identification.spouse.spouseData.label") }}
          </div>

          <TextField
            id="spouse-name"
            :value="spouseName"
            :placeholder="$t('signup.identification.spouse.spouseData.spouseName.placeholder')"
            :rules="mustCompleteRule"
            @updateValue="spouseName = $event"
            class="profile-input-field"
          />

          <TextField
            id="spouse-family-name"
            :value="spouseFamilyName"
            :placeholder="$t('signup.identification.spouse.spouseData.spouseFamilyName.placeholder')"
            :rules="mustCompleteRule"
            @updateValue="spouseFamilyName = $event"
            class="profile-input-field"
          />

          <TextField
            id="spouse-rut"
            :value="spouseIdentifier"
            :placeholder="$t('signup.identification.spouse.spouseData.spouseIdentifier.placeholder')"
            :rules="rutRules"
            @updateValue="spouseIdentifier = $event"
            class="profile-input-field"
          />

        </div>

        <p class="signup-hint">
          {{ $t("signup.identification.cmfExplanation") }}
        </p>

        <NewButton
          id="next-step"
          class="signup-step-button"
          :text="$t('signup.shared.buttons.next')"
          :type="'submit'"
          :loading="loading"
          :disabled="!valid || loading"
        />

        <div
          class="signup-cancel-container"
          @click="$router.push({ name: 'signupDashboard' })"
        >
          <p class="signup-cancel">
            {{ $t("base.cancel") }}
          </p>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AutoComplete from "@/components/shared/AutoComplete.vue";
import TextField from "@/components/shared/TextField.vue";
import userSelects from "@/mixins/userSelects.js";
import NewButton from "@/components/shared/NewButton.vue";

const { validate, format } = require("rut.js");

export default {
  name: "SpouseView",

  mixins: [userSelects],

  components: { NewButton, AutoComplete, TextField },

  data() {
    return {
      valid: false,
      loading: false,
      spouseName: null,
      spouseFamilyName: null,
      spouseIdentifier: null,
      conjugalRegime: null,
      mustCompleteRule: [v => !!v || this.$t("signup.shared.errors.mustComplete")],
      rutRules: [
        v => !!v || this.$t("signup.shared.errors.mustComplete"),
        v => validate(v) || this.$t("signup.shared.errors.rut"),
      ],
    };
  },

  computed: {
    ...mapGetters(["signupIdentificationData"]),

    displayableConjugalRegimeOptions() {
      return this.conjugalRegimeOptions[this.signupIdentificationData.maritalStatus];
    },
  },

  methods: {
    ...mapActions(["setSpouse", "setConjugalRegime"]),

    saveUserInfo() {
      this.setSpouse({
        spouseName: this.spouseName,
        spouseIdentifier: format(this.spouseIdentifier, { dots: false }),
        spouseFamilyName: this.spouseFamilyName,
      });
      this.setConjugalRegime(this.conjugalRegime);
      this.$router.push({ name: "signupIdentificationContact" });
    },

    setDefaultConjugalRegime() {
      if (!this.conjugalRegime) {
        this.conjugalRegime = this.conjugalRegimeOptions[this.signupIdentificationData.maritalStatus]
          .find(conjugalRegimeOption => conjugalRegimeOption.default).code;
      }
    },

    reloadData() {
      if (this.signupIdentificationData.spouseFamilyName) {
        this.spouseFamilyName = this.signupIdentificationData.spouseFamilyName;
      }
      if (this.signupIdentificationData.spouseName) {
        this.spouseName = this.signupIdentificationData.spouseName;
      }
      if (this.signupIdentificationData.spouseIdentifier) {
        this.spouseIdentifier = this.signupIdentificationData.spouseIdentifier;
      }
      if (this.signupIdentificationData.conjugalRegime) {
        this.conjugalRegime = this.signupIdentificationData.conjugalRegime;
      }
    },
  },

  mounted() {
    this.setDefaultConjugalRegime();
    this.reloadData();
  },
};
</script>

<style lang="scss" scoped>
.spouse-fields > :not(:last-child) {
  margin-bottom: 10px;
}
</style>
