<template>
  <div class="profile-wrapper profile-centered-wrapper">
    <div class="profile-content">
      <div class="profile-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="profile-title">
        {{ $t("profile.declarations.title") }}
      </h3>

      <v-form class="profile-form" v-model="validForm">
        <div
          v-for="(declaration, index) in declarations"
          :key="index"
          class="declaration-option"
        >
          <MultiButtonPicker
            :label="$t(`profile.declarations.${declaration.name}`)"
            :id="declaration.name"
            :items="declarationsOptions"
            :value="declarationAnswers[declaration.name]"
            @selectItem="saveAnswer(declaration.name, $event)"
            item-text="label"
            item-value="value"
            :displayNotification="declarationAnswers[declaration.name] === null"
            v-if="declaration.formComponent === 'PICKER'"
          />

          <AutoComplete
            :label="$t(`profile.declarations.${declaration.name}.label`)"
            :placeholder="$t(`profile.declarations.${declaration.name}.placeholder`)"
            :rules="mustCompleteRules"
            :id="declaration.name"
            :items="getDeclarationOptions(declaration)"
            :value="declarationAnswers[declaration.name]"
            :translatorPath="declaration.translatorPath"
            @updateValue="saveAnswer(declaration.name, $event)"
            :displayNotification="!declarationAnswers[declaration.name]"
            item-text="label"
            item-value="value"
            v-else-if="declaration.formComponent === 'AUTOCOMPLETE'"
          />

          <div
            v-if="declaration.name === 'isCrs' && declarationAnswers.isCrs"
            id="tin-declaration"
          >
            <div class="tin-declaration-item">
              <AutoComplete
                :id="`tin-country-${index}`"
                :value="taxIdentificationNumber.country"
                :items="countries"
                item-text="country"
                item-value="code"
                :rules="countryRules"
                :placeholder="$t('profile.declarations.tinDeclaration.country')"
                :displayNotification="!taxIdentificationNumber.country"
                @updateValue="taxIdentificationNumber.country = $event"
                class="profile-input-field"
              />

              <TextField
                :id="`tin-number-${index}`"
                :value="taxIdentificationNumber.identification"
                :placeholder="$t('profile.declarations.tinDeclaration.tin')"
                :rules="mustCompleteRules"
                @updateValue="taxIdentificationNumber.identification = $event"
                :displayNotification="!taxIdentificationNumber.identification"
                class="profile-input-field"
              />
            </div>
          </div>

          <div
            v-if="
              declaration.name === 'foreignIncome' && declarationAnswers.foreignIncome
            "
            class="foreign-income-declaration"
          >
            <AutoComplete
              id="capital-origin-country"
              :value="declarationAnswers.capitalOriginCountry"
              :items="countries"
              item-text="country"
              item-value="code"
              :rules="countryRules"
              :label="$t('profile.declarations.capitalOriginCountry.label')"
              :placeholder="$t('profile.declarations.capitalOriginCountry.placeholder')"
              @updateValue="declarationAnswers.capitalOriginCountry = $event"
              :displayNotification="!declarationAnswers.capitalOriginCountry"
              class="profile-input-field"
            />
          </div>
        </div>
      </v-form>
      <NewButton
        id="save-changes"
        class="profile-step-button"
        :text="successState ? $t('base.savedChanges') : $t('base.saveChanges')"
        :disabled="saveDisabled"
        :loading="performingMutation"
        @on-click="createCustomerPatch()"
        :class="{ 'success-state-btn': successState }"
      />

      <div class="profile-cancel-container" @click="$router.push({ name: 'account' })">
        <p class="profile-cancel-text">
          {{ $t("base.cancel") }}
        </p>
      </div>

      <ReusableDialog
        id="failed-patch-dialog"
        :value="errorDialog"
        :title="$t('profile.declarations.errorDialog.title')"
        :text="$t('profile.declarations.errorDialog.text')"
        @display="errorDialog = $event"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import userSelects from "@/mixins/userSelects.js";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import NewButton from "@/components/shared/NewButton.vue";
import MultiButtonPicker from "@/components/shared/MultiButtonPicker.vue";
import AutoComplete from "@/components/shared/AutoComplete.vue";
import TextField from "@/components/shared/TextField.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";

export default {
  name: "declarationsView",

  mixins: [userSelects, customersApiFunctions],

  components: {
    NewButton,
    ReusableDialog,
    MultiButtonPicker,
    AutoComplete,
    TextField,
  },

  data() {
    return {
      declarationAnswers: {},
      taxIdentificationNumber: {
        country: null,
        identification: null,
      },
      countryRules: [
        v => !!v || this.$t("shared.errors.mustComplete"),
        v => this.countries.some(el => el.code === v) ||
          this.$t("shared.errors.selectCountry"),
      ],
      mustCompleteRules: [v => !!v || this.$t("shared.errors.mustComplete")],
      validForm: false,
      // Mutation states
      performingMutation: false,
      errorDialog: false,
      successState: false,
    };
  },

  watch: {
    loadingProfile() {
      this.loadAnswers();
    },
  },

  computed: {
    ...mapGetters(["loadingProfile", "customerTin", "customerDeclarations"]),

    customerPatchPayload() {
      const {
        isPep,
        isCrs,
        isFatca,
        capitalOriginCountry,
        capitalOrigin,
        netWorth,
        salaryRange,
        // reinvestDistributions,
      } = this.declarationAnswers;

      const tinCountry = this.taxIdentificationNumber?.country || null;
      const tinIdentification = this.taxIdentificationNumber?.identification || null;

      return {
        // Customer editable attributes
        isPep,
        isCrs,
        isFatca,
        capitalOriginCountry,
        capitalOrigin,
        tinCountry,
        tinIdentification,
        netWorth,
        salaryRange,
        // reinvestDistributions,
      };
    },

    isEditedForm() {
      // Checks changes in the declarations
      // pep, fatca, crs, capitalOriginCountry, capitalOrigin and tin values

      return (
        Object.keys(this.customerPatchPayload).filter(
          key => this.customerPatchPayload[key] !== this.customerDeclarations[key],
        ).length > 0
      );
    },

    saveDisabled() {
      const { tinCountry, tinIdentification, ...mainAnswers } = this.declarationAnswers;

      // The main declarations are boolean, so the values must be different than null
      const notAnsweredDeclarations = Object.values(mainAnswers).some(
        answer => answer === null,
      );

      return (
        !this.validForm ||
        !this.isEditedForm ||
        notAnsweredDeclarations ||
        this.performingMutation
      ) && !this.successState;
    },
  },

  methods: {
    ...mapActions(["setDeclarationsData"]),

    saveAnswer(declaration, answer) {
      this.declarationAnswers[declaration] = answer;

      // If the user changes to 'No" in foreignIncome declaration, we need to set Chile
      if (declaration === "foreignIncome" && !answer) this.declarationAnswers.capitalOriginCountry = "CHL";

      // If the user changes to 'No" in isCrs declaration, we need to reset to default the tin values
      if (declaration === "isCrs" && !answer) {
        this.initializeTin();
      }
    },

    async handleSuccessfulMutation() {
      this.setDeclarationsData({
        declarationsData: this.customerPatchPayload,
        taxIdentificationNumberData: this.taxIdentificationNumber,
      });
      await this.showSuccessState();
      this.$router.push({ name: "account" });
    },

    handleErrorResponse() {
      this.errorDialog = !this.errorDialog;
    },

    async createCustomerPatch() {
      const data = {
        payload: this.customerPatchPayload,
      };

      try {
        this.performingMutation = true;
        const { errors } = await this.patchCustomerData(data);
        if (errors) {
          this.handleErrorResponse();
        } else {
          this.handleSuccessfulMutation();
        }
      } catch (error) {
        console.error(error);
        this.handleErrorResponse();
      } finally {
        this.performingMutation = false;
      }
    },

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    initializeTin() {
      if (this.customerTin) {
        this.taxIdentificationNumber = { ...this.customerTin };
      } else {
        this.taxIdentificationNumber = {
          country: null,
          identification: null,
        };
      }
    },

    loadAnswers() {
      const capitalOriginCountry =
        this.customerDeclarations.capitalOriginCountry || "CHL";

      this.declarationAnswers = {
        ...this.customerDeclarations,
        capitalOriginCountry,
        foreignIncome: capitalOriginCountry !== "CHL",
      };

      // Performs a copy from the getter customerTinValues
      // We need to use JSON stringify to avoid mutating the referenced getter

      this.initializeTin();
    },

    getDeclarationOptions(declaration) {
      let options = this.$data[declaration.options];

      // Capital origin has displayable an non displayable options
      if (declaration.name === "capitalOrigin") {
        const currentCapitalOrigin = this.customerDeclarations.capitalOrigin;

        // If the customer has a or non displayable capital origin value
        // we will display that value

        options = options.filter(
          option => option.display || option.value === currentCapitalOrigin,
        );
      }

      return options;
    },
  },

  mounted() {
    this.loadAnswers();
  },
};
</script>

<style lang="scss" scoped>
.declaration-option + .declaration-option {
  margin-top: 40px;
}

.tin-declaration-item,
.foreign-income-declaration {
  margin-top: 20px;
}

.tin-declaration-item + .tin-declaration-item {
  margin-top: 20px;
}

.profile-tin-buttons-wrapper {
  max-width: 100% !important;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
}

.profile-tin-buttons-wrapper > * {
  width: 100% !important;
}

.success-state-btn {
  background-color: #6d48ff !important;
  border: unset;
}
</style>
