<template>
  <div
    class="balance-details-fund-card"
    :class="{ disabled: disabled }"
    @click="handleClick()"
  >
    <div class="balance-fund-flex-between">
      <div class="balance-fund-flex-left">
        <FundBadge
          :fundRun="fundBalance.fundRun"
          :disabled="disabled"
          :size=40
          :iconSize=25
        />
        <div class="fund-name">{{ fundBalance.fundRun | fundShortName }}</div>
      </div>
      <div class="fund-balance-amount">
        {{ fundBalance.currencyAmount | formatBalanceAmount(balanceType) }}
      </div>
    </div>
  </div>
</template>

<script>
import FundBadge from "@/components/shared/FundBadge.vue";

export default {
  name: "BalanceDetailsFundCard",

  components: { FundBadge },

  props: {
    fundBalance: Object,
    balanceType: String,
    disabled: Boolean,
  },

  methods: {
    handleClick() {
      if (this.disabled) return;
      this.$emit("selectedFund", this.fundBalance.fundRun);
    },
  },
};
</script>

<style lang="scss" scoped>
.balance-details-fund-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 30px 10px 10px;
  height: 60px;
  border: 1px solid #e2e0e0;
}

.balance-fund-flex-left {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0 20px;
}

.fund-name,
.fund-balance-amount {
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
}

.fund-balance-amount {
  line-height: 140%;
}

.balance-fund-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disabled {
  background-color: #f7f7f7;
  opacity: 0.6;
}

.balance-details-fund-card:not(.disabled) {
  &:hover {
    cursor: pointer;
    background-color: #fafafa;
  }
}
</style>
