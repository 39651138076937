<template>
  <div
    class="compass-notice-bar"
    v-if="displayCompassNoticeBar"
    @click="navigateToSignatureNotice"
  >
    <div class="compass-notice-bar-text">
      {{ $t("compassNoticeBar.text") }}
    </div>
    <v-icon color="#fff"> mdi-chevron-right </v-icon>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CompassNoticeBar",

  computed: {
    ...mapGetters(["displayCompassNoticeBar", "isMissingSignupData"]),
  },

  methods: {
    ...mapActions(["addToRouteStack"]),

    navigateToSignatureNotice() {
      if (this.isMissingSignupData) {
        this.$router.push({ name: "accountCompassCompleteDataBeforeContract" });
      } else {
        this.addToRouteStack({ name: "home" });
        this.$router.push({ name: "accountCompassSignatureNotice" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.compass-notice-bar {
  background-color: #6d48ff;
  height: 40px;
  padding: 20px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1264px) {
    margin: 0 20px !important;
  }
}

.compass-notice-bar-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  color: #fff;
}
</style>
