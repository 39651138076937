<template>
  <div class="redemption-wrapper redemption-centered-wrapper">
    <div class="redemption-content">
      <div class="redemption-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <h2 class="redemption-header">
        {{ $t("redemptions.redemptionReasons.title") }}
      </h2>

      <div
        class="redemption-reason left-in-page"
        v-for="(reason, index) in reasons"
        :key="index"
        @click="handleAnswer(reason)"
      >
        <span>
          {{ $t(`redemptions.redemptionReasons.answers.${reason}`) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RedemptionReasons",

  data: () => ({
    reasons: ["newProject", "competition", "losses", "fear", "other"],
  }),

  methods: {
    ...mapActions(["setRedemptionData"]),

    handleAnswer(answer) {
      this.setRedemptionData({ redemptionOptions: { redemptionReasons: answer } });
      this.$router.push({ name: "redemptionOrigin" });
    },
  },
};
</script>

<style lang="scss" scoped>
.redemption-reason {
  background: #ffffff;
  border-radius: 10px;
  height: 60px;
  width: 100%;
  max-width: 420px;
  padding: 0 20px;

  &:hover {
    cursor: pointer;
    background-color: #fafafa;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
  }
}

.redemption-reason + .redemption-reason {
  margin-top: 10px;
}
</style>
