var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reinvestment-wrapper reinvestment-centered-wrapper"},[_c('div',{staticClass:"reinvestment-content"},[_c('div',{staticClass:"reinvestment-step-back"},[_c('v-icon',{attrs:{"color":"#333333","size":"22"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" mdi-arrow-left ")])],1),_vm._l((_vm.reinvestmentFunds),function(fundData,fundOrder,index){return _c('div',{key:index,staticClass:"reinvesting-fund"},[_c('i18n',{attrs:{"path":`reinvestment.chooseReinvestmentAmount.${
          fundOrder === 'originFund' ? 'moveFrom' : 'moveTo'
        }`,"tag":"p"},scopedSlots:_vm._u([{key:"fundName",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(fundData.shortName))]),_c('div',{staticClass:"inline-wrapper"},[_c('div',{staticClass:"fund-icon-container center-in-page",style:({ backgroundColor: fundData.color })},[_c('v-icon',{attrs:{"color":"#fff","size":"12"}},[_vm._v(" mdi-"+_vm._s(fundData.icon))])],1)])]},proxy:true}],null,true)})],1)}),_c('h2',{staticClass:"reinvestment-header"},[_vm._v(" "+_vm._s(_vm.$t("reinvestment.chooseReinvestmentAmount.title"))+" ")]),_c('div',{staticClass:"available-balance"},[_vm._v(" "+_vm._s(_vm.$t("reinvestment.chooseReinvestmentAmount.maxReinvestAmount", { fundBalance: `$${_vm.$options.filters.currencyNoSymbol(_vm.maxReinvestmentAmount)}`, fundName: _vm.reinvestmentFunds.originFund.shortName, }))+" ")]),_c('div',{staticClass:"reinvestment-flex-columns"},[_c('div',{staticClass:"reinvestment-column"},[_c('div',{staticClass:"custom-input-wrapper"},[_c('CustomAmountInput',{attrs:{"initial-amount":0,"defaultValue":_vm.totalReinvestment ? _vm.maxReinvestmentAmount : null,"invalid":_vm.inputAmount > _vm.maxReinvestmentAmount,"errorHint":_vm.$t(
                'reinvestment.chooseReinvestmentAmount.errorHints.maxReinvestmentAmountExceeded',
              ),"disabled":_vm.totalReinvestment,"width":_vm.$vuetify.breakpoint.xs ? '100%' : '420px'},on:{"on-enter":function($event){_vm.inputAmount < _vm.maxReinvestmentAmount && _vm.inputAmount > 0 ? _vm.confirmReinvestmentAmount() : {}},"amount-ingressed":function($event){_vm.inputAmount = $event}}})],1),_c('ReusableCheckbox',{staticClass:"total-reinvestment-checkbox",attrs:{"checked":_vm.totalReinvestment,"checkboxLabel":_vm.$t('reinvestment.chooseReinvestmentAmount.moveAllFromFund', {
              fundName: _vm.reinvestmentFunds.originFund.shortName,
            })},on:{"setChecked":function($event){_vm.totalReinvestment = !_vm.totalReinvestment}}}),_c('NewButton',{attrs:{"text":_vm.$t('base.nextStep'),"disabled":_vm.invalidAmount && !_vm.totalReinvestment},on:{"on-click":function($event){return _vm.confirmReinvestmentAmount()}}}),_c('div',{staticClass:"cancel-text",on:{"click":function($event){return _vm.$router.push({ name: 'home' })}}},[_vm._v(" "+_vm._s(_vm.$t("base.cancel"))+" ")])],1),_c('div',{staticClass:"reinvestment-column"},[_c('ReinvestmentHints')],1)])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }