<template>
  <tbody
    :class="[isMobileContainer ? 'mobile-table' : 'desktop-table']"
    id="ongoing-reinvestments-table"
  >
    <tr v-for="reinvestment in reinvestments" :key="reinvestment.id">
      <template v-if="isMobileContainer">
        <td>
          <div>
            <div class="reinvestment-item-container">
              <div>
                <div
                  class="fund-icon"
                  :style="{
                    backgroundColor: $options.filters.addFundColor(
                      reinvestment.originFundSeries.fund.run,
                    ),
                  }"
                >
                  <v-icon color="#fff" size="10">
                    {{ getFundIcon(reinvestment.originFundSeries.fund.run) }}
                  </v-icon>
                </div>
                <span>
                  {{ reinvestment.originFundSeries.fund.run | fundShortName }}
                </span>
              </div>
              <div>
                <div
                  class="fund-icon"
                  :style="{
                    backgroundColor: $options.filters.addFundColor(
                      reinvestment.destinationFundSeries.fund.run,
                    ),
                  }"
                >
                  <v-icon color="#fff" size="10">
                    {{ getFundIcon(reinvestment.destinationFundSeries.fund.run) }}
                  </v-icon>
                </div>
                <span>
                  {{ reinvestment.destinationFundSeries.fund.run | fundShortName }}
                </span>
              </div>
              <div>
                <v-icon color="#333" size="17">
                  mdi-swap-horizontal-circle-outline
                </v-icon>
                <span>
                  {{ formatReinvestmentAmount(reinvestment) }}
                </span>
              </div>
            </div>
          </div>
        </td>
        <td>
          <p>
            {{
              $t(
                `transactions.ongoingReinvestments.steps.${
                  getReinvestmentStatus(reinvestment).status
                }`,
                {
                  ...getReinvestmentStatus(reinvestment).values,
                },
              )
            }}
          </p>
        </td>
      </template>

      <template v-else>
        <td>
          <div class="movement-type">
            <v-icon color="#333" size="17"> mdi-swap-horizontal-circle-outline </v-icon>
            <span>
              {{ $t("transactions.ongoingReinvestments.transfer") }}
            </span>
          </div>
        </td>
        <td>
          <div class="reinvestment-item-container">
            <div>
              <div
                class="fund-icon"
                :style="{
                  backgroundColor: $options.filters.addFundColor(
                    reinvestment.originFundSeries.fund.run,
                  ),
                }"
              >
                <v-icon color="#fff" size="14">
                  {{ getFundIcon(reinvestment.originFundSeries.fund.run) }}
                </v-icon>
              </div>
              <span>
                {{ reinvestment.originFundSeries.fund.run | fundShortName }}
              </span>
            </div>
            <div>
              <div
                class="fund-icon"
                :style="{
                  backgroundColor: $options.filters.addFundColor(
                    reinvestment.destinationFundSeries.fund.run,
                  ),
                }"
              >
                <v-icon color="#fff" size="14">
                  {{ getFundIcon(reinvestment.destinationFundSeries.fund.run) }}
                </v-icon>
              </div>
              <span>
                {{ reinvestment.destinationFundSeries.fund.run | fundShortName }}
              </span>
            </div>
          </div>
        </td>
        <td>
          <span>
            {{
              $t(
                `transactions.ongoingReinvestments.steps.${
                  getReinvestmentStatus(reinvestment).status
                }`,
                {
                  ...getReinvestmentStatus(reinvestment).values,
                },
              )
            }}
          </span>
        </td>

        <td>
          <span>
            {{ formatReinvestmentAmount(reinvestment) }}
          </span>
        </td>
      </template>
    </tr>
  </tbody>
</template>

<script>
import { mapGetters } from "vuex";
import containerSize from "@/mixins/containerSize.js";
import funds from "../../../mixins/funds.js";

export default {
  name: "OngoingReinvestments",

  data() {
    return {
      // Needed for containerSize mixin
      containerId: "ongoing-reinvestments-table",
    };
  },

  mixins: [funds, containerSize],

  computed: {
    ...mapGetters(["reinvestments", "loadingReinvestments"]),
  },

  methods: {
    getFundShortName(fundRun) {
      return this.$options.filters.fundShortName(fundRun);
    },

    getFundIcon(fundRun) {
      return `mdi-${this.getFundStaticData(fundRun)?.icon}`;
    },

    getReinvestmentStatus(reinvestment) {
      const originFund = this.getFundShortName(reinvestment.originFundSeries.fund.run);
      const destinationFund = this.getFundShortName(
        reinvestment.destinationFundSeries.fund.run,
      );
      const { getMonthAndDay } = this.$options.filters;

      const settlementDate =
        reinvestment.subscriptionSettlementDate || reinvestment.redemptionSettlementDate;
      const integrationDate =
        reinvestment.subscriptionIntegrationDate ||
        reinvestment.redemptionIntegrationDate;
      const executionDate =
        reinvestment.subscriptionExecutionDate || reinvestment.redemptionExecutionDate;

      const statusTrace = [
        {
          step: 0,
          status: "pending",
          values: {
            date: getMonthAndDay(integrationDate),
            originFund,
            destinationFund,
          },
        },
        {
          step: 1,
          status: "ongoing",
          values: {
            date: getMonthAndDay(settlementDate),
            originFund,
            destinationFund,
          },
        },
        {
          step: 2,
          status: "ending",
          values: {
            date: getMonthAndDay(executionDate),
            originFund,
            destinationFund,
          },
        },
      ];
      return statusTrace.find(
        status => status.step === this.getStep(integrationDate, settlementDate),
      );
    },

    getStep(integrationDate, settlementDate) {
      const currentDate = this.$dayjs().format("YYYY-MM-DD");
      const formattedIntegrationDate = this.$dayjs(integrationDate).format("YYYY-MM-DD");
      const formattedSettlementDate = this.$dayjs(settlementDate).format("YYYY-MM-DD");

      if (formattedSettlementDate === currentDate) {
        return 2;
      }

      if (currentDate > formattedIntegrationDate) {
        return 1;
      }

      return 0;
    },

    formatReinvestmentAmount(reinvestment) {
      const requestedAmount =
        reinvestment.subscriptionShareState?.CLP || reinvestment.redemptionShareState.CLP;

      return `$${this.$options.filters.currencyNoSymbol(requestedAmount)}`;
    },
  },
};
</script>

<style scoped lang="scss">
span {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333;
}

p {
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  margin: 0 !important;
  text-align: left;
}

.movement-type {
  display: flex;
  gap: 0 10px;
  align-items: center;
  margin-right: 5px;
}

tbody tr:last-child {
  border-bottom: 1px solid #e2e0e0;
}

tbody tr td:last-child {
  text-align: right;
}

.desktop-table {
  tr {
    height: 60px;
    border-top: 1px solid #e2e0e0;

    .fund-icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .reinvestment-item-container > div {
      display: flex;
      align-items: center;
      gap: 0 10px;
      margin-top: 5px;
    }
  }
}

.mobile-table {
  tr {
    height: 80px;
    border-top: 1px solid #e2e0e0;
  }

  tr > :first-child {
    padding-left: 20px;
  }

  tr > :last-child {
    padding-right: 32px;
    text-align: left !important;
    span {
      font-size: 11px;
    }
  }

  .fund-icon {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    min-width: 15px;
    height: 15px;
  }

  .reinvestment-item-container > div {
    display: flex;
    align-items: center;
    gap: 0 10px;
    margin-top: 0px;
  }
}
</style>
