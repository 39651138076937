<template>
  <div class="profile-wrapper">
    <div class="profile-step-back">
      <v-icon color="#333333" size="22" @click="$router.go(-1)"> mdi-arrow-left </v-icon>
    </div>
    <h3 class="profile-title">
      {{ $t("profile.pendingContracts.title") }}
    </h3>

    <div class="pending-contracts-container" v-if="pendingContracts.length > 0">
      <div
        :id="`${contract.name}-contract`"
        class="pending-contract-container"
        v-for="(contract, index) in pendingContracts"
        :key="index"
        @click="handleContractRedirection(contract)"
      >
        <div class="pending-contract">
          <div class="pending-contract-text">
            {{ $t(`profile.pendingContracts.contracts.${contract.name}`) }}
          </div>

          <v-icon color="#fff"> mdi-chevron-right </v-icon>
        </div>
      </div>
    </div>
    <div class="all-signed-text" v-else>
      {{ $t("profile.pendingContracts.allSigned") }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PendingContracts",

  computed: {
    ...mapGetters(["canSignCompassContract"]),

    pendingContracts() {
      // Fill with pending contracts and its destination routes
      const contractsList = [
        {
          name: "COMPASS",
          route: "accountCompassSignatureNotice",
          pending: this.canSignCompassContract,
        },
      ];
      return contractsList.filter(contract => contract.pending);
    },

  },

  methods: {
    ...mapActions(["addToRouteStack"]),

    handleContractRedirection(contract) {
      this.addToRouteStack({ name: "account" });
      this.$router.push({ name: contract.route });
    },
  },
};
</script>

<style lang="scss" scoped>
.pending-contract-container {
  max-width: 420px;
}
.pending-contract {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  background-color: #6d48ff;
  padding: 20px;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
}

.pending-contract-text {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.pending-contract-container + .pending-contract-container {
  margin-top: 10px;
}

.all-signed-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  color: #333;
}
</style>
