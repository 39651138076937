<template>
  <div class="risk-profile-card">
    <div class="risk-profile-result" v-if="showProfilingResultTitle">
      {{ $t("profile.riskProfile.result") }}
    </div>

    <h3 class="risk-profile-title">
      {{ $t(`profile.riskProfile.options.${customerRiskProfile}.name`) }}
    </h3>

    <p class="risk-profile-description">
      {{ $t(`profile.riskProfile.options.${customerRiskProfile}.description`) }}
    </p>

    <NewButton
      v-if="!loadingProfile"
      :text="actionButtonText"
      @on-click="$emit('button-clicked')"
      class="risk-profile-action-button"
      button-class="black-outlined"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NewButton from "../shared/NewButton.vue";

export default {
  name: "RiskProfileCard",

  components: { NewButton },

  props: {
    actionButtonText: String,
    showProfilingResultTitle: Boolean,
  },

  computed: {
    ...mapGetters(["customerRiskProfile", "loadingProfile"]),
  },
};
</script>
<style lang="scss" scoped>
.risk-profile-card {
  border-radius: 10px;
  background-color: #fff;
  padding: 30px;
  max-width: 600px;
}

.risk-profile-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #333;
  margin-bottom: 20px;
}

.risk-profile-description,
.risk-profile-result {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  color: #333;
  margin: 0;
}

.risk-profile-result {
  margin-bottom: 10px;
}

.risk-profile-action-button {
  width: 105px !important;
  height: 40px !important;
  margin-top: 20px;
}
</style>
