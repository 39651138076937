import Vue from "vue";
import VueIntercom from "vue-intercom";
import dayjs from "dayjs";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import * as VueGoogleMaps from "vue2-google-maps";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import WebCam from "vue-web-cam";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import axios from "axios";
import VueAxios from "vue-axios";
import VueApexCharts from "vue-apexcharts";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueCookies from "vue-cookies";
import { VueReCaptcha } from "vue-recaptcha-v3";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import "./filters/index.js";
import vuetify from "./plugins/vuetify.js";
import i18n from "./plugins/i18n.js";
import auth from "./auth/index.js";
import segment from "./segment/index.js";
import "vuetify/dist/vuetify.min.css";

dayjs.locale("es");
const utc = require("dayjs/plugin/utc.js");
const timezone = require("dayjs/plugin/timezone.js");
const isLeapYear = require("dayjs/plugin/isLeapYear");

// dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isLeapYear);

dayjs.tz.setDefault("Chile/Continental");

Vue.prototype.$dayjs = dayjs;
Vue.prototype.$sentry = Sentry;

Vue.use(auth);
if (!window.Cypress) {
  Vue.use(segment);
  Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY });
}

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueLoading);
Vue.use(VueCookies, { expires: "1d", sameSite: "Lax" });
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID });
Vue.use(VueTelInput);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_PLACE_APIKEY,
    libraries: "places",
  },
});

Vue.use(VuetifyGoogleAutocomplete, {
  vueGoogleMapsCompatibility: true,
});
Vue.use(WebCam);
Vue.use(VueApexCharts);
Vue.component("ApexCharts", VueApexCharts);

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["mi.dvacapital.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.8,
  });
}

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount("#app");

if (window.Cypress) {
  // only available during E2E tests
  window.app = app;
}
