export default {
  data: () => ({
    MOVEMENT_DIRECTION: {
      SUBSCRIPTION: "in",
      DISTRIBUTION: "out",
      REDEMPTION: "out",
      INTERNAL_INCOMING_TRANSFER: "in",
      SERIES_INCOMING_TRANSFER: "in",
      SERIES_OUTBOUND_TRANSFER: "out",
      INTERNAL_OUTBOUND_TRANSFER: "out",
      OUTBOUND_TRANSFER: "out",
      INCOMING_TRANSFER: "in",
      CAPITAL_DISTRIBUTION: "in",
      PENDING_REDEMPTION: "out",
      DISTRIBUTION_REINVESTMENT: "in",
      REINVESTMENT_SUBSCRIPTION: "in",
      REINVESTMENT_REDEMPTION: "out",
    },
  }),

  methods: {
    getMovementDirection(movementType) {
      return this.MOVEMENT_DIRECTION[movementType];
    },
  },
};
