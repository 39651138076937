<template>
  <h3 class="section-title">
    {{ text }}
  </h3>
</template>

<script>
export default {
  name: "SectionTitle",

  props: {
    text: String,
  },
};
</script>

<style scoped lang="scss">
.section-title {
  margin: 0 0 20px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: #333333;

  @media (max-width: 960px) {
    margin-left: 20px;
  }
}
</style>
