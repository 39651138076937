export const customerGQLQueries = {
  getProfilesFromEmail(email) {
    return {
      operationName: "getProfilesFromEmail",
      query: `query getProfilesFromEmail($email: String) {
        emails(email: $email) {
          id
          email
          principal
          customer {
            id
            name
            lastName
            identifier
          }
        }
      }
      `,
      variables: {
        email,
      },
    };
  },

  getCustomerData({ accountId, fundRuns }) {
    return {
      query: `query ($id: ID, $fundRuns: [Int]) {
          profile(id: $id) {
            id
            name
            lastName
            identifier
            gender
            entityType
            profiling
            maritalStatus
            conjugalRegime
            capitalOrigin
            occupation
            profession
            selfEmployed
            nationality
            birthDate
            birthCountry
            isPep
            isCrs
            isFatca
            capitalOriginCountry
            netWorth
            salaryRange
            documentExpirationDate
            documentNumber
            taxIdentificationNumbers{
              id
              country
              identification
            }
            emails {
              principal
              email
            }
            phones{
              phone
            }
            addresses {
              streetName
              houseNumber
              interiorNumber
              houseType
              locality
              region
              country
            }
            spouses {
              id
              identifier
              name
              lastName
            }
            bankAccounts {
              accountNumber
              bank {
                name
                id
              }
              currency
              id
              bankAccountType {
                id
                name
              }
            }
          }
          funds(runs: $fundRuns) {
            id
            run
            name
            fantasyName
            acronym
            shortName
            identifier
            infoUrl
            fundSeries {
              id
              seriesName
              fantasyName
              currency
              symbol
              balance(currency: CLP, customerId: $id, includePendingMovements: false) {
                fundSeriesSymbol
                shareValue {
                  CLP
                  USD
                }
                exchangeRate {
                  CLPUSD
                  USDCLP
                }
                date
                current{
                  balance{
                    shares
                    CLP
                    USD
                  }
                  subscriptions{
                    shares
                    CLP
                    USD
                  }
                  reinvestedDistributions{
                    shares
                    CLP
                  }
                  result{
                    shares
                    CLP
                  }
                }
                redeemed{
                  redemptions{
                    shares
                    CLP
                  }
                  result{
                    shares
                    CLP
                  }
                  distributions{
                    CLP
                    shares
                  }
                }
                aggregated{
                  subscriptions{
                    shares
                    CLP
                  }
                  reinvestedDistributions{
                    shares
                    CLP
                  }
                  result{
                    shares
                    CLP
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        id: accountId,
        fundRuns,
      },
    };
  },

  getCustomerMovementsData({ accountId, fundRuns }) {
    return {
      query: `query getCustomerMovementsData($id: ID, $fundRuns: [Int]) {
        funds(runs: $fundRuns) {
          id
          run
          name
          fantasyName
          acronym
          shortName
          identifier
          fundSeries {
            id
            seriesName
            currency
            symbol
            movements(currency: CLP, customerId: $id) {
              id
              shareState{
                shares
                CLP
              }
              currency
              shareValue{
                CLP
                USD
              }
              settlementDate
              movementType
            }
          }
        }
      }
    `,
      variables: {
        id: accountId,
        fundRuns,
      },
    };
  },

  getBalanceHistoryData({ accountId, fundRuns }) {
    return {
      query: `query ($id: ID, $fundRuns: [Int]) {
        funds(runs: $fundRuns) {
          id
          run
          shortName
          fundSeries {
            id
            seriesName
            fantasyName
            currency
            symbol
              balanceHistory(customerId: $id, currency: CLP) {
              date
              customerId
              shareValue {
                USD
                CLP
              }
              exchangeRate {
                CLPUSD
                USDCLP
              }
              current {
                subscriptions {
                  shares
                  USD
                  CLP
                }
                reinvestedDistributions {
                  shares
                  USD
                  CLP
                }
                balance {
                  shares
                  USD
                  CLP
                }
                result {
                  shares
                  USD
                  CLP
                }
              }
              redeemed {
                redemptions {
                  shares
                  USD
                  CLP
                }
                distributions {
                  shares
                  USD
                  CLP
                }
                result {
                  shares
                  USD
                  CLP
                }
              }
              aggregated {
                subscriptions {
                  shares
                  USD
                  CLP
                }
                reinvestedDistributions {
                  shares
                  USD
                  CLP
                }
                result {
                  shares
                  USD
                  CLP
                }
              }
            }
          }
        }
      }`,
      variables: {
        id: accountId,
        fundRuns,
      },
    };
  },

  changePassword({ oldPassword, newPassword }) {
    return {
      operationName: "changePassword",
      query: `mutation changePassword($oldPassword: String!, $newPassword: String!) {
        changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
          success
        }
      }`,
      variables: {
        oldPassword,
        newPassword,
      },
    };
  },

  patchCustomerData({
    id,
    customerInput,
    phoneInput,
    addressInput,
    bankAccountInput,
    taxIdentificationNumberInput,
    spouseInput,
  }) {
    return {
      query: `mutation patchCustomerData(
        $id: ID!,
        $customerInput: CustomerInput,
        $phoneInput: PhoneInput,
        $addressInput: AddressInput,
        $bankAccountInput: BankAccountInput,
        $taxIdentificationNumberInput: TaxIdentificationNumberInput,
        $spouseInput: SpouseInput
        ) {
          patchCustomerData(
            id: $id,
            customerInput: $customerInput,
            phoneInput: $phoneInput,
            addressInput: $addressInput,
            bankAccountInput: $bankAccountInput,
            taxIdentificationNumberInput: $taxIdentificationNumberInput,
            spouseInput: $spouseInput) {
              addresses {
                id
              }
              phones {
                id
              }
              customers {
                id
              }
              customerAccounts {
                id
              }
            }
        }
      `,
      variables: {
        id,
        customerInput,
        phoneInput,
        addressInput,
        bankAccountInput,
        taxIdentificationNumberInput,
        spouseInput,
      },
    };
  },

  uploadIdentificationDocument({
    customerId,
    frontIdentificationDocument,
    backIdentificationDocument,
  }) {
    return {
      operationName: "uploadIdentificationDocument",
      query: `mutation uploadIdentificationDocument(
        $customerId: ID!,
        $frontIdentificationDocument: Upload!,
        $backIdentificationDocument: Upload!,
      ){
        uploadIdentificationDocument(
          customerId: $customerId
          frontIdentificationDocument: $frontIdentificationDocument
          backIdentificationDocument: $backIdentificationDocument
        ) {
          success
        }
      }
        `,
      variables: {
        customerId,
        frontIdentificationDocument,
        backIdentificationDocument,
      },
    };
  },

  createSpouse({
    customerId, identifier, name, lastName,
  }) {
    return {
      query: `mutation createSpouse(
        $customerId: ID!,
        $identifier: String!,
        $name: String!,
        $lastName: String!

      ){
        createSpouse(
          customerId: $customerId,
          identifier: $identifier,
          name: $name,
          lastName: $lastName
        ){
          id,
          identifier,
          name,
          lastName
        }
      }
        `,
      variables: {
        customerId,
        identifier,
        name,
        lastName,
      },
    };
  },

  patchSpouse({
    id, identifier, name, lastName,
  }) {
    return {
      query: `mutation patchSpouse(
        $id: ID!,
        $identifier: String,
        $name: String,
        $lastName: String

      ){
        patchSpouse(
          id: $id,
          identifier: $identifier,
          name: $name,
          lastName: $lastName
        ){
          id,
          identifier,
          name,
          lastName
        }
      }
        `,
      variables: {
        id,
        ...(identifier && { identifier }),
        ...(name && { name }),
        ...(lastName && { lastName }),

      },
    };
  },

  getCustomerStatus({
    customerIdentifier,
    email,
  }) {
    return {
      operationName: "customerStatus",
      query: `query customerStatus(
        $customerIdentifier: String,
        $email: String,
      ) {
        customerStatus(customerIdentifier: $customerIdentifier, email: $email)
        {
          identifier
          completedValidation
          completedIdentification
          completedProfiling
          completedContract
          completedContractCompass
          compassContractDate
          contractDate
          pendingProfilingIdentificationReview
          acceptedProfilingIdentificationReview
        }
      }`,
      variables: {
        ...(customerIdentifier && { customerIdentifier }),
        ...(email && { email }),
      },
    };
  },

  createAccountStatement({ customerId, reportDate }) {
    return {
      query: `mutation createAccountStatement($customerId: ID!, $reportDate: String!)  {
        createAccountStatement(customerId: $customerId, reportDate: $reportDate) {
          success
          file
        }
      }
      `,
      variables: {
        customerId,
        reportDate,
      },
    };
  },

  createTaxIdentificationNumber({
    customerId, country, identification,
  }) {
    return {
      query: `mutation createTaxIdentificationNumber(
        $customerId: ID!,
        $country: String!,
        $identification: String!,
      ){
        createTaxIdentificationNumber(
          customerId: $customerId,
          country: $country,
          identification: $identification,
        ){
          id,
          country,
          identification,
        }
      }
        `,
      variables: {
        customerId,
        country,
        identification,
      },
    };
  },

  patchTaxIdentificationNumber({ id, country, identification }) {
    return {
      operationName: "patchTaxIdentificationNumber",
      query: `mutation patchTaxIdentificationNumber(
        $id: ID!,
        $country: String,
        $identification: String,
      ){
        patchTaxIdentificationNumber(
          id: $id,
          country: $country,
          identification: $identification,
        ){
          id,
          country
          identification
        }
      }
        `,
      variables: {
        id,
        ...(country && { country }),
        ...(identification && { identification }),
      },
    };
  },

  signCompassContract({
    customerId,
  }) {
    return {
      query: `mutation signCompassContract(
        $customerId: ID!,
      ){
        signCompassContract(
          customerId: $customerId
        ) {
          success
        }
      }
        `,
      variables: {
        customerId,
      },
    };
  },

  signUpValidation({
    email,
    customerIdentifier,
    documentNumber,
    front,
    back,
  }) {
    return {
      query: `mutation signUpValidation(
        $email: String!
        $customerIdentifier: String!
        $documentNumber: String!
        $front: Upload!
        $back: Upload!
      ){
        signUpValidation(
          email: $email,
          customerIdentifier: $customerIdentifier,
          documentNumber: $documentNumber,
          front: $front,
          back: $back,
        ) {
          success
        }
      }
        `,
      variables: {
        email,
        customerIdentifier,
        documentNumber,
        front,
        back,
      },
    };
  },

  signUpIdentification({
    email,
    name,
    lastName,
    gender,
    birthdate,
    birthCountry,
    nationality,
    phone,
    country,
    region,
    locality,
    street,
    houseNumber,
    interiorNumber,
    maritalStatus,
    spouseName,
    spouseLastName,
    spouseIdentifier,
    conjugalRegime,
  }) {
    return {
      query: `mutation signUpIdentification(
        $email: String!
        $name: String!
        $lastName: String!
        $gender: GenderType!
        $birthdate: String!
        $birthCountry: String!
        $nationality: String!
        $phone: String!
        $country: String!
        $region: String!
        $locality: String!
        $street: String!
        $houseNumber: String!
        $interiorNumber: String
        $maritalStatus: MaritalStatus!
        $spouseName: String
        $spouseLastName: String
        $spouseIdentifier: String
        $conjugalRegime: ConjugalRegime
      ){
        signUpIdentification(
          email: $email,
          name: $name,
          lastName: $lastName,
          gender: $gender,
          birthdate: $birthdate,
          birthCountry: $birthCountry,
          nationality: $nationality,
          phone: $phone,
          country: $country,
          region: $region,
          locality: $locality,
          street: $street,
          houseNumber: $houseNumber,
          interiorNumber: $interiorNumber,
          maritalStatus: $maritalStatus,
          spouseName: $spouseName,
          spouseLastName: $spouseLastName,
          spouseIdentifier: $spouseIdentifier,
          conjugalRegime: $conjugalRegime
        ) {
          success
        }
      }
        `,
      variables: {
        email,
        name,
        lastName,
        gender,
        birthdate,
        birthCountry,
        nationality,
        phone,
        country,
        region,
        locality,
        street,
        houseNumber,
        maritalStatus,
        ...(interiorNumber && { interiorNumber }),
        ...(spouseName && { spouseName }),
        ...(spouseLastName && { spouseLastName }),
        ...(spouseIdentifier && { spouseIdentifier }),
        ...(conjugalRegime && { conjugalRegime }),
      },
    };
  },

  signUpProfiling({
    email,
    profilingScore,
    capitalOrigin,
    capitalOriginCountry,
    netWorth,
    salaryRange,
    occupation,
    profession,
    selfEmployed,
    isPep,
    isFatca,
    isCrs,
    taxIdentificationNumber,
    referredFrom,
  }) {
    return {
      query: `mutation signUpProfiling(
        $email: String!
        $profilingScore: Int!
        $capitalOrigin: CapitalOrigin!
        $capitalOriginCountry: String!
        $netWorth: NetWorth!
        $salaryRange: SalaryRange
        $occupation: String!
        $profession: String!
        $selfEmployed: Boolean!
        $isPep: Boolean!
        $isFatca: Boolean!
        $isCrs: Boolean!
        $taxIdentificationNumber: TaxIdentificationNumberInput
        $referredFrom: String!
      ){
        signUpProfiling(
          email: $email,
          profilingScore: $profilingScore,
          capitalOrigin: $capitalOrigin,
          capitalOriginCountry: $capitalOriginCountry,
          netWorth: $netWorth,
          occupation: $occupation,
          profession: $profession,
          selfEmployed: $selfEmployed,
          salaryRange: $salaryRange,
          isPep: $isPep,
          isFatca: $isFatca,
          isCrs: $isCrs,
          taxIdentificationNumber: $taxIdentificationNumber,
          referredFrom: $referredFrom
        ) {
          success
        }
      }
        `,
      variables: {
        email,
        profilingScore,
        capitalOrigin,
        capitalOriginCountry,
        netWorth,
        salaryRange,
        occupation,
        profession,
        selfEmployed,
        isPep,
        isFatca,
        isCrs,
        ...(taxIdentificationNumber && { taxIdentificationNumber }),
        referredFrom,
      },
    };
  },

  signUpContract({
    email,
    reinvestDividends,
    origin,
  }) {
    return {
      query: `mutation signUpContract(
        $email: String!
        $reinvestDividends: Boolean!
        $origin: String!
      ){
        signUpContract(
          email: $email,
          reinvestDividends: $reinvestDividends,
          origin: $origin
        ) {
          success
        }
      }
        `,
      variables: {
        email,
        reinvestDividends,
        origin,
      },
    };
  },

  createCustomerDeclaration({
    customerIdentifier,
    declaration,
    response,
  }) {
    return {
      query: `mutation createCustomerDeclaration(
        $customerIdentifier: String!
        $declaration: String!
        $response: String!
      ){
        createCustomerDeclaration(
          customerIdentifier: $customerIdentifier,
          declaration: $declaration,
          response: $response
        ) {
          success
        }
      }
        `,
      variables: {
        customerIdentifier,
        declaration,
        response,
      },
    };
  },

  getPendingMovements({ customerId }) {
    return {
      query: `query getPendingMovements(
        $customerId: ID!
      ) {
        pendingMovements(customerId: $customerId) {
          id
          movementType
          originFundSeries {
            symbol
            id
            seriesName
            fund{
              name
              run
            }
          }
          destinationFundSeries {
            symbol
            seriesName
            fund{
              name
              run
            }
          }
          executionDate
          integrationDate
          settlementDate
          currency
          shareState {
            CLP
            USD
            shares
          }
          status
          statusCode
        }
      }
      `,
      variables: {
        ...(customerId && { customerId }),
      },
    };
  },

  getPendingReinvestmentRequests({ customerId }) {
    return {
      query: `query getPendingReinvestmentRequests($customerId: ID!) {
        pendingReinvestmentRequests(customerId: $customerId) {
          id
          subscriptionExecutionDate
          subscriptionSettlementDate
          subscriptionIntegrationDate
          redemptionExecutionDate
          redemptionSettlementDate
          redemptionIntegrationDate
          subscriptionCurrency

          subscriptionShareState{
            CLP
            USD
            shares
          }
          redemptionShareState{
            CLP
            USD
            shares
          }
          status
          statusCode
          originFundSeries {
            seriesName
            fund {
              name
              run
            }
          }
          destinationFundSeries {
            seriesName
            fund {
              name
              run
            }
          }
        }
      }`,
      variables: {
        ...(customerId && { customerId }),
      },
    };
  },

  createRedemptionRequest({
    requester, requestSource, requestedAmount,
    requestedAmountCurrency, customerId, fundSeriesSymbol,
  }) {
    return {
      query: `mutation createRedemptionRequest (
        $requester: String!,
        $requestSource: String!,
        $requestedAmount: Float!,
        $requestedAmountCurrency: String!,
        $customerId: ID!,
        $fundSeriesSymbol: String!,
      ) {
        createRedemptionRequest(
          requester: $requester,
          requestSource: $requestSource,
          requestedAmount: $requestedAmount,
          requestedAmountCurrency: $requestedAmountCurrency,
          customerId: $customerId,
          fundSeriesSymbol: $fundSeriesSymbol,
          ) {
            id
            requester,
            requestSource,
            shareAmount,
            requestedAmount,
            requestedAmountCurrency,
            status,
            statusCode,
            fundSeries {
              symbol
            }
          }
        }
      `,
      variables: {
        ...(requester && { requester }),
        ...(requestSource && { requestSource }),
        ...(requestedAmount && { requestedAmount }),
        ...(requestedAmountCurrency && { requestedAmountCurrency }),
        ...(customerId && { customerId }),
        ...(fundSeriesSymbol && { fundSeriesSymbol }),
      },
    };
  },

  createReinvestmentRequest({
    requester,
    requestSource,
    requestedAmount,
    requestedAmountCurrency,
    customerId,
    originFundSeriesSymbol,
    destinationFundSeriesSymbol,
  }) {
    return {
      query: `mutation createReinvestmentRequest(
          $requester: String!
          $requestSource: String!
          $requestedAmount: Float!
          $requestedAmountCurrency: String!
          $customerId: ID!
          $originFundSeriesSymbol: String!
          $destinationFundSeriesSymbol: String!
        ) {
          createReinvestmentRequest(
            requester: $requester
            requestSource: $requestSource
            requestedAmount: $requestedAmount
            requestedAmountCurrency: $requestedAmountCurrency
            customerId: $customerId
            originFundSeriesSymbol: $originFundSeriesSymbol
            destinationFundSeriesSymbol: $destinationFundSeriesSymbol
          ) {
            id
            requestedAmount
            requestedInCLP
            requestedInShares
            requestDate
            executionDate
            integrationDate
            settlementDate
            customer {
              name
              lastName
            }
            originFundSeries {
              symbol
            }
            destinationFundSeries {
              symbol
            }
            requester
            requestSource
            status
            statusCode
          }
        }
      `,
      variables: {
        ...(requester && { requester }),
        ...(requestSource && { requestSource }),
        ...(requestedAmount && { requestedAmount }),
        ...(requestedAmountCurrency && { requestedAmountCurrency }),
        ...(customerId && { customerId }),
        ...(originFundSeriesSymbol && { originFundSeriesSymbol }),
        ...(destinationFundSeriesSymbol && { destinationFundSeriesSymbol }),
      },
    };
  },

  createPaymentIntent({ fundId, amount, customerIdentifier }) {
    return {
      query: `mutation createPaymentIntent($fundId: ID!, $amount: Int!, $customerIdentifier: String) {
        createPaymentIntent(
          customerIdentifier: $customerIdentifier
          amount: $amount
          fundId: $fundId
        ) {
          id
          object
          amount
          currency
          widgetToken
          status
          referenceId
          recipientAccount {
            holderId
            number
            type
            institutionId
          }
          senderAccount {
            holderId
            number
            type
            institutionId
          }
          createdAt
        }
      }`,
      variables: {
        fundId,
        amount,
        customerIdentifier,
      },
    };
  },

  patchPaymentIntent({ fintocId, paymentIntentStatus }) {
    return {
      query: `mutation patchPaymentIntent($fintocId: ID!, $paymentIntentStatus: PaymentIntentStatus!) {
        patchPaymentIntent(
          fintocId: $fintocId,
          status: $paymentIntentStatus
        ) {
          id
          object
          amount
          currency
          widgetToken
          status
          referenceId
          recipientAccount {
            holderId
            number
            type
            institutionId
          }
          senderAccount {
            holderId
            number
            type
            institutionId
          }
          createdAt
        }
      }`,
      variables: {
        fintocId,
        paymentIntentStatus,
      },
    };
  },

  getBanks() {
    return {
      operationName: "banks",
      query: `query banks {
          banks {
            name
            countryCode
            id
            fintocId
            fantasyName
          }
        }
      `,
    };
  },

  /* TODO: implement in customers gql API

    createDividendAnswer(
    { customerDividendId,
      dividendOptionValue,
      dividendOptionId,
      answeredByName,
      answeredByIdentifier,

    },
  ) {
    return {
      query: `mutation createDividendAnswer(
        $customerDividendId: ID!
        $dividendOptionValue: String
        $dividendOptionId: ID
        $answeredByName: String
        $answeredByIdentifier: String
      ){
        createDividendAnswer(
          customerDividendId: $customerDividendId
          dividendOptionValue: $dividendOptionValue
          dividendOptionId: $dividendOptionId
          answeredByName: $answeredByName
          answeredByIdentifier: $answeredByIdentifier
        ) {
            id
            customerDividend {
              customer {
                firstName
                lastName
              }
              fundSeriesDividend {
                fundSeries {
                  symbol
                  fundRun
                  seriesName
                }
                fundDividend {
                  declarationDate
                  paymentDate
                  reinvestmentReceiptDate
                  dividendOptions {
                    id
                    value
                  }
                }
              }
              dividendAnswer {
                dividendOption {
                  id
                  value
                }
              }
            }
          }
        }
      `,
      variables: {
        ...(customerDividendId && { customerDividendId }),
        ...(dividendOptionValue && { dividendOptionValue }),
        ...(dividendOptionId && { dividendOptionId }),
        ...(answeredByName && { answeredByName }),
        ...(answeredByIdentifier && { answeredByIdentifier }),
      },
    };
  },

  getCustomerDividends({ customerIdentifier, fromDate }) {
    const dateFilter = { operation: "ge", value: fromDate };
    return {
      operationName: "getCustomerDividends",
      query: `query getCustomerDividends(
        $customerIdentifier: String
        $dateFilter: DateFilter
      ) {
        customerDividends(
          customerIdentifier: $customerIdentifier
          declarationDate: $dateFilter
        ) {
          id
          customer {
            firstName
            lastName
          }

          fundSeriesDividend {
            fundSeries {
              symbol
              fundRun
              seriesName
            }
            fundDividend {
              declarationDate
              paymentDate
              reinvestmentReceiptDate
              dividendOptions {
                id
                value
              }
            }
          }
          dividendAnswer{
            answeredAt
            dividendOption{
              value
            }
          }

        }
      }
      `,
      variables: {
        ...(customerIdentifier && { customerIdentifier }),
        ...(fromDate && { dateFilter }),
      },
    };
  },

  */

};
