<template>
  <div
    class="option-container space-between"
    :class="[disabled ? 'disabled-card' : 'clickable-item', error ? 'error-status' : '']"
    @click="!disabled ? $emit('optionClicked') : {}"
  >
    <div class="option left-in-page">
      <div v-if="leftIcon" class="left-icon">
        <v-icon dark size="21" color="#333333"> {{ leftIcon }}</v-icon>
      </div>
      <div>
        <span class="option-title">
          {{ title }}
        </span>

        <div v-if="subtitle">
          <p class="option-subtitle">
            {{ subtitle }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="rightIcon" class="right-icon">
      <v-icon dark size="21" color="#333333"> {{ rightIcon }} </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClickableOptionCard",

  props: {
    title: String,
    subtitle: String,
    leftIcon: String,
    rightIcon: String,
    disabled: Boolean,
    error: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.option-container {
  width: 100%;
  height: 100px;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 30px;
  @media (max-width: 600px) {
    padding: 0 25px;
    height: 80px;
  }
}

.error-status {
  .left-icon i,
  .right-icon i,
  .option-title,
  .option-subtitle {
    color: #f20e45 !important;
  }
}

.option {
  display: flex;
  justify-content: left;
  align-items: baseline;
  gap: 1rem;
}
.option-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.option-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #646363;
  margin: 5px 0 0 0;

  @media (max-width: 600px) {
    font-size: 11px;
    line-height: 140%;
  }
}

.disabled-card {
  background-color: #e2e0e0 !important;
}
</style>
