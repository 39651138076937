<template>
  <div>
    <LoadingCard :fullScreen="false" class="balance-history-loader" v-if="loadingBalanceHistory" />

    <ApexCharts
      width="100%"
      type="line"
      :options="chartOptions"
      :series="relevantHistoryData.series"
      v-if="relevantHistoryData.series.length > 0"
    />
  </div>

</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import LoadingCard from "@/components/shared/LoadingCard.vue";

export default {
  name: "BalanceChart",

  data: () => ({
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
    },
    currency: "CLP",
  }),

  props: {
    fundRun: String,
  },

  mixins: [customersApiFunctions],

  components: {
    LoadingCard,
  },

  watch: {
    fundRun() {
      this.updateChartOptions();
    },

    mobileBreakpoint() {
      this.updateChartOptions();
    },

    balanceHistory() {
      this.updateChartOptions();
    },

    closeDate: {
      immediate: true,
      handler(newDate) {
        if (newDate && !this.loading && !this.balanceHistory) {
          this.fetchBalanceHistoryData();
        }
      },
    },
  },

  computed: {
    ...mapGetters(["balanceHistory", "closeDate", "loadingBalanceHistory", "loading"]),

    relevantHistoryData() {
      if (!this.balanceHistory) return { series: [] };

      // Filter by fund run if it exists, otherwise use all funds
      const historicFundsData = this.fundRun ?
        this.balanceHistory.filter(fund => fund.run === this.fundRun) :
        [...this.balanceHistory];

      // Initialize a Map to store types of balance history points
      const totalHistoryPoints = new Map();

      // Populate the Map with balance history points
      historicFundsData.forEach(fund => {
        fund.fundSeries.forEach(series => {
          if (series.balanceHistory.length > 0) {
            series.balanceHistory.forEach(history => {
              if (history.date <= this.closeDate) {
                const currentHistoryPoint = history.current;
                const currentFundBalance = currentHistoryPoint.balance[this.currency];
                const currentFundSubscriptions = currentHistoryPoint.subscriptions[this.currency];

                if (!totalHistoryPoints.has(history.date)) {
                  totalHistoryPoints.set(history.date, {
                    current: currentFundBalance,
                    subscriptions: currentFundSubscriptions,
                  });
                } else {
                  const existing = totalHistoryPoints.get(history.date);
                  existing.current += currentFundBalance;
                  existing.subscriptions += currentFundSubscriptions;
                }
              }
            });
          }
        });
      });

      // Convert the Map to arrays and sort by date
      const sortedDates = Array.from(totalHistoryPoints.keys()).sort((a, b) => new Date(a) - new Date(b));
      const sortedCurrent = sortedDates.map(date => totalHistoryPoints.get(date).current);
      const sortedSubscriptions = sortedDates.map(date => totalHistoryPoints.get(date).subscriptions);

      // Shrink data to 25 historical points
      const totalPoints = sortedCurrent.length;
      const shrinkFactor = totalPoints < 25 ? 1 : Math.floor(totalPoints / 25);

      const shrinkData = data => data.filter((_, index) => index % shrinkFactor === 0 || index === data.length - 1);

      const finalDates = shrinkData(sortedDates);
      const finalCurrent = shrinkData(sortedCurrent);
      const finalSubscriptions = shrinkData(sortedSubscriptions);

      // Create the series for the chart
      const series = ["current", "subscriptions"].map(type => ({
        name: this.$t(`home.balance.balancesSections.historyChart.balanceNames.${type}`),
        data: type === "current" ? finalCurrent : finalSubscriptions,
      }));

      return {
        dates: finalDates,
        series,
      };
    },

    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xs;
    },
  },

  methods: {
    ...mapActions(["setLoadingBalanceHistory", "setBalanceHistoryData"]),

    updateChartOptions() {
      let chartColors = Array(2).fill("#B955F6");
      if (this.fundRun) {
        chartColors = Array(2).fill(this.$options.filters.addFundColor(this.fundRun));
      }
      const strokeWidth = this.mobileBreakpoint ? 2 : 3;
      const legendMarkerSizes = { width: [40, 40], height: [3, 0] };
      const dashedLine = () => "<span class='apex-dashed-line'></span>";
      const currencyFormat = value => `$${this.$options.filters.currencyNoSymbol(value)}`;
      const markersCustomHTML = [null, dashedLine];
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          colors: chartColors,
          xaxis: {
            categories: this.relevantHistoryData.dates,
            tickAmount: this.mobileBreakpoint ? 3 : 5,
            labels: {
              rotate: 0,
              formatter(value) {
                return dayjs(value).format("DD/MM/YY");
              },
            },
          },

          yaxis: {
            labels: {
              show: true,
              align: "right",
              minWidth: 0,
              maxWidth: 160,
              style: {
                colors: [],
                fontFamily: "Work Sans",
                fontWeight: 500,
                fontSize: "10px",
                lineHeight: "170%",
                cssClass: "apexcharts-yaxis-label",
              },
              offsetX: 0,
              offsetY: 0,
              rotate: 0,
              formatter(val) {
                if (val !== undefined) {
                  const formatCash = n => {
                    if (n < 1e3) return n;
                    if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
                    if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`;
                    if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}MM`;
                    return n;
                  };
                  return `$${formatCash(val)}`;
                }

                return val;
              },
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          onItemHover: {
            highlightDataSeries: false,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
          markers: {
            width: legendMarkerSizes.width,
            height: legendMarkerSizes.height,
            radius: 12,
            customHTML: markersCustomHTML,
            offsetX: 0,
            offsetY: 0,
          },
        },
        tooltip: {
          y: {
            formatter: val => currencyFormat(val),
          },
          marker: {
            show: false,
          },
        },
        grid: {
          show: true,
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          borderColor: "#E2E0E0",
        },

        stroke: {
          width: [strokeWidth, strokeWidth],
          curve: "straight",
          dashArray: [0, strokeWidth],
        },
      };
    },
    async fetchBalanceHistoryData() {
      try {
        this.setLoadingBalanceHistory(true);
        const balanceHistoryData = await this.getBalanceHistoryData();
        this.setBalanceHistoryData(balanceHistoryData?.data?.funds);
        this.setLoadingBalanceHistory(false);
      } catch (error) {
        console.error("Error fetching balance history data", error);
        this.setLoadingBalanceHistory(false);
      }
    },

  },

  mounted() {
    this.updateChartOptions();
  },
};
</script>

<style lang="scss">
.apex-dashed-line {
  width: 40px;
  border-bottom: 3px dashed;
  display: inline-block;
}
 .balance-history-loader {
  .circle {
    height: 10px !important;
    width: 10px !important;
  }
 }
</style>
