<template>
  <v-dialog
    v-model="displayDialog"
    width="500"
    transition="dialog-top-transition"
    content-class="rounded-lg"
  >
    <v-card class="unauthorized-modal-card">
      <div class="unauthorized-title">
        {{ $t('redemptions.unauthorizedRedemption.title') }}
      </div>
      <div class="unauthorized-text">
        <i18n path="redemptions.unauthorizedRedemption.text" tag="div" v-if="unauthorizedAccountType">
          <template v-slot:accountType>
            <span>{{ $t(`redemptions.unauthorizedRedemption.${unauthorizedAccountType}`) }}</span>
          </template>
        </i18n>
      </div>

      <div class="buttons-wrapper">
        <div class="close-btn">
          <NewButton
            :text="$t('redemptions.unauthorizedRedemption.close')"
            @on-click="$emit('closeDialog')"
          />
        </div>

        <div class="copy-email-btn">
          <NewButton
            :text="$t('redemptions.unauthorizedRedemption.copyEmail')"
            @on-click="copyToClipboard('clientes@dvacapital.com')"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import clipboard from "@/mixins/clipboard.js";
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "UnauthorizedRedemption",

  components: { NewButton },

  mixins: [clipboard],

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    unauthorizedAccountType: {
      type: String,
      validator(value) {
        return ["legalEntity", "children"].includes(value);
      },
    },
  },

  computed: {
    displayDialog: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("closeDialog");
      },
    },
  },

};
</script>

<style lang="scss" scoped>

.unauthorized-modal-card{
  width: 100%;
  padding: 40px;
}

.unauthorized-title{
  font-weight: 600;
  font-size: 18px;
  line-height: 180%;
  color: #000000;
  margin-bottom: 10px;
}

.unauthorized-text{
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  color: #000000;
  margin-bottom: 40px;
}

.buttons-wrapper{
  display: flex;
  justify-content: space-between;
  gap: 0 40px;
}

.buttons-wrapper >*{
  width: 100% !important;
}

.close-btn ::v-deep .new-btn{
  background-color: #ffffff !important;
  border: 1px solid #000000;
  span{
    font-size: 14px !important;
    color: #000000 !important;
  }
}
.copy-email-btn ::v-deep .new-btn{
  span{
    font-size: 14px !important;
  }
}

</style>
