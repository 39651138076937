<template>
  <div class="home max-width-wrapper">
    <div class="compass-notice-sections">
      <CompassNoticeBar />
      <CompassPendingAccountData />
    </div>

    <LoadingCard v-if="loading" fullScreen />

    <div cols="12" class="home-sections" v-else>
      <Welcome />
      <BalanceCard />
      <Transactions id="transactions" />
      <FundCards />
      <NonInvestedFundCards />
      <FundsDiversification />
      <DividendDeclarationModal />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CompassNoticeBar from "@/components/home/CompassNoticeBar.vue";
import FundsDiversification from "@/components/fundsDiversification/FundsDiversification.vue";
import Welcome from "@/components/home/Welcome.vue";
import FundCards from "@/components/home/FundCards.vue";
import BalanceCard from "@/components/home/BalanceCard.vue";
import Transactions from "@/components/home/Transactions.vue";
import NonInvestedFundCards from "@/components/home/NonInvestedFundCards.vue";
import DividendDeclarationModal from "@/components/home/DividendDeclarationModal.vue";
import CompassPendingAccountData from "@/components/home/CompassPendingAccountData.vue";
import LoadingCard from "@/components/shared/LoadingCard.vue";

export default {
  name: "HomeView",

  data: () => ({
    loadingTimeout: null,
  }),

  components: {
    Transactions,
    FundsDiversification,
    Welcome,
    BalanceCard,
    FundCards,
    NonInvestedFundCards,
    DividendDeclarationModal,
    CompassNoticeBar,
    CompassPendingAccountData,
    LoadingCard,
  },

  computed: {
    ...mapGetters(["targetFund", "loading", "fundsBalances"]),
  },

  methods: {
    ...mapActions(["deleteParams"]),

    redirectToFund() {
      if (this.targetFund) {
        this.$router.push({
          name: "investChooseInvestmentAmount",
          params: { fundRun: Number(this.targetFund) },
        });
        this.deleteParams();
      }
    },

    redirectToErrorView() {
      this.$router.push({ name: "errorView" });
    },
  },

  watch: {
    loading: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!newValue && oldValue) {
          clearTimeout(this.loadingTimeout);
          this.redirectToFund();
        }
      },
    },

    targetFund() {
      this.redirectToFund();
    },
  },

  created() {
    // set the loading timeout when the home view is created
    this.loadingTimeout = setTimeout(() => {
      if (this.loading) {
        this.redirectToErrorView();
      }
    }, 30000); // 30 seconds timeout, modify if needed
  },

  beforeDestroy() {
    // Clear the loading timeout when the component is destroyed
    clearTimeout(this.loadingTimeout);
  },
};
</script>

<style lang="scss" scoped>
.compass-notice-sections > :first-child {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.compass-notice-sections > :last-child {
  margin-bottom: 40px !important;
}

.home-sections > :not(:first-child) {
  margin-top: 60px;
  @media (max-width: 600px) {
    margin-top: 40px;
  }
}

.home-sections #transactions::v-deep .recent-investment-alert {
  @media (max-width: 960px) {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

.home-sections,
.compass-notice-sections {
  @media (min-width: 1265px) {
    padding: 0 60px !important;
  }
  @media (min-width: 960px) and (max-width: 1264px) {
    padding: 0 40px !important;
  }
}
</style>
