<template>
  <v-dialog
    v-model="display"
    max-width="500"
    content-class="rounded-lg elevation-0"
    @keydown.enter="display = false"
    :persistent="persistent"
    :fullscreen="fullscreen"
    :transition="fullscreen ? 'scroll-y-reverse-transition' : 'scale-transition'"
  >
    <v-card elevation="0" rounded="lg" class="reusable-dialog-card">
      <div class="reusable-dialog-content">
        <div class="reusable-dialog-text-container">
          <h3 class="reusable-dialog-title" v-if="title">
            {{ title }}
          </h3>
          <div class="reusable-dialog-text">
            {{ text }}
            <slot name="slotText" />
          </div>
        </div>

        <slot name="buttonRow" />

        <div class="button-wrapper" v-if="!$slots.buttonRow">
          <NewButton
            :text="!buttonText ? $i18n.t('signup.shared.buttons.getIt') : buttonText"
            @on-click="
              $emit('getIt');
              display = false;
            "
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import NewButton from "./NewButton.vue";

export default {
  name: "ReusableDialog",

  components: { NewButton },

  props: {
    value: Boolean,
    title: String,
    text: String,
    buttonText: String,
    fullscreen: Boolean,
    persistent: Boolean,
  },

  computed: {
    display: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("display", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.reusable-dialog-content {
  height: 100%;
  padding: 40px;
  @media (max-width: 600px) {
    padding: 20px;
  }
}

.reusable-dialog-title {
  font-size: 22px;
  line-height: 140%;
  font-weight: 600;

  color: #333333;
}
.button-wrapper {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.button-wrapper > * {
  @media (max-width: 600px) {
    max-width: 100%;
  }
  max-width: 120px;
}

.reusable-dialog-text-container {
  @media (max-width: 600px) {
    margin: 20px;
  }
}

.reusable-dialog-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  margin-top: 10px;
  color: #333333;
  a {
    color: #333333;
    text-decoration: underline;
  }
}
</style>
