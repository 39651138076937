export default {
  data: () => ({
    riskProfilesList: [
      {
        type: "low",
        scoreRange: [6, 8],
        averageScore: 7,
        color: "#1AB9EC",
      },
      {
        type: "belowAverage",
        scoreRange: [9, 11],
        averageScore: 10,
        color: "#4E5FF1",
      },
      {
        type: "average",
        scoreRange: [12, 14],
        averageScore: 13,
        color: "#00AA9D",
      },
      {
        type: "aboveAverage",
        scoreRange: [15, 17],
        averageScore: 16,
        color: "#FF0079",
      },
      {
        type: "high",
        scoreRange: [18, 24],
        averageScore: 21,
        color: "#AF0053",
      },
    ],

    categoriesData: [
      {
        categoryName: "currencies",
        categoryOptions: [
          {
            optionName: "CLP",
            fundRuns: ["9395", "9994", "10275"],
          },
          {
            optionName: "USD",
            fundRuns: ["9118", "9937", "9725", "9958", "10348"],
          },
        ],
      },

      {
        categoryName: "investmentHorizons",
        categoryOptions: [
          {
            optionName: "LOW",
            fundRuns: ["9994", "10275"],
          },
          {
            optionName: "MID",
            fundRuns: ["9958", "10275"],
          },
          {
            optionName: "LONG",
            fundRuns: ["9118", "9395", "9937", "9725", "9958", "10348"],
          },
        ],
      },

      {
        categoryName: "locations",
        categoryOptions: [
          {
            optionName: "cl",
            fundRuns: ["9395", "9994"],
          },
          {
            optionName: "world",
            fundRuns: ["9118", "9937", "9725", "9958", "10275", "10348"],
          },
        ],
      },

      {
        categoryName: "fundTypes",
        categoryOptions: [
          {
            optionName: "equity",
            fundRuns: ["9118", "9395", "9725", "9937", "10348"],
          },
          {
            optionName: "fixedIncome",
            fundRuns: ["9994"],
          },
          {
            optionName: "alternatives",
            fundRuns: ["9958"],
          },
          {
            optionName: "mixed",
            fundRuns: ["10275"],
          },
        ],
      },
    ],

    // Address Selects
    houses: [
      { type: "Casa", code: 1 },
      { type: "Departamento", code: 2 },
      { type: "Condominio", code: 3 },
    ],

    regions: [
      { name: "Arica y Parinacota", code: 1 },
      { name: "Tarapacá", code: 2 },
      { name: "Antofagasta", code: 3 },
      { name: "Atacama", code: 4 },
      { name: "Coquimbo", code: 5 },
      { name: "Valparaíso", code: 6 },
      { name: "Región Metropolitana", code: 7 },
      { name: "O'Higgins", code: 8 },
      { name: "Maule", code: 9 },
      { name: "Ñuble", code: 10 },
      { name: "Bío Bío", code: 11 },
      { name: "Araucanía", code: 12 },
      { name: "Los Ríos", code: 13 },
      { name: "Los Lagos", code: 14 },
      { name: "Aysén", code: 15 },
      { name: "Magallanes y la Antártica Chilena", code: 16 },
    ],

    countries: [
      { country: "Andorra", code: "AND" },
      { country: "Emiratos Árabes Unidos", code: "ARE" },
      { country: "Afganistán", code: "AFG" },
      { country: "Antigua y Barbuda", code: "ATG" },
      { country: "Anguila", code: "AIA" },
      { country: "Albania", code: "ALB" },
      { country: "Armenia", code: "ARM" },
      { country: "Angola", code: "AGO" },
      { country: "Antártida", code: "ATA" },
      { country: "Argentina", code: "ARG" },
      { country: "Samoa Americana", code: "ASM" },
      { country: "Austria", code: "AUT" },
      { country: "Australia", code: "AUS" },
      { country: "Azerbaiyán", code: "AZE" },
      { country: "Bosnia-Herzegovina", code: "BIH" },
      { country: "Barbados", code: "BRB" },
      { country: "Bangladés", code: "BGD" },
      { country: "Bélgica", code: "BEL" },
      { country: "Burkina Faso", code: "BFA" },
      { country: "Bulgaria", code: "BGR" },
      { country: "Baréin", code: "BHR" },
      { country: "Burundi", code: "BDI" },
      { country: "Benín", code: "BEN" },
      { country: "Bermudas", code: "BMU" },
      { country: "Brunéi", code: "BRN" },
      { country: "Bolivia", code: "BOL" },
      { country: "Caribe Neerlandés", code: "BES" },
      { country: "Brasil", code: "BRA" },
      { country: "Bahamas", code: "BHS" },
      { country: "Bután", code: "BTN" },
      { country: "Botsuana", code: "BWA" },
      { country: "Bielorrusia", code: "BLR" },
      { country: "Belice", code: "BLZ" },
      { country: "Canadá", code: "CAN" },
      { country: "República Democrática del Congo", code: "COD" },
      { country: "República Centroafricana", code: "CAF" },
      { country: "República del Congo", code: "COG" },
      { country: "Suiza", code: "CHE" },
      { country: "Costa de Marfil", code: "CIV" },
      { country: "Islas Cook", code: "COK" },
      { country: "Chile", code: "CHL" },
      { country: "Camerún", code: "CMR" },
      { country: "República Popular China", code: "CHN" },
      { country: "Colombia", code: "COL" },
      { country: "Costa Rica", code: "CRI" },
      { country: "Cuba", code: "CUB" },
      { country: "Cabo Verde", code: "CPV" },
      { country: "Chipre", code: "CYP" },
      { country: "República Checa", code: "CZE" },
      { country: "Alemania", code: "DEU" },
      { country: "Yibuti", code: "DJI" },
      { country: "Dinamarca", code: "DNK" },
      { country: "Dominica", code: "DMA" },
      { country: "República Dominicana", code: "DOM" },
      { country: "Argelia", code: "DZA" },
      { country: "Ecuador", code: "ECU" },
      { country: "Estonia", code: "EST" },
      { country: "Egipto", code: "EGY" },
      { country: "Eritrea", code: "ERI" },
      { country: "España", code: "ESP" },
      { country: "Etiopía", code: "ETH" },
      { country: "Finlandia", code: "FIN" },
      { country: "Fiyi", code: "FJI" },
      { country: "Islas Malvinas", code: "FLK" },
      { country: "Estados Federados de Micronesia", code: "FSM" },
      { country: "Islas Feroe", code: "FRO" },
      { country: "Francia", code: "FRA" },
      { country: "Gabón", code: "GAB" },
      { country: "Reino Unido", code: "GBR" },
      { country: "Granada", code: "GRD" },
      { country: "Georgia", code: "GEO" },
      { country: "Guayana Francesa", code: "GUF" },
      { country: "Guernsey", code: "GGY" },
      { country: "Ghana", code: "GHA" },
      { country: "Gibraltar", code: "GIB" },
      { country: "Groenlandia", code: "GRL" },
      { country: "Gambia", code: "GMB" },
      { country: "Guinea", code: "GIN" },
      { country: "Guadalupe", code: "GLP" },
      { country: "Guinea Ecuatorial", code: "GNQ" },
      { country: "Grecia", code: "GRC" },
      { country: "Islas Georgias del Sur y Sandwich del Sur", code: "SGS" },
      { country: "Guatemala", code: "GTM" },
      { country: "Guam", code: "GUM" },
      { country: "Guinea-Bisáu", code: "GNB" },
      { country: "Guyana", code: "GUY" },
      { country: "Hong Kong", code: "HKG" },
      { country: "Honduras", code: "HND" },
      { country: "Croacia", code: "HRV" },
      { country: "Haití", code: "HTI" },
      { country: "Hungría", code: "HUN" },
      { country: "Indias Orientales Neerlandesas", code: "IDN" },
      { country: "Irlanda", code: "IRL" },
      { country: "Israel", code: "ISR" },
      { country: "Isla de Man", code: "IMN" },
      { country: "India", code: "IND" },
      { country: "Territorio Británico del Océano Índico", code: "IOT" },
      { country: "Irak", code: "IRQ" },
      { country: "Irán", code: "IRN" },
      { country: "Islandia", code: "ISL" },
      { country: "Italia", code: "ITA" },
      { country: "Jersey", code: "JEY" },
      { country: "Jamaica", code: "JAM" },
      { country: "Jordania", code: "JOR" },
      { country: "Japón", code: "JPN" },
      { country: "Kenia", code: "KEN" },
      { country: "Kirguistán", code: "KGZ" },
      { country: "Camboya", code: "KHM" },
      { country: "Kiribati", code: "KIR" },
      { country: "Comoras", code: "COM" },
      { country: "San Cristóbal y Nieves", code: "KNA" },
      { country: "Dinastía Joseon", code: "PRK" },
      { country: "Corea", code: "KOR" },
      { country: "Kuwait", code: "KWT" },
      { country: "Islas Caimán", code: "CYM" },
      { country: "Kazajistán", code: "KAZ" },
      { country: "Laos", code: "LAO" },
      { country: "Líbano", code: "LBN" },
      { country: "Santa Lucía", code: "LCA" },
      { country: "Liechtenstein", code: "LIE" },
      { country: "Sri Lanka", code: "LKA" },
      { country: "Liberia", code: "LBR" },
      { country: "Lesoto", code: "LSO" },
      { country: "Lituania", code: "LTU" },
      { country: "Luxemburgo", code: "LUX" },
      { country: "Letonia", code: "LVA" },
      { country: "Libia", code: "LBY" },
      { country: "Marruecos", code: "MAR" },
      { country: "Mónaco", code: "MCO" },
      { country: "Moldavia", code: "MDA" },
      { country: "Montenegro", code: "MNE" },
      { country: "Madagascar", code: "MDG" },
      { country: "Islas Marshall", code: "MHL" },
      { country: "República de Macedonia", code: "MKD" },
      { country: "Malí", code: "MLI" },
      { country: "Birmania", code: "MMR" },
      { country: "Mongolia", code: "MNG" },
      { country: "Macao", code: "MAC" },
      { country: "Islas Marianas del Norte", code: "MNP" },
      { country: "Martinica", code: "MTQ" },
      { country: "Mauritania", code: "MRT" },
      { country: "Work Sans", code: "MSR" },
      { country: "Malta", code: "MLT" },
      { country: "Mauricio", code: "MUS" },
      { country: "Maldivas", code: "MDV" },
      { country: "Malaui", code: "MWI" },
      { country: "México", code: "MEX" },
      { country: "Malasia", code: "MYS" },
      { country: "Mozambique", code: "MOZ" },
      { country: "Namibia", code: "NAM" },
      { country: "Níger", code: "NER" },
      { country: "Nigeria", code: "NGA" },
      { country: "Nicaragua", code: "NIC" },
      { country: "Países Bajos", code: "NLD" },
      { country: "Noruega", code: "NOR" },
      { country: "Nepal", code: "NPL" },
      { country: "Nauru", code: "NRU" },
      { country: "Niue", code: "NIU" },
      { country: "Nueva Zelanda", code: "NZL" },
      { country: "Omán", code: "OMN" },
      { country: "Panamá", code: "PAN" },
      { country: "Perú", code: "PER" },
      { country: "Papúa Nueva Guinea", code: "PNG" },
      { country: "Filipinas", code: "PHL" },
      { country: "Pakistán", code: "PAK" },
      { country: "Polonia", code: "POL" },
      { country: "San Pedro y Miquelón", code: "SPM" },
      { country: "Islas Pitcairn", code: "PCN" },
      { country: "Puerto Rico", code: "PRI" },
      { country: "Palestina", code: "PSE" },
      { country: "Portugal", code: "PRT" },
      { country: "Palaos", code: "PLW" },
      { country: "Paraguay", code: "PRY" },
      { country: "Catar", code: "QAT" },
      { country: "Reunión", code: "REU" },
      { country: "Rumania", code: "ROU" },
      { country: "Serbia", code: "SRB" },
      { country: "Rusia", code: "RUS" },
      { country: "Ruanda", code: "RWA" },
      { country: "Arabia Saudita", code: "SAU" },
      { country: "Islas Salomón", code: "SLB" },
      { country: "Seychelles", code: "SYC" },
      { country: "Sudán", code: "SDN" },
      { country: "Sudán del Sur", code: "SSD" },
      { country: "Suecia", code: "SWE" },
      { country: "Singapur", code: "SGP" },
      { country: "Santa Elena", code: "SHN" },
      { country: "Eslovenia", code: "SVN" },
      { country: "Eslovaquia", code: "SVK" },
      { country: "Sierra Leona", code: "SLE" },
      { country: "San Marino", code: "SMR" },
      { country: "Senegal", code: "SEN" },
      { country: "Somalia", code: "SOM" },
      { country: "Surinam", code: "SUR" },
      { country: "Santo Tomé y Príncipe", code: "STP" },
      { country: "El Salvador", code: "SLV" },
      { country: "Siria", code: "SYR" },
      { country: "Suazilandia", code: "SWZ" },
      { country: "Islas Turcas y Caicos", code: "TCA" },
      { country: "Chad", code: "TCD" },
      { country: "Tierras Australes y Antárticas Francesas", code: "ATF" },
      { country: "Togo", code: "TGO" },
      { country: "Tailandia", code: "THA" },
      { country: "Tayikistán", code: "TJK" },
      { country: "Tokelau", code: "TKL" },
      { country: "Timor Oriental", code: "TLS" },
      { country: "Turkmenistán", code: "TKM" },
      { country: "Túnez", code: "TUN" },
      { country: "Tonga", code: "TON" },
      { country: "Turquía", code: "TUR" },
      { country: "Trinidad y Tobago", code: "TTO" },
      { country: "Tuvalu", code: "TUV" },
      { country: "República de China", code: "TWN" },
      { country: "Tanzania", code: "TZA" },
      { country: "Ucrania", code: "UKR" },
      { country: "Uganda", code: "UGA" },
      {
        country: "Islas Ultramarinas Menores de Estados Unidos",
        code: "UMI",
      },
      { country: "Estados Unidos", code: "USA" },
      { country: "Uruguay", code: "URY" },
      { country: "Uzbekistán", code: "UZB" },
      { country: "Ciudad del Vaticano", code: "VAT" },
      { country: "San Vicente y las Granadinas", code: "VCT" },
      { country: "Venezuela", code: "VEN" },
      { country: "Islas Vírgenes Británicas", code: "VGB" },
      { country: "Islas Vírgenes de los Estados Unidos", code: "VIR" },
      { country: "Vietnam", code: "VNM" },
      { country: "Vanuatu", code: "VUT" },
      { country: "Wallis y Futuna", code: "WLF" },
      { country: "Samoa", code: "WSM" },
      { country: "Yemen", code: "YEM" },
      { country: "Mayotte", code: "MYT" },
      { country: "Sudáfrica", code: "ZAF" },
      { country: "Zambia", code: "ZMB" },
      { country: "Zimbabue", code: "ZWE" },
    ],

    bankNames: [
      { name: "Banco de Chile - Edwards - Citi", code: 1, id: 50 },
      { name: "Banco Internacional", code: 2, id: 57 },
      { name: "Scotiabank", code: 3, id: 62 },
      { name: "Banco de Crédito e Inversiones (BCI)", code: 4, id: 51 },
      { name: "Banco Bice", code: 5, id: 45 },
      { name: "Banco Santander", code: 6, id: 199 },
      { name: "Itaú Corpbanca", code: 7, id: 197 },
      { name: "Banco Security", code: 8, id: 61 },
      { name: "Banco Falabella", code: 9, id: 56 },
      { name: "Banco Ripley", code: 10, id: 60 },
      { name: "Banco Consorcio", code: 11, id: 198 },
      { name: "Banco Estado", code: 12, id: 54 },
      { name: "Banco Coopeuch", code: 13, id: 201 },
      // { name: "MACH", code: 14, id: 202 },
      { name: "TENPO", code: 15, id: 203 },
    ],

    availableBankAccountTypes: [
      { type: "Cuenta Corriente", code: 1 },
      { type: "Cuenta Vista", code: 2 },
      { type: "Chequera Eléctronica", code: 3 },
      { type: "Cuenta de Ahorro", code: 4 },
    ],
  }),
};
