<template>
  <div v-if="loading || (!loading && currentAmount > 0)">
    <div v-if="!loading">
      <SectionTitle :text="$i18n.t('home.diversification.title')" />
      <div class="diversification-items">
        <div class="diversification-container">
          <div
            v-for="proportion in currentFundProportions"
            v-bind:key="proportion.run"
            class="fund-diversification"
          >
            <FundsDiversificationFund
              :fundProportion="proportion"
              :biggestProportion="biggestProportion"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-10">
      <div v-for="n in 4" :key="n.index">
        <v-skeleton-loader type="list-item" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FundsDiversificationFund from "./FundsDiversificationFund.vue";
import SectionTitle from "../home/SectionTitle.vue";
import recommendFunds from "../../mixins/recommendFunds.js";

export default {
  name: "FundsDiversification",

  components: {
    FundsDiversificationFund,
    SectionTitle,
  },

  mixins: [recommendFunds],

  computed: {
    ...mapGetters(["loading", "customerCurrent"]),

    currentAmount() {
      if (this.customerCurrent.length > 0) {
        let total = 0;
        this.customerCurrent.forEach(fundBalance => {
          total += fundBalance.currencyAmount;
        });
        return total;
      }
      return 0;
    },

    biggestProportion() {
      return (this.currentFundProportions.at(0)?.proportion || 0) * 100;
    },

    currentFundProportions() {
      if (this.customerCurrent.length > 0) {
        let proportions = [];
        const fundProportions = {};

        /**
         * Iterate all the balances.
         * there might be more than once balance for each fund, distributed
         * in different series. First we iterate through
         * the balance to consolidate them in its funds.
         */

        this.customerCurrent.forEach(fundBalance => {
          const { fundRun } = fundBalance;
          if (!(fundRun in fundProportions)) {
            fundProportions[fundRun] = {
              run: fundRun,
              currencyAmount: 0,
              fundName: fundBalance.fundName,
              proportion: null,
            };
          }
          fundProportions[fundRun].currencyAmount += fundBalance.currencyAmount;
        });
        // Now, with the balances consolidated to avoid repeated funds,
        // we can calculate the proportions.
        Object.keys(fundProportions).forEach(fundRun => {
          fundProportions[fundRun].proportion =
            fundProportions[fundRun].currencyAmount / this.currentAmount;
        });
        proportions = Object.values(fundProportions);
        proportions.sort((a, b) => (a.proportion < b.proportion ? 1 : -1));

        return proportions;
      }
      return [];
    },
  },
};
</script>

<style scoped lang="scss">
.diversification-container {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 32px 48px 32px 48px;
  @media (max-width: 960px) {
    max-width: 100%;
    padding: 20px;
    margin: 0 20px;
  }
}
.diversification-items {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  gap: 1.5rem;
  flex-direction: row;
}
</style>
