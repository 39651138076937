<template>
  <v-navigation-drawer
    v-model="displayDrawer"
    app
    right
    temporary
    color="#FFFFFF"
    :width="$vuetify.breakpoint.xs ? '65%' : '300px'"
    id="right-toolbar"
  >
    <div class="toolbar-item">
      <div class="initials-circle dark-circle center-in-page">
        <div class="initials-text light">
          {{ currentUser | customerInitials }}
        </div>
      </div>
      <div class="customer-name">{{ currentUser }}</div>
    </div>

    <div class="internal-routes py-3">
      <div
        v-for="(item, index) in internalShortcuts"
        :key="index"
        class="routes"
        :id="item.name"
      >
        <RightToolbarItem
          :icon="item.icon"
          :route="item.route"
          :href="item.href"
          :name="item.name"
          :notificationCount="item.notificationCount"
        />
      </div>
    </div>

    <div v-if="userProfiles.length > 0" class="user-profiles">
      <div
        class="toolbar-item"
        v-for="(profile, index) in userProfiles"
        :key="`${profile.name}-${index}`"
        @click="changeSelectedProfile(profile)"
      >
        <div class="initials-circle outline-circle center-in-page">
          <div class="initials-text dark">
            {{ profile | getFullName | customerInitials }}
          </div>
        </div>
        <div class="customer-name">
          {{ profile | getFullName }}
        </div>
      </div>
    </div>
    <a href="https://ayuda.dvacapital.com/es/" target="_blank" rel="noopener noreferrer">
      <div class="toolbar-item">
        <v-icon size="18" color="#333333"> mdi-help-circle-outline </v-icon>
        <div class="toolbar-item-text">
          {{ $t("appbar.menu.help") }}
        </div>
      </div>
    </a>
    <div class="logout-actions" @click="$emit('logout')">
      <div class="toolbar-item">
        <v-icon size="18" color="#F20E45"> mdi-logout </v-icon>
        <div class="toolbar-item-text logout">
          {{ $t("appbar.menu.logout") }}
        </div>
      </div>
    </div>

    <div class="external-routes py-3">
      <div v-for="(item, index) in externalShortcuts" :key="index" class="routes">
        <RightToolbarItem :route="item.route" :href="item.href" :name="item.name" />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { userProfiles } from "@/mixins/userProfiles.js";
import RightToolbarItem from "./RightToolbarItem.vue";

export default {
  name: "RightToolbar",

  components: { RightToolbarItem },

  props: {
    internalShortcuts: Array,
    externalShortcuts: Array,
    show: Boolean,
    userProfiles: Array,
  },

  mixins: [userProfiles],

  computed: {
    ...mapGetters(["currentUser"]),

    displayDrawer: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("displayRightToolbar", value);
      },
    },
  },

  methods: {
    /**
     * Docs:
     * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/innerHeight
     */
    setInnerHeight() {
      // vh = 1 percent of the device screen height (inner height)
      const vh = window.innerHeight * 0.01;
      // Setting the value in the --vh custom property used to calc the height
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },

  created() {
    /**
     * Adjust the vh if a screen resize exists
     */
    window.addEventListener("resize", () => {
      this.setInnerHeight();
    });
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer--is-mobile {
  /**
   * Creating a own vh var.
   * We need a IOS compatible solution to height: 100vh
   */
  height: calc(var(--vh, 1vh) * 100) !important;
}

.v-navigation-drawer--temporary:not(.v-navigation-drawer--close) {
  box-shadow: 0px 0px 0px 0px !important;
}

.dark-circle {
  background: #000000;
}
.outline-circle {
  background: #ffffff;
  border: 2px solid #333333;
}

.initials-circle {
  aspect-ratio: 1;
  height: 30px;
  border-radius: 50%;
  @media (max-width: 600px) {
    height: 25px;
  }
}

.initials-text {
  font-weight: 700;
  font-size: 11.6px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 600px) {
    font-size: 10px;
  }
}

.light {
  color: #ffffff;
}
.dark {
  color: #333333;
}

.toolbar-item {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  height: 60px;
  padding: 0 20px;
  margin: 10px 0;

  &:hover {
    cursor: pointer;
    background: #f2f2f2;
    mix-blend-mode: darken;
  }
}

.toolbar-item-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
}
.logout {
  color: #f20e45;
}

.customer-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.internal-routes {
  border-top: 1px solid #e2e0e0;
  border-bottom: 1px solid #e2e0e0;
}

.logout-actions,
.user-profiles {
  border-bottom: 1px solid #e2e0e0;
}

a {
  text-decoration: none;
}
</style>
