<template>
  <TextField
    :id="id"
    :label="label"
    :rules="documentNumberRules"
    :placeholder="placeholder"
    :value="value"
    :disabled="disabled"
    :type="type"
    @beforeinput="preventInvalidInput"
    @updateValue="$emit('updateValue', $event)"
  />
</template>
<script>
import TextField from "@/components/shared/TextField.vue";

export default {
  name: "DocumentNumberTextField",

  data() {
    return {
      documentNumberRules: [
        v => !!v || this.$t("shared.errors.mustComplete"),
        v => /(^\d{3}\.\d{3}\.\d{3}$)|(^\d{9}$)/.test(v) ||
          this.$t("shared.errors.documentNumber"),
      ],
    };
  },

  props: {
    id: String,
    label: String,
    placeholder: String,
    value: [String, Number],
    disabled: Boolean,
    type: {
      type: String,
      default: "text",
    },
  },

  components: {
    TextField,
  },

  methods: {
    preventInvalidInput(event) {
      // prevents any character that is not a number or a dot
      const invalidChars = /[^\d.]/;

      if (event.data && invalidChars.test(event.data)) {
        event.preventDefault();
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
