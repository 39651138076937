<template>
  <v-app-bar
    app
    clipped-left
    id="top-toolbar"
    flat
    :height="$vuetify.breakpoint.xs ? 60 : 80"
    class="app-width"
    color="#F2F2F2"
  >
    <router-link to="/">
      <v-img
        :max-height="$vuetify.breakpoint.mdAndUp ? 30 : 25"
        :src="require('@/assets/mi-dva-logo.svg')"
        contain
        position="left"
      />
    </router-link>

    <v-spacer />
    <v-toolbar-items v-if="$vuetify.breakpoint.lgAndUp" class="ml-12">
      <div v-for="item in shortcuts" :key="item.route" class="center-in-page">
        <left-top-toolbar-item
          :route="item.route"
          :href="item.href"
          :drawerItem="false"
        />
      </div>
    </v-toolbar-items>
    <div class="toolbar-btn-row">
      <div v-if="$vuetify.breakpoint.lgAndUp">
        <v-btn
          id="customer-btn"
          outlined
          @click="$emit('toogleRightToolbar')"
          color="#000"
        >
          <span class="btn-text"> {{ currentUser | customerName }} </span>
        </v-btn>
      </div>

      <v-app-bar-nav-icon
        @click="$emit('toogleRightToolbar')"
        class="nav-icon"
        color="#000"
        dark
        v-else
      />
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import LeftTopToolbarItem from "./LeftTopToolbarItem.vue";

export default {
  name: "TopToolbar",

  components: { LeftTopToolbarItem },

  props: {
    shortcuts: Array,
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>

<style lang="scss" scoped>
#customer-btn,
#invest-btn {
  border-radius: 10px;
  min-width: 120px;
  height: 50px;
}

.toolbar-btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btn-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  letter-spacing: normal;
  color: #000;
}

#top-toolbar {
  padding: 0 40px !important;

  @media (max-width: 960px) {
    padding: 0 20px !important;
  }
}
</style>
