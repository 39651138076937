<template>
  <div class="invest-wrapper">
    <div class="invest-content">
      <div class="invest-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <div class="compass-signature-title">
        {{ $t("investment.compassSignatureNotice.title") }}
      </div>

      <div class="compass-signature-text">
        <i18n path="investment.compassSignatureNotice.text" tag="div">
          <template v-slot:breakline>
            <br />
          </template>
        </i18n>
      </div>

      <div class="more-info">
        <a
          href="https://ayuda.dvacapital.com/es/articles/9090601-fondos-de-compass-group-agf-en-mi-dva"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("investment.compassSignatureNotice.moreInfo") }}
        </a>
      </div>

      <div class="document-warning" v-if="expiredOrMissingIdDocument">
        <v-icon class="alert-icon mt-1" color="#333"> mdi-alert-circle-outline </v-icon>

        <div class="warning-text">
          <b>
            {{ $t("investment.compassSignatureNotice.warning.important") }}
          </b>

          <span>
            {{ $t("investment.compassSignatureNotice.warning.document") }}
          </span>
        </div>
      </div>

      <div class="next-button-container">
        <NewButton
          id="next-step"
          :text="$t('investment.chooseInvestmentAmount.buttonText')"
          @on-click="onAcceptRedirection()"
        />
      </div>

      <div class="invest-cancel-label" @click="$router.push({ name: 'home' })">
        {{ $t("base.cancel") }}
      </div>
    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CompassSignatureNotice",

  components: {
    NewButton,
  },

  computed: {
    ...mapGetters(["expiredOrMissingIdDocument"]),
  },

  methods: {
    ...mapActions(["addToRouteStack"]),

    onAcceptRedirection() {
      const targetRoute = this.getTargetRoute();

      this.$router.push({ name: targetRoute });
    },

    getTargetRoute() {
      if (this.expiredOrMissingIdDocument) {
        this.addToRouteStack({ name: "accountCompassContract" });
        return "accountUpdateIdDocument";
      }
      return "accountCompassContract";
    },
  },
};
</script>

<style lang="scss" scoped>
.invest-content {
  margin: 0 20px !important;
  max-width: 420px;
}

.invest-amount-warning {
  max-width: 420px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 40px;
}

.compass-signature-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  color: #333;
}

.compass-signature-text {
  color: #333;
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  margin-top: 40px;
}

.document-warning {
  width: 100%;
  height: 84px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  background-color: #fff !important;
  margin-top: 20px;
}

.invest-cancel-label {
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  text-align: right;
  color: #646363;
  margin-top: 40px;

  &:hover {
    cursor: pointer;
  }
}

br {
  display: block;
  content: "";
  margin-top: 20px;
}

.next-button-container {
  margin-top: 40px;
}
.warning-text {
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #333;
  }
  b {
    font-weight: 600;
  }
}

.more-info {
  margin-top: 20px;
}

a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #646363 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
