<template>
  <div class="profile-wrapper">
    <h3 class="profile-title">
      {{ $t("profile.account.title") }}
    </h3>

    <div class="profile-customer-row">
      <div class="initials-circle">
        <div class="initials-text">
          {{ currentUser | getFullName | customerInitials }}
        </div>
      </div>
      <span class="customer-name">
        {{ currentUser | getFullName }}
      </span>
    </div>

    <div class="account-options">
      <div
        v-for="(option, index) in accountOptions"
        :key="index"
        class="account-option-item"
        :class="{ 'separated-option': option.hasSeparator }"
      >
        <AccountOption
          class="account-option"
          :id="option.id"
          :leftIcon="option.leftIcon"
          :rightIcon="option.rightIcon"
          :notifications="option.notifications"
          :optionalClass="option.optionalClass"
          :text="
            $t(`profile.account.options.${option.name}`, {
              riskProfile: $t(`profile.riskProfile.options.${customerRiskProfile}.name`),
            })
          "
          @optionClicked="handleAccountOptionClick(option)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AccountOption from "@/components/profile/AccountOption.vue";

export default {
  name: "AccountView",

  components: { AccountOption },

  computed: {
    ...mapGetters([
      "customerRiskProfile",
      "personalDataAlertCount",
      "idDocumentAlertCount",
      "contactAddressAlertCount",
      "bankingDataAlertCount",
      "riskProfileAlertCount",
      "documentsAlertCount",
      "declarationsAlertCount",
      "transactionalProfileAlertCount",
    ]),

    currentUser() {
      return this.$auth.getProfile();
    },

    accountOptions() {
      const options = [
        {
          name: "personal",
          leftIcon: "mdi-account-outline",
          notifications: this.personalDataAlertCount,
        },
        {
          name: "identification",
          leftIcon: "mdi-id-card",
          notifications: this.idDocumentAlertCount,
        },
        {
          name: "contactAddress",
          leftIcon: "mdi-phone-outline",
          notifications: this.contactAddressAlertCount,
        },
        {
          name: "riskProfile",
          leftIcon: "mdi-parachute-outline",
          notifications: this.riskProfileAlertCount,
        },
        {
          name: "declarations",
          leftIcon: "mdi-format-list-checks",
          notifications: this.declarationsAlertCount + this.transactionalProfileAlertCount,
        },

        {
          name: "bank",
          leftIcon: "mdi-bank-outline",
          notifications: this.bankingDataAlertCount,
        },
        {
          name: "changePassword",
          leftIcon: "mdi-form-textbox-password",
        },
        {
          name: "documentsView",
          leftIcon: "mdi-content-copy",
          hasSeparator: true,
          notifications: this.documentsAlertCount,
        },
        {
          name: "logout",
          leftIcon: "mdi-logout",
          optionalClass: "logout",
        },
      ];

      // Set default values for rightIcon and hasAlertState if not provided
      // We also set the routes for each option. The only one without route is the logout option
      return options.map(option => ({
        ...option,
        id: this.getAccountOptionId(option.name),
        route: this.getAccountOptionRoute(option.name),
        rightIcon: option.name === "logout" ? null : "mdi-chevron-right",
        hasSeparator: option?.hasSeparator || false,
        notifications: option?.notifications || 0,
        optionalClass: option?.optionalClass || null,
      }));
    },
  },

  methods: {
    handleAccountOptionClick(option) {
      if (option.name === "logout") {
        this.logout();
      } else this.$router.push({ name: option.route });
    },

    getAccountOptionId(optionName) {
      // camelCase to kebab-case
      return optionName.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
    },

    getAccountOptionRoute(optionName) {
      if (optionName === "logout" || !optionName) return null;

      // Capitalize the first letter of the account option
      const capitalizedName = optionName.charAt(0).toUpperCase() + optionName.slice(1);

      // For example, accountPersonal and accountRiskProfile
      return `account${capitalizedName}`;
    },

    logout() {
      this.$intercom.shutdown();
      return this.$auth.logout();
    },
  },
};
</script>

<style lang="scss">
.profile-wrapper {
  margin: 90px 120px;
  max-width: 600px;
  @media (max-width: 960px) {
    margin: 30px 20px;
    padding-bottom: 20px;
    min-height: 600px !important;
  }

  @media (max-width: 380px) {
    padding: 0;
    min-height: 500px !important;
  }

  .profile-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    color: #333333;
    margin-bottom: 40px;
  }

  .transactional-profile-title{
    font-size: 18px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    margin-bottom: 20px;
  }

  .transactional-profile-subtitle{
    font-size: 11px;
    font-weight: 500;
    line-height: 17.6px;
    color: #646363;
    margin-bottom: 40px;
  }

  .transactional-profile-text{
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
  }

  .profile-subtitle{
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #333;
    margin-bottom: 20px;
  }

  .profile-step-back {
    margin-bottom: 20px;
  }

  .options {
    max-width: 420px;
    > * {
      margin-bottom: 10px;
    }
  }

  .profile-flex-columns {
    display: flex;
    gap: 30px;
    @media (max-width: 1280px) {
      flex-direction: column;
      gap: 60px;
    }
  }

  .profile-column {
    flex: 1;
  }

  .profile-column > * {
    max-width: 420px;
  }

  .profile-cancel-text {
    text-align: right;
    color: #646363;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;

    margin-top: 40px;
    &:hover {
      cursor: pointer;
    }
  }

  .profile-hint {
    font-weight: 500;
    font-size: 11px;
    line-height: 160%;
    color: #646363;
    margin: 40px 0 0 0 !important;
  }

  .profile-customer-row {
    display: flex;
    align-items: center;
    margin: 40px 0;
    gap: 0 20px;
    .initials-circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      .initials-text {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
      }
    }
    .customer-name {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%; /* 28.8px */
      color: #333;
    }
  }

  .account-options {
    .account-option-item {
      max-width: 420px;
    }
    .account-option-item + .account-option-item {
      margin-top: 10px;
    }
  }

  .separated-option {
    border-top: 1px solid #e2e0e0;
    border-bottom: 1px solid #e2e0e0;
    padding: 20px 0;
    margin: 20px 0 !important;
  }

  .profile-step-back {
    margin-bottom: 20px;
  }

  .profile-form {
    margin: 40px 0;

    max-width: 420px;

    .profile-input-field + .profile-input-field {
      margin-top: 20px !important;
    }

    .submit-button {
      margin-top: 40px;
    }

    .v-input--radio-group.v-input--radio-group--row .v-radio .v-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #333333;
    }

    .v-file-input .v-file-input__text--placeholder {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 27px !important;
      color: #333333 !important;
    }

    .profile-step-button {
      margin-top: 40px;
    }

    .profile-coupled-inputs
      > .datefield.v-text-field--rounded
      > .v-input__control
      > .v-input__slot {
      input {
        text-align: center;
      }
    }
    .profile-coupled-inputs > :last-child > .v-input__control > .v-input__slot {
      padding: 0 15px;
    }

    .profile-coupled-inputs
      > :not(.v-input--is-focused):not(:first-child):not(:last-child)
      > .v-input__control
      > .v-input__slot
      fieldset {
      border-width: 1px 0px 1px 0;
    }
    .input__prepend-inner {
      margin-top: 20px !important;
    }
  }
}

.profile-centered-wrapper {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.profile-content {
  max-width: 420px;
  width: 100%;
}

.profile-option-container {
  background-color: #ffffff;
  border-radius: 10px;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}

.profile-option-container + .profile-option-container {
  margin-top: 10px;
}

.profile-option-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin: 0px !important;
  padding: 0px !important;
}
</style>
