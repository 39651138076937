<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/valid-template-root -->
<template>
  </div>
</template>

<script>
export default {
  name: "CallbackView",

  async mounted() {
    await this.$auth.handleRedirectCallback();
  },
};
</script>
