var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-8 filter-funds",attrs:{"id":"filters-container"}},_vm._l((_vm.availableCategories),function(categoryOption,index){return _c('FilterFundsPill',{key:`category-${index}`,staticClass:"fund-pill",attrs:{"optionIcon":categoryOption.categoryName === 'CLEAR_FILTERS' ? 'close' : '',"optionName":categoryOption.categoryName === 'CLEAR_FILTERS'
        ? ''
        : _vm.$i18n.t(
          `home.invest.filterFunds.${categoryOption.categoryName}.${categoryOption.categoryOptionName}`,
        ),"isSelected":_vm.getCategoryMatchIndex({
        categoryName: categoryOption.categoryName,
        categoryOptionName: categoryOption.categoryOptionName,
      }) >= 0},nativeOn:{"click":function($event){return _vm.onCategoryClick({
        categoryName: categoryOption.categoryName,
        categoryOptionName: categoryOption.categoryOptionName,
      })}}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }