<template>
  <div class="redemption-wrapper redemption-centered-wrapper">
    <div class="redemption-content">
      <div class="redemption-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <h2 class="redemption-header">
        {{ $t("redemptions.redemptionOrigin.title") }}
      </h2>

      <div class="options">
        <ClickableOptionCard
          :title="$t('redemptions.redemptionOrigin.origins.total.title')"
          :subtitle="$t('redemptions.redemptionOrigin.origins.total.body')"
          @optionClicked="selectRedemptionOption('fromTotal')"
        />
        <ClickableOptionCard
          :title="$t('redemptions.redemptionOrigin.origins.fund.title')"
          :subtitle="$t('redemptions.redemptionOrigin.origins.fund.body')"
          @optionClicked="selectRedemptionOption('fromFund')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClickableOptionCard from "@/components/shared/ClickableOptionCard.vue";

export default {
  name: "RedemptionOrigin",

  components: { ClickableOptionCard },

  computed: {
    ...mapGetters(["redemption"]),
  },

  methods: {
    ...mapActions(["setRedemptionData"]),

    selectRedemptionOption(option) {
      // If the user selects the "fromTotal" option, the redemption will be proportional to the total balance
      const fromTotal = option === "fromTotal";

      this.setRedemptionData({
        ...this.redemption,
        redemptionOptions: { ...this.redemption.redemptionOptions, fromTotal },
      });

      this.$router.push({ name: fromTotal ? "chooseRedemptionAmount" : "fromFundRedemption" });
    },

  },
};
</script>
