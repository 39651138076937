<template>
  <div class="profile-wrapper profile-centered-wrapper">
    <div class="profile-content">
      <div class="profile-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <v-img
        class="intro-img"
        :src="require('@/assets/profiling-intro.svg')"
        :lazy-src="require('@/assets/profiling-intro-lazy.png')"
      />

      <h3 class="transactional-profile-title">
        {{ $t("profile.transactionalProfile.intro.title") }}
      </h3>

      <div class="transactional-profile-text">
        {{ $t("profile.transactionalProfile.intro.text") }}
      </div>

      <NewButton
        id="next-step"
        class="next-step-button"
        :text="$t('base.letsGo')"
        @on-click="$router.push({ name: 'investTransactionalProfileQuestions' })"
      />
    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "TransactionalProfileIntro",

  components: { NewButton },

};
</script>
<style lang="scss" scoped>
.profile-wrapper{
  margin: 40px 20px;
  @media (max-width: 600px) {
    margin: 30px 20px;
  }
}
.profile-step-back {
  margin-bottom: 40px;
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
}

.intro-img {
  margin: 40px 0 20px 0;
}

.next-step-button{
  margin-top: 40px;
}
</style>
