<template>
  <v-col v-if="$vuetify.breakpoint.mdAndUp">
    <v-row align="center">
      <span class="ml-4 table-filter">
        {{ $i18n.t("movements.table.filterBy") }}
      </span>
      <template v-for="(item, index) in filteringOptions()">
        <v-btn v-bind:key="index" class="my-1 mx-4" icon @click="selectFilter(item)">
          <v-avatar :color="getFilterColor(item)" v-bind:style="notSelectedBorderStyle(item)">
            <span class="white--text" v-bind:style="notSelectedSpanStyle(item)">{{ item.value | addFundAcronym }}</span>
          </v-avatar>
        </v-btn>
      </template>
    </v-row>
  </v-col>
  <v-speed-dial v-else class="filter-dial" v-model="fab" :bottom="true" :right="true" direction="top" fixed>
    <template v-slot:activator>
      <v-btn v-model="fab" color="blue darken-2" dark fab>
        <v-icon v-if="fab"> mdi-close </v-icon>
        <v-icon v-else> mdi-filter </v-icon>
      </v-btn>
    </template>
    <template v-for="(item, index) in filteringOptions()">
      <v-btn class="my-3" v-bind:key="index" icon @click="selectFilter(item)" @click.stop="return false;">
        <v-avatar v-bind:key="index" class="my-1" :color="getFilterColor(item)" v-bind:style="notSelectedBorderStyle(item)">
          <span class="white--text" v-bind:style="notSelectedSpanStyle(item)">{{ item.value | addFundAcronym }}</span>
        </v-avatar>
      </v-btn>
    </template>
  </v-speed-dial>
</template>

<script>
export default {
  name: "FundsFilter",
  data() {
    return {
      filterOptionsArray: [],
      fab: false,
    };
  },
  methods: {
    filteringOptions() {
      // If the prop includedFunds is given, the filter only applies to the includedFunds.
      // includedFunds is an array with fund runs.
      let fundOptions = {};
      const availableFundOptions = this.$options.filters.fundFullName();
      if (!this.includedFunds.length) {
        fundOptions = availableFundOptions;
      } else {
        this.includedFunds.forEach(includedFund => {
          fundOptions[Number(includedFund)] = availableFundOptions[Number(includedFund)];
        });
      }
      return Object.keys(fundOptions).map(fundRun => ({
        text: fundOptions[fundRun],
        value: fundRun,
      }));
    },

    selectFilter(item) {
      if (this.filterOptionsArray.includes(item.value)) {
        const index = this.filterOptionsArray.indexOf(item.value);
        this.filterOptionsArray.splice(index, 1);
      } else {
        this.filterOptionsArray.push(item.value);
      }
      this.handleChangeFilterArray(this.filterOptionsArray);
    },
    // Is selected by the filter
    isSelected(item) {
      if (this.filterOptionsArray.length === 0 || this.filterOptionsArray.includes(item.value)) {
        return true;
      }
      return false;
    },
    getFilterColor(item) {
      // If no filter is selected
      if (this.isSelected(item)) {
        return this.$options.filters.addFundColor(item.value);
      }
      return "white";
    },
    notSelectedBorderStyle(item) {
      if (!this.isSelected(item)) {
        return {
          border: "2px solid !important",
          borderColor: `${this.$options.filters.addFundColor(item.value)} !important`,
        };
      }
      return {};
    },
    notSelectedSpanStyle(item) {
      if (!this.isSelected(item)) {
        return {
          color: "grey !important",
        };
      }
      return {};
    },
  },
  props: {
    handleChangeFilterArray: Function,
    // Fund relevants to the filter. The user may not have all funds availables. If the
    // array is empty, it should render every option.
    includedFunds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped>
.table-filter {
  font-family: "Work Sans";
  font-style: normal;
  color: #757575 !important;
  font-size: 14px;
}

.filter-dial {
  size: 120%;
  color: "#828282";
  margin-bottom: 20%;
  margin-right: 2px;
}
</style>
