<template>
  <v-app id="main" :style="{ background: appBackground }">
    <AppToolbars
      v-if="!isAccessPage() && !isSignupPage()"
      :displayShortcuts="displayToolbarShortcuts"
      :userProfiles="userProfiles"
    />
    <SignupToolbar v-if="isSignupPage() && !isMaintenancePage()" />
    <v-main>
      <!-- <new-fund-announce
        :fundRun="'10348'"
        v-if="!isAccessPage() && !isSignupPage()"
      /> -->
      <router-view />
    </v-main>
    <MainFooter v-if="!isAccessPage() && !isSignupPage()" />
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AppToolbars from "@/components/layout/AppToolbars.vue";
import SignupToolbar from "@/components/signup/SignupToolbar.vue";
import MainFooter from "@/components/layout/MainFooter.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import { bankSelects } from "@/mixins/bankSelects.js";
import funds from "@/mixins/funds.js";
// import NewFundAnnounce from "@/components/layout/NewFundAnnounce.vue";

export default {
  name: "App",

  components: {
    AppToolbars,
    SignupToolbar,
    MainFooter,
    // NewFundAnnounce,
  },

  mixins: [customersApiFunctions, funds, bankSelects],

  data: () => ({
    userProfiles: [],

  }),

  computed: {
    ...mapState(["fundsData", "profileData", "loading"]),

    ...mapGetters(["authenticatedStatus", "investedCustomer"]),

    appBackground() {
      return this.$vuetify.theme.themes[this.theme].background;
    },

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },

    displayToolbarShortcuts() {
      // hides the shortcuts in the toolbars when the data is loading in the home page
      return !this.loading || this.$route.name !== "home";
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.$auth.getAuthenticatedStatus().then(authenticatedStatus => {
        this.setAuthenticatedStatus(authenticatedStatus);
        if (authenticatedStatus === "no-profile-data") {
          // If there is no profile it might be existing now if the customer ended his/her signup
          // We updat the main profile to check if it now exists.
          this.$auth
            .setUserMainProfile({ email: localStorage.getItem("userEmail") })
            .then(() => {
              this.$auth.getAuthenticatedStatus().then(authenticatedStatus => {
                this.setAuthenticatedStatus(authenticatedStatus);
                this.setProfiles();
                this.loadData();
              });
            });
        } else {
          this.setProfiles();
          this.loadData();
        }
      });
    },
    investedCustomer() {
      // When user is invested and tries to access to signup pages trough the url
      // we redirect him/her to the home page
      this.redirectInvestedCustomerToHomePage();
    },
  },

  methods: {
    ...mapActions([
      "setAuthenticatedStatus",
      "setLoading",
      "setLoadingProfile",
      "setLoadingPendingMovements",
      "setLoadingReinvestments",
      "setFundsData",
      "setProfileData",
      "setPendingMovementsData",
      "setReinvestmentsData",
      "setFundsDetailedData",
      "setUserData",
      "setQueryParams",
      "setTargetFund",
      "setCustomerDividendsData",
      "setLoadingCustomerDividends",
      "setBanks",
    ]),

    isAccessPage() {
      return ["login", "register", "enrolled", "reset"].includes(this.$route.name);
    },

    isSignupPage() {
      return window.location.pathname.includes("signup");
    },

    isMaintenancePage() {
      return this.$route.name === "signupUnderMaintenance";
    },

    setProfiles() {
      if (this.authenticatedStatus === "authenticated") {
        this.userProfiles = this.$auth
          .getAvailableProfiles()
          .filter(profile => !this.$auth.isSelectedProfile(profile))
          .sort((userA, userB) => userA - userB);
      }
    },

    redirectNonInvestedCustomerToSignupDashboard() {
      if (!this.investedCustomer && !this.$router.history.current.meta.signup) {
        this.$router.push({ name: "signupDashboard" });
      }
    },

    redirectInvestedCustomerToHomePage() {
      if (this.investedCustomer && this.$router.history.current.meta.signup) {
        this.$router.push({ name: "home" });
      }
    },

    async loadData() {
      if (this.authenticatedStatus === "authenticated") {
        if (!this.fundsData || !this.profileData) {
          this.setLoading(true);
          this.setLoadingProfile(true);
          this.getCustomerStatus();
          this.getCustomerData().then(customerDataResponse => {
            const customerData = customerDataResponse.data;
            Promise.all([
              this.setFundsData(customerData.funds),
              this.setProfileData(customerData.profile),
              this.setLoading(false),
              this.setLoadingProfile(false),
            ]).then(() => {
              this.redirectNonInvestedCustomerToSignupDashboard();
            });
          });

          this.setLoadingPendingMovements(true);
          this.getPendingMovements().then(pendingMovementsData => {
            this.setPendingMovementsData(pendingMovementsData);
            this.setLoadingPendingMovements(false);
          });

          this.setLoadingReinvestments(true);
          this.getPendingReinvestmentRequests().then(reinvestments => {
            this.setReinvestmentsData(reinvestments);
            this.setLoadingReinvestments(false);
          });

          this.getBanks().then(banksResponse => {
            const localBanksData = this.bankNames;

            if (!banksResponse || !Array.isArray(banksResponse)) {
              this.setBanks(localBanksData);
              return;
            }
            const banksData = localBanksData.map(bank => {
              const bankAPIData = banksResponse.find(bankAPIData => Number(bankAPIData.id) === Number(bank.id));
              return {
                ...bank,
                ...bankAPIData,
                label: bankAPIData?.fantasyName,
              };
            });
            this.setBanks(banksData);
          });
        } else {
          this.redirectNonInvestedCustomerToSignupDashboard();
        }
      }
    },
  },

  async mounted() {
    if (!this.$intercom.ready && localStorage.getItem("userEmail")) {
      this.$intercom.boot({
        user_id: localStorage.getItem("userId"),
        name: localStorage.getItem("userName"),
        email: localStorage.getItem("userEmail"),
      });
    } else if (!this.$intercom.ready && !localStorage.getItem("userEmail")) {
      this.$intercom.boot();
    } else if (this.$intercom.ready) {
      this.$intercom.show();
    }
    this.setQueryParams();
    this.setProfiles();
    await this.loadData();

    this.$auth.handleTokenExpiration();
  },
};
</script>

<style lang="scss">
#main {
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
}

.grecaptcha-badge {
  visibility: hidden;
}

.max-width-wrapper {
  max-width: 1400px;
  margin: 0 auto;
}
.center-in-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-in-page {
  display: flex;
  align-items: center;
  justify-content: left;
}
.right-in-page {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.step-content {
  padding-top: 80px;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #f2f2f2;
  @media (max-width: 600px) {
    padding: 40px 20px 0 20px;
  }
}

.component-div {
  background-color: #f2f2f2;
}

.component-iterator {
  background-color: #f2f2f2;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 98% !important; // to prevent vertical overflow
}

.fixed-btn {
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: show-btn 0.5s forwards;
}

.base-primary-button {
  background-color: #ebf0ff !important;
  border-radius: 100px !important;
  font-family: Work Sans !important;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 13px !important;
  letter-spacing: 0.1em !important;
  text-transform: uppercase;
  color: #6748ef !important;
}

@keyframes show-btn {
  100% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
}

.clickable-item {
  &:hover {
    background-color: #fafafa !important;
    cursor: pointer;
  }
}

.custom-menu {
  border: 1px solid #e2e0e0 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.dropdown-item-text {
  font-size: 14px !important;
}

.error-validation {
  font-weight: 500;
  font-size: 14px;
  color: #f20e45;
  margin-top: 10px;
}

.edit-account-form {
  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: #e2e0e0;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 60px !important;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    background: #ffffff !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #333333;
  }

  .v-input--is-disabled > .v-input__control > .v-input__slot {
    background: #fafafa !important;
  }

  :not(.file-input).v-text-field--outlined.v-input--is-focused
    > .v-input__control
    > .v-input__slot
    fieldset {
    border: 1px solid #000 !important;
  }

  .v-autocomplete__content.v-menu__content,
  .v-autocomplete__content.v-menu__content .v-card {
    border-radius: 10px !important;
  }

  .error--text.v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid #e2e0e0;
  }

  .theme--light.v-text-field--outlined {
    border-radius: 10px;
  }

  .edit-bank-field + .edit-bank-field {
    margin-top: 10px;
  }
}

.invest-step-content {
  padding-top: 60px;
  @media (max-width: 600px) {
    padding: 0;
  }
  @media (max-width: 390px) {
    height: 100%;
  }
}

.invest-wrapper {
  display: flex;
  justify-content: center;
}

.invest-content {
  @media (max-width: 600px) {
    width: 100%;
    padding: 0;
  }
  @media (max-width: 390px) {
    height: 100%;
  }
}

.invest-bank-container {
  padding-left: 10%;
  padding-right: 10%;

  @media (max-width: 600px) {
    padding: 0 20px;
  }
}

.invest-step-back {
  margin: 40px 0 20px 0;
}

.invest-amount-title-container {
  margin-bottom: 20px;
}

.invest-amount-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.invest-amount-subtitle {
  margin-bottom: 40px;
  @media (max-width: 600px) {
    margin-bottom: 0 20px;
  }
}

.invest-amount-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
}

.vti__dropdown:hover {
  background-color: inherit !important;
}

.vti__dropdown-list {
  padding-left: 0 !important;
}

.vti__dropdown-item {
  font-size: 14px;
  strong {
    font-weight: 500 !important;
  }
  &:hover {
    background-color: #fafafa !important;
  }
}

.v-toolbar__content {
  padding: 0 !important;
}
</style>
