<template>
  <div class="signup-view">
    <div class="signup-initial-form">
      <div class="signup-mi-dva-logo-container">
        <v-img
          max-width="120"
          :src="require('@/assets/mi-dva-logo.svg')"
          contain
        />
      </div>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="registerUser"
        class="signup-form register-form"
      >
        <v-text-field
          v-model="email"
          type="email"
          :hide-details="
            email !== null && !$refs.email.isFocused ? $refs.email.validate() : ''
          "
          :disabled="disabledEmail"
          rounded
          outlined
          class="signup-input-field"
          ref="email"
          :placeholder="$i18n.t('signup.auth.placeholders.email')"
          :rules="emailRules"
        />
        <v-text-field
          v-model="confirmEmail"
          type="email"
          rounded
          outlined
          class="signup-input-field"
          :hide-details="
            confirmEmail !== null && !$refs.confirmEmail.isFocused
              ? $refs.confirmEmail.validate()
              : ''
          "
          :disabled="disabledEmail"
          ref="confirmEmail"
          :placeholder="$i18n.t('signup.auth.placeholders.confirmEmail')"
          :rules="confirmEmailRules"
        />
        <v-text-field
          v-model="password"
          :hide-details="
            password !== null && !$refs.password.isFocused
              ? $refs.password.validate()
              : ''
          "
          autocomplete="new-password"
          rounded
          outlined
          persistent-hint
          class="signup-input-field"
          ref="password"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="$i18n.t('signup.auth.placeholders.password')"
          :rules="passwordRules"
        >
          <template v-slot:append v-if="password">
            <v-icon color="#333333" @click="showPassword = !showPassword">
              {{ !showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline" }}
            </v-icon>
          </template>
        </v-text-field>

        <div class="signup-create-account-btn center-in-page">
          <NewButton
            :text="$i18n.t('signup.shared.buttons.createAccount')"
            :disabled="
              !$auth.socialAuthLoading
                && (!valid || $auth.credentialsAuthLoading || !confirmEmail)
            "
            :type="'submit'"
            :loading="$auth.credentialsAuthLoading"
          />
        </div>

        <SocialButton
          :loginText="$i18n.t('signup.auth.socialLogin.signUpWithGoogle')"
          @on-click="$auth.socialLogin"
          :loading="$auth.socialAuthLoading"
        />

        <div class="signup-terms-conditions">
          {{ $i18n.t("signup.auth.terms.toContinue") }}
          <a
            :href="$i18n.t('signup.auth.terms.terms.url')"
            target="_blank"
            rel="noopener noreferrer"
            class="signup-terms-url"
            @click.stop
          >
            {{ $i18n.t("signup.auth.terms.terms.text") }}
          </a>
          <a
            :href="$i18n.t('signup.auth.terms.privacy.url')"
            target="_blank"
            rel="noopener noreferrer"
            class="signup-terms-url"
            @click.stop
          >
            {{ $i18n.t("signup.auth.terms.privacy.text") }}
          </a>
          <a
            :href="$i18n.t('signup.auth.terms.protection.url')"
            target="_blank"
            rel="noopener noreferrer"
            class="signup-terms-url"
            @click.stop
          >
            {{ $i18n.t("signup.auth.terms.protection.text") }}
          </a>
        </div>
        <div class="signup-has-account">
          <router-link to="login">
            <div>{{ this.$i18n.t("signup.auth.hasAccount") }}</div>
          </router-link>
        </div>
      </v-form>
      <RecaptchaTerms />

      <ReusableDialog
        v-if="$auth.credentialsWrongPasswordError"
        :value="$auth.credentialsWrongPasswordError"
        @display="$auth.credentialsWrongPasswordError = $event"
        :text="$i18n.t('signup.auth.after.wrong')"
        :buttonText="$i18n.t('base.close')"
      />

      <ReusableDialog
        v-if="$auth.credentialsServerError"
        :value="$auth.credentialsServerError"
        @display="$auth.credentialsServerError = $event"
        :text="$i18n.t('signup.auth.after.server')"
        :buttonText="$i18n.t('base.close')"
      />

      <ReusableDialog
        :value="$auth.failedCaptcha"
        @display="$auth.failedCaptcha = $event"
        :buttonText="$i18n.t('base.close')"
        v-if="$auth.failedCaptcha"
      >
        <template v-slot:slotText>
          {{ $i18n.t("recaptcha.failValidationMessage") }}
          <a
            href="https://ayuda.dvacapital.com/es/articles/6980872-no-puedes-autenticarte-por-recaptcha"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $i18n.t("recaptcha.failValidationRedirect") }}
          </a>
        </template>
      </ReusableDialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import { signupFunctions } from "@/mixins/signup.js";
import RecaptchaTerms from "@/components/recaptcha/RecaptchaTerms.vue";
import NewButton from "@/components/shared/NewButton.vue";
import SocialButton from "@/components/shared/SocialButton.vue";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";

export default {
  name: "SignupView",

  components: {
    NewButton,
    SocialButton,
    RecaptchaTerms,
    ReusableDialog,
  },

  mixins: [customersApiFunctions, signupFunctions],

  data() {
    return {
      a: true,
      valid: false,
      email: null,
      disabledEmail: false,
      password: null,
      confirmEmail: null,
      showPassword: false,
      underMaintenance: false,
      passwordRules: [
        v => !!v || this.$i18n.t("signup.auth.errors.mustComplete"),
        v => (v &&
            v.length >= 8 &&
            /^(?=.*[A-Z]).+$/.test(v) &&
            /^(?=.*[a-z]).+$/.test(v) &&
            /\d/.test(v)) ||
          this.$i18n.t("signup.auth.errors.password.creationRules"),
      ],
      emailRules: [
        v => !!v || this.$i18n.t("signup.auth.errors.mustComplete"),
        v => /^[a-zA-Z0-9.\-_+]+@[a-zA-Z0-9.\-_+]+\.[a-zA-Z0-9.\-_+]+$/.test(v) ||
          this.$i18n.t("signup.auth.errors.email.valid"),
      ],
    };
  },

  computed: {
    ...mapGetters(["proposedEmail"]),

    confirmEmailRules() {
      const rules = [v => !!v || this.$i18n.t("signup.auth.errors.mustComplete")];
      if (this.confirmEmail) {
        const rule = v => v === this.email || this.$i18n.t("signup.auth.errors.email.equal");
        rules.push(rule);
      }
      return rules;
    },

    normalizedEmail() {
      if (!this.email) return null;
      return this.email.toLowerCase().trim();
    },
  },

  methods: {
    ...mapActions(["setQueryParams"]),

    wrongPasswordCallback() {
      this.$auth.credentialsAuthLoading = false;
      this.$auth.credentialsWrongPasswordError = true;
      localStorage.clear();
    },

    errorCallback() {
      this.$auth.credentialsAuthLoading = false;
      this.$auth.credentialsServerError = true;
      localStorage.clear();
    },

    async noAccountCallback() {
      this.$intercom.update({
        email: this.normalizedEmail,
      });
      this.$intercom.trackEvent(this.$i18n.t("signup.intercom.create.event"), {
        status_registro: this.$i18n.t("signup.intercom.create.message"),
      });
      // eslint-disable-next-line no-undef
      // heap.identify(this.normalizedEmail());
      this.$auth.credentialsAuthLoading = true;
      const savingUserEmailResponse = await this.saveUserEmail(this.normalizedEmail);

      if (savingUserEmailResponse.error) {
        this.$auth.credentialsAuthLoading = false;
        this.$auth.credentialsServerError = true;
        return;
      }
      this.$store.commit("setPwd", { pwd: this.password });

      this.$intercom.trackEvent(this.$i18n.t("signup.intercom.hub.event"), {
        status_registro: this.$i18n.t("signup.intercom.hub.message"),
      });
      await this.getCustomerStatus();
      this.$router.push({ name: "signupDashboard" });
    },

    async registerUser() {
      await this.$auth.credentialsSignup(
        this.normalizedEmail,
        this.password,
        this.wrongPasswordCallback,
        this.noAccountCallback,
      );
    },
  },

  mounted() {
    if (this.underMaintenance) this.$router.push({ name: "signupUnderMaintenance" });
    else {
      this.setQueryParams();
      if (this.proposedEmail) {
        this.email = this.proposedEmail;
        this.confirmEmail = this.proposedEmail;
        this.disabledEmail = true;
      }
    }
  },
};
</script>

<style lang="scss">
.register-form {
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e0e0;
}

.signup-initial-form {
  background-color: #ffffff;
  max-width: 580px !important;
  width: 100%;
  border: 1px solid #e2e0e0;
  border-radius: 10px;
  padding: 80px;
  margin-top: 60px;

  @media (max-width: 1600px) {
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    padding: 40px 20px;
    margin-top: 0;
  }
}

.signup-create-account-btn {
  margin: 40px 0;
}

.signup-has-account {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  > * {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #646363 !important;
  }
}

.signup-mi-dva-logo-container {
  display: flex;
  justify-content: center;
}
</style>
