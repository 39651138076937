<template>
  <div class="animation-wrapper">
    <v-img v-for="(image, index) in images" :key="index" :src="image" height="340" :lazy-src="lazyImage" />
  </div>
</template>

<script>
export default {
  name: "AnimationWrapper",
  props: {
    images: Array,
    lazyImage: String,
  },

};
</script>

<style lang="scss" scoped>

.animation-wrapper{
  position: relative;
  width: 100%;
  background-color: #F2F2F2 !important;
}

.animation-wrapper >*{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &:nth-child(1){
    animation: hideshow 1s ease-in-out infinite;
  }
  &:nth-child(2){
    opacity: 0;
    animation: hideshow 1s 0.5s ease-in-out infinite;
  }
  &:nth-child(3){
    opacity: 0;
    animation: hideshow 1s 0.85s ease-in-out infinite;
  }
}

@keyframes hideshow {
  0% { opacity: 1; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

</style>
