<template>
  <div class="details-container">
    <div class="card-background">
      <InvestFundCard
        :fundRun="fundData.fundRun"
        class="fund-card"
        :canInvest="canInvest"
      />
    </div>
    <div class="description-container">
      <div class="what-is">
        {{ $i18n.t("fundDetails.whatIs") }}
      </div>
      <div class="description">
        {{ $i18n.t(`fund.description.${fundData.fundRun}`) }}
      </div>
    </div>

    <div class="table-container">
      <div class="table-title">
        {{ tableTitle }}
      </div>
      <TwoColumnsTable :tableData="tableData" />
    </div>

    <div class="btn-row">
      <CustomButton
        normalSpacing
        outlined
        :text="$i18n.t('fundDetails.fundPage')"
        color="#000000"
        :backgroundColor="$vuetify.breakpoint.xs ? '#FFF' : '#F2F2F2'"
        :borderRadius="'10px'"
        :upperCase="false"
        :capitalize="false"
        :fontSize="'14px'"
        :width="fundPageButtonSizes.width"
        :height="fundPageButtonSizes.height"
        :link="`https://www.dvacapital.com/fondos/${fundData.fundRun}`"
      />
      <CustomButton
        v-if="displayMoreFundsButton"
        normalSpacing
        outlined
        :text="$i18n.t('fundDetails.moreFunds')"
        color="#000000"
        :backgroundColor="$vuetify.breakpoint.xs ? '#FFF' : '#F2F2F2'"
        :borderRadius="'10px'"
        :upperCase="false"
        :capitalize="false"
        :fontSize="'14px'"
        :width="$vuetify.breakpoint.smAndDown ? '45%' : '290px'"
        :height="$vuetify.breakpoint.smAndDown ? '40px' : '50px'"
        @on-click="$emit('goBackStep', $event)"
      />
    </div>
  </div>
</template>

<script>
import CustomButton from "../shared/CustomButton.vue";
import InvestFundCard from "../shared/InvestFundCard.vue";
import TwoColumnsTable from "../shared/TwoColumnsTable.vue";

export default {
  name: "DetailedFund",

  components: { CustomButton, InvestFundCard, TwoColumnsTable },

  props: {
    fundData: {
      type: Object,
      required: true,
      default: () => {},
    },

    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },

    tableTitle: {
      type: String,
      default: "",
    },

    displayMoreFundsButton: {
      type: Boolean,
      default: true,
    },

    canInvest: Boolean,
  },

  computed: {
    fundPageButtonSizes() {
      if (this.displayMoreFundsButton) {
        if (this.$vuetify.breakpoint.smAndDown) return { height: "40px", width: "45%" };
        return { height: "50px", width: "290px" };
      }
      if (this.$vuetify.breakpoint.smAndDown) return { height: "50px", width: "100%" };
      return { height: "50px", width: "290px" };
    },
  },
};
</script>

<style lang="scss" scoped>
.details-container {
  max-width: 650px;
  width: 100%;

  @media (max-width: 600px) {
    background-color: #ffffff;
  }
}

.description-container {
  background-color: #ffffff;
  border-radius: 10px;
  @media (max-width: 600px) {
    background-color: #f2f2f2;
  }
  width: 100%;
  height: 150px;
  padding: 20px 30px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;

  @media (max-width: 600px) {
    height: inherit;
    padding: 40px 20px;
    margin: 0;
  }

  @media (max-width: 400px) {
    padding: 30px 20px;
  }

  @media (max-width: 380px) {
    padding: 10px 20px;
  }
}
.what-is {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #333333;
}
.description {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  margin-top: 10px;
}

.table-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #333333;
  padding: 20px 30px;
  @media (max-width: 600px) {
    padding: 20px;
  }
}

.table-container {
  background-color: #ffffff;
  width: 100%;
  border-radius: 10px;
  padding-bottom: 20px;
  @media (max-width: 600px) {
    padding-bottom: 0;
  }
}
.fund-card {
  padding: 20px;
}

.card-background {
  background-color: #ffffff;
  border-radius: 10px;
  @media (max-width: 600px) {
    padding: 10px 0;
  }
}

.btn-row {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  @media (max-width: 1264px) {
    padding-bottom: 80px;
  }

  @media (max-width: 600px) {
    margin: 30px 0;
    padding: 0 20px 80px 20px;
  }
  @media (max-width: 395px) {
    margin: 20px 0;
  }

  @media (max-width: 370px) {
    padding: 0 10px 80px 10px;
  }
}
</style>
