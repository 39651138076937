<template>
  <div class="profile-wrapper profile-centered-wrapper">
    <div class="profile-content">
      <h3 class="profile-title">
        {{ $t("profile.compassCompleteData.titles.beforeContract") }}
      </h3>

      <div class="profile-text">
        {{ $t("profile.compassCompleteData.text") }}
      </div>

      <div class="button-row">
        <NewButton
          :text="$t('profile.compassCompleteData.buttons.complete')"
          @on-click="$router.push({ name: 'account' })"
        />

        <NewButton
          button-class="black-outlined"
          :text="$t('profile.compassCompleteData.buttons.later')"
          @on-click="$router.push({ name: 'home' })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "CompassCompleteDataBeforeContract",

  components: { NewButton },

};
</script>

<style lang="scss" scoped>

.button-row > *:not(:first-child){
  margin-top: 20px;
}

.profile-title{
    font-size: 18px;
    line-height: 28.8px;
}

.profile-text{
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    color: #333;
    margin-bottom: 40px;

}

</style>
