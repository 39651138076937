<template>
  <div class="transactions-container" v-if="loadingTransactions || hasTransactions || displayRecentInvestmentAlert ">
    <v-skeleton-loader
      v-if="loadingTransactions"
      type="table-row-divider, table-row-divider,table-row-divider"
      class="mx-5 mt-10"
    />
    <div v-else-if="hasTransactions || displayRecentInvestmentAlert">
      <SectionTitle :text="$t('transactions.title')" class="transactions-title" />
      <div
        v-if="displayRecentInvestmentAlert"
        id="recent-investment-alert"
        class="recent-investment-alert"
        @click="$router.go(0)"
      >
        <v-icon color="#fff" size="16" class="refresh-icon"> mdi-refresh </v-icon>

        <div class="recent-investment-alert-text">
          <span>
            {{ $t("transactions.recentInvestmentAlert.processing") }}
          </span>

          <span>
            {{ $t("transactions.recentInvestmentAlert.refreshHere") }}
          </span>
        </div>
      </div>

      <div v-if="hasTransactions">
        <table class="transactions-table">
          <thead>
            <tr>
              <th id="type-col">{{ $t("transactions.table.type") }}</th>
              <th id="fund-col">{{ $t("transactions.table.fund") }}</th>
              <th id="status-col">{{ $t("transactions.table.status") }}</th>
              <th id="amount-col">{{ $t("transactions.table.amount") }}</th>
            </tr>
          </thead>
          <PendingMovements />
          <OngoingReinvestments />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SectionTitle from "./SectionTitle.vue";
import OngoingReinvestments from "./Transactions/OngoingReinvestments.vue";
import PendingMovements from "./Transactions/PendingMovements.vue";

export default {
  name: "TransactionsSection",

  components: {
    SectionTitle,
    OngoingReinvestments,
    PendingMovements,
  },

  computed: {
    ...mapGetters([
      "hasTransactions",
      "loadingTransactions",
      "displayRecentInvestmentAlert",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.transactions-container {
  container-type: inline-size;
}
@container (max-width: 960px) {
  thead {
    display: none;
  }

  .recent-investment-alert {
    align-items: flex-start !important;
  }

  .recent-investment-alert-text {
    display: flex;
    flex-direction: column;
  }

  .refresh-icon {
    margin-top: 2px;
  }
}

@container (min-width: 1280px) {
  th#fund-col {
    min-width: 168px !important;
  }
}
@container (min-width: 601px) {
  .recent-investment-alert-text {
    display: contents;
  }

  th#fund-col {
    min-width: 130px !important;
  }
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
}

tr th {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #646363;
}

thead tr th {
  text-align: left;
}

thead tr th:last-child {
  text-align: right;
}

.recent-investment-alert {
  background-color: #6d48ff;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 0 5px;
  border-radius: 10px;
  margin-bottom: 40px;

  span {
    font-weight: 500;
    font-size: 11px;
    line-height: 160%;
    color: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
