export const imagesFunctions = {

  methods: {
    handleImageSize(image, imageVariableName) {
      if (!image) return;
      this.compressImage(image, 0.9, 90, this.compressingImageCallback, {
        imageVariableName,
      });
    },

    compressImage(imageToCompress, resizingFactor, qualityPercentage, callback, callbackArgs) {
      /**
             * GENERAL STEPS:
             * 1) Read the original image file
             * 2) Convert the original image file into a compressed URL
             * 3) Convert the compressed URL into a new image file
             */

      /** Converting imageToCompress into a readable URL in base64 */
      const reader = new FileReader();
      reader.readAsDataURL(imageToCompress);
      /** If the img is lighter than 1mb, we're not going to compress  */
      if (imageToCompress?.size < 1000000) {
        callback(imageToCompress, { callbackArgs });
        return;
      }
      const imageName = this.$options.filters.removeExtension(imageToCompress.name);
      /** When the reader is loader, we need to create an img tag */
      reader.onload = event => {
        const imageUrl = event.target.result;
        const image = document.createElement("img");
        image.src = imageUrl;
        /** Creating a canvas with new dimensions ajusted by the resizing factor */
        image.onload = event => {
          const canvas = document.createElement("canvas");
          const originalSize = { width: event.target.width, height: event.target.height };
          canvas.width = originalSize.width * resizingFactor;
          canvas.height = originalSize.height * resizingFactor;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          /** We need another image tag and set it's URL */
          const newImageUrl = context.canvas.toDataURL(
            "image/jpeg",
            qualityPercentage,
          );
          const newImage = document.createElement("img");
          newImage.src = newImageUrl;
          /** The file type needs to be removed from data URL
                 *  to keep only the base64 code
                */
          const imageFile = this.urlToFile(newImageUrl, imageName);
          callback(imageFile, { callbackArgs });
          return imageFile;
        };
      };
    },

    compressingImageCallback(compressedImage, { callbackArgs }) {
      this.$data[callbackArgs.imageVariableName] = compressedImage;

      return compressedImage;
    },

    urlToFile(url, fileName) {
      const imageDataArray = url.split(",");
      const imageFormat = imageDataArray[0].match(/:(.*?);/)[1];
      const imageUrl = imageDataArray[1];
      /** Decoding base64 url */
      const unencryptedUrl = window.atob(imageUrl);
      let urlLength = unencryptedUrl.length;
      const dataArray = new Uint8Array(urlLength);
      // eslint-disable-next-line no-plusplus
      while (urlLength--) {
        dataArray[urlLength] = unencryptedUrl.charCodeAt(urlLength);
      }
      const imageFile = new File([dataArray], `${fileName}_compressed.jpg`, { type: imageFormat });
      return imageFile;
    },
  },

};
