<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="signup-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="signup-declarations-title">
        {{ $t("signup.profiling.declarations.title") }}
      </h3>
      <p class="profiling-question">
        {{ $t("signup.profiling.declarations.netWorth.question") }}
      </p>
      <div
        v-for="(netWorthOption, index) in netWorthOptions"
        :id="`net-worth-${netWorthOption.value}`"
        :key="index"
        class="profiling-option-container"
        @click="onClickProfilingCustomerNetWorth(netWorthOption.value)"
      >
        <p class="profiling-option-text">
          {{
            $t(
              `signup.profiling.declarations.netWorth.options.${netWorthOption.value}`,
            )
          }}
        </p>
      </div>
      <div
        class="signup-cancel-container"
        @click="$router.push({ name: 'signupDashboard' })"
      >
        <p class="signup-cancel">
          {{ $t("signup.profiling.shared.cancel") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import userSelects from "@/mixins/userSelects.js";

export default {
  name: "ProfilingNetWorth",

  mixins: [userSelects],

  methods: {
    ...mapActions(["setProfilingCustomerNetWorth"]),

    onClickProfilingCustomerNetWorth(option) {
      this.setProfilingCustomerNetWorth(option);
      this.$router.push({ name: "signupSalaryRange" });
    },
  },
};
</script>

<style lang="scss" scoped>
.profiling-question {
  font-weight: 500;
  font-size: 18px;
  line-height: 28.8px;
  color: #333333;
  padding: 20px 0px;
  @media (max-width: 600px) {
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

.profiling-option-container {
  background-color: #ffffff;
  border-radius: 10px;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}

.profiling-option-container + .profiling-option-container {
  margin-top: 20px;
}

.profiling-option-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin: 0px;
}
</style>
