<template>
  <div class="desktop-balance-card">
    <div class="balance-row">
      <div class="current-balance">
        <div class="label center-in-page">
          {{ $t("home.welcome.balanceCard.heading") }}
        </div>
        <div v-if="loading" class="skeleton-container">
          <v-skeleton-loader type="text" width="150" />
        </div>
        <div v-else-if="emptyBalances" class="balance amount-box">
          ${{ 0 | currencyNoSymbol }}
        </div>
        <div v-else class="balance">${{ currentBalance | currencyNoSymbol }}</div>
      </div>

      <div class="results-details">
        <div :class="[withdrawals ? 'customer-results' : 'customer-single-result']">
          <div class="current" v-if="loading || (!loading && withdrawals)">
            <div class="label center-in-page">
              {{
                $t(
                  `home.welcome.balanceCard.current${
                    currentResults > 0 ? "Profits" : "Results"
                  }`,
                )
              }}
            </div>
            <div v-if="!loading" class="results-amount amount-box">
              {{ currentResults >= 0 ? "+" : "-" }}${{
                Math.abs(currentResults || 0) | currencyNoSymbol
              }}
            </div>
            <div v-else class="skeleton-container">
              <v-skeleton-loader type="text" width="150" />
            </div>
          </div>

          <div class="historic">
            <div class="label center-in-page">
              {{
                $t(
                  `home.welcome.balanceCard.${
                    !withdrawals && !loading ? "only" : "historic"
                  }${historicalResults > 0 ? "Profits" : "Results"}`,
                )
              }}
            </div>
            <div v-if="!loading" class="results-amount amount-box">
              {{ historicalResults >= 0 ? "+" : "-" }}${{
                Math.abs(historicalResults || 0) | currencyNoSymbol
              }}
            </div>
            <div v-else class="skeleton-container">
              <v-skeleton-loader type="text" width="150" />
            </div>
          </div>
        </div>
      </div>
      <div class="button-row">
        <div class="invest-button">
          <NewButton
            :text="$t('base.invest')"
            @on-click="$emit('invest')"
            v-if="!loading"
          />
        </div>

        <div class="details-button">
          <NewButton
            :text="$t('home.welcome.balanceCard.myResults')"
            buttonClass="black-outlined"
            @on-click="$emit('getDetails')"
            v-if="!loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "DesktopBalanceCard",

  components: { NewButton },

  props: {
    loading: {
      type: Boolean,
    },
    currentBalance: {
      type: Number,
    },
    withdrawals: {
      type: Number,
    },
    emptyBalances: {
      type: Boolean,
    },
    currentResults: {
      type: Number,
    },
    historicalResults: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.balance {
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #333333;
  height: 52px;
  @media (max-width: 1500px) {
    font-size: 28px;
  }

  @media (max-width: 1280px) {
    font-size: 24px;
  }
}

.skeleton-container {
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-balance-card {
  height: 180px;
  padding: 0 80px;
  @media (max-width: 1400px) {
    padding: 0 40px;
  }
}

.balance-row {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0 80px;

  @media (max-width: 1500px) {
    gap: 0 40px;
  }
}

.current-balance {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.customer-results {
  display: flex;
  gap: 0 20px;
  white-space: nowrap;
  align-items: center;
  justify-content: space-around;
}

.customer-results .amount-box {
  min-width: 160px !important;
  justify-content: center;
  @media (max-width: 1500px) {
    min-width: 120px !important;
  }
}

.customer-single-result {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 40px;
}

.results-amount {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
}

.label {
  font-size: 14px;
  line-height: 16px;
  color: #646363;
  font-weight: 500;
}

.results-container {
  display: flex;
  white-space: nowrap;
}

.results-details {
  display: flex;
  justify-content: center;
  gap: 0 20px;
  align-items: center;
}
.amount-box {
  height: 52px;
  display: flex;
  align-items: center;
  text-align: center;
}

.button-row {
  display: flex;
  gap: 0 20px;
}

.button-row > * {
  width: 150px;
  @media (max-width: 1280px) {
    width: 120px;
  }
}
</style>
