<template>
  <div class="profile-wrapper">
    <div class="profile-step-back">
      <v-icon color="#333333" size="22" @click="$router.push({ name: 'account' })">
        mdi-arrow-left
      </v-icon>
    </div>
    <h3 class="profile-title">
      {{ $t("profile.riskProfile.title") }}
    </h3>

    <RiskProfileCard
      showProfilingResultTitle
      :actionButtonText="$t(`base.${makeProfilingTest ? 'makeTest' : 'modify'}`)"
      @button-clicked="handleRedirection()"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import RiskProfileCard from "@/components/profile/RiskProfileCard.vue";

export default {
  name: "RiskProfile",

  components: { RiskProfileCard },

  computed: {
    ...mapGetters(["customerRiskProfile"]),

    makeProfilingTest() {
      return this.customerRiskProfile === "none";
    },
  },

  methods: {
    handleRedirection() {
      const targetRoute = this.makeProfilingTest ?
        "accountProfilingStart" :
        "accountEditRiskProfile";

      this.$router.push({ name: targetRoute });
    },
  },
};
</script>
