<template>
  <div class="redemption-wrapper redemption-centered-wrapper">
    <div class="redemption-content">
      <div class="redemption-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <h2 class="redemption-header">
        {{ $t("redemptions.confirmBankAccount.title") }}
      </h2>

      <div class="redemption-flex-columns">
        <div class="redemption-column">
          <ManageCustomerBankAccount
            @onConfirm="$router.push({ name: 'redemptionSummary' })"
            @onCancel="$router.push({ name: 'home' })"
          />
        </div>

        <div class="redemption-column">
          <RedemptionHints />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageCustomerBankAccount from "@/components/shared/ManageCustomerBankAccount.vue";
import RedemptionHints from "@/components/redemptions/RedemptionHints.vue";

export default {
  name: "ConfirmBankAccount",

  components: {
    RedemptionHints,
    ManageCustomerBankAccount,
  },
};
</script>

<style lang="scss" scoped>
.redemption-content {
  max-width: 870px;
}
</style>
