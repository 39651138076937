<template>
  <div class="custom-phone-input">
    <div class="label-row" v-if="label">
      <div class="missing-value-notification" v-if="displayNotification">
        <v-icon color="#6D48FF" size="16">
          mdi-alert-circle
        </v-icon>
      </div>
      <div class="label-text">
        {{ label }}
      </div>
    </div>
    <vue-tel-input
      id="phone-input"
      :inputOptions="{ ...inputOptions }"
      :dropdownOptions="{
        ...dropdownOptions,
      }"
      :enabledCountryCode="false"
      :validCharactersOnly="true"
      :autoFormat="false"
      v-model="parsedPhone"
      mode="international"
      autocomplete="off"
      defaultCountry="CL"
      required
      class="vue-tel-input"
      @validate="validatePhone"
      @blur="
        setOutlined(false);
        phoneErrorHint = true;
      "
      @focus="
        setOutlined(true);
        phoneErrorHint = false;
      "
      ref="phone"
      :class="{
        'v-input v-text-field v-input--is-label-active v-input--is-dirty': true,
        'theme--light v-text-field--outlined v-text-field--rounded': true,
        'v-input--is-disabled': disabled,
        'theme--light.v-input--is-disabled': disabled,
      }"
    />

    <div id="phone-error" class="phone-error-hints" v-if="!phoneValid && phoneErrorHint">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PhoneInput",

  data: () => ({
    phoneErrorHint: false,
    phoneValid: false,
  }),

  props: {
    phone: String,
    dropdownOptions: {
      type: Object,
      default: () => {},
    },
    inputOptions: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: String,
    label: String,
    displayNotification: Boolean,
  },

  computed: {
    parsedPhone: {
      get() {
        return this.phone;
      },
      set(phone) {
        this.$emit("updatePhone", phone.replace(/\s/g, ""));
      },
    },
  },

  methods: {
    /** Custom method to set outlined on focus to vue-tel-input */
    setOutlined(setStyle) {
      const phoneInput = document.getElementById("phone-input");
      if (setStyle) return phoneInput.classList.add("outlined-field");
      return phoneInput.classList.remove("outlined-field");
    },

    validatePhone(values) {
      this.phoneValid = values.valid;
      this.$emit("setPhoneData", { ...values });
    },
  },
};
</script>

<style lang="scss">
.custom-phone-input{
  .vue-tel-input {
    background-color: #fff !important;
    border: 1px solid #e2e0e0;
    height: 60px;
    box-shadow: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 10px !important;
  }

  span.vti__selection{
    font-size: 14px !important;
  }

  .outlined-field {
    border: 1px solid #000 !important;
  }

  .phone-error-hints {
    color: #ff1744 !important;
    margin-top: -12px;
    margin-bottom: 8px;
    font-size: 12px;
    min-height: 14px;
    padding: 0 12px;
  }

  .label-row{
    display: flex;
    gap: 0 5px;
    margin-bottom: 10px;
  }

  .label-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    color: #333;
  }
}

</style>
