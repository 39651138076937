<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="signup-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <h2 class="signup-title">
        {{ $t("signup.identification.title") }}
      </h2>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="saveIdentificationData()"
        class="signup-form"
      >
        <div class="signup-form-label">
          {{ $t("signup.identification.contact.fields.phone.label") }}
        </div>
        <PhoneInput
          :dropdownOptions="{
            width: $vuetify.breakpoint.smAndUp ? '420px' : '330px',
            showFlags: true,
            showDialCodeInSelection: true,
            showDialCodeInList: true,
          }"
          :inputOptions="{
            placeholder: $t('signup.identification.contact.fields.phone.placeholder'),
          }"
          :errorMessage="$t('signup.identification.contact.fields.phone.invalidPhone')"
          @setPhoneData="setPhoneData"
        />

        <AutoComplete
          id="region"
          :value="region"
          :items="regions"
          item-text="name"
          item-value="name"
          :rules="mustCompleteRules"
          :label="$t('signup.identification.contact.fields.address.label')"
          :placeholder="$t('signup.identification.contact.fields.address.region')"
          @updateValue="handleRegionSelection"
          class="signup-labeled-input-field signup-input-field"
        />

        <AutoComplete
          id="locality"
          :value="locality"
          :items="localitiesList"
          :rules="mustCompleteRules"
          :placeholder="$t('signup.identification.contact.fields.address.locality')"
          @updateValue="handleLocalitySelection"
          class="signup-input-field"
        />

        <TextField
          :value="street"
          :placeholder="$t('signup.identification.contact.fields.address.street')"
          :rules="mustCompleteRules"
          @updateValue="street = $event"
          class="signup-input-field"
        />

        <TextField
          :value="houseNumber"
          :placeholder="$t('signup.identification.contact.fields.address.houseNumber')"
          :rules="mustCompleteRules"
          @updateValue="houseNumber = $event"
          class="signup-input-field"
        />

        <TextField
          :value="interiorNumber"
          :placeholder="$t('signup.identification.contact.fields.address.interiorNumber')"
          @updateValue="interiorNumber = $event"
          class="signup-input-field"
        />

        <NewButton
          id="next-step"
          class="signup-step-button"
          :class="{
            'signup-success-btn': signupStatus.completedIdentification || successState,
          }"
          :text="
            signupStatus.completedIdentification || successState
              ? $t('signup.identification.contact.button.saved')
              : $t('signup.identification.contact.button.saveAndNext')
          "
          :type="'submit'"
          :loading="loading"
          :disabled="!valid || loading || !phoneValid"
        />

        <div
          class="signup-cancel-container"
          @click="$router.push({ name: 'signupDashboard' })"
        >
          <p class="signup-cancel">
            {{ $t("base.cancel") }}
          </p>
        </div>
      </v-form>

      <ReusableDialog
        v-model="errorDialog"
        :title="$t('signup.identification.errors.title')"
        :text="$t('signup.identification.errors.message')"
        @display="errorDialog = $event"
      >
        <template v-slot:buttonRow>
          <div class="signup-button-row">
            <div class="signup-chat-btn">
              <NewButton :text="$t('base.goToChat')" @on-click="openChat()" />
            </div>

            <div class="keep-trying-btn">
              <NewButton
                :text="$t('base.keepTrying')"
                :loading="loading"
                @on-click="errorDialog = false"
              />
            </div>
          </div>
        </template>
      </ReusableDialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewButton from "@/components/shared/NewButton.vue";
import { addressSelects } from "@/mixins/addressSelects.js";
import userSelects from "@/mixins/userSelects.js";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import TextField from "@/components/shared/TextField.vue";
import AutoComplete from "@/components/shared/AutoComplete.vue";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";

export default {
  name: "ContactView",

  components: {
    NewButton,
    PhoneInput,
    ReusableDialog,
    TextField,
    AutoComplete,
  },

  mixins: [addressSelects, userSelects, customersApiFunctions],

  data() {
    return {
      valid: false,
      loading: false,
      place: null,
      mapOptions: {
        mapTypeControlOptions: {
          mapTypeIds: [],
        },
        fullscreenControl: false,
        streetViewControl: false,
      },
      phone: null,
      phoneValid: false,
      street: null,
      region: null,
      locality: null,
      houseNumber: null,
      country: "CHL",
      interiorNumber: null,
      phoneErrorHint: false,
      mustCompleteRules: [v => !!v || this.$t("signup.shared.errors.mustComplete")],
      numberRules: [
        v => !!v || this.$t("signup.shared.errors.mustComplete"),
        v => /^[-+]?\d*$/.test(v) || this.$t("signup.shared.errors.generic"),
      ],
      errorDialog: false,
      successState: false,
    };
  },

  computed: {
    ...mapGetters([
      "signupIdentificationData",
      "signupEmail",
      "signupStatus",
      "mutationErrors",
    ]),

    localitiesList() {
      if (!this.region) {
        return this.regions
          .map(region => region.localities)
          .flat(1)
          .sort();
      }
      return this.regions
        .find(region => region.name === this.region)
        ?.localities.sort();
    },
  },

  methods: {
    ...mapActions(["setContactData", "saveMutationError", "deleteFromMutationErrors"]),

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    async handleRedirection() {
      await this.showSuccessState();

      this.$router.push({ name: "signupProfilingIntro" });
    },

    handleMutationErrors(response) {
      this.loading = false;

      // if previosly there was an error in the mutation, redirect to signupDashboard
      if (
        this.mutationErrors.some(error => error?.name === "signUpIdentification") &&
        !this.signupStatus.completedProfiling
      ) {
        this.$router.push({ name: "signupDashboard" });
        return;
      }

      if (response && response.error) {
        // save error response in vuex
        this.saveMutationError({
          code: response?.errorCode,
          name: "signUpIdentification",
          view: this.$options.name,
        });
      }
    },

    handleSuccessfulMutation() {
      // If the response was successful, we need to check if there is a previous mutation error
      // if there is, we need to remove it from the vuex
      if (this.mutationErrors.some(error => error?.name === "signUpIdentification")) {
        this.deleteFromMutationErrors("signUpIdentification");
      }
    },

    buildPersonalData() {
      const { removeDots } = this.$options.filters;
      return {
        name: this.signupIdentificationData.name,
        lastName: `${this.signupIdentificationData.fatherFamilyName} ${this.signupIdentificationData.motherFamilyName}`,
        gender: this.signupIdentificationData.gender,
        birthdate: this.signupIdentificationData.birthdate,
        birthCountry: this.signupIdentificationData.birthCountry,
        nationality: this.signupIdentificationData.nacionality,
        maritalStatus: this.signupIdentificationData.maritalStatus,
        spouseName: this.signupIdentificationData.spouseName,
        spouseLastName: this.signupIdentificationData.spouseFamilyName,
        spouseIdentifier: removeDots(this.signupIdentificationData.spouseIdentifier),
        conjugalRegime: this.signupIdentificationData.conjugalRegime,
      };
    },

    buildContactData() {
      const {
        phone,
        country,
        region,
        locality,
        street,
        houseNumber,
        interiorNumber,
      } = this;

      return {
        phone,
        country,
        region,
        locality,
        street,
        houseNumber,
        interiorNumber,
      };
    },

    async saveIdentificationData() {
      if (this.signupStatus.completedIdentification || this.loading) return;
      this.loading = true;

      try {
        // builds the personal and contact data
        const personalData = this.buildPersonalData();
        const contactData = this.buildContactData();

        this.setContactData({
          ...contactData,
        });

        // builds the payload
        const payload = {
          email: this.signupEmail,
          ...personalData,
          ...contactData,
        };

        const signUpIdentificationResponse = await this.signUpIdentification(payload);
        this.loading = false;

        if (signUpIdentificationResponse.error) {
          this.handleMutationErrors(signUpIdentificationResponse);
        } else {
          this.handleSuccessfulMutation();
        }
        this.handleRedirection();
      } catch (error) {
        console.error(error);
        this.errorDialog = true;
      }
    },

    handleRegionSelection(regionName) {
      this.region = regionName;
      this.locality = null;
    },

    handleLocalitySelection(locality) {
      if (!locality) return;
      this.region = this.regions.find(region => region?.localities.includes(locality))?.name;
      this.locality = locality;
    },

    setPhoneData(data) {
      this.phoneValid = data.valid;
      this.phone = data.number;
    },

    reloadData() {
      if (this.signupIdentificationData.street) {
        this.street = this.signupIdentificationData.street;
      }
      if (this.signupIdentificationData.houseNumber) {
        this.houseNumber = this.signupIdentificationData.houseNumber;
      }
      if (this.signupIdentificationData.region) {
        this.region = this.signupIdentificationData.region;
      }
      if (this.signupIdentificationData.locality) {
        this.locality = this.signupIdentificationData.locality;
      }
      if (this.signupIdentificationData.interiorNumber) {
        this.interiorNumber = this.signupIdentificationData.interiorNumber;
      }
    },

    openChat() {
      this.$intercom.showNewMessage(
        this.$t("signup.identification.errors.intercom.failedResponse"),
      );
    },
  },

  mounted() {
    this.reloadData();
  },
};
</script>

<style lang="scss" scoped>
.spouse-fields > :not(:last-child) {
  margin-bottom: 10px;
}

.signup-chat-btn::v-deep .new-btn {
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  span {
    color: #000000 !important;
  }
}

.signup-success-btn {
  background-color: #6d48ff !important;
  border: unset;
}
</style>
