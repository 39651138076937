<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="signup-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h2 class="signup-title">
        {{$t("signup.identification.title") }}
      </h2>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="saveUserInfo"
        class="signup-form"
      >

        <TextField
          id="name"
          :value="name"
          :placeholder="$t('signup.identification.personal.fields.name.placeholder')"
          :rules="nameRules"
          @updateValue="name = $event"
          class="signup-input-field"
        />

        <TextField
          id="father-family-name"
          :value="fatherFamilyName"
          :placeholder="$t('signup.identification.personal.fields.fatherFamilyName.placeholder')"
          :rules="nameRules"
          @updateValue="fatherFamilyName = $event"
          class="signup-input-field"
        />

        <TextField
          id="mother-family-name"
          :value="motherFamilyName"
          :placeholder="$t('signup.identification.personal.fields.motherFamilyName.placeholder')"
          :rules="nameRules"
          @updateValue="motherFamilyName = $event"
          class="signup-input-field"
        />

        <BirthdateInputs
          :label="$t('signup.identification.personal.fields.birthdate.label')"
          :day="day"
          :month="month"
          :year="year"
          @updateDay="day = $event"
          @updateMonth="month = $event"
          @updateYear="year = $event"
          @setDateValidity="validBirthdate = $event"
          class="signup-labeled-input-field"
        />

        <AutoComplete
          id="birth-country"
          :value="birthCountry"
          :items="countries"
          :rules="countryRules"
          :label="$t('signup.identification.personal.fields.birthCountry.label')"
          :placeholder="$t('signup.identification.personal.fields.birthCountry.placeholder')"
          item-text="country"
          item-value="code"
          @updateValue="birthCountry = $event"
          class="signup-labeled-input-field"
        />

        <AutoComplete
          id="nationality"
          :value="nationality"
          :items="countries"
          :rules="countryRules"
          :label="$t('signup.identification.personal.fields.nationality.label')"
          :placeholder="$t('signup.identification.personal.fields.nationality.placeholder')"
          item-text="country"
          item-value="code"
          @updateValue="nationality = $event"
          class="signup-labeled-input-field"
        />

        <MultiButtonPicker
          id="gender"
          :items="availableGenders"
          :value="gender"
          :label="$t('signup.identification.personal.fields.gender.label')"
          item-text="sex"
          item-value="value"
          @selectItem="gender = $event"
          class="signup-labeled-input-field"
        />

        <AutoComplete
          id="marital-status"
          :value="maritalStatus"
          :items="availableMaritalStatus"
          item-text="status"
          item-value="value"
          :rules="maritalRules"
          :label="$t('signup.identification.personal.fields.maritalStatus.label')"
          :placeholder="$t('signup.identification.personal.fields.maritalStatus.placeholder')"
          @updateValue="maritalStatus = $event"
          class="signup-labeled-input-field"
        />

        <NewButton
          id="next-step"
          class="signup-step-button"
          :text="$t('signup.shared.buttons.next')"
          :loading="loading"
          :disabled="nextButtonDisabled"
          :type="'submit'"
        />

        <div
          class="signup-cancel-container"
          @click="$router.push({ name: 'signupDashboard' })"
        >
          <p class="signup-cancel">
            {{$t("base.cancel") }}
          </p>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import TextField from "@/components/shared/TextField.vue";
import userSelects from "@/mixins/userSelects.js";
import { signupFunctions } from "@/mixins/signup.js";
import NewButton from "@/components/shared/NewButton.vue";
import AutoComplete from "@/components/shared/AutoComplete.vue";
import MultiButtonPicker from "@/components/shared/MultiButtonPicker.vue";
import BirthdateInputs from "@/components/shared/BirthdateInputs.vue";

dayjs.extend(customParseFormat);

export default {
  name: "PersonalView",

  components: {
    NewButton, AutoComplete, TextField, MultiButtonPicker, BirthdateInputs,
  },

  mixins: [userSelects, signupFunctions],

  data() {
    return {
      valid: false,
      loading: false,
      // Values
      name: null,
      fatherFamilyName: null,
      motherFamilyName: null,
      gender: null,
      day: null,
      month: null,
      year: null,
      validBirthdate: false,
      nationality: "CHL",
      birthCountry: "CHL",
      maritalStatus: "SINGLE",
      nameRules: [v => !!v || this.$t("signup.shared.errors.mustComplete")],
      countryRules: [
        v => !!v || this.$t("signup.shared.errors.mustComplete"),
        v => this.countries.some(el => el.code === v) ||
          this.$t("signup.personal.errors.country.valid"),
      ],
      maritalRules: [
        v => !!v || this.$t("signup.shared.errors.mustComplete"),
        v => this.availableMaritalStatus.some(el => el.value === v) ||
          this.$t("signup.shared.errors.generic"),
      ],
    };
  },

  computed: {
    ...mapGetters(["signupIdentificationData"]),

    hasSpouse() {
      return this.maritalStatus === "MARRIED" || this.maritalStatus === "CIVIL_UNION";
    },

    birthdate() {
      if (this.year && this.month && this.day) {
        return `${this.year}-${this.month.padStart(2, "0")}-${this.day.padStart(2, "0")}`;
      }

      return null;
    },

    isChildren() {
      if (!this.birthdate) return false;

      const age = this.$options.filters.yearsOld(this.birthdate);
      return age < 18;
    },

    nextButtonDisabled() {
      return (
        !this.valid || !this.validBirthdate || this.isChildren || this.gender === null
      );
    },

  },

  methods: {
    ...mapActions(["setPersonal"]),

    async saveUserInfo() {
      this.loading = true;

      this.setPersonal({
        name: this.name,
        fatherFamilyName: this.fatherFamilyName,
        motherFamilyName: this.motherFamilyName,
        familyName: `${this.fatherFamilyName} ${this.motherFamilyName}`,
        gender: this.gender,
        birthdate: this.birthdate,
        birthCountry: this.birthCountry,
        nationality: this.nationality,
        maritalStatus: this.maritalStatus,
      });

      this.loading = false;
      this.$intercom.trackEvent(this.$t("signup.intercom.personal.event"), {
        status_registro: this.$t("signup.intercom.personal.message"),
      });

      if (this.hasSpouse) {
        return this.$router.push({ name: "signupIdentificationSpouse" });
      }
      return this.$router.push({ name: "signupIdentificationContact" });
    },

    reloadData() {
      if (this.signupIdentificationData.name) {
        this.name = this.signupIdentificationData.name;
      }
      if (this.signupIdentificationData.fatherFamilyName) {
        this.fatherFamilyName = this.signupIdentificationData.fatherFamilyName;
      }
      if (this.signupIdentificationData.motherFamilyName) {
        this.motherFamilyName = this.signupIdentificationData.motherFamilyName;
      }
      if (this.signupIdentificationData.gender) {
        this.gender = this.signupIdentificationData.gender;
      }
      if (this.signupIdentificationData.birthdate) {
        const dateObject = this.$dayjs(this.signupIdentificationData.birthdate, "YYYY-MM-DD", true).toDate();

        this.year = dateObject.getFullYear().toString();
        this.month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        this.day = (dateObject.getDate()).toString().padStart(2, "0");
      }
      if (this.signupIdentificationData.maritalStatus) {
        this.maritalStatus = this.signupIdentificationData.maritalStatus;
      }
      if (this.signupIdentificationData.birthCountry) {
        this.birthCountry = this.signupIdentificationData.birthCountry;
      }
      if (this.signupIdentificationData.nacionality) {
        this.nationality = this.signupIdentificationData.nacionality;
      }
    },
  },

  mounted() {
    this.reloadData();
  },
};
</script>

<style lang="scss" scoped></style>
