<template>
  <div
    class="non-invested-fund-card clickable-item"
    :style="`border: 1px solid ${borderColor};`"
  >
    <FundBadge :fundRun="Number(fundRun)" :size="80" :iconSize="40" />
    <div class="fund-content">
      <div class="fund-name">{{ fundRun | fundShortName }}</div>
      <div class="fund-short-description">
        {{ $i18n.t(`fund.shortDescription.${fundRun}`) }}
      </div>
      <div class="fund-tags">
        <div
          v-for="(tag, index) in fundTags"
          :key="index"
          class="fund-tag center-in-page"
        >
          <span class="fund-tag-text"> {{ tag }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import funds from "../../mixins/funds.js";
import FundBadge from "../shared/FundBadge.vue";

export default {
  name: "NonInvestedFundCard",

  components: { FundBadge },

  props: {
    fundRun: {
      type: String,
      required: true,
    },
    borderColor: {
      type: String,
    },
  },

  mixins: [funds],

  computed: {
    fundTags() {
      const staticData = this.getFundStaticData(this.fundRun);
      return [
        staticData.currency,
        this.$i18n.t(`fund.fundType.${this.fundRun}`),
        this.$i18n.t(`fund.shortHorizon.${this.fundRun}`),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.non-invested-fund-card {
  border-radius: 10px;
  padding: 10px;
  height: 100px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  background-color: #ffffff;
}

.fund-content {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  justify-content: space-around;
}

.fund-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #333333;
  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 23px;
  }
}

.fund-short-description {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #333333;
  @media (max-width: 370px) {
    font-size: 10px;
  }
}

.fund-tags {
  display: flex;
  gap: 0.5rem;
  @media (max-width: 370px) {
    gap: 0.3rem;
  }
}
.fund-tag {
  margin: 0px;
  height: 20px;
  border-radius: 50px;
  background-color: #f2f2f2;
}

.fund-tag-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  color: #646363;
  padding: 0 10px;
  white-space: nowrap;
  @media (max-width: 370px) {
    font-size: 9px;
  }
}
</style>
