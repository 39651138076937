<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="signup-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="signup-declarations-title">
        {{ $t("signup.profiling.declarations.title") }}
      </h3>

      <v-form class="signup-form" v-model="validForm">
        <div
          v-for="(declaration, index) in declarationQuestionList"
          :key="index"
          class="declaration-option"
        >
          <MultiButtonPicker
            :label="$t(`signup.profiling.otherDeclarations.${declaration.name}`)"
            :id="declaration.name"
            :items="declarationsOptions"
            :value="declarationAnswers[declaration.name]"
            @selectItem="saveAnswer(declaration.name, $event)"
            item-text="label"
            item-value="value"
            v-if="declaration.formComponent === 'PICKER'"
          />

          <div
            v-if="declaration.name === 'isCrs' && declarationAnswers.isCrs"
            id="tin-declaration"
          >
            <div class="tin-declaration-item">
              <AutoComplete
                :id="`tin-country-${index}`"
                :value="taxIdentificationNumber.country"
                :items="countries"
                item-text="country"
                item-value="code"
                :rules="countryRules"
                :placeholder="$t('signup.profiling.otherDeclarations.tinDeclaration.country')"
                @updateValue="taxIdentificationNumber.country = $event"
                class="signup-input-field"
              />

              <TextField
                :id="`tin-number-${index}`"
                :value="taxIdentificationNumber.identification"
                :placeholder="$t('signup.profiling.otherDeclarations.tinDeclaration.tin')"
                :rules="mustCompleteRules"
                @updateValue="taxIdentificationNumber.identification = $event"
                class="signup-input-field"
              />
            </div>
          </div>

          <div
            v-if="
              declaration.name === 'foreignIncome' && declarationAnswers.foreignIncome
            "
            class="foreign-income-declaration"
          >
            <AutoComplete
              id="capital-origin-country"
              :value="declarationAnswers.capitalOriginCountry"
              :items="countries"
              item-text="country"
              item-value="code"
              :rules="countryRules"
              :label="$t('signup.profiling.otherDeclarations.capitalOriginCountry.label')"
              :placeholder="$t('signup.profiling.otherDeclarations.capitalOriginCountry.placeholder')"
              @updateValue="declarationAnswers.capitalOriginCountry = $event"
              class="signup-input-field"
            />
          </div>
        </div>
      </v-form>
      <NewButton
        id="next-step"
        class="signup-step-button"
        :class="{ 'signup-success-btn': signupStatus.completedProfiling || successState }"
        :text="
          signupStatus.completedProfiling || successState
            ? $t('signup.profiling.otherDeclarations.success')
            : $t('base.finish')
        "
        :loading="loading"
        :disabled="!validForm"
        @on-click="onAcceptDeclarationsClick()"
      />

      <div
        class="signup-cancel-container"
        @click="$router.push({ name: 'signupDashboard' })"
      >
        <p class="signup-cancel">
          {{ $t("signup.profiling.shared.cancel") }}
        </p>
      </div>

      <HorizontalButtonDialog
        :value="errorDialog"
        :title="$t('signup.profiling.otherDeclarations.errorDialog.title')"
        :text="$t('signup.profiling.otherDeclarations.errors.default')"
        :primaryButtonText="$t('signup.profiling.otherDeclarations.errorDialog.primaryButton')"
        :secondaryButtonText="$t('signup.profiling.otherDeclarations.errorDialog.secondaryButton')"
        @primaryClick="errorDialog = false"
        @secondaryClick="openChat()"
        @display="errorDialog = $event"
      />

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import userSelects from "@/mixins/userSelects.js";
import NewButton from "@/components/shared/NewButton.vue";
import AutoComplete from "@/components/shared/AutoComplete.vue";
import TextField from "@/components/shared/TextField.vue";
import HorizontalButtonDialog from "@/components/shared/HorizontalButtonDialog.vue";
import MultiButtonPicker from "@/components/shared/MultiButtonPicker.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";

export default {
  name: "OtherDeclarations",

  mixins: [customersApiFunctions, userSelects],

  components: {
    NewButton, HorizontalButtonDialog, AutoComplete, TextField, MultiButtonPicker,
  },

  data() {
    return {
      errorDialog: false,
      declarationAnswers: {},
      loading: false,
      taxIdentificationNumber: {
        country: null,
        identification: null,
      },
      countryRules: [
        v => !!v || this.$t("signup.shared.errors.mustComplete"),
        v => this.countries.some(el => el.code === v) ||
          this.$t("signup.shared.errors.selectCountry"),
      ],
      mustCompleteRules: [
        v => !!v || this.$t("signup.shared.errors.mustComplete"),
      ],
      validForm: false,
      successState: false,
    };
  },

  computed: {
    ...mapGetters(
      [
        "signupDeclarations",
        "signupProfilingAndLaborData",
        "signupEmail",
        "signupStatus",
        "signupTin",
        "signupProfilingIdentificationError",
        "mutationErrors",
      ],
    ),

    declarationQuestionList() {
      return this.declarations.filter(declaration => declaration.signup);
    },
  },

  methods: {
    ...mapActions(["setProfilingCustomerDeclarations", "saveMutationError"]),

    saveAnswer(declaration, answer) {
      this.declarationAnswers[declaration] = answer;

      // If the user changes to 'No" in foreignIncome declaration, we need to set Chile
      if (declaration === "foreignIncome" && !answer) this.declarationAnswers.capitalOriginCountry = "CHL";

      // If the user changes to 'No" in isCrs declaration, we need to reset to default the tin values
      if (declaration === "isCrs" && !answer) {
        this.taxIdentificationNumber = {
          country: null,
          identification: null,
        };
      }
    },

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    async handleSuccessfulMutation() {
      await this.showSuccessState();

      // To implement: check if gesintel is checking the customer data
      // If the customer is pep, we redirect to the data verification page

      const { isPep } = this.declarationAnswers;

      if (isPep) {
        this.$router.push({ name: "signupDataVerification" });
      } else {
        this.$router.push({ name: "signupDashboard" });
      }
    },

    handleMutationErrors(response) {
      this.loading = false;
      // If the response is an error, we redirect to the error page
      if (response && response.error) {
        // save error response in vuex
        this.saveMutationError({
          code: response?.errorCode,
          name: "signUpProfiling",
          view: this.$options.name,
        });

        this.$router.push({ name: "signupDashboard" });
      }
    },

    buildDeclarationsData() {
      const {
        isPep,
        isCrs,
        isFatca,
        capitalOriginCountry,
        // reinvestDistributions,
      } = this.declarationAnswers;

      const tinCountry = this.taxIdentificationNumber?.country || null;
      const tinIdentification = this.taxIdentificationNumber?.identification || null;

      return {
        isPep,
        isCrs,
        isFatca,
        capitalOriginCountry,
        taxIdentificationNumber: tinCountry && tinIdentification ? {
          country: tinCountry,
          identification: tinIdentification,
        } : {},
        // reinvestDistributions,
      };
    },

    buildProfilingAndLaborData() {
      return { ...this.signupProfilingAndLaborData };
    },

    async onAcceptDeclarationsClick() {
      if (this.signupStatus.completedProfiling || this.loading || this.successState) return;

      try {
        this.loading = true;

        // Constructs the payload to send to the API
        const declarationsData = this.buildDeclarationsData();

        const profilingAndLaborData = this.buildProfilingAndLaborData();

        const payload = {
          email: this.signupEmail,
          ...declarationsData,
          ...profilingAndLaborData,
        };

        // Save the customer declarations in the store
        this.setProfilingCustomerDeclarations(declarationsData);

        const signUpProfilingResponse = await this.signUpProfiling(payload);

        this.loading = false;
        if (signUpProfilingResponse.error) {
          this.handleMutationErrors(signUpProfilingResponse);
        } else {
          this.handleSuccessfulMutation();
        }
      } catch (error) {
        console.error(error);
        this.errorDialog = true;
      }
    },

    openChat() {
      this.$intercom.showNewMessage(this.$t("signup.profiling.errors.intercom"));
    },

    initializeTin() {
      this.taxIdentificationNumber = { ...this.signupTin };
    },

    loadAnswers() {
      this.declarationAnswers = {
        ...this.signupDeclarations,
        foreignIncome: this.signupDeclarations.capitalOriginCountry !== "CHL",
      };

      this.initializeTin();
    },

  },

  mounted() {
    this.loadAnswers();
  },
};
</script>

<style lang="scss" scoped>
.other-declaration-text {
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
}

.declaration-option + .declaration-option {
  margin-top: 40px;
}

.tin-declaration-item,
.foreign-income-declaration {
  margin-top: 20px;
}

.selected-other-declaration {
  border: 1px solid #000000 !important;
}

.unselected-other-declaration {
  border: 1px solid #e2e0e0 !important;
  span {
    color: #828282 !important;
  }
}

.tin-declaration {
  margin-top: 20px;
}

.tin-declaration + .tin-declaration {
  margin-top: 20px;
}

.signup-tin-buttons-wrapper {
  max-width: 100% !important;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
}

.signup-tin-buttons-wrapper > * {
  width: 100% !important;
}

.signup-success-btn {
  background-color: #6d48ff !important;
  border: unset;
}
</style>
