<template>
  <div class="profile-wrapper profile-centered-wrapper">
    <div class="profile-content">
      <div class="profile-step-back">
        <v-icon color="#333333" size="22" @click="handleStepBack()">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="profile-title">
        {{ $t("profile.personalData.title") }}
      </h3>

      <v-form class="profile-form" v-model="formValid">
        <TextField
          id="name-input"
          :label="$t('profile.personalData.fields.name.label')"
          :value="name"
          :placeholder="$t('profile.personalData.fields.name.placeholder')"
          :rules="mustCompleteRule"
          @updateValue="name = $event"
          class="profile-input-field"
          :displayNotification="!name"
        />

        <TextField
          id="last-name-input"
          :label="$t('profile.personalData.fields.lastName.label')"
          :value="lastName"
          :placeholder="$t('profile.personalData.fields.lastName.placeholder')"
          :rules="mustCompleteRule"
          @updateValue="lastName = $event"
          class="profile-input-field"
          :displayNotification="!lastName"
        />

        <BirthdateInputs
          :label="$t('profile.personalData.fields.birthdate.label')"
          :day="day"
          :month="month"
          :year="year"
          disabled
          @updateDay="day = $event"
          @updateMonth="month = $event"
          @updateYear="year = $event"
          class="profile-input-field"
        />

        <AutoComplete
          id="birth-country"
          :value="birthCountry"
          :items="countries"
          :label="$t('profile.personalData.fields.birthCountry.label')"
          :placeholder="$t('profile.personalData.fields.birthCountry.placeholder')"
          item-text="country"
          item-value="code"
          :disabled="birthCountryDisabled"
          @updateValue="birthCountry = $event"
          class="profile-input-field"
          :displayNotification="!birthCountry"
        />

        <AutoComplete
          id="nationality"
          :value="nationality"
          :items="countries"
          :rules="mustCompleteRule"
          :label="$t('profile.personalData.fields.nationality.label')"
          :placeholder="$t('profile.personalData.fields.nationality.placeholder')"
          item-text="country"
          item-value="code"
          @updateValue="nationality = $event"
          class="profile-input-field"
          :displayNotification="!nationality"
        />

        <MultiButtonPicker
          id="genders"
          :items="availableGenders"
          :value="gender"
          :label="$t('profile.personalData.fields.gender.label')"
          item-text="sex"
          item-value="value"
          @selectItem="gender = $event"
          class="profile-input-field"
          disabled
        />

        <MultiButtonPicker
          id="selfEmployed"
          :items="selfEmployedOptions"
          :value="selfEmployed"
          item-text="label"
          item-value="value"
          :label="$t('profile.personalData.fields.selfEmployed.label')"
          @selectItem="selfEmployed = $event"
          class="profile-input-field"
          :displayNotification="selfEmployed === null"
        />

        <AutoComplete
          id="occupation"
          :value="occupation"
          :items="occupations"
          item-text="label"
          item-value="value"
          :rules="mustCompleteRule"
          :label="$t('profile.personalData.fields.occupation.label')"
          :placeholder="$t('profile.personalData.fields.occupation.placeholder')"
          @updateValue="occupation = $event"
          class="profile-input-field"
          :displayNotification="!occupation"
        />

        <AutoComplete
          id="profession"
          :value="profession"
          :items="professions"
          item-text="label"
          item-value="value"
          :rules="mustCompleteRule"
          :label="$t('profile.personalData.fields.profession.label')"
          :placeholder="$t('profile.personalData.fields.profession.placeholder')"
          @updateValue="profession = $event"
          class="profile-input-field"
          :displayNotification="!profession"
        />

        <AutoComplete
          id="marital-status"
          :value="maritalStatus"
          :items="availableMaritalStatus"
          item-text="status"
          item-value="value"
          :rules="mustCompleteRule"
          :label="$t('profile.personalData.fields.maritalStatus.label')"
          :placeholder="$t('profile.personalData.fields.maritalStatus.placeholder')"
          @updateValue="maritalStatus = $event"
          class="profile-input-field"
          :displayNotification="!maritalStatus"
        />

        <div class="spouse-fields" v-if="hasSpouse">
          <TextField
            id="spouse-name"
            :label="$t('profile.personalData.fields.spouseName.label')"
            :value="spouseName"
            :placeholder="$t('profile.personalData.fields.spouseName.placeholder')"
            :rules="mustCompleteRule"
            @updateValue="spouseName = $event"
            class="profile-input-field"
            :displayNotification="!spouseName"
          />

          <TextField
            id="spouse-family-name"
            :value="spouseFamilyName"
            :label="$t('profile.personalData.fields.spouseFamilyName.label')"
            :placeholder="$t('profile.personalData.fields.spouseFamilyName.placeholder')"
            :rules="mustCompleteRule"
            @updateValue="spouseFamilyName = $event"
            class="profile-input-field"
            :displayNotification="!spouseFamilyName"
          />

          <TextField
            id="spouse-rut"
            :value="spouseIdentifier"
            :label="$t('profile.personalData.fields.spouseIdentifier.label')"
            :placeholder="$t('profile.personalData.fields.spouseIdentifier.placeholder')"
            :rules="rutRules"
            @updateValue="spouseIdentifier = $event"
            class="profile-input-field"
            :displayNotification="!spouseIdentifier"
          />

          <AutoComplete
            id="conjugal-regime"
            :value="conjugalRegime"
            :label="$t('profile.personalData.fields.conjugalRegime.label')"
            :placeholder="$t('profile.personalData.fields.conjugalRegime.placeholder')"
            :rules="mustCompleteRule"
            :items="displayableConjugalRegimeOptions"
            item-value="code"
            translator-path="profile.personalData.fields.conjugalRegime.options"
            @updateValue="conjugalRegime = $event"
            class="profile-input-field"
            :displayNotification="!conjugalRegime"
          />
        </div>

        <NewButton
          @on-click="createCustomerPatch()"
          :disabled="saveButtonDisabled"
          :class="{ 'success-state-btn': successState }"
          :loading="performingMutation"
          :text="successState ? $t('base.savedChanges') : $t('base.saveChanges')"
          class="submit-button"
        />

        <div class="profile-cancel-text" @click="$router.push({ name: 'account' })">
          {{ $t("base.cancel") }}
        </div>

        <ReusableDialog
          id="failed-patch-dialog"
          :value="errorDialog"
          :title="$t('profile.personalData.errorDialog.title')"
          :text="$t('profile.personalData.errorDialog.text')"
          @display="errorDialog = $event"
        />

        <HorizontalButtonDialog
          :value="unsavedChangesDialog"
          :title="$t('profile.shared.unsavedChangesDialog.title')"
          :text="$t('profile.shared.unsavedChangesDialog.text')"
          :primaryButtonText="$t('profile.shared.unsavedChangesDialog.buttons.continue')"
          :secondaryButtonText="$t('profile.shared.unsavedChangesDialog.buttons.back')"
          @secondaryClick="unsavedChangesDialog = false"
          @primaryClick="$router.push({ name: 'account' })"
          @display="unsavedChangesDialog = $event"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import AutoComplete from "@/components/shared/AutoComplete.vue";
import BirthdateInputs from "@/components/shared/BirthdateInputs.vue";
import MultiButtonPicker from "@/components/shared/MultiButtonPicker.vue";
import NewButton from "@/components/shared/NewButton.vue";
import TextField from "@/components/shared/TextField.vue";
import userSelects from "@/mixins/userSelects.js";
import { mapGetters, mapActions } from "vuex";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import HorizontalButtonDialog from "@/components/shared/HorizontalButtonDialog.vue";

const { validate } = require("rut.js");

export default {
  name: "PersonalData",

  data() {
    return {
      formValid: false,
      name: null,
      lastName: null,
      day: null,
      month: null,
      year: null,
      nationality: null,
      birthCountry: null,
      gender: null,
      maritalStatus: null,
      selfEmployed: null,
      occupation: null,
      profession: null,
      spouseName: null,
      spouseFamilyName: null,
      spouseIdentifier: null,
      conjugalRegime: null,
      mustCompleteRule: [
        v => !!v || this.$t("profile.personalData.errors.mustComplete"),
      ],
      rutRules: [
        v => !!v || this.$t("profile.personalData.errors.mustComplete"),
        v => validate(v) || this.$t("profile.personalData.errors.rut"),
      ],
      unsavedChangesDialog: false,
      // Mutation states
      performingMutation: false,
      errorDialog: false,
      successState: false,
    };
  },

  components: {
    TextField,
    NewButton,
    BirthdateInputs,
    AutoComplete,
    MultiButtonPicker,
    ReusableDialog,
    HorizontalButtonDialog,
  },

  mixins: [userSelects, customersApiFunctions],

  computed: {
    ...mapGetters(["profile", "loading", "customerSpouse"]),

    hasSpouse() {
      return this.maritalStatus === "MARRIED" || this.maritalStatus === "CIVIL_UNION";
    },

    displayableConjugalRegimeOptions() {
      if (!this.hasSpouse) return [];

      return this.conjugalRegimeOptions[this.maritalStatus];
    },

    customerPatchPayload() {
      return {
        // Customer editable attributes
        name: this.name,
        lastName: this.lastName,
        maritalStatus: this.maritalStatus,
        selfEmployed: this.selfEmployed,
        occupation: this.occupation,
        profession: this.profession,
        birthCountry: this.birthCountry,
        nationality: this.nationality,
      };
    },

    spouseMatch() {
      return (
        this.spouseName === (this.customerSpouse?.name || null) &&
        this.spouseFamilyName === (this.customerSpouse?.lastName || null) &&
        this.spouseIdentifier === (this.customerSpouse?.identifier || null) &&
        this.conjugalRegime === (this.profile?.conjugalRegime || null)
      );
    },

    customerMatch() {
      return Object.keys(this.customerPatchPayload).every(
        key => this.customerPatchPayload[key] === this.profile[key],
      );
    },

    unchangedForm() {
      return this.customerMatch && this.spouseMatch;
    },

    formIsFilled() {
      // Checks if every form field is filled
      return Object.values(this.customerPatchPayload).every(field => field !== null);
    },

    saveButtonDisabled() {
      return (
        (!this.formValid || this.unchangedForm || !this.formIsFilled) &&
        !this.successState
      );
    },

    birthCountryDisabled() {
      // If the customer has registered a birth country, the autocomplete must be disabled
      return !!this.profile?.birthCountry;
    },
  },

  watch: {
    loading() {
      this.loadProfileData();
    },
  },

  methods: {
    ...mapActions(["setAccountData", "setSpouseData"]),

    loadProfileData() {
      const { profile, availableGenders } = this;

      this.name = profile?.name;
      this.lastName = profile?.lastName;
      this.profession = profile?.profession;
      this.occupation = profile?.occupation;
      this.maritalStatus = profile?.maritalStatus ?
        profile.maritalStatus.toUpperCase() :
        null;
      this.selfEmployed = profile?.selfEmployed;
      this.nationality = profile?.nationality;
      this.birthCountry = profile?.birthCountry;
      this.loadBirthdate(profile);
      this.loadGender(profile, availableGenders);
      this.loadSpouseData();
    },

    loadSpouseData() {
      this.spouseName = this.customerSpouse?.name || null;
      this.spouseFamilyName = this.customerSpouse?.lastName || null;
      this.spouseIdentifier = this.customerSpouse?.identifier || null;
      this.conjugalRegime = this.profile?.conjugalRegime || null;
    },

    loadBirthdate(profile) {
      if (profile?.birthDate) {
        const dateObject = this.$dayjs(profile.birthDate, "YYYY-MM-DD", true).toDate();

        this.year = dateObject.getFullYear().toString();
        this.month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        this.day = (dateObject.getDate()).toString().padStart(2, "0");
      }
    },

    loadGender(profile, availableGenders) {
      if (profile?.gender) {
        this.gender = availableGenders.find(
          gender => gender.sex.charAt(0).toLowerCase() === profile.gender.toLowerCase(),
        )?.value;
      }
    },

    async handleSuccessfulMutation() {
      if (!this.customerMatch) {
        // If the customer data has changed, update the customer data in the profile
        this.setAccountData(this.customerPatchPayload);
      }

      // If the spouse data has changed, update the spouse data in the profile
      if (!this.spouseMatch) {
        const { conjugalRegime, ...spouseData } = this.buildSpouseData();

        // The customer patch payload has another structure than the profile spouse data,
        // so we need to adapt it to the profile structure
        const profileSpouseData = {
          identifier: spouseData.spouseIdentifier,
          name: spouseData.spouseName,
          lastName: spouseData.spouseFamilyName,
        };

        this.setSpouseData({ conjugalRegime, spouseData: profileSpouseData });
      }

      await this.showSuccessState();
      this.$router.go(-1);
    },

    handleMutationErrors() {
      this.errorDialog = true;
      this.performingMutation = false;
    },

    buildSpouseData() {
      if (!this.hasSpouse) return {};

      const { removeDots } = this.$options.filters;

      return {
        spouseIdentifier: removeDots(this.spouseIdentifier),
        spouseName: this.spouseName,
        spouseFamilyName: this.spouseFamilyName,
        conjugalRegime: this.conjugalRegime,
      };
    },

    async createCustomerPatch() {
      // return if mutation is already in progress
      if (this.performingMutation) return;

      const data = {
        payload: { ...this.customerPatchPayload, ...this.buildSpouseData() },
      };

      try {
        this.performingMutation = true;
        const { errors } = await this.patchCustomerData(data);

        this.performingMutation = false;

        if (!errors) {
          this.handleSuccessfulMutation();
        } else {
          this.handleMutationErrors();
        }
      } catch (error) {
        console.error(error);
        this.handleMutationErrors();
      }
    },

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    handleStepBack() {
      // If there is no changes, go back directly
      if (this.unchangedForm) {
        this.$router.push({ name: "account" });
      } else {
        // Otherwise. display the unsavedChangesDialog
        this.unsavedChangesDialog = true;
      }
    },
  },

  created() {
    this.loadProfileData();
  },
};
</script>

<style lang="scss" scoped>
.success-state-btn {
  background-color: #6d48ff !important;
  border: unset;
}

.spouse-fields{
  margin-top: 20px;
}
</style>
