var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-phone-input"},[(_vm.label)?_c('div',{staticClass:"label-row"},[(_vm.displayNotification)?_c('div',{staticClass:"missing-value-notification"},[_c('v-icon',{attrs:{"color":"#6D48FF","size":"16"}},[_vm._v(" mdi-alert-circle ")])],1):_vm._e(),_c('div',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.label)+" ")])]):_vm._e(),_c('vue-tel-input',{ref:"phone",staticClass:"vue-tel-input",class:{
      'v-input v-text-field v-input--is-label-active v-input--is-dirty': true,
      'theme--light v-text-field--outlined v-text-field--rounded': true,
      'v-input--is-disabled': _vm.disabled,
      'theme--light.v-input--is-disabled': _vm.disabled,
    },attrs:{"id":"phone-input","inputOptions":{ ..._vm.inputOptions },"dropdownOptions":{
      ..._vm.dropdownOptions,
    },"enabledCountryCode":false,"validCharactersOnly":true,"autoFormat":false,"mode":"international","autocomplete":"off","defaultCountry":"CL","required":""},on:{"validate":_vm.validatePhone,"blur":function($event){_vm.setOutlined(false);
      _vm.phoneErrorHint = true;},"focus":function($event){_vm.setOutlined(true);
      _vm.phoneErrorHint = false;}},model:{value:(_vm.parsedPhone),callback:function ($$v) {_vm.parsedPhone=$$v},expression:"parsedPhone"}}),(!_vm.phoneValid && _vm.phoneErrorHint)?_c('div',{staticClass:"phone-error-hints",attrs:{"id":"phone-error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }