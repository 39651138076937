<template>
  <div class="invest-wrapper">
    <div class="invest-content">
      <div class="invest-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <h3 class="invest-title">
        {{ $t('investment.compassCompleteData.titles.reminder') }}
      </h3>

      <div class="invest-text">
        {{ $t("investment.compassCompleteData.text") }}
      </div>

      <div class="button-row">
        <NewButton
          :text="$t('investment.compassCompleteData.buttons.complete')"
          @on-click="$router.push({ name: 'account' })"
        />

      </div>

      <div
        class="invest-cancel-label"
        @click="$router.push({ name: 'home' })"
      >
        {{ $t("base.cancel") }}
      </div>
    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "CompassCompleteData",

  components: { NewButton },

};
</script>

<style lang="scss" scoped>

.invest-content{
    max-width: 420px;
}

.button-row > *:not(:first-child){
  margin-top: 20px;
}

.invest-title{
    font-family: Work Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 28.8px;
    margin-bottom: 40px;

}

.invest-text{
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    color: #333;
    margin-bottom: 40px;
}

.invest-cancel-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  text-align: right;
  color: #646363;
  margin-top: 40px;

  &:hover {
    cursor: pointer;
  }
}

</style>
