<template>
  <div class="dividend-wrapper">
    <div class="dividend-content">
      <div class="dividend-title-row">
        <v-icon color="#6D48FF" size="20"> mdi-check-circle-outline </v-icon>
        <h2>
          {{ $t("dividendDeclaration.dividendDeclarationSuccess.title") }}
        </h2>
      </div>

      <p class="dividend-text">
        {{
          $t(
            `dividendDeclaration.dividendDeclarationSuccess.${fixedDividendDates ? 'fixedDate'
              : 'variableDate'}.${reinvestDividend ? "reinvestmentText" : "depositText"
            }`,
            declarationResume,
          )
        }}
      </p>

      <NewButton :text="$t('base.finish')" @on-click="finishDeclaration()" />

      <div class="dividend-support-container">
        <span class="dividend-support" @click="openChat()">
          {{ $t("base.help") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "DividendDeclarationSuccess",

  computed: {
    ...mapGetters([
      "customerPendingDividends",
      "clpBankAccount",
      "dividendDeclarationAnswer",
    ]),

    reinvestDividend() {
      return this.dividendDeclarationAnswer === "reinvest";
    },

    displayableDividendDate() {
      return this.reinvestDividend ? "reinvestmentReceiptDate" : "paymentDate";
    },

    fixedDividendDates() {
      const dividendList = this.customerPendingDividends?.dividendList || [];
      if (dividendList.length === 0) return false;

      const displayableDate = this.displayableDividendDate;
      return dividendList.every(dividend => dividend[displayableDate] === dividendList[0][displayableDate]);
    },

    paymentDate() {
      const dividendList = this.customerPendingDividends?.dividendList || [];

      if (dividendList.length === 0) return {};

      if (this.fixedDividendDates) {
        return { exactPaymentDate: this.getFormattedDate(dividendList[0][this.displayableDividendDate]) };
      }

      const dates = dividendList.map(dividend => new Date(dividend[this.displayableDividendDate]));

      return {
        fromDate: this.getFormattedDate(Math.min(...dates)),
        toDate: this.getFormattedDate(Math.max(...dates)),
      };
    },

    declarationResume() {
      const { fundDividendNames: fundName } = this.customerPendingDividends || {};
      const { bankName, accountNumber } = this.clpBankAccount || {};
      const { exactPaymentDate, fromDate, toDate } = this.paymentDate || {};

      return {
        fundName, exactPaymentDate, fromDate, toDate, bankName, accountNumber,
      };
    },

  },

  methods: {
    ...mapActions(["setCustomerDividendsData", "cleanRouteStack"]),

    getFormattedDate(date) {
      return this.$options.filters.getDayAndMonth(date);
    },

    finishDeclaration() {
      this.setCustomerDividendsData(null);
      this.cleanRouteStack();
      this.$router.push({ name: "home" });
    },

    openChat() {
      this.$intercom.showNewMessage(
        this.$t("dividendDeclaration.dividendDeclarationSuccess.intercom", {
          fundName: this.customerPendingDividends.fundDividendNames,
        }),
      );
    },
  },
  components: { NewButton },
};
</script>

<style lang="scss" scoped>
.dividend-title-row {
  display: flex;
  align-items: center;
  gap: 0 12px;
  margin-bottom: 20px;
}
</style>
