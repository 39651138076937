<template>
  <div class="dividend-wrapper">
    <div class="dividend-content">
      <div class="dividend-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h3 class="dividend-subtitle">
        {{ $t("dividendDeclaration.expiredIdDocumentNotice.title") }}
      </h3>

      <div class="dividend-text">
        {{ $t("dividendDeclaration.expiredIdDocumentNotice.text") }}
      </div>

      <NewButton
        :text="$t('dividendDeclaration.expiredIdDocumentNotice.button')"
        @on-click="$router.push({ name: 'accountUpdateIdDocument' })"
      />

      <div class="dividend-cancel-container">
        <div class="dividend-cancel" @click="onCancel()">
          {{ $t("base.cancel") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";
import { mapActions } from "vuex";

export default {
  name: "ExpiredIdDocumentNotice",

  components: {
    NewButton,
  },

  methods: {
    ...mapActions(["cleanRouteStack"]),

    onCancel() {
      this.cleanRouteStack();
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="scss" scoped>
.dividend-wrapper {
  margin: 40px 20px;

  @media (max-width: 600px) {
    margin: 30px 20px;
  }
}
</style>
