import { render, staticRenderFns } from "./IdentificationView.vue?vue&type=template&id=f65a1dc0&scoped=true"
import script from "./IdentificationView.vue?vue&type=script&lang=js"
export * from "./IdentificationView.vue?vue&type=script&lang=js"
import style0 from "./IdentificationView.vue?vue&type=style&index=0&id=f65a1dc0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f65a1dc0",
  null
  
)

export default component.exports