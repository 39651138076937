<template>
  <div class="custom-multi-button-picker" :id="id">
    <div class="label-row" v-if="label">
      <div class="missing-value-notification" v-if="displayNotification">
        <v-icon color="#6D48FF" size="16">
          mdi-alert-circle
        </v-icon>
      </div>
      <div class="label-text">
        {{ label }}
      </div>
    </div>

    <div class="multi-button-options">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="multi-button-option"
        :class="{
          'selected-option': isSelected(item),
          'unselected-option': !isSelected(item),
          disabled: disabled,
        }"
        @click="handleOptionClick(item)"
      >
        <span class="multi-button-option-text">
          {{ item[itemText] }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MultiButtonPicker",

  props: {
    id: String,
    items: Array,
    label: String,
    itemText: String,
    itemValue: [String, Number],
    value: [String, Number, Boolean],
    disabled: Boolean,
    displayNotification: Boolean,
  },

  methods: {
    isSelected(item) {
      return this.value === item[this.itemValue];
    },

    handleOptionClick(item) {
      if (!this.disabled) {
        this.$emit("selectItem", item[this.itemValue]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-multi-button-picker {
  max-width: 420px;
}

.multi-button-options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.label-row{
  display: flex;
  gap: 0 5px;
  margin-bottom: 10px;
}

.label-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  color: #333;
}

.multi-button-option {
  border-radius: 10px;
  background-color: #fff;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex: 0 0 calc(50% - 5px);

  &:hover {
    cursor: pointer;
  }
}

.selected-option {
  border: 1px solid #000;
}

.multi-button-option-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  color: #333;
}

.disabled {
  background-color: #e2e0e0;
  cursor: auto !important;
}
</style>
