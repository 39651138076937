<template>
  <div
    class="fund-badge-container"
    :style="`height: ${size}px; width: ${size}px; min-width: ${size}px;`"
  >
    <v-icon
      class="fund-badge"
      :style="`background-color: ${fundColor}`"
      dark
      :size="`${iconSize}px`"
    >
      {{ fundIcon }}
    </v-icon>
  </div>
</template>

<script>
import funds from "@/mixins/funds.js";

export default {
  name: "FundBadge",

  props: {
    fundRun: [Number, String],
    size: {
      type: Number,
      default: 60,
    },
    iconSize: {
      type: Number,
      default: 45,
    },
  },

  mixins: [funds],

  computed: {
    fundColor() {
      return this.$options.filters.addFundColor(this.fundRun);
    },

    fundIcon() {
      return `mdi-${this.getFundStaticData(this.fundRun).icon}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fund-badge-container {
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.fund-badge {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
