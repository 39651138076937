<template>
  <div class="pill-container px-3" :style="`max-width: ${maxWidth};`">
    <v-icon dark small> {{ icon }}</v-icon>
    <span class="toast-text">
      {{ toastText }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ToastNotification",

  props: {
    toastText: {
      type: String,
      default: "",
    },
    // ideally, use the typical bootstrap classes: danger, warning, success, info, etc.
    toastType: {
      type: String,
      default: "danger",
    },
  },

  computed: {
    icon() {
      const icons = { danger: "mdi-alert-circle-outline", success: "mdi-check" };
      return icons[this.toastType];
    },

    maxWidth() {
      if (this.toastType === "success" && this.$vuetify.breakpoint.smAndDown) { return "190px"; }
      if (this.toastType === "success" && !this.$vuetify.breakpoint.smAndDown) { return "230px"; }
      return null;
    },
  },
};
</script>

<style lang="scss">
.pill-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: #000000;
  max-width: 316px;
  height: 60px;
  width: 100%;
  border-radius: 10px;
  @media (max-width: 960px) {
    gap: 12px;
  }
}

.toast-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  max-width: 250px;
  color: #ffffff;
}
</style>
