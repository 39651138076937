<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="signup-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>
      <h2 class="signup-title">
        {{ $t("signup.identification.title") }}
      </h2>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="saveCustomerIdentity()"
        class="signup-form file-inputs"
      >
        <TextField
          id="identifier"
          :label="$t('signup.identification.fields.identifier.label')"
          :value="
            signupStatus.completedValidation
              ? signupValidationData.customerIdentifier
              : customerIdentifier
          "
          :placeholder="$t('signup.identification.fields.identifier.placeholder')"
          :rules="rutRules"
          :disabled="signupStatus.completedValidation"
          @updateValue="customerIdentifier = $event"
        />

        <div v-if="!signupStatus.completedValidation && !successState">
          <div class="label-row">
            <div class="signup-form-label">
              {{ $t("signup.identification.fields.documentNumber.label") }}
            </div>
            <div
              class="signup-form-label where-is-label"
              @click="displayHintDialog = true"
            >
              {{ $t("signup.identification.fields.documentNumber.hint") }}
            </div>
          </div>

          <!-- <DocumentNumberTextField
            id="document-number"
            :value="documentNumber"
            :placeholder="$t('signup.identification.fields.documentNumber.placeholder')"
            @updateValue="documentNumber = $event"
          /> -->

          <TextField
            id="document-number"
            :value="documentNumber"
            :placeholder="$t('signup.identification.fields.documentNumber.placeholder')"
            @updateValue="documentNumber = $event"
          />
        </div>

        <div class="signup-form-label">
          {{ $t("signup.identification.images.label") }}
        </div>

        <FileInput
          id="front-identification-card"
          v-model="front"
          :rules="imageRules"
          :placeholder="$t('signup.identification.images.front.placeholder')"
          :disabled="signupStatus.completedValidation && !successState"
          ref="filef"
          @updateValue="front = $event"
          @delete="front = null"
          @on-change="handleImageSize(front, 'front')"
          class="file-input"
        />

        <FileInput
          id="back-identification-card"
          v-model="back"
          :rules="imageRules"
          :placeholder="$t('signup.identification.images.back.placeholder')"
          :disabled="signupStatus.completedValidation && !successState"
          ref="fileb"
          @updateValue="back = $event"
          @delete="back = null"
          @on-change="handleImageSize(back, 'back')"
          class="file-input"
        />

        <p class="signup-hint">
          {{ $t("signup.identification.cmfExplanation") }}
        </p>

        <NewButton
          id="next-step"
          class="signup-step-button"
          :text="
            successState
              ? $t('signup.identification.button.finished')
              : $t('signup.identification.button.finish')
          "
          :class="{ 'success-state-btn': successState }"
          :type="'submit'"
          :loading="loading"
          :disabled="!valid && !signupStatus.completedValidation"
        />

        <div
          class="signup-cancel-container"
          @click="$router.push({ name: 'signupDashboard' })"
        >
          <p class="signup-cancel">
            {{ $t("base.cancel") }}
          </p>
        </div>
      </v-form>
    </div>

    <HorizontalButtonDialog
      :value="errorDialog"
      :title="$t('signup.identification.errorDialog.title')"
      :text="$t(`signup.identification.responseErrors.${errorCode}`)"
      :primaryButtonText="$t('signup.identification.errorDialog.primaryButton')"
      :secondaryButtonText="$t('signup.identification.errorDialog.secondaryButton')"
      @primaryClick="errorDialog = false"
      @secondaryClick="openChat()"
      @display="errorDialog = $event"
    />

    <DocumentNumberHintDialog
      :dialog="displayHintDialog"
      @displayDialog="displayHintDialog = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { imagesFunctions } from "@/mixins/images.js";
import NewButton from "@/components/shared/NewButton.vue";
import HorizontalButtonDialog from "@/components/shared/HorizontalButtonDialog.vue";
import TextField from "@/components/shared/TextField.vue";
// import DocumentNumberTextField from "@/components/shared/DocumentNumberTextField.vue";
import FileInput from "@/components/shared/FileInput.vue";
import DocumentNumberHintDialog from "@/views/signup/identification/DocumentNumberHintDialog.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";

const { validate, format } = require("rut.js");

export default {
  name: "IdentificationIntro",

  components: {
    DocumentNumberHintDialog,
    NewButton,
    HorizontalButtonDialog,
    TextField,
    FileInput,
    // DocumentNumberTextField,
  },

  mixins: [customersApiFunctions, imagesFunctions],

  data() {
    return {
      documentNumber: null,
      customerIdentifier: null,
      loading: false,
      errorDialog: false,
      errorCode: "UNKNOWN_ERROR", // default error code
      displayHintDialog: false,
      valid: false,
      successState: false,
      front: null,
      back: null,
      rutRules: [
        v => !!v || this.$t("signup.shared.errors.mustComplete"),
        v => validate(v) || this.$t("signup.identification.fields.errors.identifier"),
      ],
      imageRules: [
        v => !!v ||
          this.signupStatus.completedValidation ||
          this.$t("signup.shared.errors.mustComplete"),
      ],
    };
  },

  computed: {
    ...mapGetters(["signupValidationData", "signupStatus", "signupEmail"]),
  },

  methods: {
    ...mapActions(["setIdDocumentImages", "setIdentifier"]),

    handleErrorResponse(response) {
      this.setIdentifier({
        customerIdentifier: null,
        documentNumber: null,
      });

      this.setIdDocumentImages({
        front: null,
        back: null,
      });

      this.errorCode = response?.errorCode;
      this.errorDialog = true;
      this.loading = false;
    },

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    async handleSuccessfulMutation() {
      await this.showSuccessState();
      this.$router.push({ name: "signupDashboard" });
    },

    async saveCustomerIdentity() {
      // If the user has already completed the validation, we don't need to validate again
      if (this.successState) return;

      this.setIdDocumentImages({
        front: this.front,
        back: this.back,
      });

      if (this.signupStatus.completedValidation) {
        this.handleSuccessfulMutation();
      } else {
        this.loading = true;
        const identifier = format(
          this.customerIdentifier || this.signupValidationData.customerIdentifier,
        );

        this.setIdentifier({
          customerIdentifier: identifier,
          documentNumber: this.documentNumber,
        });

        const { removeDots } = this.$options.filters;

        const payload = {
          email: this.signupEmail,
          customerIdentifier: removeDots(identifier),
          documentNumber: removeDots(this.documentNumber),
          front: this.front,
          back: this.back,
        };

        const signUpValidationResponse = await this.signUpValidation(payload);
        this.loading = false;
        if (signUpValidationResponse.error) {
          this.handleErrorResponse(signUpValidationResponse);
        } else {
          this.handleSuccessfulMutation();
        }
      }
    },

    openChat() {
      this.$intercom.showNewMessage(
        this.$t("signup.identification.errors.intercom.existingIdentifier"),
      );
    },

    reloadData() {
      if (this.signupValidationData.customerIdentifier) this.customerIdentifier = this.signupValidationData.customerIdentifier;
      if (this.signupValidationData.documentNumber) {
        this.documentNumber = this.signupValidationData.documentNumber;
      }
      if (this.signupValidationData.front instanceof File) {
        this.front = this.signupValidationData.front;
      }
      if (this.signupValidationData.back instanceof File) {
        this.back = this.signupValidationData.back;
      }
    },
  },

  mounted() {
    this.reloadData();
  },
};
</script>

<style lang="scss" scoped>
.prepend-container {
  height: 26px !important;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
}

.upload-button-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup-chat-btn::v-deep .new-btn {
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  span {
    color: #000000 !important;
  }
}

.file-input + .file-input {
  margin-top: 10px;
}

.success-state-btn {
  background-color: #6d48ff !important;
  border: unset;
}
</style>
