<template>
  <div class="invest-fund-card">
    <div class="fund-content">
      <FundBadge
        :fundRun="fundRun"
        :size="$vuetify.breakpoint.mdAndUp ? 90 : 80"
        :iconSize="$vuetify.breakpoint.mdAndUp ? 45 : 40"
      />
      <div class="fund-primary-info">
        <div class="ml-1">
          <span class="fund-name"> {{ fundRun | fundShortName }} </span>
        </div>
        <div class="pre-description ml-1">
          {{ $t(`fund.shortDescription.${fundRun}`) }}
        </div>
      </div>
    </div>

    <div
      class="desktop-invest-button-container my-auto mr-2"
      v-if="desktopInvestmentAllowed"
    >
      <v-btn
        color="#000000"
        id="invest-fund-btn"
        width="160"
        height="50"
        @click="handleInvestRedirection()"
      >
        <v-icon dark size="18" color="#ffffff"> mdi-plus-circle-outline </v-icon>
        <span class="invest-text ml-2">{{
          $t(`investment.investmentFundCard.invest`)
        }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import funds from "@/mixins/funds.js";
import values from "@/helpers/values.js";
import FundBadge from "@/components/shared/FundBadge.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "InvestFundCard",

  components: { FundBadge },

  mixins: [funds, values],

  props: {
    fundRun: {
      type: Number,
      required: true,
    },
    canInvest: Boolean,
  },

  computed: {
    ...mapGetters(["signupStatus"]),

    desktopInvestmentAllowed() {
      return this.canInvest && this.$vuetify.breakpoint.mdAndUp;
    },
  },

  methods: {
    ...mapActions(["addToRouteStack"]),

    handleInvestRedirection() {
      const hasCompassSignature = this.signupStatus.completedContractCompass;

      // If the fund is managed by compass and the customer does not have the compass signature, navigate to the compass notice page
      if (!hasCompassSignature) {
        // Add the next route to the route stack before navigating to the compass notice page
        this.addToRouteStack({
          name: "investChooseInvestmentAmount",
          params: { fundRun: this.fundRun },
        });

        this.$router.push({ name: "accountCompassSignatureNotice" });
      } else {
        // If the fund is managed by compass and the customer has the compass signature,
        // or if the fund is not managed by compass, navigate to the investment amount page

        this.$router.push({
          name: "investChooseInvestmentAmount",
          params: { fundRun: this.fundRun },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.invest-fund-card {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 130px;
  background-color: #ffffff;
  width: 100%;

  @media (max-width: 600px) {
    background-color: transparent;
    height: 100px;
  }

  .fund-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    @media (max-width: 600px) {
      gap: 10px;
    }
  }
}

.fund-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 23px;
  }
  color: #333333;
}

#invest-fund-btn {
  border-radius: 10px;
  text-transform: capitalize;
  box-shadow: none;
}
.invest-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  letter-spacing: normal;
}
.pre-description {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
  margin-top: 10px;
  max-width: 240px;
  @media (min-width: 600px) and (max-width: 960px) {
    max-width: 300px;
  }
}
</style>
