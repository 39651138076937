<template>
  <div class="pa-5">
    <div v-if="loading" class="skeleton-container">
      <v-skeleton-loader type="article" width="100%" />
    </div>
    <div v-else-if="emptyBalances" class="balance">${{ 0 | currencyNoSymbol }}</div>
    <div v-else class="balance" @click="$emit('getDetails')">
      ${{ currentBalance | currencyNoSymbol }}
    </div>

    <div v-if="!loading && !withdrawals" class="results-amount mt-3 mb-4">
      <span>
        {{ historicalResults >= 0 ? "+" : "-" }}${{
          Math.abs(historicalResults || 0) | currencyNoSymbol
        }}
      </span>
    </div>

    <div
      v-else-if="!loading && withdrawals"
      class="results-boxes mt-3 mb-4"
      @click="$emit('getDetails')"
    >
      <BalanceItem
        id="current-results"
        :cashAmount="currentResults"
        :label="
          $t(
            `home.welcome.balanceCard.current${
              currentResults > 0 ? 'Profits' : 'Results'
            }`,
          )
        "
      />

      <BalanceItem
        id="historic-results"
        :cashAmount="historicalResults"
        :label="
          $t(
            `home.welcome.balanceCard.historic${
              currentResults > 0 ? 'Profits' : 'Results'
            }`,
          )
        "
      />
    </div>

    <NewButton
      id="invest-button"
      :text="$t('base.invest')"
      icon="mdi-plus-circle-outline"
      @on-click="$emit('invest')"
    />
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";
import BalanceItem from "./BalanceItem.vue";

export default {
  name: "MobileBalanceCard",

  components: { BalanceItem, NewButton },

  props: {
    loading: {
      type: Boolean,
    },
    currentBalance: {
      type: Number,
    },
    withdrawals: {
      type: Number,
    },
    emptyBalances: {
      type: Boolean,
    },
    currentResults: {
      type: Number,
    },
    historicalResults: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.balance {
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  color: #333333;
}

.results-boxes {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.results-amount {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #333333;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 140%;
  }
}
</style>
