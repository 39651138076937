<template>
  <div class="reinvestment-wrapper reinvestment-centered-wrapper">
    <div class="reinvestment-content">
      <div class="reinvestment-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <i18n
        path="reinvestment.selectDestinationFund.fromFund"
        tag="p"
        class="reinvest-from-source-fund"
      >
        <template v-slot:fundName>
          <span> {{ reinvestment.originFund.fundRun | fundShortName }}</span>
          <div class="inline-wrapper">
            <div
              :style="{ backgroundColor: originFundData.color }"
              class="fund-icon-container center-in-page"
            >
              <v-icon color="#fff" size="12"> mdi-{{ originFundData.icon }}</v-icon>
            </div>
          </div>
        </template>
      </i18n>

      <h2 class="reinvestment-header">
        {{ $i18n.t("reinvestment.selectDestinationFund.title") }}
      </h2>

      <div class="customer-funds">
        <div
          v-for="(fundBalance, index) in fundBalances"
          :key="index"
          @click="selectFund(fundBalance.fundRun)"
          class="fund-balance"
        >
          <BalanceDetailsFundCard :fundBalance="fundBalance" class="fund-balance-card" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import funds from "@/mixins/funds.js";
import BalanceDetailsFundCard from "@/components/results/BalanceDetailsFundCard.vue";

export default {
  name: "SelectDestinationFund",

  components: {
    BalanceDetailsFundCard,
  },

  mixins: [funds],

  computed: {
    ...mapGetters(["fundsResults", "reinvestment", "otherFunds"]),

    originFundData() {
      return this.getFundStaticData(this.reinvestment.originFund.fundRun);
    },

    fundBalances() {
      return this.fundsResults
        .map(fund => ({ currencyAmount: fund.fundCurrent, fundRun: fund.fundRun }))
        .filter(fund => fund.fundRun !== this.reinvestment.originFund.fundRun)
        .sort((a, b) => b.currencyAmount - a.currencyAmount);
    },
  },

  methods: {
    ...mapActions(["setReinvestmentData"]),

    selectFund(fundRun) {
      let seriesSymbol;
      const customerQuotasArray = this.fundsResults.find(
        fund => fund.fundRun === fundRun,
      ).fundQuotas;

      // if the customer doesn't has the fund, we need to get the retail series

      if (customerQuotasArray.length === 0) {
        if (fundRun === "9725") seriesSymbol = "CFIAMDVASC";
        else {
          const otherFund = this.otherFunds.find(fund => fund.run === fundRun);
          seriesSymbol = otherFund.series[0].symbol;
        }
      } else {
        seriesSymbol = customerQuotasArray[customerQuotasArray.length - 1].symbol;
      }
      this.setReinvestmentData({
        ...this.reinvestment,
        destinationFund: { seriesSymbol, fundRun },
      });

      this.$router.push({ name: "chooseReinvestmentAmount" });
    },
  },
};
</script>

<style lang="scss" scoped>
.fund-balance + .fund-balance {
  margin-top: 10px;
}

.fund-balance {
  max-width: 420px;
}

.fund-balance-card {
  &:hover {
    cursor: pointer;
    background-color: #fafafa;
  }
}

.reinvest-from-source-fund {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.fund-icon-container {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.inline-wrapper {
  display: inline-block;
  margin-left: 5px;
}

::v-deep .balance-details-fund-card {
  border: none !important;
}
</style>
