var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup-wrapper"},[_c('div',{staticClass:"signup-content"},[_c('div',{staticClass:"signup-step-back"},[_c('v-icon',{attrs:{"color":"#333333","size":"22"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" mdi-arrow-left ")])],1),_c('h2',{staticClass:"signup-title"},[_vm._v(" "+_vm._s(_vm.$t("signup.identification.title"))+" ")]),_c('v-form',{ref:"form",staticClass:"signup-form",on:{"submit":function($event){$event.preventDefault();return _vm.saveIdentificationData()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"signup-form-label"},[_vm._v(" "+_vm._s(_vm.$t("signup.identification.contact.fields.phone.label"))+" ")]),_c('PhoneInput',{attrs:{"dropdownOptions":{
          width: _vm.$vuetify.breakpoint.smAndUp ? '420px' : '330px',
          showFlags: true,
          showDialCodeInSelection: true,
          showDialCodeInList: true,
        },"inputOptions":{
          placeholder: _vm.$t('signup.identification.contact.fields.phone.placeholder'),
        },"errorMessage":_vm.$t('signup.identification.contact.fields.phone.invalidPhone')},on:{"setPhoneData":_vm.setPhoneData}}),_c('AutoComplete',{staticClass:"signup-labeled-input-field signup-input-field",attrs:{"id":"region","value":_vm.region,"items":_vm.regions,"item-text":"name","item-value":"name","rules":_vm.mustCompleteRules,"label":_vm.$t('signup.identification.contact.fields.address.label'),"placeholder":_vm.$t('signup.identification.contact.fields.address.region')},on:{"updateValue":_vm.handleRegionSelection}}),_c('AutoComplete',{staticClass:"signup-input-field",attrs:{"id":"locality","value":_vm.locality,"items":_vm.localitiesList,"rules":_vm.mustCompleteRules,"placeholder":_vm.$t('signup.identification.contact.fields.address.locality')},on:{"updateValue":_vm.handleLocalitySelection}}),_c('TextField',{staticClass:"signup-input-field",attrs:{"value":_vm.street,"placeholder":_vm.$t('signup.identification.contact.fields.address.street'),"rules":_vm.mustCompleteRules},on:{"updateValue":function($event){_vm.street = $event}}}),_c('TextField',{staticClass:"signup-input-field",attrs:{"value":_vm.houseNumber,"placeholder":_vm.$t('signup.identification.contact.fields.address.houseNumber'),"rules":_vm.mustCompleteRules},on:{"updateValue":function($event){_vm.houseNumber = $event}}}),_c('TextField',{staticClass:"signup-input-field",attrs:{"value":_vm.interiorNumber,"placeholder":_vm.$t('signup.identification.contact.fields.address.interiorNumber')},on:{"updateValue":function($event){_vm.interiorNumber = $event}}}),_c('NewButton',{staticClass:"signup-step-button",class:{
          'signup-success-btn': _vm.signupStatus.completedIdentification || _vm.successState,
        },attrs:{"id":"next-step","text":_vm.signupStatus.completedIdentification || _vm.successState
            ? _vm.$t('signup.identification.contact.button.saved')
            : _vm.$t('signup.identification.contact.button.saveAndNext'),"type":'submit',"loading":_vm.loading,"disabled":!_vm.valid || _vm.loading || !_vm.phoneValid}}),_c('div',{staticClass:"signup-cancel-container",on:{"click":function($event){return _vm.$router.push({ name: 'signupDashboard' })}}},[_c('p',{staticClass:"signup-cancel"},[_vm._v(" "+_vm._s(_vm.$t("base.cancel"))+" ")])])],1),_c('ReusableDialog',{attrs:{"title":_vm.$t('signup.identification.errors.title'),"text":_vm.$t('signup.identification.errors.message')},on:{"display":function($event){_vm.errorDialog = $event}},scopedSlots:_vm._u([{key:"buttonRow",fn:function(){return [_c('div',{staticClass:"signup-button-row"},[_c('div',{staticClass:"signup-chat-btn"},[_c('NewButton',{attrs:{"text":_vm.$t('base.goToChat')},on:{"on-click":function($event){return _vm.openChat()}}})],1),_c('div',{staticClass:"keep-trying-btn"},[_c('NewButton',{attrs:{"text":_vm.$t('base.keepTrying'),"loading":_vm.loading},on:{"on-click":function($event){_vm.errorDialog = false}}})],1)])]},proxy:true}]),model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }