<template>
  <v-dialog v-model="display" max-width="500" content-class="rounded-lg">
    <v-card elevation="0" rounded="lg">
      <div class="error-dialog-container">
        <div class="text-wrapper">
          <h3>{{ $i18n.t("redemptions.errorDialog.title") }}</h3>
          <p class="redemption-error-text">
            {{ $i18n.t("redemptions.errorDialog.text") }}
          </p>
        </div>

        <table class="redemption-status-list">
          <thead>
            <th>
              <span>
                {{ $i18n.t("redemptions.errorDialog.table.header.breakdown") }}
              </span>
            </th>
            <th>
              <span>
                {{ $i18n.t("redemptions.errorDialog.table.header.status") }}
              </span>
            </th>
          </thead>
          <tbody>
            <tr v-for="(redemption, index) in redemptionResults" :key="index">
              <td>
                <div class="fund-redemption-details">
                  <div class="fund">
                    <div
                      class="fund-icon"
                      :style="{
                        backgroundColor: $options.filters.addFundColor(
                          redemption.fundRun,
                        ),
                      }"
                    >
                      <v-icon color="#FFFFFF" size="12">
                        {{ getFundIcon(redemption.fundRun) }}
                      </v-icon>
                    </div>
                    {{ redemption.fundRun | fundShortName }}
                  </div>
                  <div class="amount">
                    <v-icon size="16" color="#000">
                      mdi-arrow-top-right-thin-circle-outline
                    </v-icon>
                    ${{ redemption.requestedInputAmount | currencyNoSymbol }}
                  </div>
                </div>
              </td>
              <td>
                <span :class="{ 'rejected-status': !redemption.confirmed }">
                  {{
                    $i18n.t(
                      `redemptions.errorDialog.table.body.${
                        redemption.confirmed ? "confirmed" : "rejected"
                      }`,
                    )
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="button-wrapper">
          <div class="help-btn">
            <NewButton
              :text="$i18n.t('redemptions.errorDialog.buttons.help')"
              @on-click="openChat"
            />
          </div>
          <div class="accept-btn">
            <NewButton
              :text="$i18n.t('redemptions.errorDialog.buttons.accept')"
              @on-click="display = false"
            />
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import funds from "../../mixins/funds.js";
import NewButton from "../shared/NewButton.vue";

export default {
  name: "RedemptionsErrorDialog",

  components: { NewButton },

  mixins: [funds],

  props: {
    value: Boolean,
    redemptionResults: Array,
  },

  computed: {
    display: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    openChat() {
      this.$intercom.showNewMessage(this.$i18n.t("redemptions.errorDialog.intercom"));
    },

    getFundIcon(fundRun) {
      return `mdi-${this.getFundStaticData(fundRun).icon}`;
    },
  },
};
</script>

<style lang="scss" scoped>
table.redemption-status-list {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
  height: 80px;
  border-top: 1px solid #e2e0e0 !important;
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
  }
}
.error-dialog-container {
  height: 100%;
  padding: 40px;
  @media (max-width: 600px) {
    padding: 20px;
  }
}

h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  color: #333333;
  margin-bottom: 20px;
}

p {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 40px !important;
}

.button-wrapper div + div {
  margin-top: 10px;
}
.help-btn ::v-deep .new-btn {
  background-color: #ffffff !important;
  border: 1px solid #000000;
  span {
    color: #000000 !important;
  }
}

tbody tr {
  height: 80px;
  border-top: 1px solid #e2e0e0 !important;
}

tbody tr td span,
div {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000 !important;
}

tbody tr:last-child {
  border-bottom: 1px solid #e2e0e0 !important;
}

.fund {
  display: flex;
  align-items: center;
}
.fund-icon {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fund,
.amount {
  display: flex;
  height: 22px;
  align-items: center;
  gap: 0 10px;
}

.rejected-status {
  color: #f20e45 !important;
}
</style>
