<template>
  <div class="custom-birthdate-inputs">
    <div class="label" v-if="label">
      {{ label }}
    </div>
    <div class="birthdate-coupled-inputs">
      <v-text-field
        v-model="dayValue"
        outlined
        rounded
        class="datefield birthdate-field-left"
        :placeholder="$t('shared.birthdateInputs.fields.day.placeholder')"
        required
        autocomplete="off"
        maxlength="2"
        hide-details
        oninput="this.value=this.value.slice(0,this.maxLength)"
        type="number"
        ref="birthdate-input-1"
        :class="{ disabled: disabled }"
        :disabled="disabled"
      />

      <v-text-field
        v-model="monthValue"
        outlined
        rounded
        class="datefield birthdate-field-middle"
        :placeholder="$t('shared.birthdateInputs.fields.month.placeholder')"
        required
        autocomplete="off"
        maxlength="2"
        oninput="this.value=this.value.slice(0,this.maxLength)"
        type="number"
        hide-details
        ref="birthdate-input-2"
        :class="{ disabled: disabled }"
        :disabled="disabled"
      />

      <v-text-field
        v-model="yearValue"
        outlined
        rounded
        class="datefield birthdate-field-right"
        :placeholder="$t('shared.birthdateInputs.fields.year.placeholder')"
        required
        autocomplete="off"
        maxlength="4"
        oninput="this.value=this.value.slice(0,this.maxLength)"
        type="number"
        hide-details
        ref="birthdate-input-3"
        :class="{ disabled: disabled }"
        :disabled="disabled"
      />
    </div>

    <p id="invalid-date-alert" class="bithdate-error-message" v-if="showAlert">
      {{ $t("shared.birthdateInputs.invalidDate") }}
    </p>
  </div>
</template>
<script>
export default {
  name: "BirthdateInputs",

  props: {
    day: [Number, String],
    month: [Number, String],
    year: [Number, String],
    optionalRules: Array,
    label: String,
    disabled: Boolean,
  },

  watch: {
    day(newValue, oldValue) {
      this.setDateValidityOnChange();

      if (newValue && oldValue) {
        this.tabNextField(newValue, 1);
      }
    },

    month(newValue, oldValue) {
      this.setDateValidityOnChange();

      if (newValue && oldValue) {
        this.tabNextField(newValue, 2);
      }
    },

    year() {
      this.setDateValidityOnChange();
    },
  },

  computed: {
    dayValue: {
      get() {
        return this.day;
      },
      set(newValue) {
        this.$emit("updateDay", newValue);
      },
    },

    monthValue: {
      get() {
        return this.month;
      },
      set(newValue) {
        this.$emit("updateMonth", newValue);
      },
    },

    yearValue: {
      get() {
        return this.year;
      },
      set(newValue) {
        this.$emit("updateYear", newValue);
      },
    },

    showAlert() {
      if (this.disabled) return false;
      return this.day && this.month && this.year && !this.validDate;
    },

    validDate() {
      const { year, month, day } = this;

      if (year && month && day) {
        const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(
          day,
        ).padStart(2, "0")}`;
        return (
          this.$dayjs(formattedDate, "YYYY-MM-DD", true).isValid() &&
          !this.optionalNonCompliance
        );
      }
      return false;
    },

    optionalNonCompliance() {
      // if no optional rules, there is no broken rules
      if (!this.optionalRules) return false;

      // checks if there is a broken rule
      return this.optionalRules.some(brokenRule => !!brokenRule);
    },
  },

  methods: {
    tabNextField(dateValue, currentId) {
      if (dateValue && dateValue.toString().length === 2) {
        const nextInput = this.$refs[`birthdate-input-${currentId + 1}`];
        if (nextInput) {
          nextInput.focus();
        }
      }
    },

    setDateValidityOnChange() {
      const newValidity = this.validDate;

      if (newValidity !== this.previousValidity) {
        this.previousValidity = newValidity;
        this.$emit("setDateValidity", newValidity);
      }
    },
  },
};
</script>
<style lang="scss">
.custom-birthdate-inputs {
  .birthdate-coupled-inputs {
    display: flex;
    gap: 0 5px !important;
  }

  .birthdate-coupled-inputs > * {
    min-width: 65px;
  }

  .datefield {
    border-radius: 10px;
  }

  .datefield input::-webkit-outer-spin-button,
  .datefield input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .datefield input[type="number"] {
    -moz-appearance: textfield;
  }

  input {
    caret-color: black;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 160% !important;
  }

  .v-messages__message {
    line-height: 160% !important;
    font-weight: 500 !important;
  }

  .v-text-field--outlined:not(.disabled) > .v-input__control > .v-input__slot {
    background-color: #fff !important;
  }

  .disabled {
    background-color: #e2e0e0 !important ;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: #e2e0e0;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 60px !important;
  }

  :not(.file-input).v-text-field--outlined.v-input--is-focused
    > .v-input__control
    > .v-input__slot
    fieldset {
    border: 1px solid #000 !important;
  }

  .error--text.v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid #f20e45;
  }

  .theme--light.v-text-field--outlined {
    border-radius: 10px;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    color: #333;
    margin-bottom: 10px;
  }

  .bithdate-error-message {
    font-size: 12px;
    line-height: 12px;
    padding: 0 12px;
    color: #ff1744 !important;
    margin-bottom: 8px;
    margin-top: 8px;
  }
}
</style>
