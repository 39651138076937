export default {
  data() {
    return {
      contractItems: [
        {
          agf: "AMERIS",
          hasReinvestDividends: true,
          declarations: [
            { name: "cgf", values: 91 },
            { name: "crs", values: 57 },
            { name: "pep", values: 18 },
            { name: "fatca", values: 8 },
          ],
        },
        {
          agf: "COMPASS",
          hasReinvestDividends: false,
          declarations: [{ name: "cgf", values: 66 }],
        },
      ],

      contractBriefKeys: [
        "regulation",
        "agfAssociation",
        "subscriptionsRedemptions",
        "receipts",
      ],
    };
  },
  methods: {
    getAgfValues(agfName) {
      return this.contractItems.find(contract => contract.agf === agfName);
    },
  },
};
