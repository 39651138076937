<template>
  <ReusableDialog
    v-model="dividendDialog"
    :title="
      $t('dividendDeclaration.modal.title', {
        fundName: fundDividendNames,
      })
    "
    :text="
      $t('dividendDeclaration.modal.text', {
        fundName: fundDividendNames,
      })
    "
  >
    <template v-slot:buttonRow>
      <div class="dividend-button-row">
        <NewButton
          class="choose-dividend-option-button"
          :text="$t('dividendDeclaration.modal.button')"
          @on-click="$router.push({ name: 'dividendsChooseDividendOption' })"
        />
        <div class="cancel-dividend-declaration" @click="dividendDialog = false">
          {{ $t("dividendDeclaration.modal.notNow") }}
        </div>
      </div>
    </template>
  </ReusableDialog>
</template>

<script>
import { mapGetters } from "vuex";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "DividendDeclarationModal",

  data: () => ({
    dividendDialog: false,
    dividendPendingFundRuns: null,
    notAnsweredDividends: null,
  }),

  components: {
    ReusableDialog,
    NewButton,
  },

  watch: {
    loading() {
      this.checkPendingDividendDeclaration();
    },
  },

  computed: {
    ...mapGetters([
      "loadingCustomerDividends",
      "loadingProfile",
      "customerPendingDividends",
    ]),

    loading() {
      // we need to check if the customer dividends and the profile are loaded
      return this.loadingCustomerDividends || this.loadingProfile;
    },

    fundDividendNames() {
      if (
        this.customerPendingDividends &&
        this.customerPendingDividends?.fundDividendNames?.length > 0
      ) {
        return this.customerPendingDividends.fundDividendNames;
      }
      return "";
    },
  },

  methods: {
    checkPendingDividendDeclaration() {
      if (this.customerPendingDividends) {
        this.dividendDialog = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dividend-button-row {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  margin-top: 40px;
}

.cancel-dividend-declaration {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  text-align: right;
  color: #646363;

  &:hover {
    cursor: pointer;
  }
}
</style>
