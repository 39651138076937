<template>
  <div class="login-wrapper">
    <div class="login-content">
      <div class="center-in-page">
        <v-img class="mi-dva-logo" :src="require('@/assets/mi-dva-logo.svg')" />
      </div>
      <v-form v-model="valid" @keyup.enter="onLoginAttempt('credentials')" class="login-form">
        <v-text-field
          :rules="emailRules"
          :placeholder="$t('login.loginForm.emailLabel')"
          v-model="credentials.email"
          required
          outlined
          rounded
          :hide-details="
            credentials.email !== null && !$refs.email.isFocused
              ? $refs.email.validate()
              : ''
          "
          color="#828282"
          @keyup.enter="onLoginAttempt('credentials')"
          class="login-field"
          ref="email"
        />
        <v-text-field
          :rules="passwordRules"
          :placeholder="$t('login.loginForm.passwordLabel')"
          :type="showPassword ? 'text' : 'password'"
          v-model="credentials.password"
          required
          outlined
          rounded
          :hide-details="
            credentials.password !== null && !$refs.password.isFocused
              ? $refs.password.validate()
              : ''
          "
          color="#333333"
          @keyup.enter="onLoginAttempt('credentials')"
          class="login-field"
          ref="password"
        >
          <template v-slot:append v-if="credentials.password">
            <v-icon color="#333333" @click="showPassword = !showPassword">
              {{ !showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline" }}
            </v-icon>
          </template>
        </v-text-field>

        <NewButton
          id="login-btn"
          :text="$t('login.loginForm.accessButton')"
          :disabled="!valid || $auth.credentialsAuthLoading"
          :loading="$auth.credentialsAuthLoading"
          @on-click="onLoginAttempt('credentials')"
        />
        <p v-if="$auth.credentialsAuthError" class="error-credentials">
          {{ $t("login.loginForm.error.invalidCredentials") }}
        </p>
        <p v-if="$auth.credentialsServerError" class="error-credentials">
          {{ $t("login.loginForm.error.server") }}
        </p>
      </v-form>

      <SocialButton
        :loginText="$t('login.loginWithGoogle')"
        :loading="$auth.socialAuthLoading"
        id="custom-google-btn"
        :socialOption="'google'"
        @on-click="onLoginAttempt('social')"
      />

      <div class="login-anchors">
        <router-link to="signup">
          <div>{{ $t("login.createAccount") }}</div>
        </router-link>
        <router-link to="reset">
          <div>{{ $t("login.forgotPassword") }}</div>
        </router-link>
      </div>

      <RecaptchaTerms />

      <ReusableDialog
        :value="$auth.failedCaptcha"
        @display="$auth.failedCaptcha = $event"
        :buttonText="$t('base.close')"
        :title="$t('recaptcha.failValidation')"
        v-if="$auth.failedCaptcha"
      >
        <template v-slot:slotText>
          {{ $t("recaptcha.failValidationMessage") }}
          <a
            href="https://ayuda.dvacapital.com/es/articles/6980872-no-puedes-autenticarte-por-recaptcha"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t("recaptcha.failValidationRedirect") }}
          </a>
        </template>
      </ReusableDialog>
    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";
import SocialButton from "@/components/shared/SocialButton.vue";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import RecaptchaTerms from "../recaptcha/RecaptchaTerms.vue";

export default {
  name: "LoginScreen",

  components: {
    NewButton,
    SocialButton,
    RecaptchaTerms,
    ReusableDialog,
  },

  data() {
    return {
      valid: true,
      credentials: {
        email: null,
        password: null,
      },
      showPassword: false,
      emailRules: [
        v => !!v || this.$t("login.loginForm.error.emailRequired"),
        v => /.+@.+\..+/.test(v) || this.$t("login.loginForm.error.emailRequired"),
      ],
      passwordRules: [
        v => !!v || this.$t("login.loginForm.error.passwordValid"),
        v => (v && v.length > 1) || this.$t("login.loginForm.error.passwordValid"),
      ],
    };
  },

  methods: {
    async onLoginAttempt(authorizationMethod) {
      await this.$auth.login({
        authorizationMethod,
        ...(authorizationMethod === "credentials" ?
          {
            options: {
              email: this.credentials.email,
              password: this.credentials.password,
            },
          } : {}),
      });
    },
  },
};
</script>

<style scoped lang="scss">
.progress-circular {
  margin: 10px;
}

.mi-dva-logo {
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 120px;
}

.login-field + .login-field {
  margin-top: 20px;
}

#login-btn {
  margin-top: 40px;
}

.login-anchors {
  margin-top: 40px;
  text-align: center;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e0e0;
}

.login-anchors > * {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #646363;
}

.login-anchors :last-child div:last-child {
  margin-top: 20px;
}
</style>
