var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',{class:[_vm.isMobileContainer ? 'mobile-table' : 'desktop-table'],attrs:{"id":"ongoing-reinvestments-table"}},_vm._l((_vm.reinvestments),function(reinvestment){return _c('tr',{key:reinvestment.id},[(_vm.isMobileContainer)?[_c('td',[_c('div',[_c('div',{staticClass:"reinvestment-item-container"},[_c('div',[_c('div',{staticClass:"fund-icon",style:({
                  backgroundColor: _vm.$options.filters.addFundColor(
                    reinvestment.originFundSeries.fund.run,
                  ),
                })},[_c('v-icon',{attrs:{"color":"#fff","size":"10"}},[_vm._v(" "+_vm._s(_vm.getFundIcon(reinvestment.originFundSeries.fund.run))+" ")])],1),_c('span',[_vm._v(" "+_vm._s(_vm._f("fundShortName")(reinvestment.originFundSeries.fund.run))+" ")])]),_c('div',[_c('div',{staticClass:"fund-icon",style:({
                  backgroundColor: _vm.$options.filters.addFundColor(
                    reinvestment.destinationFundSeries.fund.run,
                  ),
                })},[_c('v-icon',{attrs:{"color":"#fff","size":"10"}},[_vm._v(" "+_vm._s(_vm.getFundIcon(reinvestment.destinationFundSeries.fund.run))+" ")])],1),_c('span',[_vm._v(" "+_vm._s(_vm._f("fundShortName")(reinvestment.destinationFundSeries.fund.run))+" ")])]),_c('div',[_c('v-icon',{attrs:{"color":"#333","size":"17"}},[_vm._v(" mdi-swap-horizontal-circle-outline ")]),_c('span',[_vm._v(" "+_vm._s(_vm.formatReinvestmentAmount(reinvestment))+" ")])],1)])])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.$t( `transactions.ongoingReinvestments.steps.${ _vm.getReinvestmentStatus(reinvestment).status }`, { ..._vm.getReinvestmentStatus(reinvestment).values, }, ))+" ")])])]:[_c('td',[_c('div',{staticClass:"movement-type"},[_c('v-icon',{attrs:{"color":"#333","size":"17"}},[_vm._v(" mdi-swap-horizontal-circle-outline ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("transactions.ongoingReinvestments.transfer"))+" ")])],1)]),_c('td',[_c('div',{staticClass:"reinvestment-item-container"},[_c('div',[_c('div',{staticClass:"fund-icon",style:({
                backgroundColor: _vm.$options.filters.addFundColor(
                  reinvestment.originFundSeries.fund.run,
                ),
              })},[_c('v-icon',{attrs:{"color":"#fff","size":"14"}},[_vm._v(" "+_vm._s(_vm.getFundIcon(reinvestment.originFundSeries.fund.run))+" ")])],1),_c('span',[_vm._v(" "+_vm._s(_vm._f("fundShortName")(reinvestment.originFundSeries.fund.run))+" ")])]),_c('div',[_c('div',{staticClass:"fund-icon",style:({
                backgroundColor: _vm.$options.filters.addFundColor(
                  reinvestment.destinationFundSeries.fund.run,
                ),
              })},[_c('v-icon',{attrs:{"color":"#fff","size":"14"}},[_vm._v(" "+_vm._s(_vm.getFundIcon(reinvestment.destinationFundSeries.fund.run))+" ")])],1),_c('span',[_vm._v(" "+_vm._s(_vm._f("fundShortName")(reinvestment.destinationFundSeries.fund.run))+" ")])])])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.$t( `transactions.ongoingReinvestments.steps.${ _vm.getReinvestmentStatus(reinvestment).status }`, { ..._vm.getReinvestmentStatus(reinvestment).values, }, ))+" ")])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.formatReinvestmentAmount(reinvestment))+" ")])])]],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }