<template>
  <div>
    <!-- Begin:: Navdrawer item -->
    <div class="left-toolbar-row pa-5" @click="handleRedirection()" v-if="drawerItem">
      <div class="icon-container" :class="['icon-container', { active: isActiveRoute }]">
        <v-btn icon>
          <v-icon size="25" :color="iconColor"> {{ icon }}</v-icon>
        </v-btn>
      </div>

      <div class="route-name-flex-row">
        <div class="route-name">
          {{ $t(`appbar.menu.${name}`) }}
        </div>

        <div class="notification-container" v-if="notificationCount > 0">
          <div class="notification-number">
            {{ notificationCount }}
          </div>
        </div>
      </div>
    </div>

    <!-- Begin:: Toolbar item -->
    <div v-else>
      <v-btn
        :disabled="$route.name === route"
        dark
        depressed
        class="transparent-btn"
        @click="handleRedirection()"
      >
        {{ $t(`appbar.menu.${route}`) }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftTopToolbarItem",

  props: {
    icon: String,
    route: String,
    name: String,
    notificationCount: Number,
    href: String,
    drawerItem: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    handleRedirection() {
      if (this.href) {
        window.open(this.href, "_blank");
      } else if (this.$route.name !== this.route) {
        this.$router.push({ name: this.route });
      }
    },
  },

  computed: {
    isActiveRoute() {
      return this.$route.name === this.route || this.$route.name.includes(this.route);
    },

    iconColor() {
      return this.isActiveRoute ? "#FFFFFF" : "#000000";
    },
  },
};
</script>

<style scoped lang="scss">
.transparent-btn {
  background: transparent !important;
  height: 50px;
  margin: 0 3px;
  text-transform: none !important;
  letter-spacing: normal !important;
  color: #000 !important;
}

.left-toolbar-row {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0 10px;
  &:hover {
    cursor: pointer;
    background: #f2f2f2;
    mix-blend-mode: darken;
  }
}

.route-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  color: #333333;
}

.icon-container {
  height: 40px !important;
  width: 40px !important;

  min-width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.notification-container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6d48ff;
  border-radius: 50%;
}

.notification-number {
  color: #fff;
  font-size: 11px;
}

.route-name-flex-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-container.active {
  background-color: #000000;
}
</style>
