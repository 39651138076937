<template>
  <v-btn class="social-signin-btn" @click="$emit('on-click')" :loading="loading" :disabled="disabled">
    <div class="social-logo">
      <v-img :width="30" :src="require(`@/assets/signup/${socialOption}-icon.png`)" />
    </div>
    <span class="social-signin-text">
      {{loginText}}
    </span>
  </v-btn>
</template>

<script>
export default {
  name: "SocialButton",

  props: {
    socialOption: {
      type: String,
      default: "google",
      validator(value) {
        return ["google", "facebook", "apple", "microsoft"].includes(value);
      },
    },
    loginText: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

};
</script>

<style lang="scss" scoped>

.social-signin-btn{
  height: 60px !important;
  border-radius: 10px !important;
  width: 100%;
  background-color: #ffffff !important;
  border-radius: 10px;
  border: 1px solid #000;
  height: 60px;
  box-shadow: none !important;

  &:hover{
    background-color: #fafafa !important;
  }
  .social-logo{
    margin-right: 20px;
  }
}

.social-signin-text{
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  letter-spacing: normal;
  text-transform: none;
}

</style>
