<template>
  <div class="profile-wrapper">
    <div class="profile-step-back">
      <v-icon color="#333333" size="22" @click="$router.go(-1)"> mdi-arrow-left </v-icon>
    </div>
    <h3 class="profile-title">
      {{ $t("profile.bankingData.title") }}
    </h3>

    <CustomerBankCard @toggleEditBankDialog="toggleEditBankDialog" />

    <EditBankAccountDialog
      :dialog="showEditBankDialog"
      @display="showEditBankDialog = $event"
      @onPatchSuccess="showSuccessToast()"
      v-if="showEditBankDialog"
    />

    <div class="success-toast" v-if="successToast">
      <v-scale-transition>
        <ToastNotification toastType="success" :toastText="$t('base.savedChanges')" />
      </v-scale-transition>
    </div>
  </div>
</template>
<script>
import EditBankAccountDialog from "@/components/shared/EditBankAccountDialog.vue";
import ToastNotification from "@/components/shared/ToastNotification.vue";
import CustomerBankCard from "@/components/shared/CustomerBankCard.vue";

export default {
  name: "BankingData",

  data: () => ({
    showEditBankDialog: false,
    successToast: false,
  }),

  components: {
    EditBankAccountDialog,
    ToastNotification,
    CustomerBankCard,
  },

  methods: {
    toggleEditBankDialog() {
      this.showEditBankDialog = !this.showEditBankDialog;
    },

    async showSuccessToast() {
      this.successToast = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successToast = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.success-toast {
  margin: 40px 0;
}
</style>
