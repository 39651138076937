<template>
  <div class="contract-signature-success-wrapper">
    <div class="contract-signature-success-content">
      <div class="contract-signature-success-title-column">
        <v-icon color="#6D48FF" size="46"> mdi-check-circle-outline </v-icon>
        <h2>
          {{ $t("profile.contractSignatureSuccess.title") }}
        </h2>
      </div>

      <div class="contract-signature-success-text">
        {{ $t("profile.contractSignatureSuccess.text") }}
      </div>

      <div class="finish-button-wrapper">
        <NewButton :text="$t('base.finish')" @on-click="handleSuccessRedirection()" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "ContractSignatureSuccess",

  components: { NewButton },

  computed: {
    ...mapGetters(["nextRouteFromStack"]),
  },

  methods: {
    ...mapActions(["popFromRouteStack"]),

    handleSuccessRedirection() {
      const nextRoute = this.nextRouteFromStack?.name || "home";
      const routeParams = this.nextRouteFromStack?.params;
      this.popFromRouteStack();

      this.$router.push({ name: nextRoute, ...(routeParams && { params: routeParams }) });
    },
  },
};
</script>
<style lang="scss" scoped>
h2 {
  //styleName: Subtitle/Subtitle 1 strong;
  font-family: Work Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}
.contract-signature-success-wrapper {
  display: flex;
  justify-content: center;

  margin: 120px 0;

  @media (max-width: 600px) {
    margin: 140px 40px;
  }
}
.contract-signature-success-content {
  max-width: 320px;
}
.contract-signature-success-title-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px 0;
}

.contract-signature-success-text {
  //styleName: Copy/Body copy;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 20px;
}

.finish-button-wrapper {
  margin-top: 40px;
}
</style>
