<template>
  <div class="investment-fund-card" @click="onDetailsClick">
    <div class="fund-content">
      <FundBadge
        :fundRun="fundRun"
        :size="$vuetify.breakpoint.smAndUp ? 90 : 80"
        :iconSize="$vuetify.breakpoint.smAndUp ? 45 : 40"
      />
      <div class="fund-primary-info">
        <div class="fund-name-row">
          <span class="fund-name"> {{ fundRun | fundShortName }} </span>
        </div>
        <div class="pre-description">
          {{ $t(`fund.shortDescription.${fundRun}`) }}
        </div>
        <div class="py-0 mx-1">
          <FundCategoryPills :fundRun="fundRun" />
        </div>
      </div>
    </div>

    <div class="button-row">
      <NewButton
        :text="$t('investment.investmentFundCard.details')"
        buttonClass="black-outlined"
        class="details-button"
      />

      <NewButton
        v-if="
          authenticatedStatus === 'authenticated'
            || signupStatus.completedContract
            || signupStatus.completedContractCompass
        "
        id="invest-fund-btn"
        icon="mdi-plus-circle-outline"
        :text="$t('investment.investmentFundCard.invest')"
        @on-click="onInvestClick"
        class="invest-button"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NewButton from "@/components/shared/NewButton.vue";
import FundBadge from "@/components/shared/FundBadge.vue";
import FundCategoryPills from "@/components/shared/FundCategoryPills.vue";

export default {
  name: "InvestmentFundCard",

  components: { FundBadge, FundCategoryPills, NewButton },

  props: {
    fundData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(["authenticatedStatus", "signupStatus"]),

    fundRun() {
      return this.fundData?.run || this.fundData?.fundRun;
    },
  },

  methods: {
    ...mapActions(["addToRouteStack"]),

    onDetailsClick() {
      this.$router.push({
        name: "investFundDetails",
        params: { fundRun: this.fundRun },
      });
    },

    onInvestClick() {
      // First check if the customer has the compass signature
      const hasCompassSignature = this.signupStatus.completedContractCompass;

      // If the fund is managed by compass and the customer does not have the compass signature, navigate to the compass notice page
      if (!hasCompassSignature) {
        // Add the next route to the route stack before navigating to the compass notice page
        this.addToRouteStack({
          name: "investChooseInvestmentAmount",
          params: { fundRun: this.fundRun },
        });

        this.$router.push({ name: "accountCompassSignatureNotice" });
      } else {
        // If the fund is managed by compass and the customer has the compass signature,
        // or if the fund is not managed by compass, navigate to the investment amount page

        this.$router.push({
          name: "investChooseInvestmentAmount",
          params: { fundRun: this.fundRun },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.investment-fund-card {
  container-type: inline-size;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
  max-height: 130px;
  background-color: #ffffff;
  width: 100%;
  padding: 10px;

  @media (min-width: 1264px) {
    width: 70%;
    padding: 20px;
  }

  @media (max-width: 600px) {
    height: 100px;
  }

  .fund-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    @media (max-width: 600px) {
      gap: 10px;
    }
  }

  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}

@container (max-width: 850px) {
  .button-row {
    display: none !important;
  }
}

.fund-name {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 23px;
  }

  color: #333333;
}

#invest-fund-btn {
  border-radius: 10px;
  text-transform: capitalize;
  box-shadow: none;
}

.invest-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  letter-spacing: normal;
}

.pre-description {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;

  @media (max-width: 600px) {
    line-height: 140%;
    font-size: 12px;
  }

  color: #333333;
}

::v-deep .fund-pills {
  margin-top: 10px;
  @media (max-width: 600px) {
    padding: 0;
    margin-top: 7px;
  }
}

.button-row {
  display: flex;
  justify-content: left;
  gap: 10px;
  margin-right: 20px;
}

.invest-button,
.details-button {
  width: 150px !important;
  height: 50px !important;
}

.fund-name-row {
  display: flex;
  gap: 0 7px;
}
</style>
