import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import router from "../router/index.js";

export const userProfiles = {
  mixins: [customersApiFunctions],

  methods: {
    // Set has current customer the main profile associated to given email
    async setUserMainProfile({ email, errorCallback, noAccountCallback }) {
      const {
        data: getProfilesFromEmailResponse,
        errors: getProfilesFromEmailErrors,
      } = await this.getProfilesFromEmail(email);
      const userProfiles = getProfilesFromEmailResponse.emails;
      if (getProfilesFromEmailErrors || userProfiles === null) {
        if (errorCallback) await errorCallback();
        return false;
      }
      if (userProfiles.length === 0) {
        if (noAccountCallback) await noAccountCallback();
        return false;
      }
      const { customer } = userProfiles.find(email => email.principal);
      localStorage.setItem("userId", customer.id);
      localStorage.setItem("userName", customer.name);
      localStorage.setItem("userEmail", email);
      localStorage.setItem("userIdentifier", customer.identifier);
      localStorage.setItem("profile", JSON.stringify(customer));
      localStorage.setItem("availableProfiles", JSON.stringify(userProfiles));
      return true;
    },

    // Returns selected profile
    getProfile() {
      return JSON.parse(localStorage.getItem("profile"));
    },

    getAvailableProfiles() {
      return JSON.parse(localStorage.getItem("availableProfiles")).map(emailProfile => emailProfile.customer);
    },

    // For users that have more than one account associated to the same email
    changeSelectedProfile(selectedProfile) {
      localStorage.setItem("userId", selectedProfile.id);
      localStorage.setItem("userName", selectedProfile.name);
      localStorage.setItem("userIdentifier", selectedProfile.identifier);
      localStorage.setItem("userLastName", selectedProfile.lastName);
      localStorage.setItem("profile", JSON.stringify(selectedProfile));
      router.go(router.currentRoute);
    },

    isSelectedProfile(profile) {
      return this.getProfile().id === profile.id;
    },
  },
};
