export default {
  data: () => ({
    profilingQuestionsList: [
      { order: 1, questionKey: "regardToRisk", isBonus: false },
      { order: 2, questionKey: "investmentScenario", isBonus: false },
      { order: 3, questionKey: "competition", isBonus: false },
      { order: 4, questionKey: "unemployedBeforeTravel", isBonus: false },
      { order: 5, questionKey: "savingsPercentage", isBonus: false },
      { order: 6, questionKey: "riskMeaning", isBonus: false },
      { order: 7, questionKey: "referredFrom", isBonus: true },
    ],

    answersKeys: ["veryConservative", "conservative", "risky", "veryRisky"],
    bonusKeys: ["acquitanceRelative", "socialNetworks", "influencer", "internet", "podcast", "other"],
  }),

  computed: {
    profilingQuestions() {
      return this.profilingQuestionsList.map(questionObj => ({
        question: this.$t(`profiling.questions.${questionObj.questionKey}.question`),
        order: questionObj.order,
        isBonus: questionObj.isBonus,
        answers: !questionObj.isBonus ?
          this.answersKeys.map(answer => this.$t(`profiling.questions.${questionObj.questionKey}.answerList.${answer}`)) :
          this.bonusKeys.map(answer => this.$t(`profiling.questions.${questionObj.questionKey}.answerList.${answer}`)),
      }));
    },
  },
};
