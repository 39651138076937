<template>
  <div class="profile-wrapper identification">
    <div class="profile-step-back">
      <v-icon color="#333333" size="22" @click="$router.go(-1)"> mdi-arrow-left </v-icon>
    </div>
    <h3 class="profile-title">
      {{ $t("profile.identification.title") }}
    </h3>

    <TextField
      id="name-input"
      :label="$t('profile.identification.identifier.label')"
      :value="profile.identifier"
      disabled
      class="profile-input-field"
    />

    <div class="id-document-status-label">
      {{ $t("profile.identification.idDocument.label") }}
    </div>

    <div
      class="id-document-status-pill"
      :class="[expiredOrMissingIdDocument ? 'alert-pill' : 'up-to-date-pill']"
      @click="expiredOrMissingIdDocument ? handleRedirection() : {}"
    >
      <div class="id-document-status-left-column">
        <v-icon size="18">
          {{
            expiredOrMissingIdDocument
              ? "mdi-alert-circle-outline"
              : "mdi-check-circle-outline"
          }}
        </v-icon>
        <div class="status-text">
          {{
            $t(
              `profile.identification.idDocument.${
                expiredOrMissingIdDocument ? "expired" : "upToDate"
              }`,
            )
          }}
        </div>
      </div>

      <div class="id-document-status-right-column">
        <v-icon v-if="expiredOrMissingIdDocument"> mdi-chevron-right </v-icon>
        <div class="upload-again-text" v-else @click="handleRedirection()">
          {{ $t("profile.identification.idDocument.uploadAgain") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import TextField from "@/components/shared/TextField.vue";

export default {
  name: "IdentificationView",

  components: {
    TextField,
  },

  computed: {
    ...mapGetters(["expiredOrMissingIdDocument", "profile"]),
  },

  methods: {
    ...mapActions(["addToRouteStack"]),

    handleRedirection() {
      this.addToRouteStack({ name: "accountIdentification", cancelRoute: "account" });
      this.$router.push({ name: "accountUpdateIdDocument" });
    },
  },
};
</script>

<style lang="scss" scoped>
.identification {
  max-width: 420px;
}

.expired-id-document-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  color: #333;
  margin-bottom: 10px;
  max-width: 420px;
}

.id-document-field + .id-document-field {
  margin-top: 10px;
}

.serial-number-field {
  margin-bottom: 20px;
}

.id-document-status-pill {
  height: 60px;
  padding: 20px;
  border-radius: 10px;
  gap: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.alert-pill {
  background-color: #6d48ff;
  color: #fff;

  &:hover {
    cursor: pointer;
  }

  i {
    color: #fff !important;
  }
}

.up-to-date-pill {
  background-color: #fff;
  color: #333;

  i {
    color: #35b952 !important;
  }
}

.upload-again-text {
  color: #646363 !important;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.id-document-status-left-column {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.id-document-status-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #333;
  margin: 40px 0 10px 0;
}
</style>
