import Vue from "vue";
import dayjs from "dayjs";

import { credentialsAuth } from "./credentials-auth.js";
import { socialAuth } from "./social-auth.js";

const auth = new Vue({
  mixins: [credentialsAuth, socialAuth],

  computed: {
    accessToken() {
      const credentialsAccessToken = localStorage.getItem("credentials_access_token");
      const socialAccessToken = localStorage.getItem("social_access_token");
      return credentialsAccessToken || socialAccessToken;
    },

    expiresAt() {
      const credentialsExpiresAt = localStorage.getItem("credentials_expires_at");
      const socialExpiresAt = localStorage.getItem("social_expires_at");

      return credentialsExpiresAt || socialExpiresAt;
    },
  },

  methods: {
    async login({ authorizationMethod, options }) {
      if (authorizationMethod === "credentials") {
        await this.credentialsLogin(options);
      } else if (authorizationMethod === "social") {
        await this.socialLogin();
      }
    },

    async logout() {
      if (await this.socialAuth0Client.isAuthenticated()) {
        await this.socialAuth0Client.logout();
      }
      localStorage.clear();
      window.location.replace(window.location.origin);
    },

    isUserDataAvailable() {
      return (
        localStorage.getItem("userId") &&
        localStorage.getItem("userName") &&
        localStorage.getItem("userEmail") &&
        localStorage.getItem("userIdentifier") &&
        localStorage.getItem("profile") &&
        localStorage.getItem("availableProfiles")
      );
    },

    handleTokenExpiration() {
      const tokenExpiresAt = this.expiresAt;
      if (!tokenExpiresAt) return;

      const expiresAtToDate = dayjs.unix(tokenExpiresAt / 1000);
      const currentDate = dayjs();

      const expiredToken = expiresAtToDate.isBefore(currentDate);

      if (expiredToken) this.logout();
    },

    // eslint-disable-next-line consistent-return
    async getAuthenticatedStatus() {
      let authenticated;
      if (this.credentialsAuthenticated() || this.socialAuthenticated()) {
        authenticated = true;
      }
      if (authenticated) {
        if (!this.isUserDataAvailable()) {
          return "no-profile-data";
        }
        return "authenticated";
      }
      return "not-authenticated";
    },
  },
});

export default {
  install: Vue => {
    /* eslint no-param-reassign: "error" */
    Vue.prototype.$auth = auth;
  },
};
