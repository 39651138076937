<template>
  <div class="profile-wrapper profile-centered-wrapper">
    <div class="profile-content">
      <div class="profile-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <h3 class="profile-title">
        {{ $t("profile.changePassword.title") }}
      </h3>

      <v-form class="profile-form" v-model="formValid">
        <TextField
          id="current-password"
          type="password"
          :value="currentPassword"
          :label="$t('profile.changePassword.fields.currentPassword.label')"
          :placeholder="$t('profile.changePassword.fields.currentPassword.placeholder')"
          :rules="mustCompleteRules"
          @updateValue="currentPassword = $event"
          refName="currentPassword"
          class="password-field"
        />

        <div class="recover-password-text" @click="recoverPassword()">
          {{ $t("profile.changePassword.recoverPassword") }}
        </div>

        <TextField
          id="new-password"
          type="password"
          :value="newPassword"
          :label="$t('profile.changePassword.fields.newPassword.label')"
          :placeholder="$t('profile.changePassword.fields.newPassword.placeholder')"
          :rules="mustCompleteRules"
          @updateValue="newPassword = $event"
          refName="newPassword"
          class="password-field"
        />

        <TextField
          id="confirm-new-password"
          type="password"
          :value="newPasswordConfirmation"
          :rules="confirmPasswordRules"
          :placeholder="$t('profile.changePassword.fields.newPassword.repeatPlaceholder')"
          @updateValue="newPasswordConfirmation = $event"
          refName="newPasswordConfirmation"
          class="password-field"
        />

        <div class="save-changes">
          <NewButton
            id="save-new-password-button"
            :class="{ 'success-state-btn': successState }"
            :text="successState ? $t('base.savedChanges') : $t('base.saveChanges')"
            :disabled="!formValid"
            :loading="loading"
            @on-click="updatePassword()"
          />

          <div class="profile-cancel-text" @click="$router.push({ name: 'account' })">
            {{ $t("base.cancel") }}
          </div>
        </div>
      </v-form>

      <ReusableDialog
        id="error-dialog"
        :title="$t('profile.changePassword.errorDialog.title')"
        :text="$t('profile.changePassword.errorDialog.text')"
        :value="errorDialog"
        @display="errorDialog = $event"
        v-if="errorDialog"
      />
    </div>
  </div>
</template>
<script>
import NewButton from "@/components/shared/NewButton.vue";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import TextField from "@/components/shared/TextField.vue";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";

export default {
  name: "ChangePassword",

  components: { TextField, NewButton, ReusableDialog },

  mixins: [customersApiFunctions],

  data() {
    return {
      successState: false,
      errorDialog: false,
      formValid: false,
      loading: false,
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      mustCompleteRules: [
        password => !!password || this.$t("profile.changePassword.passwordRules.mustComplete"),
      ],
      confirmPasswordRules: [
        confirmNewPassword => !!confirmNewPassword ||
          this.$t("profile.changePassword.passwordRules.emptyError"),
        confirmNewPassword => confirmNewPassword === this.newPassword ||
          this.$t("profile.changePassword.passwordRules.matchError"),
        confirmNewPassword => (confirmNewPassword &&
            confirmNewPassword.length >= 8 &&
            /^(?=.*[A-Z]).+$/.test(confirmNewPassword) &&
            /^(?=.*[a-z]).+$/.test(confirmNewPassword) &&
            /\d/.test(confirmNewPassword)) ||
          this.$t("profile.changePassword.passwordRules.creationRules"),
        confirmNewPassword => confirmNewPassword !== this.currentPassword ||
          this.$t("profile.changePassword.passwordRules.samePassword"),
      ],
    };
  },

  methods: {
    async updatePassword() {
      try {
        this.loading = true;
        const changePasswordResponse = await this.changePassword({
          oldPassword: this.currentPassword,
          newPassword: this.newPassword,
        });
        this.loading = false;

        if (changePasswordResponse?.data?.changePassword?.success) {
          await this.showSuccessState();
          this.resetPasswordFields();
          this.$router.push({ name: "account" });
        } else {
          this.errorDialog = true;
        }
      } catch (error) {
        console.error(error);
        this.errorDialog = true;
      }
    },

    async showSuccessState() {
      this.successState = true;
      await new Promise(resolve => setTimeout(resolve, 2500));
      this.successState = false;
    },

    resetPasswordFields() {
      this.currentPassword = "";
      this.newPassword = "";
      this.newPasswordConfirmation = "";
    },

    async recoverPassword() {
      if (this.loading) return;
      this.loading = true;
      const userEmail = localStorage.getItem("userEmail");
      await this.$auth.recoverPassword(userEmail?.trim(), this.onPasswordResetCallback);
      this.loading = false;
    },

    onPasswordResetCallback(err) {
      if (err) {
        this.errorDialog = true;
        console.error(err);
      } else {
        this.$router.push({ name: "accountPasswordRecoverySuccess" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.password-field + .password-field {
  margin-top: 10px;
}

.recover-password-text {
  color: #646363;
  text-align: right;
  font-size: 11px;
  font-weight: 500;
  line-height: 160%;

  margin: 10px 0 20px 0;

  &:hover {
    cursor: pointer;
  }
}

.save-changes {
  margin-top: 40px;
}

.success-state-btn {
  background-color: #6d48ff !important;
  border: unset;
}
</style>
