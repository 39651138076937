<template>
  <div class="recaptcha-terms">
    {{ $i18n.t("recaptcha.message") }}
    <a href="https://policies.google.com/privacy">
      {{ $i18n.t("recaptcha.privacy") }}
    </a>
    {{ $i18n.t("recaptcha.messageTwo") }}
    <a href="https://policies.google.com/terms">
      {{ $i18n.t("recaptcha.terms") }}
    </a>
    {{ $i18n.t("recaptcha.messageThree") }}
  </div>
</template>

<script>
export default {
  name: "RecaptchaTerms",
};
</script>

<style scoped lang="scss">
.recaptcha-terms {
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  color: #828282;
  text-align: left;
}
</style>
