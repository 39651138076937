<template>
  <div class="signup-wrapper">
    <div class="signup-content">
      <div class="screen-content">
        <v-icon size="40" color="#333">
          mdi-clock-time-four-outline
        </v-icon>

        <h2 class="signup-title">
          {{$t("signup.dataVerification.title") }}
        </h2>

        <p class="signup-text">
          {{$t("signup.dataVerification.text") }}
        </p>
      </div>

      <NewButton
        :text="$t('base.exit')"
        class="close-button"
        @on-click="$router.push({ name: 'signupDashboard' })"
      />

    </div>
  </div>
</template>

<script>
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "DataVerification",

  components: {
    NewButton,
  },

};
</script>

<style lang="scss" scoped>

.signup-wrapper{
  @media (max-width: 600px) {
    height: calc(100% - 30px);
  }
}

.screen-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

}

.signup-title{
  font-family: Work Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.8px;
  text-align: center;
  margin-top: 20px;
}

.signup-text{
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
  color: #333;
  margin: 20px 0 0 0;
}

.close-button{
  margin-top: 40px;

  @media (max-width: 600px) {
    margin: 20px 0;
  }

}

</style>
