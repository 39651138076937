import { render, staticRenderFns } from "./ChangePassword.vue?vue&type=template&id=557d2c50&scoped=true"
import script from "./ChangePassword.vue?vue&type=script&lang=js"
export * from "./ChangePassword.vue?vue&type=script&lang=js"
import style0 from "./ChangePassword.vue?vue&type=style&index=0&id=557d2c50&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "557d2c50",
  null
  
)

export default component.exports